import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { TransactionRegister } from '@hmp/models/transaction-register.model'
import { AuthService } from '@hmp/services/general/auth.service'

const log = new Logger('TransactionRegisterService')

/**
 * Đây là services dành cho TransactionRegister
 */

@Injectable({ providedIn: 'root' })
export class TransactionRegisterService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private auth: AuthService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    transaction_resgister: 'v1/transaction/register',
    approve_number: 'v1/transaction/approval-requests-number',
    approve_requests: 'v1/transaction/approval-requests',
    transaction_approve: 'v1/transaction/approve',
    transaction_reject: 'v1/transaction/reject',
    transaction_void: 'v1/transaction/void',
    transaction_detail: 'v1/transaction/register',
    transaction_summary: 'v1/transaction/summary',
    add_new_payment: 'v1/transaction/payment',
    add_new_expense: 'v1/transaction/expense',
    all_components: 'v1/budget/budget-version-components',
    all_sub_components: 'v1/budget/budget-components-list',
    upload_media: 'v1/media/upload-file',
    update_files: 'v1/transaction/files',
  }

  getApprovalNumber(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<any>(this._apiPath.approve_number, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getApprovalRequest(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<any>(this._apiPath.approve_requests, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllComponents(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<any>(this._apiPath.all_components, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllSubComponents(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<any>(this._apiPath.all_sub_components, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllTranSactionHistory(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<any>(this._apiPath.transaction_resgister, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllTranSactionSummary(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<any>(this._apiPath.transaction_summary, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  CreatePaymentTransaction(body, params?: string): Observable<any> {
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('enteredBy', this.auth.getCurrentUserName())
      .set('uid', this.auth.getCurrentUserId())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .postBudget(`${this._apiPath.add_new_payment}${pa}`, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createExpenseTransaction(body, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('enteredBy', this.auth.getCurrentUserName())
      .set('uid', this.auth.getCurrentUserId())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .postBudget(`${this._apiPath.add_new_expense}${pa}`, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id?: string): Observable<TransactionRegister> {
    return this.apiService
      .getBudget<TransactionRegister>(
        `${this._apiPath.transaction_detail}/${id}`
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateApproval(id, body?: string, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('enteredBy', this.auth.getCurrentUserName())
      .set('uid', this.auth.getCurrentUserId())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .patch(`${this._apiPath.transaction_approve}/${id}${pa}`, { body: body })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateVoid(id?: string, body?: string, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('enteredBy', this.auth.getCurrentUserName())
      .set('uid', this.auth.getCurrentUserId())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .patch(`${this._apiPath.transaction_void}/${id}${pa}`, { body: body })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateReject(id?: string, body?: string, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('enteredBy', this.auth.getCurrentUserName())
      .set('uid', this.auth.getCurrentUserId())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .patch(`${this._apiPath.transaction_reject}/${id}${pa}`, {
        body: body,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateMediaFiles(id, body?: string[]): Observable<any> {
    return this.apiService
      .patch(`${this._apiPath.update_files}/${id}`, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
