import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CashJournal, CashJournalList } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('CashJournalService')

/**
 * Đây là services dành cho CashJournalService
 */
@Injectable({ providedIn: 'root' })
export class CashJournalService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    list: 'cashjournal/cashjournal_entity',
    detail: 'cashjournal/cashjournal_entity',
    export: 'export/export_cash_journal',
  }

  /**
   * Lấy tất cả CashJournal
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[cashjournal_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<CashJournal> {
    return this.apiService
      .get<CashJournal>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res: CashJournal): Observable<any> {
    res.cid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getExport(start_date, end_date): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('start_date', start_date)
      .set('end_date', end_date)
    return this.apiService.get<any>(`${this._apiPath.export}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
