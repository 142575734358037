import {
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core'
import * as _moment from 'moment'
const moment = _moment
import { Logger } from '@hmp'
import { MomentDateAdapter } from '@angular/material-moment-adapter'

const log = new Logger('YearDatePickerComponent')

const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}
@Component({
  selector: 'app-year-date-picker',
  templateUrl: './year-date-picker.component.html',
  styleUrls: ['./year-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearDatePickerComponent implements OnInit {
  @Input() label = 'Select Year'
  @Input() current = moment()
  @Output() onChange = new EventEmitter<any>()
  constructor() {}

  ngOnInit() {}

  yearChosenMonthHandler(val, dp) {
    this.current = moment(val)
    this.onChange.emit(this.current)
    dp.close()
  }
}
