import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ExportModalComponent } from './export-modal.component'
import { WeekDatePickerComponent } from './partial/week-date-picker/week-date-picker.component'
import { MonthDatePickerComponent } from './partial/month-date-picker/month-date-picker.component'
import { YearDatePickerComponent } from './partial/year-date-picker/year-date-picker.component'
import { DayDatePickerComponent } from './partial/day-date-picker/day-date-picker.component'
@NgModule({
  declarations: [
    ExportModalComponent,
    WeekDatePickerComponent,
    MonthDatePickerComponent,
    YearDatePickerComponent,
    DayDatePickerComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatDialogModule,
    MatInputModule,
  ],
  exports: [
    ExportModalComponent,
    WeekDatePickerComponent,
    MonthDatePickerComponent,
    YearDatePickerComponent,
    DayDatePickerComponent,
  ],
  entryComponents: [ExportModalComponent],
})
export class ExportModalModule {}
