import { Injectable } from '@angular/core'
import { Role } from '../../models'
import { Logger } from '../../logger.service'
import { AuthService } from './auth.service'
import * as _ from 'lodash'

const log = new Logger('PermissionsService')
export enum RoleActionsData {
  View = 1,
  Edit = 2,
  Delete = 3,
  Create = 4,
}
/**
 * Service xử lý quyền
 */

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  roles: Role[] = []
  operationsRoles = [6, 5, 22, 7, 8, 23, 9, 19]

  constructor(private auth: AuthService) { }

  isOperationsRole(roleId) {
    if (!roleId) return false
    return this.operationsRoles.some(e => e == roleId)
  }

  setProfileRoles(roles) {
    // log.debug('setProfileRoles', roles)
    this.roles = roles
    // this.setAppNavigation()
  }

  allowRoles(roles: Role[]) {
    const newRoles = roles.filter(r => {
      switch (r.entity) {
        case 'access_device_entity':
          return false
        default:
          return true
      }
    })
    this.roles = newRoles
  }

  hasPermission(
    entity,
    roleAction: RoleActionsData = RoleActionsData.View
  ): boolean {
    const localUser = this.auth.getCurrentUser()

    if (!localUser) {
      return false
    }
    if (localUser && localUser.admin_business) {
      return true
    }
    if (localUser && (this.roles == null || this.roles.length == 0)) {
      this.roles = localUser.user_roles
    }
    const role = this.getRoleByEntity(entity)
    if (
      !role ||
      !role.entity_actions ||
      role.entity_actions.indexOf(roleAction) === -1
    ) {
      return false
    }
    return true
  }

  hasFieldPermission(entity, entityField, roleAction) {
    const localUser = this.auth.getCurrentUser()
    if (localUser && localUser.admin_business) {
      return true
    }

    const role = this.getRoleByEntity(entity)
    if (!role) {
      return false
    }

    if (!role.entity_fields) {
      return false
    }

    const roleF = role.entity_fields.find(r => r.field === entityField)
    if (!roleF || !roleF.field_actions) {
      return false
    }
    if (roleF.field_actions.indexOf(roleAction) === -1) {
      return false
    }
    return true
  }

  getRoleByEntity(entity) {
    if (!this.roles) {
      const localUser = this.auth.getLocalUserProfile()
      if (localUser) {
        this.roles = localUser && localUser.user_roles
        return this.roles.find(r => r.entity === entity)
      } else {
        return
      }
    }
    return this.roles.find(r => r.entity === entity)
  }
}
