import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  AfterViewInit,
  HostListener,
} from '@angular/core'
import { Logger } from '../logger.service'
import { NgControl } from '@angular/forms'

const log = new Logger('InputNumberDirective')
@Directive({
  selector: '[HMPNum]',
})
export class InputNumberDirective {
  @Input()
  numType: string =
    'unsigned_integer' ||
    'integer_with_negative' ||
    'unsigned_decimal' ||
    'decimal_with_negative'
  @Input()
  private regex = {
    unsigned_integer: new RegExp(/^\d+$/gm),
    integer_with_negative: new RegExp(/^([-][0-9]\d*|0)$/gm),
    // unsigned_decimal: new RegExp(/^[0-9]\d*(\.\d+)?$/gm),
    unsigned_decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g),
    decimal_with_negative: new RegExp(/^[-]?[0-9]+(\.[0-9]*){0,1}$/g),
    // decimal_with_: new RegExp(/^[-]?[1-9]\d*(\.\d+)?$/gm)
  }
  private keysToIgnores = {
    unsigned_integer: [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ],
    integer_with_negative: [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ],
    unsigned_decimal: [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ],
    decimal_with_negative: [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ],
  }
  constructor(private elem: ElementRef, private control: NgControl) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // log.debug("KEYDOWN : ", event);
    if (
      this.keysToIgnores[this.numType].indexOf(event.key) !== -1 ||
      event.key === '.'
    ) {
      return
    }
    if (this.isMacintosh()) {
      if (
        event.metaKey &&
        (event.code === 'KeyA' ||
          event.code === 'KeyC' ||
          event.code === 'KeyV' ||
          event.code === 'KeyX')
      ) {
        return
      }
    }
    if (this.isWindows()) {
      if (
        event.ctrlKey &&
        (event.code === 'KeyA' ||
          event.code === 'KeyC' ||
          event.code === 'KeyV' ||
          event.code === 'KeyX')
      ) {
        return
      }
    }
    let current: string = this.elem.nativeElement.value
    let next: string = current.concat(event.key)
    if (!String(next).match(this.regex[this.numType])) {
      event.preventDefault()
    }
  }

  // @HostListener("keypress", ["$event"])
  // handleKeypress(event: any) {
  //   if (!this.isChromeAndroid()) {
  //     log.debug("KEYPRESS: ", event);
  //   }
  // }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    // log.debug("KEYUP : ", event);
    if (event.key === '.' || event.key === '0') {
      return
    }
    let next: string = this.elem.nativeElement.value
    if (
      next &&
      next.slice(-1) != '.' &&
      String(next).match(this.regex[this.numType || 'unsigned_decimal'])
    ) {
      let val = Number(next)
      // let endComma = next.split(".")[1];
      // if (
      //   this.numType === "unsigned_integer" ||
      //   this.numType === "integer_with_negative"
      // ) {
      //   val = parseInt(next);
      // } else {
      //   Number().valueOf()
      //   val = this.round(next, endComma ? endComma.length : 0);
      // }
      this.control.control.setValue(val)
    }

    // if (next && String(next).match(this.regex[this.numType])) {
    //   let val = Number(next) || 0;
    //   this.control.control.setValue(val);
    //   event.preventDefault();
    // }
    // let current: string = this.elem.nativeElement.value;
    // let next: string = current.concat(event.key);
    // if (next && !String(next).match(this.regex[this.numType])) {
    //   event.preventDefault();
    // } else if (event.key === ".") {
    // } else {
    //   let val = next; //Number(next) || 0;
    //   this.control.control.setValue(val);
    //   event.preventDefault();
    // }
  }

  round(number, precision) {
    number = +number
    precision = precision ? +precision : 0
    if (precision == 0) {
      return Math.round(number)
    }
    var sign = 1
    if (number < 0) {
      sign = -1
      number = Math.abs(number)
    }

    // Shift
    number = number.toString().split('e')
    number = Math.round(
      +(number[0] + 'e' + (number[1] ? +number[1] + precision : precision))
    )
    // Shift back
    number = number.toString().split('e')
    return (
      +(number[0] + 'e' + (number[1] ? +number[1] - precision : -precision)) *
      sign
    )
  }

  truncDigits(inputNumber, digits) {
    let fact = Math.pow(10, digits)
    return Math.floor(inputNumber * fact) / fact
  }

  @HostListener('cut', ['$event'])
  handleCut(event: any) {
    // log.debug("handleCut : ", event);
    if (!this.isChromeAndroid()) {
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: any) {
    // log.debug("handlePaste : ", event);
    if (!this.isChromeAndroid()) {
    }
  }

  isChromeAndroid(): boolean {
    return (
      /chrome/i.test(navigator.userAgent) &&
      /android/i.test(navigator.userAgent)
    )
  }

  isMacintosh() {
    return navigator.platform.indexOf('Mac') > -1
  }

  isWindows() {
    return navigator.platform.indexOf('Win') > -1
  }
}
