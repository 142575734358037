import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { BlockRoom } from '../../../models'

const log = new Logger('BlockRoomService')

/**
 * Đây là services dành cho BlockRoom
 */
@Injectable({ providedIn: 'root' })
export class BlockRoomService {
  constructor(private apiService: ApiService) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'procurement/asset_types_entity',
    detail: 'room/blocked_entity',
    confirm_block: 'room/blocked_entity/create_reservation_from_blocked',
    update_note: 'room/blocked_entity/update_note'
  }

  getDetail(id: string): Observable<BlockRoom> {
    return this.apiService.get<BlockRoom>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res: BlockRoom): Observable<any> {
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: BlockRoom): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.bid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  confirmBlock(res): Observable<any> {
    return this.apiService.post(this._apiPath.confirm_block, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateNote(res): Observable<any> {
    return this.apiService.post(this._apiPath.update_note, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
