import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core'
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { fromEvent } from 'rxjs'
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Logger } from '@hmp'
import { Router } from '@angular/router'

const log = new Logger('QuickLaunchNavigationComponent')
@Component({
  selector: 'app-quick-launch-navigation',
  templateUrl: './quick-launch-navigation.component.html',
  styleUrls: ['./quick-launch-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLaunchNavigationComponent implements OnInit, AfterViewInit {
  isSearch = false
  filterNav: any[] = []
  navs: any
  @ViewChild('searchNav', { static: false }) searchNavElement: ElementRef
  constructor(
    private _fuseNavigationService: FuseNavigationService,
    public dialogRef: MatDialogRef<QuickLaunchNavigationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit() {
    this.isSearch = false
    this.navs = this.data.navigation
    this.filterNav = this.navs = this._fuseNavigationService.getFlatNavigation(
      this.navs
    )
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchNavElement.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value
        }),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.isSearch = true
        this.onSearchNavChange(text)
      })
  }

  displayFn(nav?: any): string | undefined {
    return nav ? nav.title : undefined
  }

  onGoToNav(nav) {
    let url = nav && nav.option && nav.option.value && nav.option.value.url
    if (url) {
      this.dialogRef.close()
      this.router.navigateByUrl(url)
    }
  }

  onSearchNavChange(value) {
    if (value === '') {
      this.isSearch = false
      this.filterNav = this.navs
      return
    }

    this.filterNav = this.navs.filter(navigationItem => {
      return navigationItem.title.toLowerCase().includes(value)
    })
  }
}
