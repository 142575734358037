import { Directive, ElementRef, HostListener } from '@angular/core'
import { Logger } from '../logger.service'

const log = new Logger('InputFocusDirective')
@Directive({
  selector: '[focusEnter]',
})
export class InputFocusDirective {
  private el: ElementRef
  constructor(private elem: ElementRef) {
    this.el = this.elem
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(e) {
    log.debug('KEYDOWN : ', e)
    // tslint:disable-next-line: triple-equals
    // if ((e.which == 13 || e.code == 'Enter')) {
    //   e.preventDefault();
    //   if (e.srcElement.nextElementSibling) {
    //     e.srcElement.nextElementSibling.focus();
    //   }
    //   else {
    //     console.log('close keyboard');
    //   }
    //   return;
    // }
  }
}
