import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { AssetOwner } from '@hmp'

const log = new Logger('AssetOwnerService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class AssetOwnerService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) { }
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    asset_owner: {
      list_asset_owner: 'manager/asset_owner_entity',
      detail: 'manager/asset_owner_entity',
    },
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[asset_owner_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.asset_owner.list_asset_owner, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<AssetOwner> {
    return this.apiService
      .get<AssetOwner>(`${this._apiPath.asset_owner.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res): Observable<any> {
    res.tid = null
    return this.apiService.post(this._apiPath.asset_owner.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: AssetOwner): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.asset_owner.detail}/${res.aid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.asset_owner.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
