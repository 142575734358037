import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Assettypes } from '../../../models/assettypes.model'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('AssettypesService')

/**
 * Đây là services dành cho Assettypes
 */
@Injectable({ providedIn: 'root' })
export class AssettypesService {
  constructor(
    private apiService: ApiService,
    private fieldInfo: FieldInfoService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'procurement/asset_types_entity',
    detail: 'procurement/asset_types_entity',
  }

  /**
   * Lấy tất cả asset type đang available
   */
  getAll(params?: any): Observable<Assettypes[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[asset_type_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<Assettypes[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Assettypes dưạ vào @id
   * @param Id ID của Assettypes
   */
  getDetail(id: string): Observable<Assettypes> {
    return this.apiService
      .get<Assettypes>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo Assettypes
   * @param res Thông tin Assettypes
   */
  create(res: Assettypes): Observable<any> {
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Assettypes
   *
   * @param res Thông tin Assettypes muốn chỉnh sửa
   */
  edit(res: Assettypes): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.atid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Assettypes
   *
   * @param id Xóa Season bằng Assettypes ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
