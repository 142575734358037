import { NgModule } from '@angular/core'

import { KeysPipe } from './keys.pipe'
import { GetByIdPipe } from './getById.pipe'
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe'
import { FilterPipe } from './filter.pipe'
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe'
import { SearchPipe } from './search.pipe'
import { SortByPipe } from './sort-by.pipe'

@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    SearchPipe,
    SortByPipe
  ],
  imports: [],
  exports: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    SearchPipe,
    SortByPipe
  ],
})
export class FusePipesModule {}
