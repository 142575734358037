import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { AccountingCodes } from '@hmp/models/accounting-codes.model'

const log = new Logger('AccountingCodesService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class AccountingCodesService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'manager/account_code_entity',
    detail: 'manager/account_code_entity',
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[ac_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: AccountingCodes): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.id}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res: AccountingCodes): Observable<any> {
    res.id = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<AccountingCodes> {
    return this.apiService
      .get<AccountingCodes>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
