import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { RevenueService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Revenue } from '../../models'

const log = new Logger('RevenueDetailResolver')
@Injectable({ providedIn: 'root' })
export class RevenueDetailResolver implements Resolve<Observable<Revenue>> {
  constructor(private resService: RevenueService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['rid']
    
    return this.resService.getDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/revenue']))
    )
  }
}
