import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { PaymentService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Payment } from '../../models'

const log = new Logger('PaymentDetailResolver')
@Injectable({ providedIn: 'root' })
export class PaymentDetailResolver implements Resolve<Observable<Payment>> {
  constructor(private paymentService: PaymentService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['pid']
    
    return this.paymentService.getDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/payment']))
    )
  }
}
