import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { InventoryMaster, EntityRef } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('InventoryMasterService')
@Injectable({ providedIn: 'root' })
export class InventoryMasterService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) { }

  private _apiPath = {
    list: 'inventorymaster/inventorymaster_entity',
    detail: 'inventorymaster/inventorymaster_entity',
    refer: 'inventorymaster/inventorymaster_list_references',
  }

  getAll(params?: any): Observable<InventoryMaster[]> {
    let pa = new HttpParams().set(
      'parameters[im_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<InventoryMaster[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllIngredients(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[im_business_id]', this.storage.getActiveLocalBusinessID())
      .set('parameters[inventory_master_type]', '1')
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllRecipes(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set(
        'parameters[im_business_id]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('parameters[inventory_master_type]', '2')
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getListRefer(iid: string, params?: any): Observable<EntityRef[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('iid', iid)
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<EntityRef[]>(this._apiPath.refer, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<InventoryMaster> {
    return this.apiService
      .get<InventoryMaster>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res: InventoryMaster): Observable<any> {
    res.iid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: InventoryMaster): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.iid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
