import {
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core'
import * as _moment from 'moment'
const moment = _moment
import { Logger } from '@hmp'
import { MomentDateAdapter } from '@angular/material-moment-adapter'

const log = new Logger('WeekDatePickerComponent')

const MY_FORMATS = {
  parse: {
    dateInput: 'MM DDD YYYY',
  },
  display: {
    dateInput: 'W MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}
@Component({
  selector: 'app-week-date-picker',
  templateUrl: './week-date-picker.component.html',
  styleUrls: ['./week-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class WeekDatePickerComponent implements OnChanges {
  @Input() label = 'Select Week'
  @Input() current = moment()
  currentVal
  @Output() onChange = new EventEmitter<any>()

  weekFilter = (d): boolean => {
    const day = moment(d).day()
    // Prevent Saturday and Sunday from being selected.
    return day === 1
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onCurrentValFormat()
  }

  onCurrentValFormat() {
    const start = moment(this.current)
      .startOf('week')
      .format('DD MMM YY')
    const end = moment(this.current)
      .endOf('week')
      .format('DD MMM YY')
    this.currentVal = `${start} to ${end}`
  }

  onDateChanges(val): void {
    this.current = val.value
    this.onCurrentValFormat()
    this.onChange.emit(this.current)
  }
}
