import { element } from 'protractor';
import { Pipe, PipeTransform } from '@angular/core'
import { HMPGlobal } from '../config/HMPGlobal'
import { AuthService } from '@hmp/services'
import { DecimalPipe } from '@angular/common'
@Pipe({
  name: 'hmpCurrency',
})
export class HMPCurrencyPipe implements PipeTransform {
  constructor(private auth: AuthService, private cp: DecimalPipe) {}
  transform(
    value: number,
    override?: boolean,
    id?: string,
    display?: string,
    digit?: string,
    prefix?: boolean,
    negativeNumber?: number
  ): any {
    const currency = this.auth.getCurrentCurrency()
    if (override === true) {
      // Custom Override
      const currencyInfo =
        id === '' ? HMPGlobal.getCurrency(currency) : HMPGlobal.getCurrency(id)
      const cDigit = digit || currencyInfo.digit
      // let nNegativeNumber = negativeNumber || currencyInfo.negativeNumber;
      const cPrefix = prefix || currencyInfo.prefix
      const cDisplay =
        display && (display === 'name' || display === 'symbol')
          ? display === 'name'
            ? currencyInfo.name
            : currencyInfo.symbol
          : currencyInfo.symbol
      const pup = this.cp.transform(value, cDigit) || '0'
      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '')
        const result = cPrefix ? `-${cDisplay} ${pup2}` : `${pup} ${cDisplay}`

        return result
      }
      const result = cPrefix ? `${cDisplay} ${pup}` : `${pup} ${cDisplay}`
      // console.log('currencyInfo Override', currencyInfo)
      return result
    } else {
      const currencyInfo = HMPGlobal.getCurrency(currency)
      // console.log('currencyInfo', currencyInfo)

      const pup = this.cp.transform(value, currencyInfo.digit) || '0'

      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '')
        const result = currencyInfo.prefix
          ? `-${currencyInfo.symbol} ${pup2}`
          : `${pup} ${currencyInfo.symbol}`

        return result
      }
      const result = currencyInfo.prefix
        ? `${currencyInfo.symbol} ${pup}`
        : `${pup} ${currencyInfo.symbol}`

      return result
    }
  }
}
