import { Injectable } from '@angular/core'
import { EncryptDescryptService } from './encryptdescrypt.service'
import { Logger } from '../logger.service'
import { LocalStorageConfig } from '../config/local.cf'
const log = new Logger('LocalStorageService')
const localConfig = new LocalStorageConfig()

/**
 * Service Xử lý lưu trữ LocalStorage
 */

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor(private secure: EncryptDescryptService) {}

  /**
   * Lưu dữ liệu vào localStorage và mã hoá nếu muốn
   * @param name Key để lưu
   * @param data Dữ liệu
   * @param isEncrypt Bạn có muốn mã hoá không?
   * @param isJson Đây là json hay đối tượng bình thường?
   */
  saveItem(
    name: string,
    data: any,
    isEncrypt: boolean = false,
    isJson: boolean = false
  ) {
    if (isEncrypt) {
      const dataEncrypt = this.secure.encrypt(data, isJson)
      localStorage.setItem(name, dataEncrypt)
    } else {
      if (isJson) {
        localStorage.setItem(name, JSON.stringify(data))
      } else {
        localStorage.setItem(name, data)
      }
    }
  }

  /**
   * Lấy dữ liệu từ localStorage
   * @param name Key muốn lấy
   * @param isEncryptData Đây có phải là dữ liệu đã mã hoá không?
   * @param isJson Đây là json hay đối tượng bình thường?
   */
  getItem(
    name: string,
    isEncryptData: boolean = false,
    isJson: boolean = false
  ) {
    const localData = localStorage.getItem(name)
    if (!localData) {
      return
    }
    if (isEncryptData) {
      return this.secure.descrypt(localData, isJson)
    }
    if (isJson) {
      try {
        return JSON.parse(localData)
      } catch (e) {
        return null
      }
    }
    return localData
  }

  /**
   * Xoá dữ liệu từ localStorage
   * @param name Tên muốn xoá
   */
  removeItem(name) {
    localStorage.removeItem(name)
  }

  /**
   * Xoá tất cả dữ liệu từ localStorage
   */
  removeAll() {
    localStorage.clear()
  }

  /**
   * USER TOKEN
   */

  /**
   * Lấy Access Token
   */
  getUserToken(): string {
    const storeToken = this.getItem(
      localConfig.localStorageKey.UserToken,
      true,
      true
    )
    if (storeToken) {
      return storeToken.access_token
    }
    return
  }

  getUserRoles() {
    const storeToken = this.getItem(
      localConfig.localStorageKey.CurrentUser,
      true,
      true
    )
    if (storeToken) {
      return storeToken.user_roles
    }
    return []
  }

  /**
   * Lấy User Refresh Token
   */
  getUserRefreshToken(): string {
    const storeToken = this.getItem(
      localConfig.localStorageKey.UserToken,
      true,
      true
    )
    if (storeToken) {
      return storeToken.refresh_token
    }
    return
  }

  /**
   * Lưu thông tin user vào localStorage
   * @param currentUser Thông tin token (access, refresh, expire) của user
   */
  saveUserToken(currentUser) {
    this.saveItem(
      localConfig.localStorageKey.UserToken,
      currentUser,
      true,
      true
    )
  }

  getNotificationToken() {
    return this.getItem(
      localConfig.localStorageKey.Notification_Token,
      true,
      true
    )
  }

  saveNotificationToken(token) {
    this.saveItem(
      localConfig.localStorageKey.Notification_Token,
      token,
      true,
      true
    )
  }

  removeNotificationToken() {
    this.removeItem(localConfig.localStorageKey.Notification_Token)
  }

  /**
   * Xoá user Token
   */
  destroyUserToken() {
    this.removeItem(localConfig.localStorageKey.UserToken)
    this.removeItem(localConfig.localStorageKey.CurrentUser)
    this.removeItem(localConfig.localStorageKey.LocalLanguage)
  }

  getActiveLocalBusinessID() {
    const bs = this.getItem('atb', true, true)
    if (bs) {
      return bs.bid
    }
    return
  }
}
