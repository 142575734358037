import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
const log = new Logger('GuestExportDatabaseService')
import * as _moment from 'moment'
import { HMPGlobal } from '@hmp/config/HMPGlobal'
import { AuthService } from '../general/auth.service'
import { DecimalPipe } from '@angular/common'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class BudgetComponentDetailExportService {
  constructor(
    private auth: AuthService,
    private cp: DecimalPipe
  ) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }
  
  onGenerateReportExcel(
    title,
    cname,
    costCenter,
    businessName,
    data: any[], 
    sheetname,
    filename,
  ) {
    const headerRow1Columns = [
      '',
      '',
      '',
      'BUDGET',
      'ACTUALS TO DATE',
      '',
      'EST. FOR COMPLETION',
      '',
      'FORECAST TOTAL COST',
      '',
      'B2A VARIANCE',
      ''
    ]
    const headerRow2Columns = [
        '',
        `${costCenter}`,
        '',
        `${this.getCurrencyInfo().name}`,
        `${this.getCurrencyInfo().name}`,
        'BGT %',
        `${this.getCurrencyInfo().name}`,
        'BGT % ',
        `${this.getCurrencyInfo().name}`,
        'BGT %',
        `${this.getCurrencyInfo().name}`,
        'BGT %'
      ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })


    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'A5', width: 10 },
      { key: 'B5', width: 10 },
      { key: 'C5', width: 15 },
      { key: 'D5', width: 15 },
      { key: 'E5', width: 15 },
      { key: 'F5', width: 15 },
      { key: 'G5', width: 15 },
      { key: 'H5', width: 15 },
      { key: 'I5', width: 15 },
      { key: 'J5', width: 15 },
      { key: 'K5', width: 15 },
      { key: 'L5', width: 15 },
    ]

    // TITLE ROW 0,1,2
    worksheet.mergeCells('B2', 'L2');
    let titleRow = worksheet.getCell('B2');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
      color: { argb: '000000' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    let date = worksheet.getCell('B3');
    const now = new Date()
    const dateNow = moment(now)
    date.value = moment(dateNow).format("DD-MMM-YYYY, HH:mm A")
    date.font = {
      name: 'Calibri',
      size: 10,
      italic: true,
      bold: false,
      color: { argb: '000000' }
    }
    date.alignment = { vertical: 'middle', horizontal: 'left' }

    let name = worksheet.getCell('B4');
    name.value = cname
    name.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
      color: { argb: '000000' }
    }
    name.alignment = { vertical: 'middle', horizontal: 'left' }

    let namedemo = worksheet.getCell('L4');
    namedemo.value = businessName;
    namedemo.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
      color: { argb: '000000' }
    }
    namedemo.alignment = { vertical: 'middle', horizontal: 'right' }

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          right: {style:'thin', color: {argb:'F3F4F3'}}
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
      if (number > 2) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
          right: {style:'thin', color: {argb:'F3F4F3'}}
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    headerRow1.height = 20
    worksheet.mergeCells('E5', 'F5');
    worksheet.mergeCells('G5', 'H5');
    worksheet.mergeCells('I5', 'J5');
    worksheet.mergeCells('K5', 'L5');
    let headerRow2 = worksheet.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'F3F4F3',
            },
            bgColor: {
              argb: 'F3F4F3',
            },
        },
        cell.border = {
            right: {style:'thin', color: {argb:'ffffff'}}
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    worksheet.getCell('B6').alignment = { horizontal: 'left', vertical: 'middle'  }
    // headerRow2.height = 20
    this.loadData(worksheet, data)
    const rowValues = [];
    rowValues[2] = 'Total';
    rowValues[4] = this.totalBudget(data);
    rowValues[5] = this.totalActuals(data);
    rowValues[6] = this.totalPercentBGTActuals(data);
    rowValues[7] = this.totalEST(data);
    rowValues[8] = this.totalPercentEST(data);
    rowValues[9] = this.totalBudgetCost(data);
    rowValues[10] = this.totalPercentCost(data);
    rowValues[11] = this.totalBudgetB2A(data);
    rowValues[12] = this.totalPercentB2A(data);
    
    let totalRow = worksheet.addRow(rowValues);
    totalRow.eachCell((cell, number) => {
      if(number == 2){
        cell.font = {
          name: 'Calibri',
          color: { argb: '428BCA' },
          size: 12,
          bold: true,
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
      if (number > 2) {
        cell.font = {
          name: 'Calibri',
          color: { argb: '428BCA' },
          size: 12,
          bold: true,
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    totalRow.getCell(4).numFmt = '#,##0.00';
    totalRow.getCell(5).numFmt = '#,##0.00';
    totalRow.getCell(7).numFmt = '#,##0.00';
    totalRow.getCell(9).numFmt = '#,##0.00';
    totalRow.getCell(11).numFmt = '#,##0.00';
      
    const lastRow = worksheet.lastRow;
    lastRow.font = {
        name: 'Calibri',
        color: { argb: '428BCA' },
        size: 12,
        bold: true,
    }
    worksheet.mergeCells('B5', 'C5');
    worksheet.mergeCells('B6', 'C6');
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  totalBudgetSummary(element: any) {
    let total: number = 0
    const budgetAmount = Number.parseFloat(element.budgetAmount) || 0;
    total = total + budgetAmount;
    return total;
  }

  totalBudget(data) {
    if(!data){
      return
    }
    let total: number = 0
    data.forEach(element => {
      const totalBudget = this.totalBudgetSummary(element)
      total = total + Number(totalBudget)
    });
    return total;
  }

  totalActualsSummary(element: any) {
    let total: number = 0
    const actualAmount = Number.parseFloat(element.actualAmount) || 0;
    total = total + actualAmount;
    return total;
  }

  totalActuals(data) {
    let total: number = 0
    data.forEach(element => {
      const totalBudget = this.totalActualsSummary(element)
      total = total + Number(totalBudget)
    });
    return total;
  }

  totalPercentBGTActuals(data){
    if(this.totalActuals(data) && this.totalBudget(data)){
      let total: number = 0;
      const totalActuals = this.totalActuals(data);
      const totalBudget = this.totalBudget(data)
      total = Number(totalActuals)/totalBudget;
      if(total > 0){
        return (total*100).toFixed(1);
      }
    }
    return `0.0`
  }

  totalESTSummary(element: any) {
    let total: number = 0
    const estimate = Number(element.estimate);
    total = total + estimate;
    return total;
  }

  totalEST(data) {
    let total: number = 0
    data.forEach(element => {
      const totalBudget = this.totalESTSummary(element)
      total = total + Number(totalBudget)
    });
    return total;
  }

  totalPercentEST(data){
    if(this.totalEST(data) && this.totalBudget(data)){
      let total: number = 0;
      total = this.totalEST(data)/this.totalBudget(data);
      if(total > 0){
        return (total*100).toFixed(1);
      }
    }
    return `0.0`
  }

  totalBudgetCostSummary(element: any) {
    let total: number = 0
    const actualAmount = Number.parseFloat(element.actualAmount) || 0;
    const estimate = Number.parseFloat(element.estimate) || 0;
    const totalCost = actualAmount + estimate
    total = total + totalCost;
    return total;
  }

  totalBudgetCost(data) {
    if(!data){
      return
    }
    let total: number = 0
    data.forEach(element => {
      const totalBudget = this.totalBudgetCostSummary(element)
      total = total + Number(totalBudget)
    });
    return total;
  }

  totalPercentCost(data){
    if(this.totalBudgetCost(data) && this.totalBudget(data)){
      let total: number = 0;
      total = this.totalBudgetCost(data)/this.totalBudget(data);
      if(total > 0){
        return (total*100).toFixed(1);
      }
    }
    return `0.0`
  }

  totalBudgetB2ASummary(element: any) {
    let total: number = 0
    const actualAmount = Number.parseFloat(element.actualAmount) || 0;
    const estimate = Number.parseFloat(element.estimate) || 0;
    const totalBud = Number.parseFloat(element.budgetAmount) || 0;
    const totalB2A = actualAmount + estimate
    total = totalB2A - Number(totalBud)
    return total;
  }

  totalBudgetB2A(data) {
    if(!data){
      return
    }
    let total: number = 0
    data.forEach(element => {
      const totalBudget = this.totalBudgetB2ASummary(element)
      total = total + Number(totalBudget)
    });
    return total;
  }

  totalPercentB2A(data){
    if(this.totalBudgetB2A(data) && this.totalBudget(data)){
      let total: number = 0;
      total = this.totalBudgetB2A(data)/this.totalBudget(data);
      return (total*100).toFixed(1);
    }
    return 0
  }

  private loadData(worksheet, data){
      
      for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn
      
      dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell('B').alignment = { horizontal: 'left' }
      dataRow.getCell('C').alignment = { horizontal: 'left' }
      dataRow.getCell('D').alignment = { horizontal: 'right' }
      dataRow.getCell('E').alignment = { horizontal: 'right' }
      dataRow.getCell('F').alignment = { horizontal: 'right' }
      dataRow.getCell('G').alignment = { horizontal: 'right' }
      dataRow.getCell('H').alignment = { horizontal: 'right' }
      dataRow.getCell('I').alignment = { horizontal: 'right' }
      dataRow.getCell('J').alignment = { horizontal: 'right' }
      dataRow.getCell('K').alignment = { horizontal: 'right' }
      dataRow.getCell('L').alignment = { horizontal: 'right' }

      dataRow.getCell('C').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('D').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('E').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('F').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('G').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('H').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('I').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('J').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('K').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell('L').border = { right: {style:'thin', color: {argb:'E0E0E0'}} }
      dataRow.getCell(4).numFmt = '#,##0.00';
      dataRow.getCell(5).numFmt = '#,##0.00';
      dataRow.getCell(7).numFmt = '#,##0.00';
      dataRow.getCell(9).numFmt = '#,##0.00';
      dataRow.getCell(11).numFmt = '#,##0.00';
      if(r.data && r.data.length > 0){
        this.loadData(worksheet, r.data)
      }
    }
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    // let name = ''
    // if(data.costCenter){
    //     name = '' + data.costCenter + '    '+this.getSpace(data.level)+ data.name
    // }else{
    //     name = data.name
    // }
    const percentBGTActual = Number(data.actualAmount)/Number(data.budgetAmount)
    const actualBGT = (percentBGTActual*100).toFixed(1)
    const percentEstimateBgt= Number(data.estimate)/Number(data.budgetAmount)
    const estimateBgt = (percentEstimateBgt*100).toFixed(1)
    const totalCost = Number(data.actualAmount) + Number(data.estimate)
    const b2F = totalCost - data.budgetAmount
    const percentForecastBgt= Number(totalCost)/Number(data.budgetAmount)
    const forecastBgt = (percentForecastBgt*100).toFixed(1)
    const percentb2fBgt= Number(b2F)/Number(data.budgetAmount)
    const b2fBgt = (percentb2fBgt*100).toFixed(1)

    const budgetAmount = this.currency(data.budgetAmount)
    const actualAmount = this.currency(data.actualAmount)
    const estimate = this.currency(data.estimate) 
    const totalCostCurrency = this.currency(totalCost)
    const b2FCurrency = this.currency(b2F)
 
    row.push('')
    row.push(data.no)
    row.push(this.getSpace(data.level) + data.name )
    row.push(budgetAmount + this.getSpaceRight(data.level))
    row.push(actualAmount + this.getSpaceRight(data.level))
    row.push(`${actualBGT}%` + this.getSpaceRight(data.level))
    row.push(estimate + this.getSpaceRight(data.level))
    row.push(`${estimateBgt}%` + this.getSpaceRight(data.level))
    row.push(totalCostCurrency + this.getSpaceRight(data.level))
    row.push(`${forecastBgt}%` + this.getSpaceRight(data.level))
    row.push(b2FCurrency + this.getSpaceRight(data.level))
    row.push(`${b2fBgt}%` + this.getSpaceRight(data.level))
    
    return row
  }

  getSpace(level){
    let space = ''
    for( let i=0; i<= level; i++){
      space = '  '+space
    }
    return space
  }

  getSpaceRight(level){
    let space = ''
    if(level > 0){
      for( let i=0; i<= level; i++){
        space = ' '+space
      }
      return space
    }
    return space = ''
  }

  private getCellFormat(isPercent = true) {
    if (isPercent) {
      return '0.0%'
    }
    return '#,##0.00'
  }

  currency(
    value: number,
    override?: boolean,
    id?: string,
    display?: string,
    digit?: string,
    prefix?: boolean,
    negativeNumber?: number
  ): any {
    const currency = this.auth.getCurrentCurrency()
    if (override === true) {
      // Custom Override
      const currencyInfo =
        id === '' ? HMPGlobal.getCurrency(currency) : HMPGlobal.getCurrency(id)
      const cDigit = digit || currencyInfo.digit
      // let nNegativeNumber = negativeNumber || currencyInfo.negativeNumber;
      const cPrefix = prefix || currencyInfo.prefix
      const cDisplay =
        display && (display === 'name' || display === 'symbol')
          ? display === 'name'
            ? currencyInfo.name
            : currencyInfo.symbol
          : currencyInfo.symbol
      const pup = this.cp.transform(value, cDigit) || '0'
      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '')
        const result = cPrefix ? `-${cDisplay} ${pup2}` : `${pup} ${cDisplay}`

        return result
      }
      const result = cPrefix ? `${cDisplay} ${pup}` : `${pup} ${cDisplay}`
      // console.log('currencyInfo Override', currencyInfo)
      return result
    } else {
      const currencyInfo = HMPGlobal.getCurrency(currency)
      // console.log('currencyInfo', currencyInfo)

      const pup = this.cp.transform(value, currencyInfo.digit) || '0'

      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '')
        const result = `-${pup2}`

        return result
      }
      const result = `${pup}`

      return result
    }
  }
}
