import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('DailyTransactionsExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class DailyTransactionsExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onDailyTransactionsExcel(title, data: any[], sheetname, filename, logo) {
    const headerRow1Columns = [
      '',
      'Transaction No',
      'Transaction Date',
      'Order No',
      'Invoice No',
      'Accounting Code',
      'Accounting Description',
      'Transaction Type',
      'Reservation No',
      'Amount DR',
      'Amount CR',
      'Division',
      'Unit No',
      'Guest Name',
      'Bill To',
      'Tax ID',
      'Tax Invoice No',
      'Receipt No',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: true,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: true }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 20 },
      { key: 'C2', width: 20 },
      { key: 'C3', width: 20 },
      { key: 'C4', width: 30 },
      { key: 'C5', width: 30 },
      { key: 'C6', width: 30 },
      { key: 'C7', width: 30 },
      { key: 'C8', width: 30 },
      { key: 'C9', width: 30 },
      { key: 'C10', width: 30 },
      { key: 'C11', width: 30 },
      { key: 'C12', width: 30 },
      { key: 'C13', width: 30 },
      { key: 'C14', width: 30 },
      { key: 'C15', width: 30 },
      { key: 'C16', width: 30 },
      { key: 'C17', width: 30 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title}`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:E4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E3:E4')
    }

    worksheet.addRow([])
    let sumRowHeader = worksheet.addRow([])
    sumRowHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 17
      dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(2).alignment = { horizontal: 'left' }
      dataRow.getCell(3).alignment = { horizontal: 'center' }
      dataRow.getCell(4).alignment = { horizontal: 'center' }
      dataRow.getCell(5).alignment = { horizontal: 'left' }
    }

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.transaction_no)
    row.push(data.Tdate)
    row.push(data.order_no)
    row.push(data.invoice_no)
    row.push(data.accounting_code)
    row.push(data.accounting_description)
    row.push(data.transaction_type)
    row.push(data.reservation_no)
    row.push(data.amount_dr)
    row.push(data.amount_cr)
    row.push(data.division)
    row.push(data.unit_no)
    row.push(data.guest_name)
    row.push(data.bill_to)
    row.push(data.tax_id)
    row.push(data.tax_invoice_no)
    row.push(data.receipt_no)
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
