import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('EventPlannerService')

/**
 * Đây là services dành cho Planner
 */

@Injectable({ providedIn: 'root' })
export class EventPlannerService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    event_calendar: 'manager/event_calendar',
    detail: 'manager/event_entity',
    event_type: 'manager/eventtype_entity',
  }

  getEventCalendar(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.event_calendar, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService.get<any>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllEventType(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[eventtype_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.event_type, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  create(res): Observable<any> {
    res.eid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }
  edit(res): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.eid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }
  delete(res): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.detail}/${(res.eid, res)}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
