import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'

const log = new Logger('VideoLibraryService')

/**
 * Đây là services dành cho VideoLibrary
 */
@Injectable({ providedIn: 'root' })
export class VideoLibraryService {
  constructor(private apiService: ApiService) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    list: 'guest_help/guest_help_entity',
  }

  /**
   * Lấy tất cả Revenue
   */
  getAll(params?: any): Observable<any[]> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
