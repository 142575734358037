import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
// import { User } from "../models";
import { UserService } from '../services'
import { Subject, BehaviorSubject } from 'rxjs'
import { Logger } from '../logger.service'
import { LocalStorageService } from '../helper'
import { LocalStorageConfig } from '../config/local.cf'
// import { AuthService } from "../../auth/auth.service";

const log = new Logger('LanguageService')
const localConfig = new LocalStorageConfig()

/**
 * Thiết lập ngôn ngữ cho App
 */
@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _defaultLanguage: string = 'en'
  private _langListen = new BehaviorSubject<string>(this._defaultLanguage)
  private _langSupport = ['en', 'vi', 'th']

  constructor(
    private translate: TranslateService,
    // private auth: AuthService,
    private userService: UserService,
    private storage: LocalStorageService
  ) {
    this.setLanguage()
  }

  private getLanguageCode(lang: string) {
    switch (lang) {
      case 'en':
        return 'en_US'
      case 'vi':
        return 'vi_VN'
      case 'th':
        return 'th_TH'
      default:
        return 'en_US'
    }
  }

  getGoogleLanguageCode(lang: string) {
    switch (lang) {
      case 'en':
        return 'en_US'
      default:
        return lang
    }
  }

  getBrowserLanguage(): string {
    return this.translate.getBrowserLang()
  }

  getCultureLanguage(): string {
    return this.getLanguageCode(this.getUserLocalLanguage())
  }

  getUserLocalLanguage(): string {
    // let curUser = this.auth.getCurrentUser();
    const lang = 'en'
    if (this._langSupport.indexOf(lang)) {
      return lang
    }
    return this._defaultLanguage
  }

  getUserProfileLanguage() {
    this.userService.userProfile().subscribe(userProfile => {
      return userProfile.language
    })
  }

  getCurrentLanguage() {
    return this.translate.currentLang
  }

  setDefaultLanguage() {
    this.translate.setDefaultLang(this._defaultLanguage)
    this.useLanguage(this._defaultLanguage)
  }

  setAppLanguage() {
    const localLang = this.getUserLocalLanguage()
    if (localLang) {
      this.useLanguage(localLang)
    } else {
      this.setDefaultLanguage()
    }
  }

  useLanguage(lang: string) {
    log.debug('Use Language: ', lang)
    ;-this.saveLocalLanguage(lang)
    this._langListen.next(lang)
  }

  private setLanguage() {
    this._langListen.subscribe(lang => {
      log.info('setLanguage ', lang)
      this.translate.use(lang)
      this.userService.saveUserProfileLanguage(lang)
    })
  }

  private saveLocalLanguage(lang: string) {
    const language = lang || this._defaultLanguage
    if (language && this._langSupport.indexOf(language)) {
      log.debug('Local Language Saved: ', language)
      // this.storage.saveUserLocalLanguage(language);
    } else {
      // this.storage.saveUserLocalLanguage(this._defaultLanguage);
    }
  }
}
