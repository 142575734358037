import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { AssetOwner } from '../../models'
import { AssetOwnerService } from '@hmp/services/hmp/asset-owner/asset-owner.service'

const log = new Logger('AssetOwnerDetailResolver')
@Injectable({ providedIn: 'root' })
export class AssetOwnerDetailResolver
  implements Resolve<Observable<AssetOwner>> {
  constructor(
    private AssetOwnerService: AssetOwnerService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['aid']
    
    return this.AssetOwnerService.getDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/asset-owner']))
    )
  }
}
