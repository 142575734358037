import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('GuestRegistrationSettingsService')

@Injectable({ providedIn: 'root' })
export class GuestRegistrationSettingsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    list: 'business/guest_registration_settings',
    action: 'business/guest_registration_settings',
  }

  getGuestRegistrationSettings(lang?:string, params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService.get(`${this._apiPath.list}/${bid}/${lang}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getGuestRegistrationSettingsReservaions(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService.get(`${this._apiPath.list}/${bid}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.action}`, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
}
