import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as _moment from 'moment'
const moment = _moment
import { Logger } from '@hmp'

const log = new Logger('ExportModalComponent')

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportModalComponent implements OnInit {
  currentExportType = 'current'
  weekClass
  currentYear = moment()
  currentMonth = moment()
  currentWeek = moment()
  fromMonth = moment()
  toMonth = moment().add(1, 'month')
  constructor(
    public dialogRef: MatDialogRef<ExportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {}

  weekFilter = (d): boolean => {
    const day = moment(d).day()
    // Prevent Saturday and Sunday from being selected.
    return day === 1
  }

  getWeekSelect(isPrefix = true) {
    let currentWeek = this.currentWeek.format('W')
    let startDate = this.currentWeek.startOf('isoWeek').format('D MMM')
    let endDate = this.currentWeek.endOf('isoWeek').format('D MMM/YY')
    if (isPrefix) {
      return `Week ${currentWeek}: (${startDate} - ${endDate})`
    } else {
      return `(${startDate} - ${endDate})`
    }
  }

  getMonthSelect(isPrefix = true) {
    if (isPrefix)
      return `Month Selected: ${this.currentMonth.format('MMMM YYYY')}`
    else return `${this.currentMonth.format('MMMM YYYY')}`
  }

  getMonthRangeSelect(isPrefix = false) {
    if (isPrefix)
      return `From: ${this.fromMonth.format(
        'MMMM YYYY'
      )} - To: ${this.toMonth.format('MMMM YYYY')}`
    else
      return `${this.fromMonth.format('MMMM YYYY')} - ${this.toMonth.format(
        'MMMM YYYY'
      )}`
  }

  getYearSelect(isPrefix = true) {
    if (isPrefix) return `Year Selected: ${this.currentYear.format('YYYY')}`
    else return `${this.currentYear.format('YYYY')}`
  }

  monthChosenYearHandler(val) {
  }

  monthChosenMonthHandler(val, dp) {
    this.currentMonth = moment(val)
    dp.close()
  }

  fromMonthChosenYearHandler(val) {
  }

  fromMonthChosenMonthHandler(val, dp) {
    this.fromMonth = moment(val)
    dp.close()
  }

  toMonthChosenYearHandler(val) {
  }

  toMonthChosenMonthHandler(val, dp) {
    this.toMonth = moment(val)
    dp.close()
  }

  yearChosenYearHandler(val, dp) {
    this.currentYear = moment(val)
    dp.close()
  }

  onWeekChanges(val) {
    this.currentWeek = val
  }

  onMonthChanges(val) {
    this.currentMonth = val
  }

  onMonthRangeFromChanges(val) {
    this.fromMonth = val
  }

  onMonthRangeToChanges(val) {
    this.toMonth = val
  }

  onYearChanges(val) {
    this.currentYear = val
  }

  onClickExport() {
    let res = {
      type: this.currentExportType,
    }
    switch (this.currentExportType) {
      case 'week':
        let startW = this.getUTCUnix(
          moment(this.currentWeek).startOf('isoWeek')
        )
        let endW = this.getUTCUnix(moment(this.currentWeek).endOf('isoWeek'))
        res['startDate'] = startW
        res['endDate'] = endW
        res['dateLabel'] = this.getWeekSelect()
        break
      case 'month':
        let startM = this.getUTCUnix(moment(this.currentMonth).startOf('month'))
        let endM = this.getUTCUnix(moment(this.currentMonth).endOf('month'))
        res['startDate'] = startM
        res['endDate'] = endM
        res['dateLabel'] = this.getMonthSelect(false)
        break

      case 'monthrange':
        let startFromM = this.getUTCUnix(
          moment(this.fromMonth).startOf('month')
        )
        let endToM = this.getUTCUnix(moment(this.toMonth).endOf('month'))
        res['startDate'] = startFromM
        res['endDate'] = endToM
        res['dateLabel'] = this.getMonthRangeSelect()
        break
      case 'year':
        let startY = this.getUTCUnix(moment(this.currentYear).startOf('year'))
        let endY = this.getUTCUnix(moment(this.currentYear).endOf('year'))
        res['startDate'] = startY
        res['endDate'] = endY
        res['dateLabel'] = this.getYearSelect(false)
        break
      default:
        break
    }
    this.dialogRef.close(res)
  }

  getUTCUnix(date) {
    return moment.utc(moment(date).format('LLL')).unix()
  }
}
