import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { ToastrService, ActiveToast } from 'ngx-toastr'
import { Notification } from '../../../models/notifications.model'

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private subject = new Subject<Notification>()
  constructor(private toastr: ToastrService) {}

  getNotification(): Observable<any> {
    return this.subject.asObservable()
  }

  success(message, duration = 2000) {
    // this.notifi(message, duration, 'success');
    return this.toast(message, '', 'success', duration)
  }

  info(message, duration = 5000) {
    // this.notifi(message, duration, 'info');
    return this.toast(message, '', 'info', duration)
  }

  error(message, duration = 4000) {
    // this.notifi(message, duration, 'error');
    return this.toast(message, '', 'error', duration)
  }
  warning(message, duration = 2000) {
    // this.notifi(message, duration, 'error');
    return this.toast(message, '', 'warning', duration)
  }

  notifi(
    message: string = '',
    duration: number = 2000,
    classContainer: string = ''
  ): void {
    this.subject.next(<Notification>{
      message: message,
      class: classContainer,
      duration: duration,
    })
  }

  clear() {
    this.subject.next()
  }

  toast(
    message = '',
    title = '',
    type: 'success' | 'error' | 'info' | 'warning' = 'success',
    duration = 3000,
    tapDismiss = false,
    progress = true,
    positionClass?
  ): ActiveToast<any> {
    const config = {
      closeButton: true,
      timeOut: duration,
      enableHtml: true,
      progressBar: progress,
      tapToDismiss: tapDismiss,
    }
    if (positionClass) {
      config['positionClass'] = positionClass
    }
    switch (type) {
      case 'error':
        return this.toastr.error(message, title, config)
      case 'success':
        return this.toastr.success(message, title, config)
      case 'warning':
        return this.toastr.warning(message, title, config)
      default:
        return this.toastr.info(message, title, config)
    }
  }
}
