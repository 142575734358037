import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { RoomRatesPrice } from '@hmp'

const log = new Logger('ChannelManagerService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class ChannelManagerService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    room_rates: {
      list_room_rates: 'channel_ota/channel_room_rates',
      detail: 'channel_ota/channel_room_rates',
      list_channel_manager: 'channel_ota/list_all_channel',
      list_base_on_rate: 'channel_ota/list_master_rates',
      calcRatePrice: 'channel_ota/list_master_rates/cal_price',
    },
    reservation: {
      ota_guest_info: 'channel_ota/ota_guest_info',
    },
    booking_group: {
      list_reservation: 'channel_ota/booking_group',
      combine_reservation: 'channel_ota/booking_group/combine_booking_group',
    },
    rate_plan: {
      list_all_room_rate_plan: 'channel_ota/ota_room_rates',
    },
    ota_log: 'channel_ota/ota_logs'
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams()
    // let pa = new HttpParams().set(
    //   "parameters[table_business_id]",
    //   this.storage.getActiveLocalBusinessID()
    // );
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.room_rates.list_room_rates, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllChannel(params?: any): Observable<any> {
    // let pa = new HttpParams()
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.room_rates.list_channel_manager, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res): Observable<any> {
    return this.apiService.post(this._apiPath.room_rates.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: RoomRatesPrice): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.room_rates.detail}/${res.rid}/${res.rateId}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  delete(res: RoomRatesPrice): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.room_rates.detail}/${res.rid}/${res.rateId}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy Info của Guest từ OTA
   * @param params Danh sách tham số muốn truyền.
   *
   */
  getGuestInfoOTA(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.reservation.ota_guest_info, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllBookingGroup(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.booking_group.list_reservation, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  combineBooking(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.booking_group.combine_reservation, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllRoomRatePlan(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.rate_plan.list_all_room_rate_plan, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getBaseOnRate(params?: any, rtID?: string): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.room_rates.list_base_on_rate, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  calcRatePrice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.room_rates.calcRatePrice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getOTAActivityLog(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.ota_log, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
