import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('DailyReportService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class POSDailyReportService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'report/daily_pos_reports',
    create: 'report/daily_pos_reports/generate',
    exportPDF: 'report/daily_pos_reports/export_pdf',
    exportExcel: 'report/daily_pos_reports/export_excel',
    send_email: 'report/daily_pos_reports/send_mail',
  }

  getDailyReport(type?, params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('type', type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res: any): Observable<any> {
    res.id = null
    return this.apiService.post(this._apiPath.create, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
  exportPDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.exportPDF,
      res,
      null,
      null,
      'blob'
    )
  }
  exportExcel(res?: any): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.exportExcel,
      res,
      null,
      null,
      'blob'
    )
  }
  sendMailDailyReport(res): Observable<any> {
    return this.apiService.post(this._apiPath.send_email, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
}
