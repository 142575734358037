import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ActivityType } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('ActivityTypeService')

/**
 * Đây là services dành cho ExpenseService
 */
@Injectable({ providedIn: 'root' })
export class ActivityTypeService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    list: 'atsc/atsc_entity',
    detail: 'atsc/atsc_entity',
  }

  /**
   * Lấy tất cả Expense
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[atsc_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Expense dưạ vào @id
   * @param Id ID của Expense
   */
  getDetail(id: string): Observable<ActivityType> {
    return this.apiService
      .get<ActivityType>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo Expense
   * @param res Thông tin Expense
   */
  create(res: ActivityType): Observable<any> {
    res.aid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Expense
   *
   * @param res Thông tin Expense muốn chỉnh sửa
   */
  edit(res: ActivityType): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.aid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Expense
   *
   * @param id Xóa Expense bằng Expense ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
