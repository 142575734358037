import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Promotions } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('PromotionsService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class PromotionsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'promotions/promotions_entity',
    detail: 'promotions/promotions_entity',
    add_discount: 'promotions/discount_entity/add_discount_for_entity',
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[promotions_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Promotions dưạ vào @id
   * @param Id ID của Promotions
   */
  getDetail(id: string): Observable<Promotions> {
    return this.apiService
      .get<Promotions>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo Promotions
   * @param res Thông tin Promotions
   */
  create(res: Promotions): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Promotions
   *
   * @param res Thông tin Promotions muốn chỉnh sửa
   */
  edit(res: Promotions): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.pid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Promotions
   *
   * @param id Xóa Promotions bằng Promotions ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  add_discount_for_entity(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_discount, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
