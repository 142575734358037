import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { ReservationService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Reservation } from '../../models/reservation.model'

const log = new Logger('ReservationResolver')
@Injectable({ providedIn: 'root' })
export class ReservationDetailResolver
  implements Resolve<Observable<Reservation>> {
  constructor(private resService: ReservationService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['rid']
    log.debug('route: ', mode)
    return this.resService.getReservationDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/reservations']))
    )
  }
}
