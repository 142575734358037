import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Maintenance, Comment } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { RoomService } from '../room/room.service'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('MaintenanceService')

/**
 * Đây là services dành cho Maintenance
 */
@Injectable({ providedIn: 'root' })
export class MaintenanceService {
  constructor(
    private apiService: ApiService,
    private fieldInfo: FieldInfoService,
    private storage: LocalStorageService
  ) { }
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'maintenance/maintenance_entity',
    list_room: 'room/room_entity',
    detail: 'maintenance/maintenance_entity',
    change_status: 'maintenance/maintenance_entity/change_status',
    // change_type: "maintenance/maintenance_entity/change_type",
    byroom: 'room/get_maintenance_for_room',
    add_mess: 'maintenance/maintenance_entity/add_message',
    comment: 'maintenance/maintenance_entity/maintenance_add_comment',
  }

  /**
   * Lấy tất cả rooms đang available
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[mt_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả rooms đang available
   */
  getMaintenanceByAccount(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả rooms đang available
   */
  getMaintenanceByRoom(roomID, params?: any): Observable<any> {
    let pa = new HttpParams().set('room_id', roomID)
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.byroom, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Reservation dưạ vào @resId
   * @param Id ID của Reservation
   */
  getDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getStatus(): Observable<any> {
    return this.fieldInfo.getFieldInfo('mt_status').pipe(
      map(data => {
        return data.values
      })
    )
  }
  getMtType(): Observable<any> {
    return this.fieldInfo.getFieldInfo('mt_type').pipe(
      map(data => {
        return data.values
      })
    )
  }
  changeStatus(res): Observable<any> {
    // res = {
    //   "mid": "102",
    //   "status": "3"
    // }
    return this.apiService.post(this._apiPath.change_status, res).pipe(
      map(data => {
        return data
      })
    )
  }
  // changeType(res): Observable<any> {
  //   return this.apiService.post(this._apiPath.change_type, res).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }

  /**
   * Tạo Maintenance
   * @param res Thông tin Maintenance
   */
  create(res: Maintenance): Observable<any> {
    res.mid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
      })
    )
  }

  createComment(res: Comment): Observable<any> {
    return this.apiService.post(this._apiPath.comment, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Thêm message
   * @param res message
   */
  addMess(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_mess, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Maintenance
   *
   * @param res Thông tin Reservation muốn chỉnh sửa
   */
  edit(res: Maintenance): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.mid}`, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Xóa thông tin Maintenance
   *
   * @param id Xóa Season bằng Maintenance ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả rooms đang available
   */
  getAllRooms(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list_room, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
