import { NgModule } from '@angular/core'
import { HMPCurrencyPipe } from './hmpcurrency.pipe'
import { HMPDatePipe } from './hmpdate.pipe'
import { EscapeHtmlPipe } from './keephtml.pipe'
import { EscapeUrlPipe } from './keepurl.pipe'
import { SafeStylePipe } from './safestyle.pipe'
import { LoadingObsPipe } from './loadingObs.pipe'
import { UnixDatePipe } from './hmp-unixdate.pipe'
import { CurrencyPipe } from './currency.pipe'
import { CurrencySymbolPipe } from './symbolcurrency.pipe'
const PIPES = [
  HMPCurrencyPipe,
  CurrencyPipe,
  HMPDatePipe,
  EscapeHtmlPipe,
  SafeStylePipe,
  LoadingObsPipe,
  EscapeUrlPipe,
  UnixDatePipe,
  CurrencySymbolPipe
]
@NgModule({
  declarations: [...PIPES],
  imports: [],
  providers: [],
  exports: [...PIPES],
})
export class HMPPipesModule {}
