import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FileManage } from '../../../models'
import { LocalStorageService } from '@hmp/helper'
import { HttpEvent, HttpHandler, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http'

const log = new Logger('UploadFilesService')

/**
 * Đây là services dành cho VendorService
 */
@Injectable({ providedIn: 'root' })
export class UploadFilesService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    upload: 'file_api/file/create_raw',
    upload_tr: 'v1/media/upload-file',
  }

  /**
   * Tạo Vendor
   * @param res Thông tin Vendor
   */
   uploadImage(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.post(this._apiPath.upload, pa).pipe(
      map(data => {
        log.debug(data)
        return
      })
    )
  }

  getOptions(headers?: HttpHeaders, params?): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    return headers;
  }

  upload(data: any, fieldImage = 'files[]', headers?: HttpHeaders): Observable<any> {
    const formData = new FormData();
    formData.append(fieldImage, data);
    // let headers = new HttpHeaders();
    return this.apiService.post(this._apiPath.upload, formData, headers).pipe(
      map(data => {
        log.debug("upload",data)
        return
      })
    )

  }

  uploadImage2(data?: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('files[]', data);
    return this.apiService.post(this._apiPath.upload, formData).pipe(
      map(data => {
        log.debug(data)
        return
      })
    )
  }

  uploadImage3(data: any, fieldImage = 'files[]'): Observable<any> {
    const headers = new HttpHeaders()
    headers.append('Skip-Content-Type', 'true');
    const formData = new FormData();
    formData.append(fieldImage, data);
    return this.apiService.post(this._apiPath.upload, formData);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let contentType
    if (request.headers.has('Content-Type'))
       contentType = request.headers.get('Content-Type');

    request = request.clone({
      setHeaders: {
        'Content-Type': (contentType != 'application/json' ? 'application/text' :  contentType)
      }
    });
    return next.handle(request);
  }

  uploadFile(data: any, fieldImage = 'files[]'): Observable<any> {
    log.debug("DATA UPLOAD", data)
    let formData = new FormData();
    if(data && data.length > 0){
      for (let i = 0; i < data.length; i++) {
        formData.append(fieldImage, data[i])
      }
    }else{
      formData.append(fieldImage, '')
    }
    
    return this.apiService.post(this._apiPath.upload, formData, {}, undefined, 'json', true, false).pipe(
        map(data => {
          log.debug("Content uploadFile",data)
          return data;
        })
      )
  }
  uploadFileTR(data: any, fieldImage = 'files[]'): Observable<any> {
    log.debug("DATA UPLOAD", data)
    let formData = new FormData();
    if(data && data.length > 0){
      for (let i = 0; i < data.length; i++) {
        formData.append(fieldImage, data[i])
      }
    }else{
      formData.append(fieldImage, '')
    }
    
    return this.apiService
      .postBudget(
        this._apiPath.upload_tr,
        formData,
        {},
        undefined,
        'json',
        true,
        false
      )
      .pipe(
        map(data => {
          log.debug('Content uploadFile', data)
          return data
        })
      )
  }
}
