import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { ChannelSettings } from '@hmp/models'
const log = new Logger('ChannelSettingsService')
@Injectable({ providedIn: 'root' })
export class ChannelSettingsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  private _apiPath = {
    channel_settings: 'reservations/res_tags_entity',
  }

  getAllChannelSettings(tag_channel?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('tag_channel', tag_channel || '')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.channel_settings, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  update(data: ChannelSettings): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.channel_settings}/${data.id}`, data)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(data: ChannelSettings): Observable<any> {
    data.id = null
    return this.apiService.post(this._apiPath.channel_settings, data).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.channel_settings}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<ChannelSettings> {
    return this.apiService
      .get<ChannelSettings>(`${this._apiPath.channel_settings}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
