import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('VarianceReportExportService')
import * as _moment from 'moment'
import { join } from 'lodash'
import { DecimalPipe } from '@angular/common'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class VarianceReportExportService {
  constructor(private auth: AuthService, private decimal: DecimalPipe) {}

  getCurrencyInfo() {
    const currentGeneral = this.auth.getBusinessGeneralInfo()
    let currency = 'usd'
    if (currentGeneral) {
      currency = currentGeneral.business_currency || 'usd'
    }
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateVarianceReportExcel(
    title,
    yearTitle,
    lastYearTtitle,
    groupData: any,
    sheetname,
    filename,
    logo,
    kpiGroup?: any[],
    isActual?,
    dataUse?: any[],
    yearSelected?: any,
    varianceType?: string
  ) {
    const headerRow1Columns = [
      '',
      '',
      'YTD',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'YTD',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'YTD',
      '%',
    ]
    const data: any[] = groupData.thisYear
    log.error('DATA EXCEL', data)
    const kpi = groupData.kpiFirst
    const workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: true }],
    })
    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 28 },
      { key: 'C2', width: 13 },
      { key: 'C3', width: 11 },
      { key: 'C4', width: 11 },
      { key: 'C5', width: 11 },
      { key: 'C6', width: 11 },
      { key: 'C7', width: 11 },
      { key: 'C8', width: 11 },
      { key: 'C9', width: 11 },
      { key: 'C10', width: 11 },
      { key: 'C11', width: 11 },
      { key: 'C12', width: 11 },
      { key: 'C13', width: 11 },
      { key: 'C14', width: 11 },
      { key: 'C15', width: 11 },
      { key: 'C16', width: 11 },
      { key: 'C17', width: 11 },
      { key: 'C18', width: 11 },
      { key: 'C19', width: 11 },
      { key: 'C20', width: 11 },
      { key: 'C21', width: 11 },
      { key: 'C22', width: 11 },
      { key: 'C23', width: 11 },
      { key: 'C24', width: 11 },
      { key: 'C25', width: 11 },
      { key: 'C26', width: 11 },
      { key: 'C27', width: 11 },
      { key: 'C28', width: 11 },
      { key: 'C29', width: 11 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    const titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    const titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:AD4')
    const titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      const logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    const headerRow2 = worksheet.addRow([])
    headerRow2.height = 20

    if (varianceType == 'yoy') {
      headerRow2.getCell(2).value = yearTitle
    }

    headerRow2.getCell(2).alignment = {
      horizontal: 'left',
      vertical: 'middle',
    }
    headerRow2.getCell(2).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      // underline: 'single',
      color: { argb: 'ff0000' },
    }
    const headerRow3 = worksheet.addRow([])
    headerRow3.height = 24
    worksheet.mergeCells('C7:O7')
    worksheet.mergeCells('P7:AB7')
    worksheet.mergeCells('AC7:AD7')
    if (varianceType == 'yoy') {
      headerRow3.getCell(5).value = yearSelected
      headerRow3.getCell(25).value = lastYearTtitle
      headerRow3.getCell(30).value =
        'YoY ' + `(${yearSelected} - ${lastYearTtitle})`
    } else {
      headerRow3.getCell(5).value = 'Actual'
      headerRow3.getCell(25).value = 'Budget'
      headerRow3.getCell(30).value = 'Actual - Budget'
    }
    headerRow3.eachCell((cell, number) => {
      if (number > 1 && number <= 15) {
        ;(cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'fcbd00',
          },
          bgColor: {
            argb: 'fcbd00',
          },
        }),
          (cell.border = {
            right: { style: 'thin', color: { argb: 'ffffff' } },
          })
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
      if (number >= 16 && number <= 28) {
        ;(cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '92d050',
          },
          bgColor: {
            argb: '92d050',
          },
        }),
          (cell.border = {
            right: { style: 'thin', color: { argb: 'ffffff' } },
          })
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
      if (number >= 29) {
        ;(cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '808080',
          },
          bgColor: {
            argb: '808080',
          },
        }),
          (cell.border = {
            right: { style: 'thin', color: { argb: 'ffffff' } },
          })
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow3.getCell(8).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    }
    headerRow3.getCell(8).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    }
    headerRow3.getCell(25).alignment = {
      horizontal: 'center',
      vertical: 'middle',
    }
    headerRow3.getCell(25).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    }
    headerRow3.getCell(30).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    }

    // worksheet.addRow([])

    const headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 2) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        if (isActual) {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          }
        }
      }
    })
    headerRow1.height = 18

    if (isActual) {
      const dataUseData = ['', '', '']
      for (let index = 1; index <= 12; index++) {
        const dataN = this.getDataUseByMonth(index, dataUse)
        dataUseData.push(dataN)
      }
      const headerRow2 = worksheet.addRow(dataUseData)
      headerRow2.font = {
        name: 'Calibri',
        size: 11,
        bold: true,
      }
      headerRow2.eachCell((cell, number) => {
        // if (number == 3) {
        //   cell.border = {
        //     bottom: { style: 'medium' },
        //   }
        // }
        if (number > 3) {
          cell.border = {
            bottom: { style: 'medium' },
          }
          cell.alignment = { horizontal: 'center', vertical: 'middle' }
          const dataN = this.getDataUseByMonth(number - 3, dataUse)
          if (dataN == 'Actual') {
            cell.font = { color: { argb: '001195E5' } }
          } else if (dataN == 'Budget') {
            cell.font = { color: { argb: '00E51111' } }
          }
        }
      })
      headerRow2.height = 22
    }
    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      const children = r.children as any[]
      const type = r.type
      switch (type) {
        case 'root':
          const dataRootRowColumn = this.generateBoBRootColumnArr(r)
          this.generateRootRow(worksheet, r, dataRootRowColumn)
          break
        case 'rootvalue':
          const dataRowColumn = this.generateBoBRowValueColumnArr(r)
          const rootvalueRow = worksheet.addRow(dataRowColumn)
          this.formatRowDefaultValue(rootvalueRow, r)
          rootvalueRow.font = {
            name: 'Calibri',
            size: 10,
            bold: true,
          }

          rootvalueRow.eachCell((cell, number) => {
            if (number > 1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'eeeeee',
                },
                bgColor: {
                  argb: 'eeeeee',
                },
              }
            }
          })

          break
        default:
          break
      }
      if (children && children.length > 0) {
        children.map(c => {
          const cType = c.type
          if (cType === 'row') {
            const arrRowColumn = this.generateBoBRowValueColumnArr(c, '    ')
            const defaultRow = worksheet.addRow(arrRowColumn)
            this.formatRowDefaultValue(defaultRow, c)
            if (c.id === 'gopmargin' || c.id === 'gross_profit_margin') {
              defaultRow.font = {
                name: 'Calibri',
                size: 10,
                bold: false,
                italic: 'true',
              }
            }
          } else if (cType === 'total') {
            const dataRowColumnArr = this.generateBoBRowValueColumnArr(
              c,
              '        '
            )
            const totalRow = worksheet.addRow(dataRowColumnArr)
            this.formatRowDefaultValue(totalRow, c)
            totalRow.font = {
              name: 'Calibri',
              size: 10,
              bold: true,
            }
            totalRow.eachCell((cell, number) => {
              if (number > 2) {
                // cell.border = {
                //   top: { style: 'thin' },
                // }
                // if (number === 3) {
                //   cell.border = {
                //     right: { style: 'thin' },
                //     top: { style: 'thin' },
                //   }
                // }
              }
            })
          }
        })
      }
    }
    for (let index = 0; index < groupData.lastYear.length; index++) {
      const r = groupData.lastYear[index]
      const children = r.children as any[]
      const type = r.type

      if (r.id === 'revenue') {
        if (children[0].id === 'room') {
          worksheet.getCell('P11').value = children[0].monthvalues['ytd']
          worksheet.getCell('Q11').value = children[0].monthvalues['jan']
          worksheet.getCell('R11').value = children[0].monthvalues['feb']
          worksheet.getCell('S11').value = children[0].monthvalues['mar']
          worksheet.getCell('T11').value = children[0].monthvalues['apr']
          worksheet.getCell('U11').value = children[0].monthvalues['may']
          worksheet.getCell('V11').value = children[0].monthvalues['jun']
          worksheet.getCell('W11').value = children[0].monthvalues['jul']
          worksheet.getCell('X11').value = children[0].monthvalues['aug']
          worksheet.getCell('Y11').value = children[0].monthvalues['sep']
          worksheet.getCell('Z11').value = children[0].monthvalues['oct']
          worksheet.getCell('AA11').value = children[0].monthvalues['nov']
          worksheet.getCell('AB11').value = children[0].monthvalues['dec']
        }
        if (children[1].id === 'fb') {
          worksheet.getCell('P12').value = children[1].monthvalues['ytd']
          worksheet.getCell('Q12').value = children[1].monthvalues['jan']
          worksheet.getCell('R12').value = children[1].monthvalues['feb']
          worksheet.getCell('S12').value = children[1].monthvalues['mar']
          worksheet.getCell('T12').value = children[1].monthvalues['apr']
          worksheet.getCell('U12').value = children[1].monthvalues['may']
          worksheet.getCell('V12').value = children[1].monthvalues['jun']
          worksheet.getCell('W12').value = children[1].monthvalues['jul']
          worksheet.getCell('X12').value = children[1].monthvalues['aug']
          worksheet.getCell('Y12').value = children[1].monthvalues['sep']
          worksheet.getCell('Z12').value = children[1].monthvalues['oct']
          worksheet.getCell('AA12').value = children[1].monthvalues['nov']
          worksheet.getCell('AB12').value = children[1].monthvalues['dec']
        }
        if (children[2].id === 'transfer') {
          worksheet.getCell('P13').value = children[2].monthvalues['ytd']
          worksheet.getCell('Q13').value = children[2].monthvalues['jan']
          worksheet.getCell('R13').value = children[2].monthvalues['feb']
          worksheet.getCell('S13').value = children[2].monthvalues['mar']
          worksheet.getCell('T13').value = children[2].monthvalues['apr']
          worksheet.getCell('U13').value = children[2].monthvalues['may']
          worksheet.getCell('V13').value = children[2].monthvalues['jun']
          worksheet.getCell('W13').value = children[2].monthvalues['jul']
          worksheet.getCell('X13').value = children[2].monthvalues['aug']
          worksheet.getCell('Y13').value = children[2].monthvalues['sep']
          worksheet.getCell('Z13').value = children[2].monthvalues['oct']
          worksheet.getCell('AA13').value = children[2].monthvalues['nov']
          worksheet.getCell('AB13').value = children[2].monthvalues['dec']
        }
        if (children[3].id === 'excursion') {
          worksheet.getCell('P14').value = children[3].monthvalues['ytd']
          worksheet.getCell('Q14').value = children[3].monthvalues['jan']
          worksheet.getCell('R14').value = children[3].monthvalues['feb']
          worksheet.getCell('S14').value = children[3].monthvalues['mar']
          worksheet.getCell('T14').value = children[3].monthvalues['apr']
          worksheet.getCell('U14').value = children[3].monthvalues['may']
          worksheet.getCell('V14').value = children[3].monthvalues['jun']
          worksheet.getCell('W14').value = children[3].monthvalues['jul']
          worksheet.getCell('X14').value = children[3].monthvalues['aug']
          worksheet.getCell('Y14').value = children[3].monthvalues['sep']
          worksheet.getCell('Z14').value = children[3].monthvalues['oct']
          worksheet.getCell('AA14').value = children[3].monthvalues['nov']
          worksheet.getCell('AB14').value = children[3].monthvalues['dec']
        }
        if (children[4].id === 'other') {
          worksheet.getCell('P15').value = children[4].monthvalues['ytd']
          worksheet.getCell('Q15').value = children[4].monthvalues['jan']
          worksheet.getCell('R15').value = children[4].monthvalues['feb']
          worksheet.getCell('S15').value = children[4].monthvalues['mar']
          worksheet.getCell('T15').value = children[4].monthvalues['apr']
          worksheet.getCell('U15').value = children[4].monthvalues['may']
          worksheet.getCell('V15').value = children[4].monthvalues['jun']
          worksheet.getCell('W15').value = children[4].monthvalues['jul']
          worksheet.getCell('X15').value = children[4].monthvalues['aug']
          worksheet.getCell('Y15').value = children[4].monthvalues['sep']
          worksheet.getCell('Z15').value = children[4].monthvalues['oct']
          worksheet.getCell('AA15').value = children[4].monthvalues['nov']
          worksheet.getCell('AB15').value = children[4].monthvalues['dec']
        }
        if (children[5].id === 'total') {
          worksheet.getCell('P16').value = children[5].monthvalues['ytd']
          worksheet.getCell('Q16').value = children[5].monthvalues['jan']
          worksheet.getCell('R16').value = children[5].monthvalues['feb']
          worksheet.getCell('S16').value = children[5].monthvalues['mar']
          worksheet.getCell('T16').value = children[5].monthvalues['apr']
          worksheet.getCell('U16').value = children[5].monthvalues['may']
          worksheet.getCell('V16').value = children[5].monthvalues['jun']
          worksheet.getCell('W16').value = children[5].monthvalues['jul']
          worksheet.getCell('X16').value = children[5].monthvalues['aug']
          worksheet.getCell('Y16').value = children[5].monthvalues['sep']
          worksheet.getCell('Z16').value = children[5].monthvalues['oct']
          worksheet.getCell('AA16').value = children[5].monthvalues['nov']
          worksheet.getCell('AB16').value = children[5].monthvalues['dec']
        }
      }
      if (r.id === 'cost_of_revenue') {
        if (children[0].id === 'room') {
          worksheet.getCell('P18').value = children[0].monthvalues['ytd']
          worksheet.getCell('Q18').value = children[0].monthvalues['jan']
          worksheet.getCell('R18').value = children[0].monthvalues['feb']
          worksheet.getCell('S18').value = children[0].monthvalues['mar']
          worksheet.getCell('T18').value = children[0].monthvalues['apr']
          worksheet.getCell('U18').value = children[0].monthvalues['may']
          worksheet.getCell('V18').value = children[0].monthvalues['jun']
          worksheet.getCell('W18').value = children[0].monthvalues['jul']
          worksheet.getCell('X18').value = children[0].monthvalues['aug']
          worksheet.getCell('Y18').value = children[0].monthvalues['sep']
          worksheet.getCell('Z18').value = children[0].monthvalues['oct']
          worksheet.getCell('AA18').value = children[0].monthvalues['nov']
          worksheet.getCell('AB18').value = children[0].monthvalues['dec']
        }
        if (children[1].id === 'fb') {
          worksheet.getCell('P19').value = children[1].monthvalues['ytd']
          worksheet.getCell('Q19').value = children[1].monthvalues['jan']
          worksheet.getCell('R19').value = children[1].monthvalues['feb']
          worksheet.getCell('S19').value = children[1].monthvalues['mar']
          worksheet.getCell('T19').value = children[1].monthvalues['apr']
          worksheet.getCell('U19').value = children[1].monthvalues['may']
          worksheet.getCell('V19').value = children[1].monthvalues['jun']
          worksheet.getCell('W19').value = children[1].monthvalues['jul']
          worksheet.getCell('X19').value = children[1].monthvalues['aug']
          worksheet.getCell('Y19').value = children[1].monthvalues['sep']
          worksheet.getCell('Z19').value = children[1].monthvalues['oct']
          worksheet.getCell('AA19').value = children[1].monthvalues['nov']
          worksheet.getCell('AB19').value = children[1].monthvalues['dec']
        }
        if (children[2].id === 'transfer') {
          worksheet.getCell('P20').value = children[2].monthvalues['ytd']
          worksheet.getCell('Q20').value = children[2].monthvalues['jan']
          worksheet.getCell('R20').value = children[2].monthvalues['feb']
          worksheet.getCell('S20').value = children[2].monthvalues['mar']
          worksheet.getCell('T20').value = children[2].monthvalues['apr']
          worksheet.getCell('U20').value = children[2].monthvalues['may']
          worksheet.getCell('V20').value = children[2].monthvalues['jun']
          worksheet.getCell('W20').value = children[2].monthvalues['jul']
          worksheet.getCell('X20').value = children[2].monthvalues['aug']
          worksheet.getCell('Y20').value = children[2].monthvalues['sep']
          worksheet.getCell('Z20').value = children[2].monthvalues['oct']
          worksheet.getCell('AA20').value = children[2].monthvalues['nov']
          worksheet.getCell('AB20').value = children[2].monthvalues['dec']
        }
        if (children[3].id === 'excursion') {
          worksheet.getCell('P21').value = children[3].monthvalues['ytd']
          worksheet.getCell('Q21').value = children[3].monthvalues['jan']
          worksheet.getCell('R21').value = children[3].monthvalues['feb']
          worksheet.getCell('S21').value = children[3].monthvalues['mar']
          worksheet.getCell('T21').value = children[3].monthvalues['apr']
          worksheet.getCell('U21').value = children[3].monthvalues['may']
          worksheet.getCell('V21').value = children[3].monthvalues['jun']
          worksheet.getCell('W21').value = children[3].monthvalues['jul']
          worksheet.getCell('X21').value = children[3].monthvalues['aug']
          worksheet.getCell('Y21').value = children[3].monthvalues['sep']
          worksheet.getCell('Z21').value = children[3].monthvalues['oct']
          worksheet.getCell('AA21').value = children[3].monthvalues['nov']
          worksheet.getCell('AB21').value = children[3].monthvalues['dec']
        }
        if (children[4].id === 'other') {
          worksheet.getCell('P22').value = children[4].monthvalues['ytd']
          worksheet.getCell('Q22').value = children[4].monthvalues['jan']
          worksheet.getCell('R22').value = children[4].monthvalues['feb']
          worksheet.getCell('S22').value = children[4].monthvalues['mar']
          worksheet.getCell('T22').value = children[4].monthvalues['apr']
          worksheet.getCell('U22').value = children[4].monthvalues['may']
          worksheet.getCell('V22').value = children[4].monthvalues['jun']
          worksheet.getCell('W22').value = children[4].monthvalues['jul']
          worksheet.getCell('X22').value = children[4].monthvalues['aug']
          worksheet.getCell('Y22').value = children[4].monthvalues['sep']
          worksheet.getCell('Z22').value = children[4].monthvalues['oct']
          worksheet.getCell('AA22').value = children[4].monthvalues['nov']
          worksheet.getCell('AB22').value = children[4].monthvalues['dec']
        }
        if (children[5].id === 'total') {
          worksheet.getCell('P23').value = children[5].monthvalues['ytd']
          worksheet.getCell('Q23').value = children[5].monthvalues['jan']
          worksheet.getCell('R23').value = children[5].monthvalues['feb']
          worksheet.getCell('S23').value = children[5].monthvalues['mar']
          worksheet.getCell('T23').value = children[5].monthvalues['apr']
          worksheet.getCell('U23').value = children[5].monthvalues['may']
          worksheet.getCell('V23').value = children[5].monthvalues['jun']
          worksheet.getCell('W23').value = children[5].monthvalues['jul']
          worksheet.getCell('X23').value = children[5].monthvalues['aug']
          worksheet.getCell('Y23').value = children[5].monthvalues['sep']
          worksheet.getCell('Z23').value = children[5].monthvalues['oct']
          worksheet.getCell('AA23').value = children[5].monthvalues['nov']
          worksheet.getCell('AB23').value = children[5].monthvalues['dec']
        }
      }
      if (r.id === 'gross_profit') {
        worksheet.getCell('P24').value = r.monthvalues['ytd']
        worksheet.getCell('Q24').value = r.monthvalues['jan']
        worksheet.getCell('R24').value = r.monthvalues['feb']
        worksheet.getCell('S24').value = r.monthvalues['mar']
        worksheet.getCell('T24').value = r.monthvalues['apr']
        worksheet.getCell('U24').value = r.monthvalues['may']
        worksheet.getCell('V24').value = r.monthvalues['jun']
        worksheet.getCell('W24').value = r.monthvalues['jul']
        worksheet.getCell('X24').value = r.monthvalues['aug']
        worksheet.getCell('Y24').value = r.monthvalues['sep']
        worksheet.getCell('Z24').value = r.monthvalues['oct']
        worksheet.getCell('AA24').value = r.monthvalues['nov']
        worksheet.getCell('AB24').value = r.monthvalues['dec']
        if (children[0].id === 'gross_profit_margin') {
          worksheet.getCell('P25').value = children[0].monthvalues['ytd']
          worksheet.getCell('Q25').value = children[0].monthvalues['jan']
          worksheet.getCell('R25').value = children[0].monthvalues['feb']
          worksheet.getCell('S25').value = children[0].monthvalues['mar']
          worksheet.getCell('T25').value = children[0].monthvalues['apr']
          worksheet.getCell('U25').value = children[0].monthvalues['may']
          worksheet.getCell('V25').value = children[0].monthvalues['jun']
          worksheet.getCell('W25').value = children[0].monthvalues['jul']
          worksheet.getCell('X25').value = children[0].monthvalues['aug']
          worksheet.getCell('Y25').value = children[0].monthvalues['sep']
          worksheet.getCell('Z25').value = children[0].monthvalues['oct']
          worksheet.getCell('AA25').value = children[0].monthvalues['nov']
          worksheet.getCell('AB25').value = children[0].monthvalues['dec']
        }
      }
      if (r.id === 'operating_expenses') {
        if (children[0].id === 'payroll') {
          worksheet.getCell('P27').value = children[0].monthvalues['ytd']
          worksheet.getCell('Q27').value = children[0].monthvalues['jan']
          worksheet.getCell('R27').value = children[0].monthvalues['feb']
          worksheet.getCell('S27').value = children[0].monthvalues['mar']
          worksheet.getCell('T27').value = children[0].monthvalues['apr']
          worksheet.getCell('U27').value = children[0].monthvalues['may']
          worksheet.getCell('V27').value = children[0].monthvalues['jun']
          worksheet.getCell('W27').value = children[0].monthvalues['jul']
          worksheet.getCell('X27').value = children[0].monthvalues['aug']
          worksheet.getCell('Y27').value = children[0].monthvalues['sep']
          worksheet.getCell('Z27').value = children[0].monthvalues['oct']
          worksheet.getCell('AA27').value = children[0].monthvalues['nov']
          worksheet.getCell('AB27').value = children[0].monthvalues['dec']
        }
        if (children[1].id === 'otherexpense') {
          worksheet.getCell('P28').value = children[1].monthvalues['ytd']
          worksheet.getCell('Q28').value = children[1].monthvalues['jan']
          worksheet.getCell('R28').value = children[1].monthvalues['feb']
          worksheet.getCell('S28').value = children[1].monthvalues['mar']
          worksheet.getCell('T28').value = children[1].monthvalues['apr']
          worksheet.getCell('U28').value = children[1].monthvalues['may']
          worksheet.getCell('V28').value = children[1].monthvalues['jun']
          worksheet.getCell('W28').value = children[1].monthvalues['jul']
          worksheet.getCell('X28').value = children[1].monthvalues['aug']
          worksheet.getCell('Y28').value = children[1].monthvalues['sep']
          worksheet.getCell('Z28').value = children[1].monthvalues['oct']
          worksheet.getCell('AA28').value = children[1].monthvalues['nov']
          worksheet.getCell('AB28').value = children[1].monthvalues['dec']
        }
        if (children[2].id === 'total') {
          worksheet.getCell('P29').value = children[2].monthvalues['ytd']
          worksheet.getCell('Q29').value = children[2].monthvalues['jan']
          worksheet.getCell('R29').value = children[2].monthvalues['feb']
          worksheet.getCell('S29').value = children[2].monthvalues['mar']
          worksheet.getCell('T29').value = children[2].monthvalues['apr']
          worksheet.getCell('U29').value = children[2].monthvalues['may']
          worksheet.getCell('V29').value = children[2].monthvalues['jun']
          worksheet.getCell('W29').value = children[2].monthvalues['jul']
          worksheet.getCell('X29').value = children[2].monthvalues['aug']
          worksheet.getCell('Y29').value = children[2].monthvalues['sep']
          worksheet.getCell('Z29').value = children[2].monthvalues['oct']
          worksheet.getCell('AA29').value = children[2].monthvalues['nov']
          worksheet.getCell('AB29').value = children[2].monthvalues['dec']
        }
      }
      if (r.id === 'gross_operating_profit') {
        worksheet.getCell('P30').value = r.monthvalues['ytd']
        worksheet.getCell('Q30').value = r.monthvalues['jan']
        worksheet.getCell('R30').value = r.monthvalues['feb']
        worksheet.getCell('S30').value = r.monthvalues['mar']
        worksheet.getCell('T30').value = r.monthvalues['apr']
        worksheet.getCell('U30').value = r.monthvalues['may']
        worksheet.getCell('V30').value = r.monthvalues['jun']
        worksheet.getCell('W30').value = r.monthvalues['jul']
        worksheet.getCell('X30').value = r.monthvalues['aug']
        worksheet.getCell('Y30').value = r.monthvalues['sep']
        worksheet.getCell('Z30').value = r.monthvalues['oct']
        worksheet.getCell('AA30').value = r.monthvalues['nov']
        worksheet.getCell('AB30').value = r.monthvalues['dec']
        if (children[0].id === 'gopmargin') {
          worksheet.getCell('P31').value = children[0].monthvalues['ytd']
          worksheet.getCell('Q31').value = children[0].monthvalues['jan']
          worksheet.getCell('R31').value = children[0].monthvalues['feb']
          worksheet.getCell('S31').value = children[0].monthvalues['mar']
          worksheet.getCell('T31').value = children[0].monthvalues['apr']
          worksheet.getCell('U31').value = children[0].monthvalues['may']
          worksheet.getCell('V31').value = children[0].monthvalues['jun']
          worksheet.getCell('W31').value = children[0].monthvalues['jul']
          worksheet.getCell('X31').value = children[0].monthvalues['aug']
          worksheet.getCell('Y31').value = children[0].monthvalues['sep']
          worksheet.getCell('Z31').value = children[0].monthvalues['oct']
          worksheet.getCell('AA31').value = children[0].monthvalues['nov']
          worksheet.getCell('AB31').value = children[0].monthvalues['dec']
        }
      }
      switch (type) {
        // case 'root':
        //   const dataRootRowColumn = this.generateBoBRootColumnArr(r)
        //   this.generateRootRow(worksheet, r, dataRootRowColumn)
        //   break
        case 'rootvalue':
          const dataRowColumn = this.generateBoBRowValueColumnArr(
            r,
            null,
            null,
            true
          )
          let currentRow
          currentRow = worksheet.getRow(25)
          currentRow = worksheet.getRow(30)
          this.formatRowDefaultValue(currentRow, r)
          currentRow.font = {
            name: 'Calibri',
            size: 10,
            bold: true,
          }

          currentRow.eachCell((cell, number) => {
            if (number > 1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'eeeeee',
                },
                bgColor: {
                  argb: 'eeeeee',
                },
              }
            }
          })

          break
        default:
          break
      }
    }
    for (let index = 0; index < groupData.yoyData.length; index++) {
      const r = groupData.yoyData[index]
      const type = r.type

      if (r.key === 'room' && r.type === 'revenue') {
        worksheet.getCell('AC11').value = r.yoy_amount
        worksheet.getCell('AD11').value = r.yoy_percent
        worksheet.getCell('AD11').numFmt = '0.0%'
      }
      if (r.key === 'fb' && r.type === 'revenue') {
        worksheet.getCell('AC12').value = r.yoy_amount
        worksheet.getCell('AD12').value = r.yoy_percent
        worksheet.getCell('AD12').numFmt = '0.0%'
      }
      if (r.key === 'transfer' && r.type === 'revenue') {
        worksheet.getCell('AC13').value = r.yoy_amount
        worksheet.getCell('AD13').value = r.yoy_percent
        worksheet.getCell('AD13').numFmt = '0.0%'
      }
      if (r.key === 'excursion' && r.type === 'revenue') {
        worksheet.getCell('AC14').value = r.yoy_amount
        worksheet.getCell('AD14').value = r.yoy_percent
        worksheet.getCell('AD14').numFmt = '0.0%'
      }
      if (r.key === 'other' && r.type === 'revenue') {
        worksheet.getCell('AC15').value = r.yoy_amount
        worksheet.getCell('AD15').value = r.yoy_percent
        worksheet.getCell('AD15').numFmt = '0.0%'
      }
      if (r.key === 'total_revenue' && r.type === 'total') {
        worksheet.getCell('AC16').value = r.yoy_amount
        worksheet.getCell('AD16').value = r.yoy_percent
        worksheet.getCell('AD16').numFmt = '0.0%'
      }
      if (r.key === 'room' && r.type === 'cost') {
        worksheet.getCell('AC18').value = r.yoy_amount
        worksheet.getCell('AD18').value = r.yoy_percent
        worksheet.getCell('AD18').numFmt = '0.0%'
      }
      if (r.key === 'fb' && r.type === 'cost') {
        worksheet.getCell('AC19').value = r.yoy_amount
        worksheet.getCell('AD19').value = r.yoy_percent
        worksheet.getCell('AD19').numFmt = '0.0%'
      }
      if (r.key === 'transfer' && r.type === 'cost') {
        worksheet.getCell('AC20').value = r.yoy_amount
        worksheet.getCell('AD20').value = r.yoy_percent
        worksheet.getCell('AD20').numFmt = '0.0%'
      }
      if (r.key === 'excursion' && r.type === 'cost') {
        worksheet.getCell('AC21').value = r.yoy_amount
        worksheet.getCell('AD21').value = r.yoy_percent
        worksheet.getCell('AD21').numFmt = '0.0%'
      }
      if (r.key === 'other' && r.type === 'cost') {
        worksheet.getCell('AC22').value = r.yoy_amount
        worksheet.getCell('AD22').value = r.yoy_percent
        worksheet.getCell('AD22').numFmt = '0.0%'
      }
      if (r.key === 'total_cost' && r.type === 'total') {
        worksheet.getCell('AC23').value = r.yoy_amount
        ;(worksheet.getCell('AD23').value = r.yoy_percent),
          (worksheet.getCell('AD23').numFmt = '0.0%')
      }
      if (r.key === 'gross_profit' && r.type === 'total') {
        worksheet.getCell('AC24').value = r.yoy_amount
        worksheet.getCell('AD24').value = r.yoy_percent
        worksheet.getCell('AD24').numFmt = '0.0%'
      }
      if (r.key === 'gross_profit_margin' && r.type === 'total') {
        worksheet.getCell('AC25').value = r.yoy_amount
        worksheet.getCell('AD25').value = r.yoy_percent
        worksheet.getCell('AD25').numFmt = '0.0%'
      }
      if (r.key === 'payroll' && r.type === 'payroll') {
        worksheet.getCell('AC27').value = r.yoy_amount
        worksheet.getCell('AD27').value = r.yoy_percent
        worksheet.getCell('AD27').numFmt = '0.0%'
      }
      if (r.key === 'otherexpense' && r.type === 'payroll') {
        worksheet.getCell('AC28').value = r.yoy_amount
        worksheet.getCell('AD28').value = r.yoy_percent
        worksheet.getCell('AD28').numFmt = '0.0%'
      }
      if (r.key === 'total_expense' && r.type === 'total') {
        worksheet.getCell('AC29').value = r.yoy_amount
        worksheet.getCell('AD29').value = r.yoy_percent
        worksheet.getCell('AD29').numFmt = '0.0%'
      }
      if (r.key === 'gop') {
        worksheet.getCell('AC30').value = r.yoy_amount
        worksheet.getCell('AD30').value = r.yoy_percent
        worksheet.getCell('AD30').numFmt = '0.0%'
      }
      if (r.key === 'gop_margin' && r.type === 'total') {
        worksheet.getCell('AC31').value = r.yoy_amount
        worksheet.getCell('AD31').value = r.yoy_percent
        worksheet.getCell('AD31').numFmt = '0.0%'
      }

      switch (type) {
        // case 'root':
        //   const dataRootRowColumn = this.generateBoBRootColumnArr(r)
        //   this.generateRootRow(worksheet, r, dataRootRowColumn)
        //   break
        case 'rootvalue':
          const dataRowColumn = this.generateBoBRowValueColumnArr(
            r,
            null,
            null,
            true
          )
          let currentRow
          currentRow = worksheet.getRow(25)
          currentRow = worksheet.getRow(30)
          this.formatRowDefaultValue(currentRow, r)
          currentRow.font = {
            name: 'Calibri',
            size: 10,
            bold: true,
          }

          currentRow.eachCell((cell, number) => {
            if (number > 1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'eeeeee',
                },
                bgColor: {
                  argb: 'eeeeee',
                },
              }
            }
          })

          break
        default:
          break
      }
    }
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    if (kpi && kpi.length > 0) {
      const kipTitle = `KEY OPERATING INDICATORS (currency in ${
        this.getCurrencyInfo().name
      })`
      const kpiRow = worksheet.addRow([' ', kipTitle])
      kpiRow.font = {
        name: 'Calibri',
        size: 11,
        bold: true,
      }
      for (let index = 2; index < 29; index++) {
        kpiRow.getCell(index).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'eeeeee',
          },
          bgColor: {
            argb: 'eeeeee',
          },
        }
      }

      for (let index = 0; index < kpi.length; index++) {
        const r = kpi[index]
        const type = r.type
        switch (type) {
          case 'root':
            const dataRootRowColumn = this.generateBoBRootColumnArr(r)
            this.generateRootRow(worksheet, r, dataRootRowColumn)
            break
          case 'row':
            const dataRowColumn = this.generateBoBRowValueColumnArr(r, '')
            const defaultRow = worksheet.addRow(dataRowColumn)
            this.formatRowDefaultValue(defaultRow, r)
            break
          default:
            break
        }
      }
    }
    if (groupData.kpiSecond && groupData.kpiSecond.length > 0) {
      const kipTitle = `KEY OPERATING INDICATORS (currency in ${
        this.getCurrencyInfo().name
      })`
      for (let index = 0; index < groupData.kpiSecond.length; index++) {
        const r = groupData.kpiSecond[index]
        worksheet.getCell('P35').value = kipTitle
        if (r.id === 'num_room') {
          worksheet.getCell('P36').value = r.monthvalues['ytd']
          worksheet.getCell('Q36').value = r.monthvalues['jan']
          worksheet.getCell('R36').value = r.monthvalues['feb']
          worksheet.getCell('S36').value = r.monthvalues['mar']
          worksheet.getCell('T36').value = r.monthvalues['apr']
          worksheet.getCell('U36').value = r.monthvalues['may']
          worksheet.getCell('V36').value = r.monthvalues['jun']
          worksheet.getCell('W36').value = r.monthvalues['jul']
          worksheet.getCell('X36').value = r.monthvalues['aug']
          worksheet.getCell('Y36').value = r.monthvalues['sep']
          worksheet.getCell('Z36').value = r.monthvalues['oct']
          worksheet.getCell('AA36').value = r.monthvalues['nov']
          worksheet.getCell('AB36').value = r.monthvalues['dec']
        }
        if (r.id === 'rns_afs') {
          worksheet.getCell('P37').value = r.monthvalues['ytd']
          worksheet.getCell('Q37').value = r.monthvalues['jan']
          worksheet.getCell('R37').value = r.monthvalues['feb']
          worksheet.getCell('S37').value = r.monthvalues['mar']
          worksheet.getCell('T37').value = r.monthvalues['apr']
          worksheet.getCell('U37').value = r.monthvalues['may']
          worksheet.getCell('V37').value = r.monthvalues['jun']
          worksheet.getCell('W37').value = r.monthvalues['jul']
          worksheet.getCell('X37').value = r.monthvalues['aug']
          worksheet.getCell('Y37').value = r.monthvalues['sep']
          worksheet.getCell('Z37').value = r.monthvalues['oct']
          worksheet.getCell('AA37').value = r.monthvalues['nov']
          worksheet.getCell('AB37').value = r.monthvalues['dec']
        }
        if (r.id === 'rns_occupied') {
          worksheet.getCell('P38').value = r.monthvalues['ytd']
          worksheet.getCell('Q38').value = r.monthvalues['jan']
          worksheet.getCell('R38').value = r.monthvalues['feb']
          worksheet.getCell('S38').value = r.monthvalues['mar']
          worksheet.getCell('T38').value = r.monthvalues['apr']
          worksheet.getCell('U38').value = r.monthvalues['may']
          worksheet.getCell('V38').value = r.monthvalues['jun']
          worksheet.getCell('W38').value = r.monthvalues['jul']
          worksheet.getCell('X38').value = r.monthvalues['aug']
          worksheet.getCell('Y38').value = r.monthvalues['sep']
          worksheet.getCell('Z38').value = r.monthvalues['oct']
          worksheet.getCell('AA38').value = r.monthvalues['nov']
          worksheet.getCell('AB38').value = r.monthvalues['dec']
        }
        if (r.id === 'paid_occupancy') {
          worksheet.getCell('P39').value = r.monthvalues['ytd']
          worksheet.getCell('Q39').value = r.monthvalues['jan']
          worksheet.getCell('R39').value = r.monthvalues['feb']
          worksheet.getCell('S39').value = r.monthvalues['mar']
          worksheet.getCell('T39').value = r.monthvalues['apr']
          worksheet.getCell('U39').value = r.monthvalues['may']
          worksheet.getCell('V39').value = r.monthvalues['jun']
          worksheet.getCell('W39').value = r.monthvalues['jul']
          worksheet.getCell('X39').value = r.monthvalues['aug']
          worksheet.getCell('Y39').value = r.monthvalues['sep']
          worksheet.getCell('Z39').value = r.monthvalues['oct']
          worksheet.getCell('AA39').value = r.monthvalues['nov']
          worksheet.getCell('AB39').value = r.monthvalues['dec']
        }
        if (r.id === 'arr') {
          worksheet.getCell('P40').value = r.monthvalues['ytd']
          worksheet.getCell('Q40').value = r.monthvalues['jan']
          worksheet.getCell('R40').value = r.monthvalues['feb']
          worksheet.getCell('S40').value = r.monthvalues['mar']
          worksheet.getCell('T40').value = r.monthvalues['apr']
          worksheet.getCell('U40').value = r.monthvalues['may']
          worksheet.getCell('V40').value = r.monthvalues['jun']
          worksheet.getCell('W40').value = r.monthvalues['jul']
          worksheet.getCell('X40').value = r.monthvalues['aug']
          worksheet.getCell('Y40').value = r.monthvalues['sep']
          worksheet.getCell('Z40').value = r.monthvalues['oct']
          worksheet.getCell('AA40').value = r.monthvalues['nov']
          worksheet.getCell('AB40').value = r.monthvalues['dec']
        }
        if (r.id === 'revpar') {
          worksheet.getCell('P41').value = r.monthvalues['ytd']
          worksheet.getCell('Q41').value = r.monthvalues['jan']
          worksheet.getCell('R41').value = r.monthvalues['feb']
          worksheet.getCell('S41').value = r.monthvalues['mar']
          worksheet.getCell('T41').value = r.monthvalues['apr']
          worksheet.getCell('U41').value = r.monthvalues['may']
          worksheet.getCell('V41').value = r.monthvalues['jun']
          worksheet.getCell('W41').value = r.monthvalues['jul']
          worksheet.getCell('X41').value = r.monthvalues['aug']
          worksheet.getCell('Y41').value = r.monthvalues['sep']
          worksheet.getCell('Z41').value = r.monthvalues['oct']
          worksheet.getCell('AA41').value = r.monthvalues['nov']
          worksheet.getCell('AB41').value = r.monthvalues['dec']
        }
      }
    }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private getCellFormat(row) {
    if (
      row.id === 'gopmargin' ||
      row.id === 'gross_profit_margin' ||
      row.id === 'paid_occupancy'
    ) {
      return '0.0%'
    }
    return '#,##0'
  }

  private getDataUseByMonth(m, dataUse) {
    if (!dataUse || dataUse.length === 0) {
      return
    }
    const isDa = dataUse.find(d => d.month == m)
    if (isDa) {
      const d = isDa.data_use as string
      const ma = d.charAt(0).toUpperCase() + d.substr(1).toLowerCase()
      return ma
    }
    return
  }

  private generateRootRow(worksheet, r, column) {
    const dataRow = worksheet.addRow(column)
    dataRow.height = 18
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(2).font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    for (let index = 2; index < 31; index++) {
      dataRow.getCell(index).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'eeeeee',
        },
        bgColor: {
          argb: 'eeeeee',
        },
      }
    }
    dataRow.eachCell((cell, number) => {
      if (number > 1) {
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'eeeeee',
          },
          bgColor: {
            argb: 'eeeeee',
          },
        }
      }
    })
    return dataRow
  }

  private formatRowDefaultValue(dataRow, r, isKpi?) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        //  ;(cell.fill = {
        //    type: 'pattern',
        //    pattern: 'solid',
        //    fgColor: {
        //      argb: 'fcbd00',
        //    },
        //    bgColor: {
        //      argb: 'fcbd00',
        //    },
        //  })
      }
    })
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    if (isKpi) {
      dataRow.getCell(2).alignment = { horizontal: 'right', vertical: 'middle' }
    }
    dataRow.getCell(3).numFmt = this.getCellFormat(r)
    // dataRow.getCell(3).border = {
    //   right: { style: 'thin' },
    // }
    if (r.id === 'gross_profit') {
      for (let index = 16; index < 31; index++) {
        dataRow.getCell(index).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'eeeeee',
          },
          bgColor: {
            argb: 'eeeeee',
          },
        }
      }
    }

    dataRow.getCell(4).numFmt = this.getCellFormat(r)
    dataRow.getCell(5).numFmt = this.getCellFormat(r)
    dataRow.getCell(6).numFmt = this.getCellFormat(r)
    dataRow.getCell(7).numFmt = this.getCellFormat(r)
    dataRow.getCell(8).numFmt = this.getCellFormat(r)
    dataRow.getCell(9).numFmt = this.getCellFormat(r)
    dataRow.getCell(10).numFmt = this.getCellFormat(r)
    dataRow.getCell(11).numFmt = this.getCellFormat(r)
    dataRow.getCell(12).numFmt = this.getCellFormat(r)
    dataRow.getCell(13).numFmt = this.getCellFormat(r)
    dataRow.getCell(14).numFmt = this.getCellFormat(r)
    dataRow.getCell(15).numFmt = this.getCellFormat(r)
    dataRow.getCell(16).numFmt = this.getCellFormat(r)
    dataRow.getCell(17).numFmt = this.getCellFormat(r)
    dataRow.getCell(18).numFmt = this.getCellFormat(r)
    dataRow.getCell(19).numFmt = this.getCellFormat(r)
    dataRow.getCell(20).numFmt = this.getCellFormat(r)
    dataRow.getCell(21).numFmt = this.getCellFormat(r)
    dataRow.getCell(22).numFmt = this.getCellFormat(r)
    dataRow.getCell(23).numFmt = this.getCellFormat(r)
    dataRow.getCell(24).numFmt = this.getCellFormat(r)
    dataRow.getCell(25).numFmt = this.getCellFormat(r)
    dataRow.getCell(26).numFmt = this.getCellFormat(r)
    dataRow.getCell(27).numFmt = this.getCellFormat(r)
    dataRow.getCell(28).numFmt = this.getCellFormat(r)
    dataRow.getCell(29).numFmt = this.getCellFormat(r)
    dataRow.getCell(30).numFmt = this.getCellFormat(r)
    return dataRow
  }

  private generateBoBRootColumnArr(data) {
    const row = []
    row.push('')
    row.push(data.title)
    row.push('')
    row.push('')
    return row
  }

  private generateBoBRowValueColumnArr(
    data,
    prefixText?,
    isYtd = false,
    isLastYear = false
  ) {
    const monthValue = data.monthvalues
    const row = []
    // log.error(monthValue)
    if (!isYtd) {
      row.push('')
      if (!isLastYear) {
        if (prefixText) {
          row.push(prefixText + data.title)
        } else {
          row.push(data.title)
        }
      }
    }
    row.push(monthValue['ytd'])
    if (!isYtd) {
      for (let index = 1; index <= 12; index++) {
        const keyVal = index.toString()
        const keyName = this.getKeyByMonthNumber(keyVal)
        row.push(monthValue[keyName])
      }
    } else {
      // Xu ly data cua row tiep theo
      row.push('1')
      row.push('2')
    }

    return row
  }

  private getKeyByMonthNumber(m) {
    switch (m) {
      case '1':
        return 'jan'
      case '2':
        return 'feb'
      case '3':
        return 'mar'
      case '4':
        return 'apr'
      case '5':
        return 'may'
      case '6':
        return 'jun'
      case '7':
        return 'jul'
      case '8':
        return 'aug'
      case '9':
        return 'sep'
      case '10':
        return 'oct'
      case '11':
        return 'nov'
      case '12':
        return 'dec'
      default:
        return 'jan'
    }
  }
}
