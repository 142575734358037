import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import { Observer, Observable } from 'rxjs'

const log = new Logger('UltilitiesService')

@Injectable({ providedIn: 'root' })
export class UltilitiesService {
  constructor() { }

  getBase64ImageFromURL(url: string): Observable<string> {
    // tslint:disable-next-line: deprecation
    return Observable.create((observer: Observer<string>) => {

      // create an image object
      const img = new Image()
      if (/^([\w]+\:)?\/\//.test(url) && url.indexOf(location.host) === -1) {
        img.crossOrigin = 'anonymous' // or "use-credentials"
      }
      img.src = url
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img))
          observer.complete()
        }
        img.onerror = err => {
          observer.error(err)
        }
      } else {
        observer.next(this.getBase64Image(img))
        observer.complete()
      }
    })
  }

  convertURLToBytesXML = (image, callback) => {
    let request = new XMLHttpRequest();
    request.onload = () => {
      let reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(request.response);
    };
    request.open('GET', image);
    request.responseType = 'blob';
    request.send();
  };

  getBase64FromURLXML(url) {
    return new Promise((res, rej) => {
      this.convertURLToBytesXML(url, (base64) => {
        res(base64)
      })
    });
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const maxHeight = 56
    const canvas = document.createElement('canvas')

    let height = img.height
    let width = img.width

    // calculate the width and height, constraining the proportions
    if (height > maxHeight) {
      width = Math.round((width *= maxHeight / height))
      height = maxHeight
    }

    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(img, 0, 0, width, height)

    // Convert the drawn image to Data URL
    const dataURL = canvas.toDataURL('image/png')
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
  }
}
