import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import * as fs from 'file-saver'
import {
  CalendarByRoomtype,
  CalendarData,
  CalendarResponseByRoomtype,
  CalendarResponseByRoomtypeDataRoom,
  CalendarStatistic,
  Reservation,
  Room,
} from '../../../models'
import { HttpParams, HttpClient } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
const log = new Logger('ReservationService')

/**
 * Đây là services dành cho Reservation
 */

@Injectable({ providedIn: 'root' })
export class ReservationService {
  isFirstLoadListReservation = true
  constructor(
    private apiService: ApiService,
    private _http: HttpClient,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'reservations/reservations_entity',
    list_by_date: 'reservations/res_by_date',
    list_by_date_new: 'reservations/list_reservations',
    create: 'reservations/reservations_entity',
    detail: 'reservations/reservations_entity',
    update_note: 'reservations/reservations_entity/update_reservations_notes',
    check: 'reservations/room_business/check',
    res_log: 'reservations/reservations_log_entity',
    overview: 'reservations/reservations_overview',
    changedate: 'reservations/reservations_modify/add_days',
    change_date_day_use: 'reservations/reservations_entity/day_use_change_time',
    changeAgent: 'reservations/reservations_entity/change_agent',
    changeroom: 'reservations/reservations_modify/room_change',
    changeRoomType:
      'reservations/reservations_modify/change_room_type_unallocated',
    changechannel: 'reservations/reservations_import_update/change_channel',
    setroompax: 'reservations/reservations_entity/change_room_pax',
    customprice: 'reservations/reservations_modify/custom_price_room',
    add_unallocated_room:
      'reservations/reservations_modify/add_room_for_unallocated',
    list_unallocated_room: 'reservations/list_room_from_reservations',
    list_new_room_change: 'reservations/reservations_list_room_available',
    rooms_available: 'reservations/reservations_room_list',
    reservations_room_day_use: 'reservations/reservations_room_day_use',
    changeStatus: 'reservations/reservations_entity/change_status',
    calendar: 'reservations/reservations_calendar',
    roomcalendar: 'reservations/booking_calendar',
    discount_offer: 'reservations/reservations_modify/discount_offer',
    remove_discount_offer:
      'reservations/reservations_modify/remove_discount_offer',
    list_reservations_arrival: 'reservations/reservations_arrival',
    birthdays: 'business/get_hr_account_birthday',
    add_guest: 'reservations/reservations_guest_stay/add_guest_stay',
    view_info_room: 'reservations/reservations_guest_stay',
    transfer_today: 'business/transfer_coming',
    excusion_today: 'business/excursion_coming',
    create_packages_reservation: 'reservations/reservations_packages/create',
    invoice: 'reservations/reservation_invoice',
    credit_note: 'reservations/reservation_invoice/credit_note_create',
    reservation_invoice_element: 'reservations/reservation_invoice_element',
    create_invoice: 'reservations/reservations_invoice/invoice_create',
    reservation_room:
      'reservations/reservations_reservationroom/change_reservation_room_status',
    export: {
      reservation_list: 'export/export_reservations',
      reservation_activities: 'export/export_activities',
    },
    outoforder: 'room/closed_entity',
    export_pdf: 'reservations/reservations_invoice/export_pdf',
    send_email_pdf: 'reservations/reservations_invoice/send_mail',
    export_invoice_pdf: 'reservations/reservation_tax_invoice/export_pdf',
    export_receipt_pdf: 'reservations/reservations_entity/export_pdf',
    guest: {
      qrcode: 'guest/guest_account_res/qrcode',
      check_login: 'guest/guest_account_res/check_login_complete',
      check_in_form: 'guest/reservation_guest_checkin',
    },
    confirm_res_directbooking:
      'public/public_book_reservations/direct_booking_staff_confirm',
    sendmailresdetail:
      'reservations/reservations_entity/send_email_reservation_detail',
    list_accouting_codes: 'manager/account_code_entity',
    edit_accounting_code:
      'reservations/reservations_entity/edit_accounting_codes',
    reservations_search_name: 'reservations/reservations_search_name',
    reservations_group: 'reservations/reservations_group',
    calendar_daily_price: 'reservations/ratedaily_entity',
    calendar_daily_price_range:
      'reservations/ratedaily_entity/set_price_date_range',
    room_daily_price: 'reservations/reservations_modify/change_room_day_price',
    tax_invoice_res: {
      tax_invoice: 'reservations/reservation_tax_invoice',
      sendEmai: 'reservations/reservation_tax_invoice/send_mail',
    },
    finance_reservation_reports: 'report/reservation_reports',
    bill_to: 'reservations/reservation_invoice/bill_to',
    undoCheckIn: 'reservations/reservations_entity/undo_check_out',
    undoCancelled: 'reservations/reservations_entity/undo_cancelled',
    roomUndoCancelled: 'reservations/reservations_reservationroom/reservation_room_undo_cancel',
    undoNoShow: 'reservations/reservations_entity/undo_no_show',
    data_invoice_Reservation: 'reservations/invoice_generator_reservation_data',
    tax_invoice_detail: 'reservations/invoice_generator_entity',
    hide_tax_reservation: 'taxes/taxes_entity/add_hidden_entity',
    hide_item_invoice_reservation: 'reservations/invoice_generator_reservation_invoice_data/hide_items',
    reset_hide_tax_reservation: 'taxes/taxes_entity/reset_hidden_entity',
    reset_hide_tax_invoice_reservation:
      'taxes/taxes_entity/reset_hidden_entity',
    res_serviceCharge_settings:
      'servicecharge/sc_settings_entity/switch_on_off_charge',
    resRoomDetail: 'payment/guest_folio_retrieve',
    calendar_statitics: 'reservations/booking_calendar_statistic_room',
    calendar_reservation_byrooms: 'reservations/list_booking_by_rooms',
    calendar_reservation_by_bookings: 'reservations/list_day_use_bookings',
    res_detail_new: 'reservations/reservations_detail',
    reservation_guest_note: 'reservations/reservations_guest_notes',
    reservation_guest_info: 'reservations/reservations_guest_information',
    unallocated: 'reservations/unallocated_bookings',
    list_room_to_pay_activity: 'reservations/reservations_detail_rooms',
    change_due_date: 'reservations/invoice_generator_entity/update_due_date',
    change_pro_forma: 'reservations/invoice_generator_entity/custom_title',
    list_banks_invoice: 'report/bank_entity',
    update_banks_invoice: 'reservations/invoice_generator_entity/update_bank',
    multiple_invoice: {
      data_invoice_Reservation_new:
        'reservations/invoice_generator_reservation_invoice_data',
      save:
        'reservations/invoice_generator_reservation_invoice_data/save_invoices',
      delete: 'reservations/invoice_generator_reservation_invoice_data',
      invoice_each_room:'reservations/invoice_generator_reservation_invoice_data/create_room_invoices'
    },
    new_res: {
      create_res_guest:'reservations/reservations_book_rooms',
      create_res_package:'reservations/reservations_book_package',
      create_res_dau_use:'reservations/reservations_book_day_use',
      new_rooms_available: 'reservations/reservations_available_rooms',
      new_rooms_day_use_available: 'reservations/reservations_day_use_available_rooms',
      new_rooms_package_available: 'reservations/reservations_packages_available_rooms',
    },
    display_paid: 'reservations/invoice_generator_reservation_invoice_data/show_hide_paid',
    display_outstanding: 'reservations/invoice_generator_reservation_invoice_data/show_hide_outstanding',
    add_discount: 'activities/reservations_activities/add_discount',
    update_out_of_order_note:'room/closed_entity/update_note',
    reservation_update_guest_name:'reservations/reservations_entity/change_guest_name'
  }

  private currentCalendarDataSubject = new BehaviorSubject<CalendarData>(
    undefined
  )
  private currentCalendarDayUseDataSubject = new BehaviorSubject<CalendarData>(
    undefined
  )
  public currentCalendarData$ = this.currentCalendarDataSubject.asObservable()
  private currentCalendarBid

  isCalendarFirstLoad() {
    const calVal = this.currentCalendarDataSubject.value
    if (calVal && calVal.isCalendarFirstLoad) {
      return true
    }
    return false
  }

  setLocalCalendar(calendar?: CalendarResponseByRoomtype[]) {
    const calVal = this.currentCalendarDataSubject.value
    const response: CalendarData = {
      calendar: calendar,
      statistic: calVal.statistic,
      isCalendarFullLoaded: true,
      isCalendarFirstLoad: false,
    }
    this.currentCalendarDataSubject.next(response)
  }
  setLocalCalendarDayUse(calendar?: CalendarResponseByRoomtype[]) {
    const response: CalendarData = {
      calendar: calendar,
      isCalendarFullLoaded: true,
      isCalendarFirstLoad: false,
    }
    this.currentCalendarDayUseDataSubject.next(response)
  }

  getCalendarStatistics(bid: string, start, end): Observable<CalendarData> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('start_date', start)
      .set('end_date', end)
    // const response: CalendarData = {
    //   calendar: [],
    //   statistic: [],
    //   isCalendarFullLoaded: false,
    //   isCalendarFirstLoad: true,
    // }
    // this.currentCalendarDataSubject.next(response)
    return this.apiService.get(this._apiPath.calendar_statitics, pa).pipe(
      map((data: any) => {
        const calVal = this.currentCalendarDataSubject.value
        let isCalendarFullLoaded = false
        let isCalendarFirstLoad =
          calVal && calVal.isCalendarFirstLoad ? true : false
        if (this.currentCalendarBid != bid) {
          this.currentCalendarBid = bid
          isCalendarFirstLoad = true
          isCalendarFullLoaded = true
        }
        let statistic = []
        let calendar = []

        if (data.statistic) {
          statistic = data.statistic
        }
        if (data.calendar) {
          calendar = data.calendar
        }
        const response: CalendarData = {
          calendar: calendar,
          statistic: statistic,
          // isCalendarFullLoaded: false,
          // isCalendarFirstLoad: calVal.isCalendarFirstLoad,
          isCalendarFullLoaded: isCalendarFullLoaded,
          isCalendarFirstLoad: isCalendarFirstLoad,
        }
        this.currentCalendarDataSubject.next(response)
        return response
      })
    )
  }

  getCalendarReservationsByRoomType(
    bid: string,
    start,
    end,
    roomtypeid
  ): Observable<CalendarResponseByRoomtypeDataRoom[]> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('start_date', start)
      .set('end_date', end)
      .set('room_type', roomtypeid)
    return this.apiService.get(this._apiPath.calendar_reservation_byrooms, pa)
  }

  getCalendarReservationsByRoomTypeDayUse(
    bid?: string,
    start?,
    end?
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('start_date', start)
      .set('end_date', end)
    return this.apiService
      .get(this._apiPath.calendar_reservation_by_bookings, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  // getCalendarReservationsByRoomTypeDayUse(
  //   bid?: string,
  //   start?,
  //   end?,
  //   roomtypeid?
  // ): Observable<any> {
  //   let pa = new HttpParams()
  //     .set('bid', bid)
  //     .set('start_date', start)
  //     .set('end_date', end)
  //     .set('room_type', roomtypeid)
  //   return this.apiService
  //     .get(this._apiPath.calendar_reservation_by_bookings, pa)
  //     .pipe(
  //       map((data: any) => {
  //         const calVal = this.currentCalendarDayUseDataSubject.value
  //         let isCalendarFullLoaded = false
  //         let isCalendarFirstLoad =
  //           calVal && calVal.isCalendarFirstLoad ? true : false
  //         if (this.currentCalendarBid != bid) {
  //           this.currentCalendarBid = bid
  //           isCalendarFirstLoad = true
  //           isCalendarFullLoaded = true
  //         }
  //         let calendar = []
  //         if (data) {
  //           calendar = data
  //         }
  //         const response: CalendarData = {
  //           calendar: calendar,
  //           isCalendarFullLoaded: isCalendarFullLoaded,
  //           isCalendarFirstLoad: isCalendarFirstLoad,
  //         }
  //         this.currentCalendarDayUseDataSubject.next(response)
  //         return response
  //       })
  //     )
  // }

  /**
   * Lấy tất cả rooms đang available
   */
  getAllReservations(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        this.isFirstLoadListReservation = false
        return data
      })
    )
  }

  getReservationsReport(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get(this._apiPath.finance_reservation_reports, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getListAccountingCodes(account_code?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[ac_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', 'true')
      .set('parameters[ac_number_type]', account_code)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_accouting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllReservationsByDate(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.list_by_date, pa).pipe(
      map(data => {
        this.isFirstLoadListReservation = false
        return data
      })
    )
  }
  getAllReservationsByDateNew(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.list_by_date_new, pa).pipe(
      map(data => {
        this.isFirstLoadListReservation = false
        return data
      })
    )
  }

  getAllReservationsUnallocated(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.unallocated, pa).pipe(
      map(data => {
        this.isFirstLoadListReservation = false
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Reservation dưạ vào @resId
   * @param resId ID của Reservation
   */
  getReservationDetail(resId: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/${resId}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getReservationDetailNew(resId: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.res_detail_new}/${resId}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getReservationGuestInfo(resId: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.reservation_guest_info}/${resId}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getReservationGuestNote(resId: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.reservation_guest_note}/${resId}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReservationInvoice(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.invoice, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.create_invoice, res).pipe(
      map(data => {
        return data
      })
    )
  }
  createBillTo(res): Observable<any> {
    return this.apiService.post(this._apiPath.bill_to, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createNewInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.invoice, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createCreditNote(res): Observable<any> {
    return this.apiService.post(this._apiPath.credit_note, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getReservationInvoiceElement(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get(this._apiPath.reservation_invoice_element, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReservationDataNew(rid?, params?: any, type?): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', rid)
      .set('type', type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.multiple_invoice.data_invoice_Reservation_new, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getReservationData(rid?, params?: any, isReset?): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', rid)
      .set('is_reset', isReset)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.data_invoice_Reservation, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getReservationInvoiceDataNew(rid?, params?: any, isReset?): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', rid)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.multiple_invoice.data_invoice_Reservation_new, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getResLog(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.res_log, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllReservationsByClient(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Reservation dưạ vào @resId
   * @param resId ID của Reservation
   */
  getRoomsAvailable(
    bid: string,
    checkin,
    checkout,
    params?: any
  ): Observable<Room[]> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('checkin', checkin)
      .set('checkout', checkout)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<Room[]>(this._apiPath.rooms_available, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getNewRoomsAvailable(
    bid: string,
    checkin,
    checkout,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('checkin', checkin)
      .set('checkout', checkout)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.new_res.new_rooms_available, pa).pipe(
      map(data => {
        if (data.data && data.data.length > 0) {
          data.data.map(r => {
            let clone = JSON.parse(JSON.stringify(r.prices))
            if (!r.hasOwnProperty('dailyRate')) {
              r['dailyRate'] = clone
            }
          })
        }
        return data
      })
    )
  }
  getNewRoomsDayUseAvailable(
    bid: string,
    time_checkin,
    time_checkout,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('time_checkin', time_checkin)
      .set('time_checkout', time_checkout)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.new_res.new_rooms_day_use_available, pa).pipe(
      map(data => {
        if (data.data && data.data.length > 0) {
          data.data.map(r => {
            let clone = JSON.parse(JSON.stringify(r.prices))
            if (!r.hasOwnProperty('dailyRate')) {
              r['dailyRate'] = clone
            }
          })
        }
        return data
      })
    )
  }
  getNewRoomsPackageAvailable(
    bid: string,
    time_checkin,
    time_checkout,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('checkin', time_checkin)
      .set('checkout', time_checkout)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<Room[]>(this._apiPath.new_res.new_rooms_package_available, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getRoomsDayuse(
    bid: string,
    checkin,
    timeCheckIn,
    timeCheckOut,
    params?: any
  ): Observable<Room[]> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('date', checkin)
      .set('time_checkin', timeCheckIn)
      .set('time_checkout', timeCheckOut)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<Room[]>(this._apiPath.reservations_room_day_use, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getweatherData(city: string, API_KEY: string): Observable<any> {
    return this._http
      .get(
        'https://api.openweathermap.org/data/2.5/weather?q=' +
          city +
          '&APPID=' +
          API_KEY +
          '&units=metric'
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getWeatherFiveDays(city: string, API_KEY: string): Observable<any> {
    return this._http
      .get(
        'https://api.openweathermap.org/data/2.5/forecast?q=' +
          city +
          '&APPID=' +
          API_KEY +
          '&units=metric'
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Thay đổi room trong reservation
   */
  getNewRoomChange(
    room_id,
    room_type_id,
    checkin,
    checkout
  ): Observable<Room[]> {
    let pa = new HttpParams()
      .set('room_id', room_id)
      .set('room_type_id', room_type_id)
      .set('check_in', checkin)
      .set('check_out', checkout)
    return this.apiService
      .get<Room[]>(this._apiPath.list_new_room_change, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy Reservation Overview.
   * @param resId Reservation ID
   */
  getReservationOverview(resId: string): Observable<any> {
    let pa = new HttpParams().set('rid', resId)
    return this.apiService.get(this._apiPath.overview, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy Reservation Calendar
   * @param bid Business ID
   * @param startdate Ngày bắt đầu
   */
  getReservationCalendar(bid, startdate): Observable<any> {
    let pa = new HttpParams().set('bid', bid).set('start_date', startdate)
    return this.apiService.get(this._apiPath.calendar, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy Reservation Calendar
   * @param bid Business ID
   * @param startdate Ngày bắt đầu
   */
  getRoomsCalendar(bid, startdate, enddate, rooms?): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('start_date', startdate)
      .set('end_date', enddate)
    return this.apiService.get(this._apiPath.roomcalendar, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getRoomsCalendarNew(bid, startdate, enddate): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('start_date', startdate)
      .set('end_date', enddate)
      .set('rooms', '1')
    return this.apiService.get(this._apiPath.roomcalendar, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getReservationsarrival(bid, type, date): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('type', type)
      .set('date', date)
    return this.apiService
      .get(this._apiPath.list_reservations_arrival, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReservationsarrivalBirthday(bid, type, day, month): Observable<any> {
    let pa = new HttpParams()
      .set('bid', bid)
      .set('type', type)
      .set('day', day)
      .set('month', month)
    return this.apiService.get(this._apiPath.birthdays, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getTransferToday(bid, start_date): Observable<any> {
    let pa = new HttpParams().set('bid', bid).set('start_date', start_date)
    return this.apiService.get(this._apiPath.transfer_today, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getExcursionToday(bid, start_date): Observable<any> {
    let pa = new HttpParams().set('bid', bid).set('start_date', start_date)
    return this.apiService.get(this._apiPath.excusion_today, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  checkRoomTypeAvailable(res): Observable<any> {
    return this.apiService.post(this._apiPath.check, res).pipe(
      map(data => {
        if (data) {
          return data[0]
        }
        return false
      })
    )
  }

  checkRoomAvailable(res): Observable<any> {
    return this.apiService.post(this._apiPath.check, res).pipe(
      map(data => {
        if (data) {
          return data[0]
        }
        return false
      })
    )
  }

  updateDiscount(res): Observable<any> {
    return this.apiService.post(this._apiPath.discount_offer, res).pipe(
      map(data => {
        return data
      })
    )
  }

  removeDiscount(res): Observable<any> {
    return this.apiService.post(this._apiPath.remove_discount_offer, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createStep1(res): Observable<any> {
    return this.apiService.post(this._apiPath.create, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Reservation
   * @param res Thông tin Reservation
   */
  create(res: Reservation): Observable<any> {
    res.rid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Thay Đổi Status của Reservation
   * @param resId ID Reservation
   * @param status ID trạng thái Reservation
   */
  changeStatus(resId, status): Observable<any> {
    return this.apiService
      .post(this._apiPath.changeStatus, { rid: resId, status: status })
      .pipe(
        map(data => {
          return data
        })
      )
  }
  changeStatusToSendLetter(res): Observable<any> {
    return this.apiService.post(this._apiPath.changeStatus, res).pipe(
      map(data => {
        return data
      })
    )
  }
  changeDirectBooking(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.confirm_res_directbooking, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Chỉnh sửa ngày đặt phòng
   *
   * @param res Thông tin ngày tháng của Reservation muốn chỉnh sửa
   */
  changedate(res): Observable<any> {
    return this.apiService.post(this._apiPath.changedate, res).pipe(
      map(data => {
        return data
      })
    )
  }
  changeDateDayUse(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_date_day_use, res).pipe(
      map(data => {
        return data
      })
    )
  }
  setroompax(res): Observable<any> {
    return this.apiService.post(this._apiPath.setroompax, res).pipe(
      map(data => {
        return data
      })
    )
  }
  editAccountingCode(res): Observable<any> {
    return this.apiService.post(this._apiPath.edit_accounting_code, res).pipe(
      map(data => {
        return data
      })
    )
  }
  customroomprice(res): Observable<any> {
    return this.apiService.post(this._apiPath.room_daily_price, res).pipe(
      map(data => {
        return data
      })
    )
  }
  update_room_dailyprice(res): Observable<any> {
    return this.apiService.post(this._apiPath.room_daily_price, res).pipe(
      map(data => {
        return data
      })
    )
  }

  addunallocatedroom(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_unallocated_room, res).pipe(
      map(data => {
        return data
      })
    )
  }
  onUpdateGuestName(body): Observable<any> {
    return this.apiService.post(this._apiPath.reservation_update_guest_name, body).pipe(
      map(data => {
        return data
      })
    )
  }

  changeAgent(res): Observable<any> {
    return this.apiService.post(this._apiPath.changeAgent, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Thay đổi phòng
   *
   * @param res Thông tin room của Reservation muốn thay đổi
   */
  changeroom(res): Observable<any> {
    return this.apiService.post(this._apiPath.changeroom, res).pipe(
      map(data => {
        return data
      })
    )
  }
  changeRoomType(res): Observable<any> {
    return this.apiService.post(this._apiPath.changeRoomType, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeChannel(res): Observable<any> {
    return this.apiService.post(this._apiPath.changechannel, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Reservation
   *
   * @param res Thông tin Reservation muốn chỉnh sửa
   */
  edit(res: any): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.rid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateNote(res: any): Observable<any> {
    return this.apiService.post(`${this._apiPath.update_note}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Reservation
   *
   * @param rid Xóa Reservation bằng Reservation ID
   */
  deleteReservation(resId): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${resId}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Package Reservation
   * @param res Thông tin Reservation
   */
  createPackageReservation(res: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.create_packages_reservation, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  editPackageReservation(res: any): Observable<any> {
    return this.apiService
      .post(`${this._apiPath.create_packages_reservation}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getExportReservationList(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.export.reservation_list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getListUnallocatedRoom(params): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_unallocated_room, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getExportReservationActivities(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.export.reservation_activities, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  addGuest(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_guest, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeStatusInRoom(res): Observable<any> {
    return this.apiService.post(this._apiPath.reservation_room, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getViewInfoRoom(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.view_info_room, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  editGuestInfo(res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.view_info_room}/${res.gtid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteGuest(gtid): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.view_info_room}/${gtid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createOutOfOrderRoom(res): Observable<any> {
    return this.apiService.post(this._apiPath.outoforder, res).pipe(
      map(data => {
        return data
      })
    )
  }

  removeOutOfOrderRoom(cid): Observable<any> {
    return this.apiService.put(this._apiPath.outoforder + '/' + cid).pipe(
      map(data => {
        return data
      })
    )
  }

  accessByQRCode(res): Observable<any> {
    return this.apiService.post(this._apiPath.guest.qrcode, res).pipe(
      map(data => {
        return data
      })
    )
  }

  exportPdf(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_pdf,
      res,
      null,
      null,
      'blob'
    )
  }
  exportInvoicePDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_invoice_pdf,
      res,
      null,
      null,
      'blob'
    )
  }
  exportReceiptPDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_receipt_pdf,
      res,
      null,
      null,
      'blob'
    )
  }

  sendMailInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.send_email_pdf, res).pipe(
      map(data => {
        return data
      })
    )
  }

  checkCodeLoginComplete(res): Observable<any> {
    return this.apiService.post(this._apiPath.guest.check_login, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getResCheckInForm(params): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.guest.check_in_form, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  sendemailResDetail(res): Observable<any> {
    return this.apiService.post(this._apiPath.sendmailresdetail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getReservationBySearchName(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.reservations_search_name, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createGroupReservation(res): Observable<any> {
    return this.apiService.post(this._apiPath.reservations_group, res).pipe(
      map(data => {
        return data
      })
    )
  }
  /**
   * Update Calendar Daily Price
   * @param res Thông tin Daily Price
   */
  updateDailyPrice(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.calendar_daily_price, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateDailyPriceRange(res: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.calendar_daily_price_range, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getTaxInvoiceRes(rid?, invoice_id?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', rid)
      .set('invoice_id', invoice_id)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.tax_invoice_res.tax_invoice, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createTaxInvoiceRes(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.tax_invoice_res.tax_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  sendEMailTaxInvoice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.tax_invoice_res.sendEmai, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  undoCheckIn(res): Observable<any> {
    return this.apiService.post(this._apiPath.undoCheckIn, res).pipe(
      map(data => {
        return data
      })
    )
  }
  undoCancellded(res): Observable<any> {
    return this.apiService.post(this._apiPath.undoCancelled, res).pipe(
      map(data => {
        return data
      })
    )
  }
  roomUndoCancelled(res): Observable<any> {
    return this.apiService.post(this._apiPath.roomUndoCancelled, res).pipe(
      map(data => {
        return data
      })
    )
  }
  undoNoShow(res): Observable<any> {
    return this.apiService.post(this._apiPath.undoNoShow, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetailInvoice(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.tax_invoice_detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createHideTax(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.hide_tax_reservation, res).pipe(
      map(data => {
        return data
      })
    )
  }
  updateItemInvoice(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.hide_item_invoice_reservation, res).pipe(
      map(data => {
        return data
      })
    )
  }

  resetHideTaxReservation(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.reset_hide_tax_reservation, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  resetHideTaxInvoiceReservation(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.reset_hide_tax_invoice_reservation, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateServiceChargesSetting(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.res_serviceCharge_settings, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReservationRoomDetail(room_id?, rid?, bid?): Observable<any> {
    let pa = new HttpParams()
      .set('room_id', room_id)
      .set('rid', rid)
      .set('bid', bid)
    return this.apiService.get(this._apiPath.resRoomDetail, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getListRoomToPayActivity(resId: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.list_room_to_pay_activity}/${resId}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  changeDueDate(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_due_date, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeProFormaInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_pro_forma, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getListBanksInvoice(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_banks_invoice, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  updateBanksInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.update_banks_invoice, res).pipe(
      map(data => {
        return data
      })
    )
  }
  createInvoiceNew(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.multiple_invoice.data_invoice_Reservation_new, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  
  createInvoiceEachRoom(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.multiple_invoice.invoice_each_room, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  onSaveMultipleInvoices(res): Observable<any> {
    return this.apiService.post(this._apiPath.multiple_invoice.save, res).pipe(
      map(data => {
        return data
      })
    )
  }
  deleteMultipleInvoice(ivID): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.multiple_invoice.delete}/${ivID}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createResRoomNights(body): Observable<any> {
    return this.apiService.post(this._apiPath.new_res.create_res_guest, body).pipe(
      map(data => {
        return data
      })
    )
  }
  createNewResPackage(body): Observable<any> {
    return this.apiService.post(this._apiPath.new_res.create_res_package, body).pipe(
      map(data => {
        return data
      })
    )
  }
  createNewResDayUse(body): Observable<any> {
    return this.apiService.post(this._apiPath.new_res.create_res_dau_use, body).pipe(
      map(data => {
        return data
      })
    )
  }

  updateDisplayPaid(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.display_paid, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateDisplayOutstanding(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.display_outstanding, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  addActivityDiscount(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.add_discount, res).pipe(map( data => {
      return data
    }))
  }

  updateOutOfOrderNote(res): Observable<any> {
    return this.apiService.post(this._apiPath.update_out_of_order_note, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
