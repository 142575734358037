import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('ProductionReportsExportService')

@Injectable({ providedIn: 'root' })
export class ProductionReportsExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateProductionReportExcel(
    title,
    rns: any[],
    data: any[],
    sheetname,
    filename,
    logo,
    agentInfo?
  ) {
    const headerRow1Columns = [
      '',
      'D/M',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 8 },
      { key: 'C2', width: 8 },
      { key: 'C3', width: 8 },
      { key: 'C4', width: 8 },
      { key: 'C5', width: 8 },
      { key: 'C6', width: 8 },
      { key: 'C7', width: 8 },
      { key: 'C8', width: 8 },
      { key: 'C9', width: 8 },
      { key: 'C10', width: 8 },
      { key: 'C11', width: 8 },
      { key: 'C12', width: 8 },
      { key: 'C13', width: 8 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:N4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'I3:I4')
    }

    worksheet.addRow([])

    if (agentInfo) {
      let agentRow = worksheet.addRow([' ', agentInfo])
      agentRow.height = 30
      agentRow.getCell(2).font = {
        name: 'Calibri',
        size: 10,
        bold: true,
        underline: 'single',
      }
      agentRow.alignment = { horizontal: 'left', vertical: 'middle' }
    }

    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        if (number === 2) {
          cell.border.left = { style: 'medium' }
        } else if (number === 14) {
          cell.border.right = { style: 'medium' }
        }
      }
    })
    headerRow1.height = 20

    let headerRow2 = worksheet.addRow(rns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        if (number === 2) {
          cell.border.left = { style: 'medium' }
        } else if (number === 14) {
          cell.border.right = { style: 'medium' }
        }
      }
    })
    headerRow2.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 17
      dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(2).border = {
        left: { style: 'medium' },
        right: { style: 'thin' },
      }
      dataRow.getCell(14).border = {
        right: { style: 'medium' },
      }
      if (index === data.length - 1) {
        dataRow.eachCell((cell, number) => {
          if (number > 1) {
            cell.border = {
              bottom: { style: 'medium' },
            }
            if (number === 2) {
              cell.border.left = { style: 'medium' }
              cell.border.right = { style: 'thin' }
            } else if (number === 14) {
              cell.border.right = { style: 'medium' }
            }
          }
        })
      }
    }

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data && data.day > 0 ? data.day : '')
    row.push(data && data.jan > 0 ? data.jan : '')
    row.push(data && data.feb > 0 ? data.feb : '')
    row.push(data && data.mar > 0 ? data.mar : '')
    row.push(data && data.apr > 0 ? data.apr : '')
    row.push(data && data.may > 0 ? data.may : '')
    row.push(data && data.jun > 0 ? data.jun : '')
    row.push(data && data.jul > 0 ? data.jul : '')
    row.push(data && data.aug > 0 ? data.aug : '')
    row.push(data && data.sep > 0 ? data.sep : '')
    row.push(data && data.oct > 0 ? data.oct : '')
    row.push(data && data.nov > 0 ? data.nov : '')
    row.push(data && data.dec > 0 ? data.dec : '')
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
