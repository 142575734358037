import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from '@angular/core'
import { Logger } from '../logger.service'
import { Observable, fromEvent } from 'rxjs'

const log = new Logger('HMPScrollSpyDirective')
@Directive({
  selector: '[hmpScrollSpy]',
})
export class HMPScrollSpyDirective implements OnDestroy, AfterViewInit {
  @Input() public spiedTags = []
  @Output() public sectionChange = new EventEmitter<string>()
  private scrollEvent$
  private currentSection: string

  constructor(private _el: ElementRef) {}

  ngAfterViewInit(): void {
    log.debug('spiedTags', this.spiedTags)
    this.scrollEvent$ = fromEvent(this._el.nativeElement, 'scroll').subscribe(
      (e: any) => {
        log.debug('scroll$ ', e)
        this.onScroll(e)
      }
    )
  }

  ngOnDestroy() {
    this.scrollEvent$.unsubscribe()
  }

  onScroll(event: any) {
    // log.debug('HMPScrollSpyDirective ', event);
    let currentSection: string
    const children = this._el.nativeElement.children
    const scrollTop = event.target.scrollTop
    const parentOffset = event.target.offsetTop
    for (let i = 0; i < children.length; i++) {
      const element = children[i]
      if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
        if (element.offsetTop - parentOffset <= scrollTop) {
          currentSection = element.id
        }
      }
    }
    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection
      this.sectionChange.emit(this.currentSection)
    }
  }
}
