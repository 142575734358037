import { Injectable } from '@angular/core'
import { Excursion, DigitalAssets } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
const log = new Logger('DigitalAssetsService')
@Injectable({
  providedIn: 'root',
})
export class DigitalAssetsService {
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private router: Router,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    list: 'digital_assets/digital_entity',
    detail: 'digital_assets/digital_entity',
  }

  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[digital_assets_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(val: DigitalAssets): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${val.did}`, val).pipe(
      map(data => {
        return data
      })
    )
  }

  create(val: DigitalAssets): Observable<any> {
    return this.apiService.post(this._apiPath.detail, val).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  detail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
