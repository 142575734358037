import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { ScheduleService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Schedule } from '../../models'

const log = new Logger('ScheduleDetailResolver')
@Injectable({ providedIn: 'root' })
export class ScheduleDetailResolver implements Resolve<Observable<Schedule>> {
  constructor(
    private scheduleService: ScheduleService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let id = route.params['sid']
    
    return this.scheduleService.getDetail(id).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/schedule']))
    )
  }
}
