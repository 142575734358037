import { Injectable } from '@angular/core';
import { BudgetBuilder } from '@hmp/models/budget-builder.model';

@Injectable({ providedIn: 'root' })
export class BudgetInputService {
  
  flatJsonArray(flattenedAray: Array<BudgetBuilder>, node: BudgetBuilder[]) {
    const array: Array<BudgetBuilder> = flattenedAray;
    node.forEach(element => {
      if (element.data) {
        array.push(element);
        this.flatJsonArray(array, element.data);
      }
    });
    return array;
  }

  findNodeMaxId(node: BudgetBuilder[]) {
    const flatArray = this.flatJsonArray([], node);
    const flatArrayWithoutChildren = [];
    flatArray.forEach(element => {
      flatArrayWithoutChildren.push(element.rootComponentId);
    });
    return Math.max(...flatArrayWithoutChildren);
  }

  findPosition(id: string, data: BudgetBuilder[]) {
    for (let i = 0; i < data.length; i += 1) {
      if (id === data[i].rootComponentId) {
        return i;
      }
    }
  }

  findFatherNode(id: string, data: BudgetBuilder[]) {
    for (let i = 0; i < data.length; i += 1) {
      const currentFather = data[i];
      for (let z = 0; z < currentFather.data.length; z += 1) {
        if (id === currentFather.data[z]['rootComponentId']) {
          return [currentFather, z];
        }
      }
      for (let z = 0; z < currentFather.data.length; z += 1) {
        if (id !== currentFather.data[z]['rootComponentId']) {
          const result = this.findFatherNode(id, currentFather.data);
          if (result !== false) {
            return result;
          }
        }
      }
    }
    return false;
  }

}