import { Injectable } from '@angular/core'
import { SocialsConfig } from '../../../config/social.cf'
import { Logger } from '../../../logger.service'

declare let FB: any
const log = new Logger('FacebookLoginService')

@Injectable({ providedIn: 'root' })
export class FacebookLoginService {
  private cf = new SocialsConfig()

  constructor() {}

  initScipt(curLang) {
    let url = `https://connect.facebook.net/${curLang}/sdk.js`

    this.loadScript(url, () => {
      log.debug('Facebook URL: ', url)
      FB.init({
        appId: this.cf.FaceBookAppID,
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: this.cf.FacebookAppVersion,
      })

      FB.AppEvents.logPageView()
    })
  }

  signIn(): Promise<any> {
    return new Promise((resolve, reject) => {
      FB.login(
        (response: any) => {
          log.debug('Signin Res: ', response)
          if (response.authResponse && response.status === 'connected') {
            const accessToken = FB.getAuthResponse()['accessToken']
            log.debug('accessToken: ', accessToken)
            resolve(accessToken)
          }
        },
        { scope: this.cf.FacebookAppScope }
      )
    })
  }

  getStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus(function(response: any) {
        log.debug('getLoginStatus response: ', response)
        if (response.status === 'connected') {
          const accessToken = FB.getAuthResponse()['accessToken']
          log.debug('accessToken: ', accessToken)
          FB.api('/me?fields=name,email,picture', (res: any) => {
            log.debug('getLoginStatus: ', res)
          })
          resolve(accessToken)
        }
      })
    })
  }

  private loadScript(url: string, callback: any) {
    if (document.getElementById('facebook-sdk')) {
      return
    }
    if (document.getElementById('fb-root')) {
      return
    }
    let signInJS = document.createElement('script')
    signInJS.async = true
    signInJS.src = url
    signInJS.id = 'facebook-sdk'
    signInJS.onload = callback

    document.head.appendChild(signInJS)
  }
}
