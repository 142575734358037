import { Injectable } from '@angular/core'
import { AbstractControl, Validator } from '@angular/forms'
import { Logger } from '../logger.service'
const log = new Logger('HmpValidator')

/**
 * Service Xử lý lưu trữ LocalStorage
 */

@Injectable({ providedIn: 'root' })
export class HmpValidator {
  constructor() {}

  // Validates Email
  static email(control: AbstractControl) {
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value == '') {
      return
    }
    if (!EMAIL_REGEXP.test(control.value)) {
      return { invalidEmail: true }
    }
    return null
  }

  // Validates Username
  static username(control: AbstractControl) {
    const USERNAME_REGEXP = /^(?=[\w\s-+.])\s*[a-zA-Z0-9-]+([+._@]?[a-zA-Z0-9-])+\s*$/
    if (control.value == '') {
      return
    }
    if (!USERNAME_REGEXP.test(control.value)) {
      return { invalidUsername: true }
    }
    return null
  }

  // Validates Input not white space
  static space(control: AbstractControl) {
    const SPACE_REGEXP = /^\S*$/
    if (control.value == '') {
      return
    }
    if (!SPACE_REGEXP.test(control.value)) {
      return { invalidSpace: true }
    }
    return null
  }

  static requiredSelect(control: AbstractControl) {
    if (control.value == '') {
      return
    }
    log.debug('XXX TEST', control.value)
    if (typeof control.value === 'string') {
      return { invalidRequiredSelect: true }
    }
    return null
  }

  // Validates Input not not negative integers
  static price(control: AbstractControl) {
    // const PRICE_REGEXP = /^[+]?[0-9]{1,3}(?:[0-9]*(?:[.,][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?|(?:\.[0-9]{3})*(?:,[0-9]{2})?)$/;
    const PRICE_REGEXP = /^[0-9]\d*(\.\d+)?$/g
    if (control.value == '') {
      return null
    }
    if (control.value != '') {
      if (!PRICE_REGEXP.test(control.value)) {
        return { invalidPrice: true }
      }
    }
    return null
  }

  // Validates Input not not negative integers
  static number(control: AbstractControl) {
    const PRICE_REGEXP = /^[0-9]+$/

    if (control.value != '') {
      if (!PRICE_REGEXP.test(control.value)) {
        return { invalidNumber: true }
      }
    }
  }
}
