import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams, HttpClient } from '@angular/common/http'
import { ApiService } from '../../general/api.service'
import { LocalStorageService } from '../../../helper'
const log = new Logger('AppDashboardService')
@Injectable({
  providedIn: 'root',
})
export class AppDashboardService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private _http: HttpClient
  ) {}
  private _apiPath = {
    date_change: 'business/dashboard_fd_tab',
    weather: 'business/business_get_weather',
    dashboard: {
      occ_arr_revpar: 'dashboard/dashboard_bob_tab_occ_arr_revpar',
      revenue_chart: 'dashboard/dashboard_bob_tab_revenue',
      paid_occupancy_chart: 'dashboard/dashboard_bob_tab_paid_occupancy',
    },
    frontDesk: {
      notice_board: 'notice/notification_entity',
      arriving: 'dashboard/dashboard_fd_tab_arriving',
      checkout: 'dashboard/dashboard_fd_tab_checkout',
      activities: 'dashboard/dashboard_fd_tab_activities',
      staff_happening: 'dashboard/dashboard_fd_tab_staff_happening',
      rooms_management: 'dashboard/dashboard_fd_tab_rooms_management',
    },
    businessOnTheBooks: {
      weekly_pickup: 'dashboard/dashboard_bob_tab_weekly_pickup',
      monthly_pickup: 'dashboard/dashboard_bob_tab_monthly_pickup',
      room_nights: 'dashboard/dashboard_bob_tab_room_nights_table',
      search_pickup: 'business/dashboard_bob_tab_pickup',
      booking_ticker: 'business/dashboard_booking_ticker_tab',
    },
    anlytics: {
      arr_chart: 'dashboard/dashboard_analytics_tab_arr_chart',
      gop_chart: 'dashboard/dashboard_analytics_tab_gop_margin_chart',
      agent_product: 'dashboard/dashboard_analytics_tab_agent_production',
      channel_product: 'dashboard/dashboard_analytics_tab_channel_production',
      revenue_mix: 'dashboard/dashboard_analytics_tab_revenue_mix',
    },
  }

  getOtherDateChange(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.date_change, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllNoticeBoard(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'parameters[n_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.frontDesk.notice_board, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllRoomsManagement(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.frontDesk.rooms_management, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllGuestArriving(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.frontDesk.arriving, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllGuestCheckOut(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.frontDesk.checkout, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllActivities(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.frontDesk.activities, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllStaffHappening(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.frontDesk.staff_happening, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllOccArrRevpar(date, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.dashboard.occ_arr_revpar, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getWeeklyPickup(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.businessOnTheBooks.weekly_pickup, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getMonthlyPickup(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.businessOnTheBooks.monthly_pickup, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getBobPickup(
    type = 'month' || 'week',
    startDate,
    endDate,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('type', type)
      .set('start_date', startDate)
      .set('end_date', endDate)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.businessOnTheBooks.search_pickup, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllBookingTickers(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.businessOnTheBooks.booking_ticker, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllRoomNight(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.businessOnTheBooks.room_nights, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllChannelProduction(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.anlytics.channel_product, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllAgentProduction(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.anlytics.agent_product, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getPaidOccChart(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.dashboard.paid_occupancy_chart, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getRevenueChart(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.dashboard.revenue_chart, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getGopChart(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.anlytics.gop_chart, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getArrChart(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.anlytics.arr_chart, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getRevenueMixChart(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.anlytics.revenue_mix, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getweatherData(city: string, API_KEY: string): Observable<any> {
    return this._http
      .get(
        'https://api.openweathermap.org/data/2.5/weather?q=' +
          city +
          '&APPID=' +
          API_KEY +
          '&units=metric'
      )
      .pipe(
        map(data => {
          // log.debug(data.json());
          return data
        })
      )
  }

  getWeather5Days(): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    return this.apiService.get<any>(this._apiPath.weather, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getWeatherFiveDays(
    lat: string,
    lng: string,
    API_KEY: string
  ): Observable<any> {
    const headers = {
      'Content-Type': 'text/plain',
      Accept: '*/*',
    }
    const path = `http://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&APPID=${API_KEY}&units=metric`
    return this.apiService.getFromPath(path, headers).pipe(
      map(data => {
        return data
      })
    )
  }
}
