import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { User } from '../../models'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { AuthService, UserService } from '../../services'

const log = new Logger('ProfileDetailResolver')
@Injectable({ providedIn: 'root' })
export class ProfileDetailResolver implements Resolve<Observable<User>> {
  constructor(
    private userService: UserService,
    private router: Router,
    private auth: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.userService.userProfile().pipe(
      map(data => {
        log.debug('Data: ', data)
        this.auth.setAuth(data)
        return data
      }),
      catchError(err => this.router.navigate(['/']))
    )
  }
}
