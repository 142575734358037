import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('BookingEngineCustomizeLayoutService')

@Injectable({ providedIn: 'root' })
export class BookingEngineCustomizeLayoutService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    detail: 'direct_booking/customize_layout',
    settings: 'direct_booking/direct_booking_entity',
    payment_gateways:'direct_booking/db_payment_gateways',
    db_settings:'direct_booking/direct_booking_settings',
    db_customize:'direct_booking/direct_booking_customize',
    db_enable_language:'direct_booking/get_business_enable_languages',
  }

  getBookingEngineCustomizeLayout(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .get(`${this._apiPath.detail}/${bid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDirectBookingEnableLanguage(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.db_enable_language, pa).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  getDirectBookingCustomize(language?, params?: any,): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService .get(`${this._apiPath.db_customize}/${bid}/${language}`, pa)
    .pipe(
      map(data => {
        return data
      })
    )
  }
  
  update(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateSettings(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.db_settings, res).pipe(
      map(data => {
        return data
      })
    )
  }
  updateDBCustomize(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.db_customize, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[db_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.settings, pa).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  getDirectBookingSettings(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .get(`${this._apiPath.db_settings}/${bid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDirectBookingPaymentGateways(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .get(`${this._apiPath.payment_gateways}/${bid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
