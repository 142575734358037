import { PLATFORM_ID, Inject, Injectable } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import * as Constants from './device-detector.constants'
import { ReTree } from './retree'

export interface DeviceInfo {
  userAgent: string
  os: string
  browser: string
  device: string
  os_version: string
  browser_version: string
}

@Injectable({ providedIn: 'root' })
export class DeviceInfoService {
  ua = ''
  userAgent = ''
  os = ''
  browser = ''
  device = ''
  os_version = ''
  browser_version = ''
  constructor(@Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.ua = window.navigator.userAgent
    }
    this._setDeviceInfo()
  }

  /**
   * @author Ahsan Ayaz
   * @desc Sets the initial value of the device when the service is initiated.
   * This value is later accessible for usage
   */
  private _setDeviceInfo() {
    let reTree = new ReTree()
    let ua = this.ua
    this.userAgent = ua
    let mappings = [
      { const: 'OS', prop: 'os' },
      { const: 'BROWSERS', prop: 'browser' },
      { const: 'DEVICES', prop: 'device' },
      { const: 'OS_VERSIONS', prop: 'os_version' },
    ]

    mappings.forEach(mapping => {
      this[mapping.prop] = Object.keys(Constants[mapping.const]).reduce(
        (obj: any, item: any) => {
          obj[Constants[mapping.const][item]] = reTree.test(
            ua,
            Constants[`${mapping.const}_RE`][item]
          )
          return obj
        },
        {}
      )
    })

    mappings.forEach(mapping => {
      this[mapping.prop] = Object.keys(Constants[mapping.const])
        .map(key => {
          return Constants[mapping.const][key]
        })
        .reduce((previousValue, currentValue) => {
          return previousValue === Constants[mapping.const].UNKNOWN &&
            this[mapping.prop][currentValue]
            ? currentValue
            : previousValue
        }, Constants[mapping.const].UNKNOWN)
    })

    this.browser_version = '0'
    if (this.browser !== Constants.BROWSERS.UNKNOWN) {
      let re = Constants.BROWSER_VERSIONS_RE[this.browser]
      let res = reTree.exec(ua, re)
      if (!!res) {
        this.browser_version = res[1]
      }
    }
  }

  /**
   * @author Ahsan Ayaz
   * @desc Returns the device information
   * @returns the device information object.
   */
  public getDeviceInfo(): DeviceInfo {
    const deviceInfo: DeviceInfo = {
      userAgent: this.userAgent,
      os: this.os,
      browser: this.browser,
      device: this.device,
      os_version: this.os_version,
      browser_version: this.browser_version,
    }
    return deviceInfo
  }

  /**
   * @author Ahsan Ayaz
   * @desc Compares the current device info with the mobile devices to check
   * if the current device is a mobile.
   * @returns whether the current device is a mobile
   */
  public isMobile(): boolean {
    return [
      Constants.DEVICES.ANDROID,
      Constants.DEVICES.IPHONE,
      Constants.DEVICES.I_POD,
      Constants.DEVICES.BLACKBERRY,
      Constants.DEVICES.FIREFOX_OS,
      Constants.DEVICES.WINDOWS_PHONE,
      Constants.DEVICES.VITA,
    ].some(item => {
      return this.device === item
    })
  }

  /**
   * @author Ahsan Ayaz
   * @desc Compares the current device info with the tablet devices to check
   * if the current device is a tablet.
   * @returns whether the current device is a tablet
   */
  public isTablet() {
    return [Constants.DEVICES.I_PAD, Constants.DEVICES.FIREFOX_OS].some(
      item => {
        return this.device === item
      }
    )
  }

  /**
   * @author Ahsan Ayaz
   * @desc Compares the current device info with the desktop devices to check
   * if the current device is a desktop device.
   * @returns whether the current device is a desktop device
   */
  public isDesktop() {
    return [
      Constants.DEVICES.PS4,
      Constants.DEVICES.CHROME_BOOK,
      Constants.DEVICES.UNKNOWN,
    ].some(item => {
      return this.device === item
    })
  }
}
