import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { AuthService, BulletinService } from '../../services'
import { Bulletin } from '@hmp'

const log = new Logger('NoticeDetailResolver')
@Injectable({ providedIn: 'root' })
export class BulletinDetailResolver implements Resolve<Observable<Bulletin>> {
  constructor(
    private bulletinService: BulletinService,
    private router: Router,
    private auth: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let id = route.params['id']
    
    return this.bulletinService.getBulletinDetail(id).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/bulletin']))
    )
  }
}
