import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'
import { Component } from '@angular/core'
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr'
import { Logger } from '@hmp'

const log = new Logger('AppToastComponent')
@Component({
  selector: 'app-toast-component',
  styles: [
    `
      :host {
        margin: 20px 0;
        padding: 10px 10px 10px 10px;
        width: 96%;
        max-width: 300px;
        border-radius: 3px;
        color: #ffffff;
        pointer-events: all;
        cursor: pointer;
      }
    `,
  ],
  template: `
    <div
      class="app-toasts"
      (swiperight)="onSwipeRight($event)"
      [style.display]="state.value === 'inactive' ? 'none' : ''"
    >
      <div class="icon">
        <ng-container [ngSwitch]="toastType">
          <div class="icon-error" *ngSwitchCase="'toast-error'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill-rule="evenodd"
            >
              <circle fill="currentColor" cx="12" cy="12" r="12" />
              <path
                d="M9.84 17.853L5.19 13c-.128-.134-.19-.307-.19-.518s.064-.384.19-.518l1.012-1.037c.128-.154.287-.23.48-.23s.36.077.506.23l3.062 3.225 6.562-6.91c.146-.154.314-.23.506-.23.153 0 .286.05.398.147l1.092 1.12c.128.134.19.307.19.518s-.064.384-.19.518l-8.066 8.466a.61.61 0 0 1-.492.23c-.16 0-.297-.05-.41-.147z"
                fill="#fff"
                fill-rule="nonzero"
              />
            </svg>
          </div>
          <div class="icon-information" *ngSwitchCase="'toast-info'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill-rule="evenodd"
            >
              <circle fill="currentColor" cx="12" cy="12" r="12" />
              <path
                d="M9.84 17.853L5.19 13c-.128-.134-.19-.307-.19-.518s.064-.384.19-.518l1.012-1.037c.128-.154.287-.23.48-.23s.36.077.506.23l3.062 3.225 6.562-6.91c.146-.154.314-.23.506-.23.153 0 .286.05.398.147l1.092 1.12c.128.134.19.307.19.518s-.064.384-.19.518l-8.066 8.466a.61.61 0 0 1-.492.23c-.16 0-.297-.05-.41-.147z"
                fill="#fff"
                fill-rule="nonzero"
              />
            </svg>
          </div>
          <div class="icon-warning" *ngSwitchCase="'toast-warning'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill-rule="evenodd"
            >
              <circle fill="currentColor" cx="12" cy="12" r="12" />
              <path
                d="M9.84 17.853L5.19 13c-.128-.134-.19-.307-.19-.518s.064-.384.19-.518l1.012-1.037c.128-.154.287-.23.48-.23s.36.077.506.23l3.062 3.225 6.562-6.91c.146-.154.314-.23.506-.23.153 0 .286.05.398.147l1.092 1.12c.128.134.19.307.19.518s-.064.384-.19.518l-8.066 8.466a.61.61 0 0 1-.492.23c-.16 0-.297-.05-.41-.147z"
                fill="#fff"
                fill-rule="nonzero"
              />
            </svg>
          </div>
          <div class="icon-success" *ngSwitchDefault>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill-rule="evenodd"
            >
              <circle fill="currentColor" cx="12" cy="12" r="12" />
              <path
                d="M9.84 17.853L5.19 13c-.128-.134-.19-.307-.19-.518s.064-.384.19-.518l1.012-1.037c.128-.154.287-.23.48-.23s.36.077.506.23l3.062 3.225 6.562-6.91c.146-.154.314-.23.506-.23.153 0 .286.05.398.147l1.092 1.12c.128.134.19.307.19.518s-.064.384-.19.518l-8.066 8.466a.61.61 0 0 1-.492.23c-.16 0-.297-.05-.41-.147z"
                fill="#fff"
                fill-rule="nonzero"
              />
            </svg>
          </div>
        </ng-container>
      </div>
      <div class="messages">
        <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
        >
          {{ title }}
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          {{ message }}
        </div>
      </div>
      <div class="right-btn">
        <a
          *ngIf="options.closeButton"
          (click)="remove()"
          class="toast-btn close-icon"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
            <path
              d="M11.12 9l6.394-6.394c.58-.58.58-1.537 0-2.12s-1.537-.58-2.12 0L9 6.88 2.606.488c-.58-.58-1.537-.58-2.12 0a1.5 1.5 0 0 0-.441 1.059 1.5 1.5 0 0 0 .441 1.059L6.88 9 .488 15.394a1.5 1.5 0 0 0-.441 1.059 1.5 1.5 0 0 0 .441 1.059c.58.58 1.537.58 2.12 0L9 11.12l6.394 6.394c.58.58 1.537.58 2.12 0s.58-1.537 0-2.12L11.12 9z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </svg>
        </a>
      </div>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0)',
              opacity: 0,
            }),
            style({
              transform: '',
              opacity: 1,
            }),
            style({
              transform: '',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class AppToastComponent extends Toast {
  // used for demo purposes
  actionText = 'detail'
  toastType = ''
  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage)
    this.toastType = toastPackage.toastType
  }

  action(event: Event) {
    event.stopPropagation()
    this.toastPackage.triggerAction()
    return false
  }

  onSwipeRight(val) {
    this.toastrService.remove(this.toastPackage.toastId)
  }
}
