import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { BusinessPermissions } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('BusinessPermissionsService')

/**
 * Đây là services dành cho Permissions
 */

@Injectable({ providedIn: 'root' })
export class BusinessPermissionsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    get_by_roles: 'permissionscontent/permissionscontent_business_settings',
    update_by_roles:
      'permissionscontent/permissionscontent_business_update/update',
    all_new_roles: 'permissionscontent/roles_entity',
  }

  getAllRoles(): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('hr', '1')
    return this.apiService.get(this._apiPath.all_new_roles, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createRole(title): Observable<any> {
    const res = {
      title: title,
      role_business: {
        entity_id: this.storage.getActiveLocalBusinessID(),
      },
    }
    return this.apiService.post(this._apiPath.all_new_roles, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeRoleName(rid, name): Observable<any> {
    const res = {
      title: name,
      role_business: {
        entity_id: this.storage.getActiveLocalBusinessID(),
      },
    }
    return this.apiService
      .put(`${this._apiPath.all_new_roles}/${rid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * DESCR
   */

  /**
   * Lấy tất cả Permissions by Role ID
   * @param roleId Role ID
   */
  getBusinessPermissionByRole(roleId): Observable<BusinessPermissions[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', roleId)
    return this.apiService
      .get<BusinessPermissions[]>(this._apiPath.get_by_roles, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Chỉnh sửa thông tin Permissions
   * @param roleId Role ID
   * @param pers Permisson
   */
  updatePermissionByRole(roleId, pers: BusinessPermissions[]): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', roleId)
    return this.apiService
      .post(this._apiPath.update_by_roles, pers, '', pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
