// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  version: 'v5.5.26',
  // name: 'LIVE',
  // SERVER_URL: 'https://web.hmpmaster.com',
  // API_PATH: 'https://live.hmpmaster.com/api/',
  
  // name: "TEST",
  // SERVER_URL: "https://test-front.hmpmaster.com",
  // API_PATH: "https://test.hmpmaster.com/api/",
  
  name: 'DEV',
  SERVER_URL: 'https://360-dev.hmpmaster.dev',
  API_PATH: 'https://api-dev.hmpmaster.dev/api/',

  API_PATH_BUDGET: 'https://app-develop.hmpmaster.dev/',
  UPLOAD_URL_PATH: 'file/create_raw',
  firebase: {
    apiKey: 'AIzaSyAlEzR6jbB9zgC9u32NDvfT_lTi7CnOJKM',
    authDomain: 'hmp-master.firebaseapp.com',
    databaseURL: 'https://hmp-master.firebaseio.com',
    projectId: 'hmp-master',
    storageBucket: 'hmp-master.appspot.com',
    messagingSenderId: '831611177774',
  },
  CDN: 'https://cdn-dev.hmpmaster.dev/',
  ABA_CHECKOUT:
    'https://checkout-sandbox.payway.com.kh/api/payment-gateway/v1/payments/purchase',
  ABA_VERIFY:
    'https://checkout-sandbox.payway.com.kh/api/payment-gateway/v1/payments/check-transaction',
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
