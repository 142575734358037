import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
const log = new Logger('BudgetInputExportService')
import * as _moment from 'moment'
import { HMPGlobal } from '@hmp/config/HMPGlobal'
import { AuthService } from '../general/auth.service'
import { DecimalPipe } from '@angular/common'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class BudgetInputExportService {
  enterBy
  constructor(
    private auth: AuthService,
    private cp: DecimalPipe
  ) {
    this.enterBy = this.auth.getCurrentUserName();
  }
  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }
  onGenerateReportExcel(
    title, 
    data: any[],
    businessName,
    sheetname,
    filename,
  ) {
    data = data.sort().reverse();
    const headerRow1Columns = [
      '',
      'COMPONENT NAME',
      'GL',
      'NO.',
      'COST CENTER',
      'ENTERED BY',
      '% OF TOTAL BUDGET',
      'BUDGET ('+this.getCurrencyInfo().name+')'
    ]
    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })


    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'A5', width: 10 },
      { key: 'B5', width: 50 },
      { key: 'C5', width: 20 },
      { key: 'D5', width: 20 },
      { key: 'E5', width: 20 },
      { key: 'F5', width: 20 },
      { key: 'G5', width: 20 },
      { key: 'H5', width: 20 }
    ]

    // TITLE ROW 0,1,2
    worksheet.mergeCells('B2', 'H2');
    let titleRow = worksheet.getCell('B2');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri (Body)',
      size: 14,
      bold: true,
      color: { argb: '000000' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    let date = worksheet.getCell('B3');
    const now = new Date()
    const dateNow = moment(now)
    date.value = moment(dateNow).format("DD-MMM-YYYY, HH:mm A")
    date.font = {
      name: 'Calibri (Body)',
      size: 10,
      italic: true,
      bold: false,
      color: { argb: '000000' }
    }
    date.alignment = { vertical: 'middle', horizontal: 'left' }

    let name = worksheet.getCell('B4');
    name.value = 'Budget Input'
    name.font = {
      name: 'Calibri (Body)',
      size: 14,
      bold: true,
      color: { argb: '000000' }
    }
    name.alignment = { vertical: 'middle', horizontal: 'left' }

    let namedemo = worksheet.getCell('H4');
    namedemo.value = businessName
    namedemo.font = {
      name: 'Calibri (Body)',
      size: 14,
      bold: true,
      color: { argb: '000000' }
    }
    namedemo.alignment = { vertical: 'middle', horizontal: 'right' }
    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri (Body)',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
      if (number > 6) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    // worksheet.getCell('B6').alignment = { horizontal: 'left', vertical: 'middle'  }
    // headerRow2.height = 20
    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      //load name
      const dataPaymentRowColumnName = this.generateSummaryValueColumnArrName(r)
      let dataRowName = worksheet.addRow(dataPaymentRowColumnName)
      this.formatRowDefaultValueName(worksheet, dataRowName)
      //load child
      this.loadData(worksheet, r, data)
      const dataRowColumnSummary = this.generateColumnSummary(r, data)
      let dataRowSummary = worksheet.addRow(dataRowColumnSummary)
      this.formatRowDefaultValueSummary(worksheet, dataRowSummary)
    }
    const dataRowColumnTotal = this.generateSummaryValueColumnArrTotal(data)
    let dataRowTotal = worksheet.addRow(dataRowColumnTotal)
    this.formatRowDefaultValueTotal(dataRowTotal)
    const lastRow = worksheet.lastRow;
    lastRow.font = {
        name: 'Calibri (Body)',
        color: { argb: '428BCA' },
        size: 12,
        bold: true,
    }
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  totalBudget(data) {
    let total: number = 0
    if(data){
      data.forEach(element => {
        total = total + element.amount
      });
    }
    return total;
  }

  percentBudget(data, amount){
    if(amount > 0){
      let total: number = 0;
      const tBudget = this.totalBudget(data)
      total = amount/Number(tBudget);
      return (total*100).toFixed(1);
    }else{
      return 0
    }
  }

  percentTotalBudget(id, data){
    let total: number = 0
    if(data){
      if (data != undefined && data.id === id) {
        data.data.forEach(element => {
          const sum = this.percentBudget(data, element.amount);
          total = total + Number(sum)
        });
        return Math.round(total).toFixed(1);
      }
      // data.forEach(element => {
      //   const sum = this.percentBudget(data, element.amount);
      //   total = total + Number(sum)
      // });
    }
    return Math.round(total).toFixed(1);
  }
  

  private generateSummaryValueColumnArrName(data) {
    const row = []
    row.push('')
    row.push(`${data.name}`)
    for (let index = 0; index < 6; index++) {
      row.push('')
    }
    return row
  }

  private generateColumnSummary(data, budget) {
    const row = [];
    let percent = 0
    const percentBudget = this.percentBudget(budget, data.amount)
    percent += Number(percentBudget)
    const amount = Number(data.amount)
    row.push('');
    row.push(`Component Summary`);
    row.push('');
    row.push('');
    row.push(data.costCenter);
    row.push(this.enterBy);
    row.push(percent.toFixed(1) +`%`);
    row.push(amount);
    // for (let index = 0; index < 6; index++) {
    //   row.push('')
    // }
    return row
  }

  private formatRowDefaultValueSummary(worksheet, dataRow) {
    dataRow.eachCell((cell, number) => {
        if(number > 1){
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
            }
            cell.font = {
                name: 'Calibri (Body)',
                size: 10,
                bold: true,
            }
            cell.alignment = { horizontal: 'left', vertical: 'middle' }
        }
        if(number > 6){
            cell.alignment = { horizontal: 'right', vertical: 'middle' }
        }
        if(number === 8){
            cell.numFmt = '#,##0.00'
        }
        
      
    })
    return dataRow
  }

  private formatRowDefaultValueName(worksheet, dataRow) {
    dataRow.eachCell((cell, number) => {
    if (number > 1) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'dce6f1',
            },
            bgColor: {
              argb: 'dce6f1',
            },
         
        }
    }
     if (number == 2) {
        const dobCol = worksheet.getColumn(number);
        dobCol.width = 25
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        cell.font = {
          name: 'Calibri (Body)',
          size: 10,
          bold: true,
        }
      }
      if (number == 3) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
        cell.font = {
          name: 'Calibri (Body)',
          size: 10,
          bold: false,
        }
        
      }
      
    })
    return dataRow
  }

  private generateValueColumnChild(budget, data) {
    let name = ''
    const value = data;
    if(value.name){
        name = this.getSpace(data.level) + value.name
    }
    const persion = this.percentBudget(budget, value.amount)
    const amount = this.currency(value.amount)
    const row = [];
    row.push('');
    row.push(name);
    row.push(this.getSpace(data.level) + value.gl);
    row.push(this.getSpace(data.level) + value.no);
    row.push(value.costCenter);
    row.push(value.enteredBy);
    row.push(persion+ '%' + this.getSpaceRight(data.level));
    row.push(amount + this.getSpaceRight(data.level));
    return row
  }

  private loadData(worksheet, data, budget){
    let length = 0
    if(data.data && data.data.length){
        length = data.data.length
    }else if(data.length){
        length = data.length
    }else{
        length = 0
    }
    if(data || data.data){
        for (let index = 0; index < length; index++) {
            let r
            if(data.data){
                r = data.data[index]
            }else if(data){
                r = data[index]
            }
            let dataRowColumn
            dataRowColumn = this.generateValueColumnChild(budget, r)
            let dataRow = worksheet.addRow(dataRowColumn)
            dataRow.font = {
            name: 'Calibri',
            size: 10,
            bold: false,
            }
            dataRow.alignment = { horizontal: 'left', vertical: 'middle' }
            dataRow.getCell('B').alignment = { horizontal: 'left' }
            dataRow.getCell('C').alignment = { horizontal: 'left' }
            dataRow.getCell('D').alignment = { horizontal: 'left' }
            dataRow.getCell('E').alignment = { horizontal: 'left' }
            dataRow.getCell('F').alignment = { horizontal: 'left' }
            dataRow.getCell('G').alignment = { horizontal: 'right' }
            dataRow.getCell('H').alignment = { horizontal: 'right' }
            // dataRow.getCell('H').numFmt = '#,##0.00'
            if(r && r.data){
                this.loadData(worksheet, r.data, budget)
            }
        }
    }
    
}

private generateSummaryValueColumnArrTotal(data) {
    const row = []
    const amountP = this.totalBudget(data)
    row.push('')
    row.push('Total Budget')
    row.push('')
    row.push('')
    row.push('')
    row.push('')
    row.push('')
    row.push(Number(`${amountP}`))
    
    return row
}

private formatRowDefaultValueTotal(dataRow) {
    dataRow.font = {
      name: 'Calibri (Body)',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 3) {
        cell.numFmt = '#,##0.00'
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    return dataRow
  }

getSpace(level){
    let space = ''
    for( let i=0; i<= level; i++){
      space = '  '+space
    }
    return space
  }

  getSpaceRight(level){
    let space = ''
    if(level > 1){
      for( let i=0; i<= level; i++){
        space = ' '+space
      }
      return space
    }
    return space = ''
  }

  currency(
    value: number,
    override?: boolean,
    id?: string,
    display?: string,
    digit?: string,
    prefix?: boolean,
    negativeNumber?: number
  ): any {
    const currency = this.auth.getCurrentCurrency()
    if (override === true) {
      // Custom Override
      const currencyInfo =
        id === '' ? HMPGlobal.getCurrency(currency) : HMPGlobal.getCurrency(id)
      const cDigit = digit || currencyInfo.digit
      // let nNegativeNumber = negativeNumber || currencyInfo.negativeNumber;
      const cPrefix = prefix || currencyInfo.prefix
      const cDisplay =
        display && (display === 'name' || display === 'symbol')
          ? display === 'name'
            ? currencyInfo.name
            : currencyInfo.symbol
          : currencyInfo.symbol
      const pup = this.cp.transform(value, cDigit) || '0'
      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '')
        const result = cPrefix ? `-${cDisplay} ${pup2}` : `${pup} ${cDisplay}`

        return result
      }
      const result = cPrefix ? `${cDisplay} ${pup}` : `${pup} ${cDisplay}`
      // console.log('currencyInfo Override', currencyInfo)
      return result
    } else {
      const currencyInfo = HMPGlobal.getCurrency(currency)
      // console.log('currencyInfo', currencyInfo)

      const pup = this.cp.transform(value, currencyInfo.digit) || '0'

      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '')
        const result = `-${pup2}`

        return result
      }
      const result = `${pup}`

      return result
    }
  }
}
