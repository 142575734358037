import { Injectable } from '@angular/core'
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { AuthService } from './auth.service'
import { Logger } from '../../logger.service'
import { PermissionsService } from './permissions.service'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

const log = new Logger('AuthGuardService')
@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private permissionServices: PermissionsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isLoggedIn.pipe(
      map(yes => {
        // const currentRoute = window.location.origin + state.url;
        if (!yes) {
          this.router.navigate(['/login'], {
            queryParams: { redirect: state.url },
            replaceUrl: true,
          })
          return yes
        }
        const routerEntity = route.data
        if (!routerEntity || !routerEntity.entity || !routerEntity.action) {
          return true
        }
        const isAllow = this.permissionServices.hasPermission(
          routerEntity.entity,
          routerEntity.action
        )
        if (!isAllow) {
          this.router.navigate(['/denied'])
          return false
        }
        const localUser = this.auth.getCurrentUser()
        const bid = this.auth.getActiveLocalBusinessID()
        if (localUser && bid) {
          const busRoles = localUser.roles_business as any[]
          if (busRoles.length > 0) {
            const busRole = busRoles.find(e => e.business_id == bid)
            const busRoleId = busRole && busRole.business_role
            if (this.permissionServices.isOperationsRole(busRoleId)) {
              this.router.navigate(['/denied'])
              return false
            }
          }
        }
        return true
      })
    )
  }
}
