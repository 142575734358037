import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import {
  OutletsItem,
  OutletsCategory,
  OutletsModifier,
  OutletsOption,
} from '@hmp/models'

const log = new Logger('Outlets')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class OutletsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    items: {
      list: 'outlets/list_all_outlets_items',
      detail: 'outlets/outlets_item_entity',
      item: 'outlets/outlets_item_entity',
      update: 'outlets/outlets_order_entity/update_line_item_detail',
      send_email_outlets: 'outlets/outlets_order_entity/send_mail',
      custom_price_outlets:
        'outlets/outlets_order_entity/custom_price_line_item',
      void_outlets_order: 'outlets/outlets_order_entity/void_order',
    },
    categories: {
      list: 'outlets/outlets_category_entity',
      detail: 'outlets/outlets_category_entity',
    },
    modifiers: {
      list: 'outlets/outlets_attributes_entity',
      detail: 'outlets/outlets_attributes_entity',
    },
    options: {
      list: 'outlets/outlets_options_entity',
      detail: 'outlets/outlets_options_entity',
    },
    orders: {
      list: 'outlets/outlets_order_entity',
      detail: 'outlets/outlets_order_entity',
      changeOrderStatus: 'outlets/outlets_order_entity/change_cart_status',
      close_order: 'outlets/outlets_order_entity/close_order',
      re_close_order: 'outlets/outlets_order_entity/re_close_order',
      change_qty: 'outlets/outlets_order_entity/change_qty_line_item',
      add_service_charge: 'outlets/outlets_order_entity/add_service_charge',
    },
    cart: {
      add_item: 'outlets/outlets_order_entity/add_item_to_cart',
      remove_item: 'outlets/outlets_order_entity/remove_line_item_from_cart',
    },
    promotion: {
      add_discount: 'promotions/discount_entity/add_discount_for_entity',
      remove_coupon: 'promotions/discount_entity/remove_discount_for_entity',
    },
    list_accouting_codes: 'manager/account_code_entity',
    edit_account_code_outlets:
      'outlets/outlets_order_entity/edit_accounting_codes',
    confirm_outlets_res:
      'outlets/outlets_order_entity/reservation_confirm_order',
    invoice: {
      create_invoice: 'reservations/tax_invoice',
      invoice: 'reservations/tax_invoice',
    },
    send_email_invoice: 'reservations/tax_invoice/send_mail',
    sc_app_percent: 'servicecharge/sc_app_percent',
    coupon: {
      detail: 'outlets/discount_outlets',
      remove: 'fb/discount_order_item',
      add_discount: 'outlets/discount_outlets/add_coupon',
      remove_discount: 'outlets/discount_outlets/remove_coupon',
    },
    ltr_uom: {
      create: 'business/long_term_resident/create_fdo_order',
      list: 'business/long_term_resident',
    },
    pos: {
      guest: 'reservations/reservations_front_desk',
      create_order: 'reservations/reservations_front_desk/create_order',
      create_order_ltr_uom: 'business/front_desk_ltr_uom/create_order',
      ltr_uom: 'business/front_desk_ltr_uom',
      walk_in: 'outlets/active_front_desk_order',
    },
    export_invoice_pdf: 'reservations/tax_invoice/export_pdf',
    list_pm_method: 'payment/pay_method_entity',
    tax_invoice_outlet:
      'reservations/invoice_generator_entity/create_fdo_invoice',
    update_outlet_bill_to_invoice:
      'reservations/invoice_generator_entity/update_bill_to',
    change_status_tax: 'reservations/reservations_front_desk/on_off_tax',
    custum_tax_price: 'reservations/reservations_front_desk/custom_tax',
    hide_tax: 'taxes/taxes_entity/add_hidden_entity',
    fd_serviceCharge_setting:
      'servicecharge/sc_settings_entity/switch_on_off_charge',
    all_guest_outlet_new: 'reservations/reservations_front_desk',
    pay_outlets_ksher: 'outlets/outlets_order_entity/close_order',
    re_pay_outlets_ksher: 'outlets/outlets_order_entity/re_close_order',
  }

  getPOSGuest(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.pos.guest, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getPOSGuestNew(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.all_guest_outlet_new, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getLtrUomActive(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.pos.ltr_uom, pa).pipe(
      map(data => {
        return data && data
      })
    )
  }

  getWalkIn(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.pos.walk_in, pa).pipe(
      map(data => {
        return data && data
      })
    )
  }

  createKitchenOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.pos.create_order, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createOrderLtrUom(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.pos.create_order_ltr_uom, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getListItems(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.items.item, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getListAccountingCodes(account_code?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[ac_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', 'true')
      .set('parameters[ac_number_type]', account_code)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_accouting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllItems(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.items.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getOutletsItemsDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.items.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  editItem(res: OutletsItem): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.items.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createItem(res: OutletsItem): Observable<any> {
    res.id = null
    res.oi_business = { entity_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.items.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteItem(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.items.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllCategories(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.categories.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  editCategory(res: OutletsCategory): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.categories.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createCategory(res: OutletsCategory): Observable<any> {
    res.id = null
    res.oc_business = { entity_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.categories.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteCategory(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.categories.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllModifiers(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.modifiers.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  editModifier(res: OutletsModifier): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.modifiers.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createModifier(res: OutletsModifier): Observable<any> {
    res.id = null
    res.oa_business = { entity_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.modifiers.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteModifier(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.modifiers.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getOptionByModifier(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[oao_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.options.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  editOption(res: OutletsOption): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.options.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createOption(res: OutletsOption): Observable<any> {
    res.id = null
    res.oao_business = { entity_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.options.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteOption(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.options.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllOrder(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[oo_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.orders.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getOrderByID(order_id, params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(`${this._apiPath.orders.detail}/${order_id}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  addItemToCart(res): Observable<any> {
    res['business_id'] = this.storage.getActiveLocalBusinessID()
    return this.apiService.post(this._apiPath.cart.add_item, res).pipe(
      map(data => {
        return data
      })
    )
  }

  removeLineItem(res): Observable<any> {
    return this.apiService.post(this._apiPath.cart.remove_item, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateLineItem(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.change_qty, res).pipe(
      map(data => {
        return data
      })
    )
  }

  addCouponCode(res): Observable<any> {
    res['bid'] = this.storage.getActiveLocalBusinessID()
    return this.apiService.post(this._apiPath.promotion.add_discount, res).pipe(
      map(data => {
        return data
      })
    )
  }

  addDiscountCode(res): Observable<any> {
    res['bid'] = this.storage.getActiveLocalBusinessID()
    return this.apiService.post(this._apiPath.coupon.add_discount, res).pipe(
      map(data => {
        return data
      })
    )
  }

  removeDiscountCode(res) {
    return this.apiService.post(this._apiPath.coupon.remove_discount, res).pipe(
      map(data => {
        return data
      })
    )
  }

  removeCouponCode(res) {
    return this.apiService
      .post(this._apiPath.promotion.remove_coupon, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateItemOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.items.update, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeOrderStatus(status, order_id): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.changeOrderStatus, {
        status: status,
        order_id: order_id,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  closeOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.close_order, res).pipe(
      map(data => {
        return data
      })
    )
  }
  reCloseOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.re_close_order, res).pipe(
      map(data => {
        return data
      })
    )
  }
  customItemPriceOutlets(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.items.custom_price_outlets, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  voidOutletsOrder(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.items.void_outlets_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  sendemailOutlets(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.items.send_email_outlets, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  editAccountCodeOutlets(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.edit_account_code_outlets, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  confirm_outlets_res(res): Observable<any> {
    return this.apiService.post(this._apiPath.confirm_outlets_res, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getDataInvoice(entity_id?, entity_type?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('entity_id', entity_id)
      .set('entity_type', entity_type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.invoice.invoice, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  createInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.invoice.create_invoice, res).pipe(
      map(data => {
        return data
      })
    )
  }
  sendemailOutletsInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.send_email_invoice, res).pipe(
      map(data => {
        return data
      })
    )
  }
  addServiceCharge(res) {
    return this.apiService
      .post(this._apiPath.orders.add_service_charge, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getPercentCharge(app?, params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('app', app)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.sc_app_percent, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createCouponItem(val): Observable<any> {
    return this.apiService.post(this._apiPath.coupon.detail, val).pipe(
      map(data => {
        return data
      })
    )
  }
  editCouponItem(res?, did?): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.coupon.detail}/${did}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  deleteCouponItem(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.coupon.remove}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getLongTermResident(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.ltr_uom.list, pa).pipe(
      map(data => {
        return data && data.data
      })
    )
  }
  createLTRUOMOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.ltr_uom.create, res).pipe(
      map(data => {
        return data
      })
    )
  }
  exportInvoicePDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_invoice_pdf,
      res,
      null,
      null,
      'blob'
    )
  }

  getListPaymentMethodFD(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[pm_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
      .set('bill_to', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_pm_method, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createTaxOutletInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.tax_invoice_outlet, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateOutletBillToInvoiceGenerator(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.update_outlet_bill_to_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  changeStatusTax(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_status_tax, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createTax(res): Observable<any> {
    res.tid = null
    return this.apiService.post(this._apiPath.custum_tax_price, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetailOrder(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.orders.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  createHideTax(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.hide_tax, res).pipe(
      map(data => {
        return data
      })
    )
  }
  updateServiceChargesSetting(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.fd_serviceCharge_setting, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  
  onPayOuletsKsher(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.pay_outlets_ksher, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  onRePayOuletsKsher(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.re_pay_outlets_ksher, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
