import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { FoodBeverageService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { FBItems } from '../../models'

const log = new Logger('FBItemsResolver')
@Injectable({ providedIn: 'root' })
export class FBItemsResolver implements Resolve<Observable<FBItems>> {
  constructor(
    private resService: FoodBeverageService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['itid']
    
    return this.resService.getFBItemsDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/fb']))
    )
  }
}
