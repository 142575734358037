import { Injectable } from '@angular/core'
import { HR, Reservation } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('HRService')

@Injectable({ providedIn: 'root' })
export class HRService {
  constructor(
    private apiService: ApiService,
    private globalEntityServices: GlobalEntityService,
    private jwtService: JwtService,
    private router: Router,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho Agent
   */
  private _apiPath = {
    list: 'business/hr',
    detail: 'business/hr',
    agent_database: 'business/agents_database',
    roles: 'global/hr_roles',
    listAccByRole: 'business/hr_account',
    ByBusAndRole: 'business/get_hr_account',
    reservation_agent: 'business/list_reservation_by_agent',
    agent_by_agent_class: 'business/list_user_agent_class',
    avatar_account: 'business/get_avatar_account',
    block_account: 'business/hr_account/blocked',
    guest: {
      detail: 'client/guests',
      guest_history: 'client/guest_history',
      guest_favourites: 'client/guest_favourites',
      guest_information: 'guest/guest_merge/view_profile',
      guest_merge: 'guest/guest_merge/merge_profile',
      guest_export: 'guest/guest_database_export',
    },
    tags: {
      list: 'tags/tags_entity',
      detail: 'tags/tags_entity',
    },
    list_hr: 'business/filter_hr',
    unit_owner: 'business/unit_owner_folio',
    filter_agent: 'business/filter_agent',
    filter_guest: 'business/filter_guest',
    create_guest: 'business/user_guest_info',
    update_guest: 'reservations/reservations_guest_stay',
    list_guest_database: 'business/guests_database',
    delete_guest: 'guest/guest_database',
  }

  getAllHRByBusiness(id: string, params?: any): Observable<any> {
    let pa = new HttpParams().set('bid', id)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllGuestDatabase(id: string, params?: any): Observable<any> {
    let pa = new HttpParams().set('bid', id)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list_guest_database, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllHR(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list_hr, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllAgentDatabase(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.agent_database, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getHRByUserAndBusiness(
    bid: string,
    hrid: number,
    role_type?: number,
    params?: any
  ): Observable<HR> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<HR>(`${this._apiPath.list}/${hrid}/${bid}/${role_type}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetailUser(hrid: number, params?: any): Observable<HR> {
    let bid = this.storage.getActiveLocalBusinessID()
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<HR>(`${this._apiPath.guest.detail}/${bid}/${hrid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getHistoryGuest(hrid: number, params?: any): Observable<any> {
    let bid = this.storage.getActiveLocalBusinessID()
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(`${this._apiPath.guest.guest_history}/${bid}/${hrid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllFavourites(hrid: number, params?: any): Observable<any> {
    let bid = this.storage.getActiveLocalBusinessID()
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(`${this._apiPath.guest.guest_favourites}/${bid}/${hrid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllUnitOwners(params?: any): Observable<any> {
    let bid = this.storage.getActiveLocalBusinessID()
    let pa = new HttpParams().set('bid', bid).set('select_box', 'TRUE')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(`${this._apiPath.unit_owner}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getListAccountByRoles(role): Observable<HR[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('role', role)
    return this.apiService.get<HR[]>(this._apiPath.listAccByRole, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  filterAgent(type?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('type', type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.filter_agent, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  filter_guest(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.filter_guest, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAccountByBussinesAndRole(roleList): Observable<HR[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (roleList) {
      pa.set('roles', roleList)
    }
    return this.apiService.get<HR[]>(this._apiPath.ByBusAndRole, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAgentByAgentClass(agentClass): Observable<HR[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('agent_class', agentClass)
    return this.apiService
      .get<HR[]>(this._apiPath.agent_by_agent_class, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllRoles(): Observable<any> {
    return this.apiService.get(this._apiPath.roles).pipe(
      map(data => {
        return data
      })
    )
  }

  detail(res): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/` + res).pipe(
      map(data => {
        return data
      })
    )
  }

  getAvatar(uid): Observable<any> {
    let pa = new HttpParams().set('uids', uid)
    return this.apiService.get<any>(this._apiPath.avatar_account, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res): Observable<any> {
    res.uid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createClient(rid, res): Observable<any> {
    return this.apiService.post(`${this._apiPath.detail}?rid=${rid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res, bid: string): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.detail}/${res.uid}/${bid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  delete(id, bid: string): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}/${bid}`).pipe(
      map(data => {
        return data
      })
    )
  }

  block(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.block_account}`, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getReservationByAgent(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.reservation_agent, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllTags(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[tags_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.tags.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createTag(res): Observable<any> {
    return this.apiService.post(this._apiPath.tags.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  mergeViewProfile(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.guest.guest_information, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  mergeGuest(group_id, res): Observable<any> {
    return this.apiService
      .post(`${this._apiPath.guest.guest_merge}/${group_id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  editTag(res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.tags.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteTag(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.tags.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getGuestDatabaseExport(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.guest.guest_export, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  createGuestDatabase(res): Observable<any> {
    return this.apiService.post(this._apiPath.create_guest, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateGuestDatabase(gtid, res: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.update_guest}/${gtid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deletedGuest(id:string, bid: string): Observable<any> {
    return this.apiService.delete(`${this._apiPath.delete_guest}/${id}/${bid}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
