import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { TableService, DirectBookingSettingService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { DBPackages } from '@hmp'

const log = new Logger('DbPackagesDetailResolver')
@Injectable({ providedIn: 'root' })
export class DbPackagesDetailResolver implements Resolve<Observable<DBPackages>> {
  constructor(private dbService: DirectBookingSettingService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['id']
    
    return this.dbService.getDBPackagesDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/direct-booking-setting/direct-booking-packages']))
    )
  }
}
