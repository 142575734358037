import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { Logger } from '@hmp/logger.service'
import { ApiService } from '@hmp/services/general/api.service'

const log = new Logger('FCMNotificationsService')
@Injectable({ providedIn: 'root' })
export class FCMNotificationsService {
  constructor(private apiService: ApiService) {}

  private _apiPath = {
    url: 'https://fcm.googleapis.com/fcm/send',
  }

  sendNotifyByTopic(
    firebaseKey,
    topic,
    notification,
    data,
    priority = 'high'
  ): Observable<any> {
    let headers = 'Authorization: key=' + firebaseKey
    let content = {
      notification: notification,
      data: data,
      to: topic,
      priority: priority,
    }

    return this.apiService.post(this._apiPath.url, content, headers).pipe(
      map(data => {
        log.debug('NOTIFY: ', data)
        return data
      })
    )
  }
}
