import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
import { ApiService } from '@hmp/services/general/api.service'
import { Logger } from '@hmp/logger.service'
import { CardTransactionFees } from '@hmp/models'

const log = new Logger('CardTransactionFeesService')

@Injectable({ providedIn: 'root' })
export class CardTransactionFeesService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    details: 'business/card_fee_entity',
    charge_stores: 'business/business_restaurants',
  }

  getAllCardTransactionFees(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.details, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  onUpdateCardTransactionFee(res: CardTransactionFees): Observable<any> {
    return this.apiService.put(`${this._apiPath.details}/${res.id}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  onAddCardTransactionFee(res: CardTransactionFees): Observable<any> {
    res.id = null
    return this.apiService.post(this._apiPath.details, res).pipe(
      map(data => {
        return data
      })
    )
  }
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.details}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetails(id: string): Observable<CardTransactionFees> {
    return this.apiService
      .get<CardTransactionFees>(`${this._apiPath.details}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getChargeStores(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.charge_stores, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
