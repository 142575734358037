import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('PLExportService')
import * as _moment from 'moment'
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms'
import { stringify } from 'querystring'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class PLExportService {
  // tslint:disable: typedef
  // tslint:disable: triple-equals
  constructor(private auth: AuthService) {}

  getCurrencyInfo() {
    const currentGeneral = this.auth.getBusinessGeneralInfo()
    let currency = 'usd'
    if (currentGeneral) {
      currency = currentGeneral.business_currency || 'usd'
    }
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateReportExcel(
    title,
    yearTitle,
    data: any[],
    sheetname,
    filename,
    logo,
    kpi?: any[],
    header1Column?,
    header2Column?,
    monthKey?
  ) {
    const workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    const worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 15,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 50 },
      { key: 'C2', width: 20 },
      { key: 'C3', width: 20 },
      { key: 'C3', width: 20 },
      { key: 'C4', width: 18 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    const titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    const titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:F4')
    const titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 23
    if (logo) {
      const logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    const headerRow2 = worksheet.addRow([])
    headerRow2.height = 23

    headerRow2.getCell(2).value = yearTitle

    headerRow2.getCell(2).alignment = {
      horizontal: 'left',
      vertical: 'middle',
    }
    headerRow2.getCell(2).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      underline: 'single',
    }

    worksheet.addRow([])

    const headerRow = worksheet.addRow(header1Column)
    headerRow.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    headerRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.border = {
          top: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
      if (cell._address === 'C9') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f36059',
          },
          bgColor: {
            argb: 'f36059',
          },
        }
      }
      if (cell._address === 'D9' || cell._address === 'E9') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '1195e5',
          },
          bgColor: {
            argb: '1195e5',
          },
        }
      }
    })
    headerRow.height = 21

    const headerRowSub = worksheet.addRow(header2Column)
    headerRowSub.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    headerRowSub.eachCell((cell, number) => {
      if (number > 2) {
        cell.border = {
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
      if (cell._address === 'C10') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f36059',
          },
          bgColor: {
            argb: 'f36059',
          },
        }
      }
      if (cell._address === 'D10' || cell._address === 'E10') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '1195e5',
          },
          bgColor: {
            argb: '1195e5',
          },
        }
      }
    })
    headerRowSub.height = 21

    worksheet.mergeCells('F9:F10')

    const abCell = worksheet.getCell('F10')
    abCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
      color: { argb: 'FFFFFF' },
    }
    abCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '636363',
      },
      bgColor: {
        argb: '636363',
      },
    }
    abCell.border = {
      bottom: { style: 'thin' },
      top: { style: 'thin' },
      right: { style: 'thin' },
    }
    abCell.alignment = { horizontal: 'center', vertical: 'middle' }
    abCell.height = 23
    abCell.value = '% (A-B)'

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      const children = r.children as any[]
      const type = r.type
      switch (type) {
        case 'root':
          const dataRootRowColumn = this.generateBoBRootColumnArr(r)
          this.generateRootRow(worksheet, r, dataRootRowColumn)
          break
        case 'rootvalue':
          const dataRowColumn = this.generateBoBRowValueColumnArr(r)
          const rootvalueRow = worksheet.addRow(dataRowColumn)
          this.formatRowDefaultValue(rootvalueRow, r)
          rootvalueRow.font = {
            name: 'Calibri',
            size: 10,
            bold: true,
          }
          rootvalueRow.eachCell((cell, number) => {
            if (number > 2) {
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              }
              if (number === 5) {
                cell.border = {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' },
                }
              }
            }
          })

          break
        default:
          break
      }
      if (children && children.length > 0) {
        children.map(c => {
          const cType = c.type
          if (cType === 'row') {
            const arrRowColumn = this.generateBoBRowValueColumnArr(c, '    ')
            const defaultRow = worksheet.addRow(arrRowColumn)
            this.formatRowDefaultValue(defaultRow, c)
            if (c.id === 'gopmargin' || c.id === 'gross_profit_margin') {
              defaultRow.font = {
                name: 'Calibri',
                size: 10,
                bold: false,
                italic: 'true',
              }
            }
          } else if (cType === 'total') {
            const dataRowColumnArr = this.generateBoBRowValueColumnArr(
              c,
              '        '
            )
            const totalRow = worksheet.addRow(dataRowColumnArr)
            this.formatRowDefaultValue(totalRow, c)
            totalRow.font = {
              name: 'Calibri',
              size: 10,
              bold: true,
            }
            totalRow.eachCell((cell, number) => {
              if (number > 2) {
                cell.border = {
                  top: { style: 'thin' },
                  right: { style: 'thin' },
                }
                if (number === 5) {
                  cell.border = {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                  }
                }
                if (number === 6) {
                  cell.border = {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                  }
                }
              }
            })
          }
        })
      }
    }
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    if (kpi && kpi.length > 0) {
      const kipTitle = `KEY OPERATING INDICATORS (currency in ${
        this.getCurrencyInfo().name
      })`
      const kpiRow = worksheet.addRow([' ', kipTitle])
      kpiRow.font = {
        name: 'Calibri',
        size: 11,
        bold: true,
      }
      for (let index = 0; index < kpi.length; index++) {
        const r = kpi[index]
        const dataRowColumn = this.generateKPIColumnArr(r, monthKey, '')
        const defaultRow = worksheet.addRow(dataRowColumn)
        this.formatKPIValue(defaultRow, r, true)
      }
    }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private getCellFormat(row) {
    if (
      row.id === 'gopmargin' ||
      row.id === 'gross_profit_margin' ||
      row.id === 'paid_occupancy'
    ) {
      return '0.0%'
    }
    return '#,##0'
  }

  private generateRootRow(worksheet, r, column) {
    const dataRow = worksheet.addRow(column)
    dataRow.height = 21
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(2).font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    dataRow.getCell(3).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(4).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(5).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(6).border = {
      right: { style: 'thin' },
    }
    return dataRow
  }

  private formatRowDefaultValue(dataRow, r, isKpi?) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        cell.border = {
          right: { style: 'thin' },
        }
      }
    })
    dataRow.height = 18
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    if (isKpi) {
      dataRow.getCell(2).alignment = { horizontal: 'right', vertical: 'middle' }
    }
    dataRow.getCell(3).numFmt = this.getCellFormat(r)
    dataRow.getCell(3).border = {
      right: { style: 'thin' },
    }

    dataRow.getCell(4).numFmt = this.getCellFormat(r)
    dataRow.getCell(4).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(5).numFmt = this.getCellFormat(r)
    dataRow.getCell(5).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(6).numFmt = '0.0%'
    // log.error('dataRow', dataRow)
    if (
      r.monthvalues.ab == Infinity ||
      r.monthvalues.ab == -Infinity ||
      Number.isNaN(r.monthvalues.ab)
    ) {
      // dataRow.getCell(6)._value.model.value = ''
    }
    return dataRow
  }

  private generateBoBRootColumnArr(data) {
    const row = []
    row.push('')
    row.push(data.title)
    row.push('')
    row.push('')
    return row
  }

  private generateBoBRowValueColumnArr(data, prefixText?) {
    const monthValue = data.monthvalues
    const row = []
    row.push('')
    if (prefixText) {
      row.push(prefixText + data.title)
    } else {
      row.push(data.title)
    }
    row.push(monthValue['bob'])
    row.push(monthValue['budget'])
    row.push(monthValue['actual'])
    row.push(monthValue['ab'])
    return row
  }

  private generateKPIColumnArr(data, monthKey, prefixText?) {
    const monthValue = data.monthvalues
    const row = []
    row.push('')
    if (prefixText) {
      row.push(prefixText + data.title)
    } else {
      row.push(data.title)
    }
    row.push(monthValue[monthKey])
    return row
  }

  private formatKPIValue(dataRow, r, isKpi?) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.height = 18
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    if (isKpi) {
      dataRow.getCell(3).alignment = { horizontal: 'right', vertical: 'middle' }
      dataRow.getCell(3).numFmt = this.getCellFormat(r)
    }
    return dataRow
  }
}
