import { Injectable } from '@angular/core'
import { AngularFireMessaging } from '@angular/fire/messaging'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { AuthService, ApiService, NotificationsService } from '@hmp/services'
import { environment } from 'environments/environment'
import { LocalStorageService } from '@hmp/helper'
import { Logger } from '@hmp/logger.service'
import { tap } from 'rxjs/operators'

const log = new Logger('WebNotificationsService')
@Injectable({
  providedIn: 'root',
})
export class WebNotificationsService {
  isSubscribe$ = new BehaviorSubject(false)

  private _apiPath = {
    subscribe_topic: 'global/topics_firebase/register',
  }
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private apiServices: ApiService,
    private auth: AuthService,
    private storage: LocalStorageService
  ) {}

  get currentTopic() {
    return this.storage.getNotificationToken()
  }

  get isTopicAllow(): boolean {
    const no = this.currentTopic
    return no && no.token && no.topic
  }

  onTokenChanges(): Observable<string> {
    return this.angularFireMessaging.tokenChanges
  }

  onRequestPermission(): Observable<string> {
    return this.angularFireMessaging.requestToken
  }

  onReceiveMessages(): Observable<any> {
    return this.angularFireMessaging.messages
  }

  onSubscribeTopic(token): Observable<any> {
    const uid = this.auth.getCurrentUserId()
    if (!uid) {
      return
    }
    const topic = `webapp_${environment.name.toLowerCase()}_${this.auth.getActiveLocalBusinessID()}_${uid}`
    const res = {
      token: token,
      topic: topic,
    }
    return this.apiServices.post(this._apiPath.subscribe_topic, res).pipe(
      tap(data => {
        log.debug('TOKEN SUBSCRIBE DATA: ', res, data)
        this.storage.saveNotificationToken(res)
        this.isSubscribe$.next(true)
      })
    )
  }

  onUnsubscribeTopic(): Observable<any> {
    const res = this.currentTopic
    const topic = res && res.topic
    const token = res && res.token
    if (!topic || !token) {
      return of(null)
    }
    return this.apiServices
      .post(this._apiPath.subscribe_topic, {
        token: token,
        topic: topic,
        type: 'delete',
      })
      .pipe(
        tap(data => {
          log.debug('UNSUBSCRIBE DATA: ', data)
          this.storage.removeNotificationToken()
          this.isSubscribe$.next(false)
        })
      )
  }
}
