import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

const log = new Logger('PaymenAbaService')

/**
 * Đây là services dành cho Payment
 */

@Injectable({ providedIn: 'root' })
export class PaymenAbaService {
  constructor(
    private apiService: ApiService,
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    payment_aba: 'payment/payment_entity/res_paid_payment',
  }

  /**
   * Lấy tất cả Payment đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  
  
  payAba(res): Observable<any> {
    return this.apiService.post(this._apiPath.payment_aba, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
