import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { AuthService } from '@hmp/services/general/auth.service'

const log = new Logger('PaymentGatewaysService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class PaymentGatewaysService {
  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    providers: 'payment_gateway_providers',
    payment_gateways: 'payment_gateways',
    update_status: 'payment_gateways/status',
    pm_gateway_detail: 'business/business_online_payment_method',
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.payment_gateways, pa).pipe(
      map(data => {
        if (data && data.data.length > 0) {
          this.auth.setPG(data)
        }
        return data
      })
    )
  }

  getPMGatewayDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.pm_gateway_detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getPGMethod(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('display', '1')
      .set('status', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.payment_gateways, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getPGsProvider(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.providers, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  update(id: string, body: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.payment_gateways}/${id}`, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res): Observable<any> {
    return this.apiService.post(this._apiPath.payment_gateways, res).pipe(
      map(data => {
        return data
      })
    )
  }
  updateStatus(res): Observable<any> {
    return this.apiService.post(this._apiPath.update_status, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.payment_gateways}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.payment_gateways}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
