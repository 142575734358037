import { Injectable } from '@angular/core'
import { Business } from '../../../models'
import { ApiService } from '../../general/api.service'
import { Observable, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { LocalStorageService } from '../../../helper'
import { AuthService } from '../../../services/general/auth.service'
import { HttpParams } from '@angular/common/http'

const log = new Logger('BusinessService')

/**
 * Đây là service xử lý về Business
 */
@Injectable({ providedIn: 'root' })
export class BusinessService {
  isBusinessChange$ = new BehaviorSubject<boolean>(false)
  isBusinessChange = this.isBusinessChange$.asObservable()
  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private storage: LocalStorageService
  ) {}

  private businessSubject = new BehaviorSubject<Business[]>({} as Business[])
  public MyBusinessList = this.businessSubject.asObservable()

  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    listbusiness: 'business/business_entity',
    businessdetail: 'business/business_entity',
    mybusines: 'business/my_business',
    dashboard_fd_tab: 'business/dashboard_fd_tab',
    report_agent_production: 'business/report_agent_production',
    addnealy: 'business/business_entity',
    property_facilities: 'business/business_entity',
    timezone: 'business/business_list_timezones',
    business_setting: 'business/business_setting_entity',
    restaurantPOS: 'business/business_restaurants',
    payment_gateways: 'payment_gateways',
    get_business:'business/get_business'
  }

  /**
   * Lấy tất cả rooms đang available
   */
  getAllBusiness(): Observable<[Business]> {
    return this.apiService.get<[Business]>(this._apiPath.listbusiness).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin business đã sub.
   */
  getMyBusiness(isSetFirst: boolean = false): Observable<[Business]> {
    return this.apiService.get<[Business]>(this._apiPath.mybusines).pipe(
      map(data => {
        log.debug('getMyBusiness: ', data)
        this.businessSubject.next(data)
        if (isSetFirst && data) {
          const firstB = data[0]
          log.debug('First: ', firstB)
          if (firstB) {
            this.auth.setLocalBusiness(firstB)
            this.auth.setActiveBusiness(firstB)
          }
        }
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Room dưạ vào @roomId
   * @param id ID của Room
   */
  getBusinessDetail(id: string): Observable<Business> {
    return this.apiService
      .get<Business>(`${this._apiPath.businessdetail}/${id}`)
      .pipe(
        map(data => {
          // log.error('BUSINESS DETAIL', data)
          // if (data && data.bid) {
          //   this.isBusinessChange$.next(true)
          // }
          // this.auth.setActiveBusiness(data)
          return data
        })
      )
  }

  getBusinessDetails(id: string): Observable<Business> {
    return this.apiService
      .get<Business>(`${this._apiPath.get_business}/${id}`)
      .pipe(
        map(data => {
          if (data && data.bid) {
            this.isBusinessChange$.next(true)
          }
          this.auth.setActiveBusiness(data)
          return data
        })
      )
  }

  /**
   *
   * @param room Thông tin phòng
   */
  createBusiness(bus: Business): Observable<any> {
    bus.bid = null
    return this.apiService.post(this._apiPath.businessdetail, bus).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin room
   *
   * @param room Thông tin room muốn chỉnh sửa
   */
  editBusiness(bus: Business): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.businessdetail}/${bus.bid}`, bus)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Xóa thông tin room
   *
   * @param rid Xóa room bằng Room ID
   */
  deleteBusiness(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.businessdetail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllTimeZone(): Observable<any> {
    return this.apiService.get<any>(this._apiPath.timezone).pipe(
      map(data => {
        return data
      })
    )
  }

  getFDTab(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.dashboard_fd_tab, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getReportAgentProduction(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(this._apiPath.report_agent_production, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getRestaurantPOS(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.restaurantPOS, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   *
   *  Thông tin nearly place
   */
  createNearlyPlace(bus: Business): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.addnealy}/${bus.bid}`, bus)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateNearlyPlace(bus: Business): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.addnealy}/${bus.bid}`, bus)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  /**
   *
   *  Thông tin Property facilities
   */
  createPropertyFacilities(bus: Business): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.property_facilities}/${bus.bid}`, bus)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updatePropertyFacilities(bus: Business): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.property_facilities}/${bus.bid}`, bus)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createNightAuditSetting(res): Observable<any> {
    return this.apiService.post(this._apiPath.business_setting, res).pipe(
      map(data => {
      })
    )
  }

  getBusinessSetting(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.business_setting}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
