import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  HostListener,
} from '@angular/core'
import { Logger } from '../logger.service'

const log = new Logger('HMPCalendarWeekHoverDirective')
@Directive({
  selector: '[WHover]',
})
export class HMPCalendarWeekHoverDirective {
  @Input() WeekHover: string

  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  mouseover(event: Event) {
    if (!this.WeekHover) return
    let week = '.week-' + this.WeekHover
    this.elem.nativeElement.parentElement
      .querySelectorAll(week)
      .forEach(sel => {
        this.renderer.addClass(sel, 'week-hover')
      })
  }
  @HostListener('mouseleave')
  mouseleave(event: Event) {
    if (!this.WeekHover) return
    let week = '.week-' + this.WeekHover
    this.elem.nativeElement.parentElement
      .querySelectorAll(week)
      .forEach(sel => {
        this.renderer.removeClass(sel, 'week-hover')
      })
  }
}
