import { Injectable } from '@angular/core'
import { CoreIcon } from './core-icon.type'

@Injectable({
  providedIn: 'root',
})
export class CoreIconsRegistry {
  private reg = new Map<string, string>()

  public registerIcons(icons: CoreIcon[]): void {
    icons.forEach((i: CoreIcon) => this.reg.set(i.name, i.svg))
  }

  public getIcon(name: string): string | undefined {
    if (!this.reg.has(name)) {
      console.warn(`${name} not found.`)
    }
    return this.reg.get(name)
  }
}
