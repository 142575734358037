import { Injectable } from '@angular/core'
import { Excursion } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { LocalStorageService } from '@hmp/helper'
const log = new Logger('ExcursionService')
@Injectable({
  providedIn: 'root',
})
export class ExcursionService {
  constructor(
    private apiService: ApiService,
    private globalEntityServices: GlobalEntityService,
    private jwtService: JwtService,
    private router: Router,
    private fieldInfo: FieldInfoService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    listexcursion: 'activities/excursion_entity',
    excursiondetail: 'activities/excursion_entity',
    excursionStatus: 'activities/excursion_entity/change_status',
    excursionAccount: 'business/get_all_supplier',
    excursionReport: 'activities/excursion_report',
  }

  getAll(rid): Observable<Excursion[]> {
    // let pa = new HttpParams();
    // let pa = new HttpParams().set("parameters[activities_business_id]", this.storage.getActiveLocalBusinessID());
    // if (params) {
    //   Object.keys(params).forEach(function (key) {
    //     pa = pa.append(key, params[key]);
    //   });
    // }
    return this.apiService
      .get<Excursion[]>(`${this._apiPath.listexcursion}/${rid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllExcursion(params?: any): Observable<any> {
    let pa = new HttpParams()
    // let pa = new HttpParams().set("parameters[activities_template]", 1);
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.listexcursion, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  /**
   * Lấy tất cả rooms đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */

  editExcursion(excursion: Excursion): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.excursiondetail}/${excursion.eid}`, excursion)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  /**
   *
   * @param excursion Thông tin
   */
  createExcursion(excursion: Excursion): Observable<any> {
    return this.apiService.post(this._apiPath.excursiondetail, excursion).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteEx(eid): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.excursiondetail}/${eid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  reportEx(eid?: any): Observable<any> {
    let params = new HttpParams().set('eid', eid)
    return this.apiService
      .get(`${this._apiPath.excursionReport}?${params}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllreportEx(eid, fromDate, toDate, type): Observable<any> {
    let pa = new HttpParams()
      .set('eid', eid)
      .set('from', fromDate)
      .set('to', toDate)
      .set('type', type)
    return this.apiService.get(this._apiPath.excursionReport, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Reservation dưạ vào @resId
   * @param Id ID của Reservation
   */
  getDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.excursiondetail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  changeStatus(status): Observable<any> {
    return this.apiService.post(this._apiPath.excursionStatus, status).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllEXAccount(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.excursionAccount, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
