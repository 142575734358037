import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { LocalStorageService } from '@hmp/helper'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

const log = new Logger('PasswordService')

/**
 * Đây là services dành cho ReportsService
 */
@Injectable({ providedIn: 'root' })
export class PasswordService {
  constructor(
    private apiService: ApiService,
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    ckeck_pass: 'user_oauth/check_pass',
  }

  create(res): Observable<any> {
    return this.apiService.post(this._apiPath.ckeck_pass, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  
}
