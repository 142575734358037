import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import { LocalStorageService } from '../../helper'

const log = new Logger('JwtService')

@Injectable({ providedIn: 'root' })
export class JwtService {
  constructor(private storage: LocalStorageService) {}

  getToken(): string {
    return this.storage.getUserToken()
  }

  getRefreshToken(): string {
    return this.storage.getUserRefreshToken()
  }

  verifyToken() {
    let curAccessToken = this.getToken()
    if (curAccessToken) {
    }
  }

  saveToken(currentUser: any) {
    this.destroyToken()
    this.storage.saveUserToken(currentUser)
  }

  destroyToken() {
    this.storage.destroyUserToken()
  }
}
