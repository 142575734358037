import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('GuestExportDatabaseService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class RoomRegisterExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateReportExcel(title, data: any, sheetname, filename) {
    const headerRow1Columns = [
      '',
      'Room Type',
      'Room Number',
      'Room Name',
      'Ownership',
      'Status',
      'Reservation Number',
      'Guest Name',
      'Check-In Date',
      'Check-Out Date',
    ]

    const headerRow2Columns = [
      '',
      'Room Type',
      'Room Number',
      'Room Name',
      'Ownership',
      'Status',
      'Occupant Type',
      'Occupant Name',
      'Occupancy Start Date',
      'Occupancy End Date',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: true,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: true }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 30 },
      { key: 'C2', width: 30 },
      { key: 'C3', width: 30 },
      { key: 'C4', width: 30 },
      { key: 'C5', width: 20 },
      { key: 'C6', width: 30 },
      { key: 'C7', width: 30 },
      { key: 'C8', width: 30 },
      { key: 'C9', width: 30 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `ROOM REGISTER ${moment.utc().format('DD-MMM-YY')}`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:J4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    worksheet.addRow([])

    let hotelRental = worksheet.addRow([])
    hotelRental.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    hotelRental.alignment = { horizontal: 'left', vertical: 'middle' }
    hotelRental.getCell(2).value = 'Hotel Rental Pool'
    hotelRental.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 20

    if (data && data.rental_pool) {
      for (let index = 0; index < data.rental_pool.length; index++) {
        const r = data.rental_pool[index]
        let dataRow = worksheet.addRow()
        dataRow.font = {
          name: 'Calibri',
          size: 10,
          bold: true,
        }
        dataRow.getCell(2).value = r.room_type
        for (let index = 0; index < r.data.length; index++) {
          const room = r.data[index]
          let dataRowColumn = this.generateProductionReportColumnArr(room)
          let dataRow = worksheet.addRow(dataRowColumn)
          dataRow.font = {
            name: 'Calibri',
            size: 10,
            bold: false,
          }
          dataRow.height = 17
          dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
          dataRow.getCell(2).alignment = { horizontal: 'left' }
          dataRow.getCell(3).alignment = { horizontal: 'center' }
          dataRow.getCell(4).alignment = { horizontal: 'center' }
          dataRow.getCell(5).alignment = { horizontal: 'center' }
          dataRow.getCell(6).alignment = { horizontal: 'center' }
          switch (room.status) {
            case 'Blocked':
              dataRow.getCell(6).font = { color: { argb: '428bca' } }
              break
            case 'Available':
              dataRow.getCell(6).font = { color: { argb: '68ac6f' } }
              break
            case 'Occupied':
              dataRow.getCell(6).font = { color: { argb: 'e66e6f' } }
              break
            case 'Dirty':
              dataRow.getCell(6).font = { color: { argb: 'dc8a02' } }
              break
            default:
              dataRow.getCell(6).font = { color: { argb: 'dc8a02' } }
              break
          }
          dataRow.getCell(7).alignment = { horizontal: 'center' }
          dataRow.getCell(8).alignment = { horizontal: 'center' }
          dataRow.getCell(9).alignment = { horizontal: 'center' }
        }
      }
    }

    worksheet.addRow([])
    worksheet.addRow([])

    let hotelOtherUnit = worksheet.addRow([])
    hotelRental.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    hotelOtherUnit.alignment = { horizontal: 'left', vertical: 'middle' }
    hotelOtherUnit.getCell(2).value = 'Other Units'
    hotelOtherUnit.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }

    let headerRow2 = worksheet.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow2.height = 20

    if (data && data.other_units) {
      for (let index = 0; index < data.other_units.length; index++) {
        const r = data.other_units[index]
        let dataRow = worksheet.addRow()
        dataRow.font = {
          name: 'Calibri',
          size: 10,
          bold: true,
        }
        dataRow.getCell(2).value = r.room_type
        for (let index = 0; index < r.data.length; index++) {
          const room = r.data[index]
          let dataRowColumn = this.generateOtherUnitsReportColumnArr(room)
          let dataRow = worksheet.addRow(dataRowColumn)
          dataRow.font = {
            name: 'Calibri',
            size: 10,
            bold: false,
          }
          dataRow.height = 17
          dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
          dataRow.getCell(2).alignment = { horizontal: 'left' }
          dataRow.getCell(3).alignment = { horizontal: 'center' }
          dataRow.getCell(4).alignment = { horizontal: 'center' }
          dataRow.getCell(5).alignment = { horizontal: 'center' }
          dataRow.getCell(6).alignment = { horizontal: 'center' }
          switch (room.status) {
            case 'Blocked':
              dataRow.getCell(6).font = { color: { argb: '428bca' } }
              break
            case 'Available':
              dataRow.getCell(6).font = { color: { argb: '68ac6f' } }
              break
            case 'Occupied':
              dataRow.getCell(6).font = { color: { argb: 'e66e6f' } }
              break
            case 'Dirty':
              dataRow.getCell(6).font = { color: { argb: 'dc8a02' } }
              break
            default:
              dataRow.getCell(6).font = { color: { argb: 'dc8a02' } }
              break
          }
          dataRow.getCell(7).alignment = { horizontal: 'center' }
          dataRow.getCell(8).alignment = { horizontal: 'center' }
          dataRow.getCell(9).alignment = { horizontal: 'center' }
        }
      }
    }

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.room_type)
    row.push(data.room_number)
    row.push(data.room_name)
    row.push(data.ownership)
    row.push(data.status)
    row.push(data.reservation_number)
    row.push(data.guest_name)
    const checkin = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
    const checkout = moment.utc(moment.unix(data.check_out)).format('DD-MMM-YY')
    data.check_in ? row.push(checkin) : row.push('')
    data.check_out ? row.push(checkout) : row.push('')

    return row
  }

  private generateOtherUnitsReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.room_type)
    row.push(data.room_number)
    row.push(data.room_name)
    row.push(data.ownership)
    row.push(data.status)
    row.push(data.occupant_type)
    row.push(data.guest_name)
    const checkin = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
    const checkout = moment.utc(moment.unix(data.check_out)).format('DD-MMM-YY')
    data.check_in ? row.push(checkin) : row.push('')
    data.check_out ? row.push(checkout) : row.push('')

    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
