import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
import { GuestContactCenter } from '@hmp'
const log = new Logger('GuestContactCenterService')
@Injectable({
  providedIn: 'root',
})
export class GuestContactCenterService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private storage: LocalStorageService
  ) { }

  private _apiPath = {
    list: 'contact_center/contact_center_entity',
    detail: 'contact_center/contact_center_entity',
    list_type: 'contact_center/contact_center_list_type',
    add_new_comment: 'contact_center/contact_center_entity/contact_center_add_comment',
    list_comment: 'contact_center/contact_center_list_comment',
  }
  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[cc_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getDetailContactCenter(id: string): Observable<GuestContactCenter> {
    return this.apiService
      .get<GuestContactCenter>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  getAllType(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_type, pa).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
  addComment(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_new_comment, res).pipe(
      map(data => {
        log.debug('ADD CMT', data)
        return data
      })
    )
  }
  getAllCommentCC(cid, params?: any): Observable<any[]> {
    let pa = new HttpParams().set('bid', this.storage.getActiveLocalBusinessID()).set('cid', cid)
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.list_comment, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
