import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HMPContentPermissionDirective } from './hmp-content-permission.directive'
import { HMPFieldsPermissionDirective } from './hmp-field-permission.directive'
import { InputNumberDirective } from './input-number.directive'
import { HMPCalendarWeekHoverDirective } from './hmp-calendar-weekhover.directive'
import { InputFocusDirective } from './input-focus.directive'
import { HMPScrollSpyDirective } from './hmp-scrollspy.directive'
import { InputTrimDirective } from './input-trim.directive'
import { CustomTooltipDirective } from './custom-tooltip.directive'
// import { HMPDraggableDirective } from './hmp-draggable.directive'
const DIRECTIVES = [
  HMPContentPermissionDirective,
  HMPFieldsPermissionDirective,
  InputNumberDirective,
  HMPCalendarWeekHoverDirective,
  HMPScrollSpyDirective,
  InputFocusDirective,
  CustomTooltipDirective,
  InputTrimDirective,
  // HMPDraggableDirective,
]
@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  providers: [],
  exports: [CommonModule, ...DIRECTIVES],
})
export class HMPDirectivesModule {}
