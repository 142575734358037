import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: "orderBy", pure: false})
export class SortByPipe implements PipeTransform {
  transform(array: Array<any>) {
    if(array){
      return array.sort((a, b) => a.title.localeCompare(b.title));
      // return array.sort((a, b) => {
      //   const [stringA, numberA] = this.order(a.title);
      //   const [stringB, numberB] = this.order(b.title);
      //   const comparison = stringA.toString().localeCompare(stringB.toString());
      //   return comparison === 0 ? Number(numberA) - Number(numberB) : comparison;
      // })
    }
  }

  //  order (item) {
  //     const [, stringPart = '', numberPart = 0] = /(^[a-zA-Z]*)(\d*)$/.exec(item) || [];
  //   return [stringPart, numberPart];
  // }
}