import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { GuestDossier } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('GuestDossierService')

@Injectable({
  providedIn: 'root',
})
export class GuestDossierService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'guest_dossier/guest_dossier_entity',
    detail: 'guest_dossier/guest_dossier_entity',
  }
  /**
   * Lấy tất cả GuestFolio đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams()
    // let pa = new HttpParams().set(
    //   "parameters[gt_agent]",
    //   this.storage.getActiveLocalBusinessID()
    // );
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
  getDetail(id: string): Observable<GuestDossier> {
    return this.apiService
      .get<GuestDossier>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  edit(res: GuestDossier): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.gdid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
  create(res: GuestDossier): Observable<any> {
    res.gdid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        log.debug(data)
        return
      })
    )
  }
}
