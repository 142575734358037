import { Injectable } from '@angular/core'
import {
  FBCategory,
  FBItems,
  FBIAttributes,
  FBOrder,
  Business,
  FBPromotions,
} from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('FoodBeverageService')

@Injectable({ providedIn: 'root' })
export class FoodBeverageService {
  constructor(
    private apiService: ApiService,
    private globalEntityServices: GlobalEntityService,
    private jwtService: JwtService,
    private router: Router,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    menu: {
      all: 'fb/list_all_items',
      detail: 'fb/fb_item_entity',
    },
    category: {
      list: 'fb/fb_category_entity',
      detail: 'fb/fb_category_entity',
    },
    order_history: {
      list: 'fb/fb_order_entity',
      detail: 'fb/fb_order_entity',
      void_order: 'fb/kitchen_orders/void_order',
    },
    items: {
      list: 'fb/fb_item_entity',
      detail: 'fb/fb_item_entity',
      update: 'fb/kitchen_orders/update_line_item_detail',
      custom_item_price: 'fb/fb_order_entity/custom_price_line_item',
      send_email_fb: 'fb/kitchen_orders/send_email_fb_order',
    },
    attribute: {
      list: 'fb/fb_attributes_entity',
      detail: 'fb/fb_attributes_entity',
    },
    table: {
      list: 'table/table_entity',
      available: 'table/table_available',
    },
    pos: {
      guest: 'reservations/reservations_pos',
      open_table: 'table/table_activities',
      long_term_resident: 'business/long_term_resident',
      ltr_create_order: 'business/long_term_resident/create_order',
    },
    attr_option: {
      list: 'fb/fb_attr_option_entity',
      detail: 'fb/fb_attr_option_entity',
    },
    orders: {
      list: 'fb/fb_order_entity',
      listFBAccount: 'business/get_all_supplier',
      addItem: 'fb/fb_order_entity/add_item_to_card',
      changeQTyLineItem: 'fb/fb_order_entity/change_qty_line_item',
      removeLineItem: 'fb/fb_order_entity/remove_line_item',
      voidingLineItem: 'fb/fb_order_entity/voiding_line_item',
      changeOrderStatus: 'fb/fb_order_entity/order_change_status',
      confirmOrderActivities: 'fb/fb_order_entity/comfirm_order_activities',
      updateCartOrder: 'fb/fb_order_entity/update_cart_order',
      remove_oid: 'fb/fb_order_entity',
      create_kitchen_order: 'fb/kitchen_orders/create_kitchen_orders',
      add_item: 'fb/kitchen_orders/add_item_to_exist_cart',
      order_detail: 'fb/fb_order_entity',
      add_coupon: 'fb/discount_order_item/add_coupon',
      add_service_charge: 'fb/kitchen_orders/add_service_charge',
      remove_coupon: 'fb/discount_order_item/remove_coupon',
      close_order: 'fb/kitchen_orders/close_order',
      re_close_order: 'fb/kitchen_orders/re_close_order',
      activate_kitchen_order: 'fb/kitchen_orders/create_kitchen_orders',
      create_opentab_order: 'activities/open_tabs/create_order_open_tabs',
      send_to_kitchen: 'fb/kitchen_orders/send_lineitem_to_kitchen_order',
      pos_combine_order: 'fb/fb_order_entity/pos_combine_order',
      pos_division_order: 'fb/fb_order_entity/pos_division_order',
      pos_split_order: 'fb/fb_order_entity/pos_split_order',
    },
    reservation_room_active: 'reservations/reservation_room_active',
    list_accouting_codes: 'manager/account_code_entity',
    edit_account_code_fb: 'fb/fb_order_entity/edit_accounting_codes',
    invoice: {
      create_invoice: 'reservations/tax_invoice',
      invoice: 'reservations/tax_invoice',
      send_email_invoice: 'reservations/tax_invoice/send_mail',
    },
    coupon: {
      list_coupon: 'promotions/discount_entity',
      detail_coupon: 'promotions/discount_entity',
      detail: 'fb/discount_order_item',
      delete: 'promotions/promotions_entity',
    },
    export_invoice_pdf: 'reservations/tax_invoice/export_pdf',
    list_pm_method: 'payment/pay_method_entity',
    create_delay_time: 'fb/kitchen_orders/update_pax_delay_times',
    create_tax_invoice_fb:
      'reservations/invoice_generator_entity/create_fab_invoice',
    update_fb_bill_to_invoice:
      'reservations/invoice_generator_entity/update_bill_to',
    hide_tax: 'taxes/taxes_entity/add_hidden_entity',
    fb_serviceCharge_settings:
      'servicecharge/sc_settings_entity/switch_on_off_charge',
    fb_tax_settings: 'business/taxes_entity/switch_on_off_tax_entity',
    fb_generate_discount: 'fb/kitchen_orders/generate_discount',
    move_table: 'table/table_activities/move_table',
    pay_order_via_ksher:"fb/kitchen_orders/close_order"
  }

  createCouponItem(val: FBPromotions): Observable<any> {
    return this.apiService.post(this._apiPath.coupon.detail, val).pipe(
      map(data => {
        return data
      })
    )
  }
  editCouponItem(res?, did?): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.coupon.detail}/${did}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  deleteCouponItem(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.coupon.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getDetailCoupon(id: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.coupon.detail_coupon}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllCouponItem(params?: any, type = 'fb_order'): Observable<any> {
    let pa = new HttpParams()
      .set(
        'parameters[discount_business_id]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('parameters[discount_type]', type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.coupon.list_coupon, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getResRoomActivePOS(date?, params?: any): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any[]>(this._apiPath.reservation_room_active, pa)
      .pipe(
        map(data => {
          log.debug('DATA ROOMS ACTIVE', data)
          return data
        })
      )
  }
  getListAccountingCodes(account_code?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[ac_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', 'true')
      .set('parameters[ac_number_type]', account_code)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_accouting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllFBCategory(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[fb_category_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<FBCategory>(this._apiPath.category.list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllOrderHistory(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[fb_order_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.order_history.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetailOrder(id: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.order_history.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllFBItems(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[item_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.items.list, pa).pipe(
      map(data => {
        if (data) {
          return data
        }
      })
    )
  }

  getAllFB(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.menu.all, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllFBAttributes(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[attributes_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.attribute.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllOptionByAttr(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.attr_option.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getFBCategoryDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.category.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getFBItemsDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.items.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  createFBCategory(res): Observable<any> {
    res.cid = null
    return this.apiService.post(this._apiPath.category.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createFBItem(res): Observable<any> {
    res.itid = null
    return this.apiService.post(this._apiPath.items.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createFBAttr(res): Observable<any> {
    res.aid = null
    return this.apiService.post(this._apiPath.attribute.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createFBAttrOption(res): Observable<any> {
    res.oid = null
    return this.apiService.post(this._apiPath.attr_option.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  editFBCategory(res, cid): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.category.detail}/${cid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  editFBItems(res, itid): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.items.detail}/${itid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  editFBAttr(res, aid): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.attribute.detail}/${aid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  editFBAttrOption(res, oid): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.attr_option.detail}/${oid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteFBCategory(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.category.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteFBItems(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.items.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteFBOrderItem(oid): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.orders.remove_oid}/${oid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteFBAttr(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.attribute.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteFBAttrOption(oid): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.attr_option.detail}/${oid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getFBListOrders(params?: any): Observable<FBOrder> {
    let pa = new HttpParams().set(
      'parameters[fb_order_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<FBOrder>(this._apiPath.orders.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getOrderById(oid): Observable<FBOrder> {
    return this.apiService
      .get<FBOrder>(`${this._apiPath.orders.list}/${oid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getFBLisNewtOrders(params?: any): Observable<FBOrder> {
    let pa = new HttpParams().set(
      'parameters[fb_order_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<FBOrder>(this._apiPath.orders.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getListAccountFB(params?: any): Observable<Business[]> {
    let pa = new HttpParams()
      .set('business_id', this.storage.getActiveLocalBusinessID())
      .set('type', '3')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<Business[]>(this._apiPath.orders.listFBAccount, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  addItemToCart(
    itemId,
    quantily,
    reservations_id,
    attr_options
  ): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.addItem, {
        business_id: this.storage.getActiveLocalBusinessID(),
        item_id: itemId,
        quantily: quantily,
        reservations_id: reservations_id,
        attr_options: attr_options,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  changeQTyLineItem(line_item_id, quantily): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.changeQTyLineItem, {
        line_item_id: line_item_id,
        qty: quantily,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  removeLineItem(line_item_id, order_id): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.removeLineItem, {
        line_item_id: line_item_id,
        order_id: order_id,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  voidingLineItem(line_item_id): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.voidingLineItem, {
        line_item_id: line_item_id,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  changeOrderStatus(status, order_id): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.changeOrderStatus, {
        status: status,
        order_id: order_id,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  confirmOrderActivities(
    reservations_id,
    order_id,
    datetime,
    supplier_id,
    note,
    type_pay,
    user_pay,
    fb_meal_number,
    title,
    room_id?
  ): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.confirmOrderActivities, {
        reservations_id: reservations_id,
        order_id: order_id,
        datetime: datetime,
        supplier_id: supplier_id,
        note: note,
        type_pay: type_pay,
        user_pay: user_pay,
        fb_meal_number: Number(fb_meal_number),
        title: title,
        room_id: room_id,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateCartOrder(order_id, line_items): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.updateCartOrder, {
        order_id: order_id,
        line_items: line_items,
      })
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateItemOrder(itemOrder): Observable<any> {
    return this.apiService.post(this._apiPath.items.update, itemOrder).pipe(
      map(data => {
        return data
      })
    )
  }

  getTableList(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[table_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.table.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getPOSGuest(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.pos.guest, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getLongTermResident(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.pos.long_term_resident, pa)
      .pipe(
        map(data => {
          return data && data.data
        })
      )
  }

  getTableAvailable(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any[]>(this._apiPath.table.available, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createKitchenOrder(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.create_kitchen_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  onPayOrderViaKsher(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.pay_order_via_ksher, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  onRePayOrderViaKsher(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.re_close_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  ltrCreateOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.pos.ltr_create_order, res).pipe(
      map(data => {
        return data
      })
    )
  }

  addItemToOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.add_item, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getPOSGuestOrderByOID(oid, params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(`${this._apiPath.orders.order_detail}/${oid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  addCouponCode(res) {
    return this.apiService.post(this._apiPath.orders.add_coupon, res).pipe(
      map(data => {
        return data
      })
    )
  }
  addServiceCharge(res) {
    return this.apiService
      .post(this._apiPath.orders.add_service_charge, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  removeCouponCode(res) {
    return this.apiService.post(this._apiPath.orders.remove_coupon, res).pipe(
      map(data => {
        return data
      })
    )
  }

  closeOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.close_order, res).pipe(
      map(data => {
        return data
      })
    )
  }
  reCloseOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.re_close_order, res).pipe(
      map(data => {
        return data
      })
    )
  }

  activateActiveOrders(res): Observable<any> {
    res.itid = null
    return this.apiService
      .post(this._apiPath.orders.activate_kitchen_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getPOSOpenTab(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.pos.open_table, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createOpenTabsOrder(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.create_opentab_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  sendItemsToKitchen(bid, oid) {
    let res = {
      business_id: bid,
      order_id: oid,
    }
    return this.apiService.post(this._apiPath.orders.send_to_kitchen, res).pipe(
      map(data => {
        return data
      })
    )
  }

  combineTable(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.pos_combine_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  divisionOrder(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.orders.pos_division_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  splitOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.orders.pos_split_order, res).pipe(
      map(data => {
        return data
      })
    )
  }

  voidOrder(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.order_history.void_order, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  customItemPrice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.items.custom_item_price, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  sendemailFB(res): Observable<any> {
    return this.apiService.post(this._apiPath.items.send_email_fb, res).pipe(
      map(data => {
        return data
      })
    )
  }
  editAccountCodeFB(res): Observable<any> {
    return this.apiService.post(this._apiPath.edit_account_code_fb, res).pipe(
      map(data => {
        return data
      })
    )
  }
  getDataInvoice(entity_id?, entity_type?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('entity_id', entity_id)
      .set('entity_type', entity_type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.invoice.invoice, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  createInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.invoice.create_invoice, res).pipe(
      map(data => {
        return data
      })
    )
  }
  sendEmailFbInvoice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.invoice.send_email_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  exportInvoicePDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_invoice_pdf,
      res,
      null,
      null,
      'blob'
    )
  }

  getListPaymentMethodFB(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[pm_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
      .set('bill_to', '1')
      .set('foc', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_pm_method, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  createDelayTime(res): Observable<any> {
    return this.apiService.post(this._apiPath.create_delay_time, res).pipe(
      map(data => {
        return data
      })
    )
  }

  create_tax_invoice_fb(res): Observable<any> {
    return this.apiService.post(this._apiPath.create_tax_invoice_fb, res).pipe(
      map(data => {
        return data
      })
    )
  }
  updateFbBillToInvoiceGenerator(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.update_fb_bill_to_invoice, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createHideTax(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.hide_tax, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateServiceChargesSetting(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.fb_serviceCharge_settings, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateTaxesSetting(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.fb_tax_settings, res).pipe(
      map(data => {
        return data
      })
    )
  }
  
  fbGenerateDiscount(res): Observable<any> {
    return this.apiService.post(this._apiPath.fb_generate_discount, res).pipe(
      map(data => {
        return data
      })
    )
  }
  
  moveTableOpenTabs(body): Observable<any> {
    return this.apiService.post(this._apiPath.move_table, body).pipe(
      map(data => {
        return data
      })
    )
  }
}
