import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import {
  AuthService,
  NoticeService,
  DirectBookingSettingService,
} from '../../services'
import { CommonPages } from '@hmp/models/direct-booking.model'

const log = new Logger('CommonPagesDetailResolver')
@Injectable({ providedIn: 'root' })
export class CommonPagesDetailResolver
  implements Resolve<Observable<CommonPages>> {
  constructor(
    private resService: DirectBookingSettingService,
    private router: Router,
    private auth: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['id']
    
    return this.resService.getCommonPagesDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err =>
        this.router.navigate(['/direct-booking-setting/common-pages'])
      )
    )
  }
}
