import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'

const log = new Logger('FieldInfoService')
@Injectable({ providedIn: 'root' })
export class FieldInfoService {
  constructor(private apiService: ApiService) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    info: 'field/field_info',
  }

  getFieldInfo(fieldName): Observable<any> {
    let params = new HttpParams().set('field_name', fieldName)
    return this.apiService.get(this._apiPath.info, params).pipe(
      map(data => {
        // log.debug('Field: ', data)
        return data
      })
    )
  }
}
