export * from './business/business-detail.resolver'
export * from './reservation/reservation-detail.resolver'
export * from './room/room-detail.resolver'
export * from './room/extra-amenities-detail.resolver'
export * from './roomtype/roomtype-detail.resolver'
export * from './user/profile-detail.resolver'
export * from './agent/agent-detail.resolver'
export * from './housekeeping/housekeeping-detail.resolver'
export * from './season/season-detail.resolver'
export * from './maintenance/maintenance-detail.resolver'
export * from './hr/hr-detail.resolver'
export * from './agent-manager/agent-account-detail.resolver'
export * from './client-manager/client-account-detail.resolver'
export * from './guests/guests-detail.resolver'
export * from './food-beverage/fbcategory-detail.resolver'
export * from './food-beverage/fbitems-detail.resolver'
export * from './activity/excursion.resolver'
export * from './activity/transfer.resolver'
export * from './payroll/payroll-detail.resolver'
export * from './supplier/supplier-detail.resolver'
export * from './guest-folio/guestfolio-detail.resolver'
export * from './schedule/schedule-detail.resolver'
export * from './schedule/scheduleoff-detail.resolver'
export * from './dbpackages/db-packages-detail.resolver'
export * from './vendor/vendor-detail.resolver'
export * from './assettype/assettype-detail.resolver'
export * from './asset/asset-detail.resolver'
export * from './revenue/revenue-detail.resolver'
export * from './common-pages/common-pages-detail.resolver'
export * from './expense/expense-detail.resolver'
export * from './paybill/paybill-detail.resolver'
export * from './purchase/purchase-detail.resolver'
export * from './cashjournal/cashjournal-detail.resolver'
export * from './promotions/promotions-detail.resolver'
export * from './salescalls/salescalls-detail.resolver'
export * from './digitalassets/digitalassets-detail.resolver'
export * from './inventory-master/inventory-master-detail.resolver'
export * from './payment/payment-detail.resolver'
export * from './packages/dailyactivities-detail.resolver'
export * from './packages/packagesactivities-detail.resolver'
export * from './reviews/reviews-detail.resolver'
export * from './activity-type/activity-type.resolver'
export * from './discount-promotions/discount-promotions-detail.resolver'
export * from './table/table-detail.resolver'
export * from './taxes/taxes-detail.resolver'
export * from './guest-dossier/guestdossier-detail.resolver'
export * from './task/task-detail.resolver'
export * from './asset-owner/asset-owner-detail.resolver'
export * from './financing-journal/financing-detail.resolver'
export * from './bulletin/bulletin-detail.resolver'
export * from './guest-letter/guest-letter-detail.resolver'
export * from './notice/notice-detail.resolver'
export * from './invoice-agent/invoice-agent-detail.resolver'
export * from './accounting-codes/accounting-codes-detail.resolver'
export * from './payment-method/payment-method-detail.resolver'
export * from './service-charges/service-charges-detail.resolver'
export * from './rate-code/rate-code-detail.resolver'
export * from './restaurant/restaurant.resolver'
export * from './frontdesk/frontdesk.resolver'
export * from './payment-gateways/payment-gateways.resolver'
export * from './printer-settings/printer-settings-detail.resolver'
