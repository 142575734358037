import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  AfterViewInit,
} from '@angular/core'
import { PermissionsService, RoleActionsData } from '../services'
import { Logger } from '../logger.service'

const log = new Logger('HmpFieldsPermissionDirective')
@Directive({
  selector: '[PERF]',
})
export class HMPFieldsPermissionDirective implements AfterViewInit {
  @Input()
  Entity: string
  @Input()
  EntityField: string
  constructor(
    private elem: ElementRef,
    private renderer: Renderer2,
    private permissionService: PermissionsService
  ) {}
  ngAfterViewInit(): void {
    this.onCheckingField()
  }
  onCheckingField() {
    let entity = this.Entity
    let entityfield = this.EntityField
    if (entity && entityfield) {
      let isHasEditPermission = this.permissionService.hasFieldPermission(
        entity,
        entityfield,
        RoleActionsData.Edit
      )
      let isHasViewPermission = this.permissionService.hasFieldPermission(
        entity,
        entityfield,
        RoleActionsData.View
      )
      if (isHasEditPermission) {
        log.debug('VIEW and EDIT ALLOWED')
      } else if (isHasViewPermission) {
        this.renderer.setAttribute(this.elem.nativeElement, 'readonly', 'true')
        this.renderer.setAttribute(this.elem.nativeElement, 'disabled', 'true')
      } else {
        let parNode = this.renderer.parentNode(this.elem.nativeElement)
        this.renderer.removeChild(parNode, this.elem.nativeElement)
      }
    }
    log.debug('ELE ', this.elem.nativeElement)
    log.debug('ELE ', this.renderer)
  }
}
