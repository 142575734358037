import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'fuse-nav-horizontal-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class FuseNavHorizontalItemComponent {
  @HostBinding('class')
  classes = 'nav-item'

  @Input()
  item: any

  /**
   * Constructor
   */
  constructor() {}
}
