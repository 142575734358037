import { NgModule } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { HMPPipesModule } from './pipes/hmp-pipes.module'
import { HMPDirectivesModule } from './directives/hmp-directives.module'
import { CoreIconsModule } from './modules/core-icons/core-icons.module'

@NgModule({
  imports: [CommonModule, CoreIconsModule, HMPPipesModule, HMPDirectivesModule],
  providers: [DecimalPipe],
  exports: [CommonModule, CoreIconsModule, HMPPipesModule, HMPDirectivesModule],
})
export class HMPSharedModule {}
