import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { WebsiteOrganizer } from '@hmp/models'

const log = new Logger('WebsiteOrganizerService')

@Injectable({ providedIn: 'root' })
export class WebsiteOrganizerService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    list_customize: 'business/website_entity',
    action: 'business/website_entity',
    web_enable_language: 'business/website_enable_languages',
    website_settings:'business/website_settings_entity',
    create_website_customize:'business/website_entity/save_content',
  }

   getWebsiteSetting(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .get(`${this._apiPath.website_settings}/${bid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllCustomize(language?, params?: any,): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    ).set('lang', language)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list_customize, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  // getDetail(id: string): Observable<any> {
  //   return this.apiService.get(`${this._apiPath.detail}/${id}`).pipe(
  //     map(data => {
  //       return data
  //     })
  //   )
  // }

  create(res: WebsiteOrganizer): Observable<any> {
    return this.apiService.post(this._apiPath.website_settings, res).pipe(
      map(data => {
        return data
      })
    )
  }
  
  createCustomize(res: WebsiteOrganizer): Observable<any> {
    return this.apiService.post(this._apiPath.action, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createContentCustomize(res: WebsiteOrganizer): Observable<any> {
    return this.apiService.post(this._apiPath.create_website_customize, res).pipe(
      map(data => {
        return data
      })
    )
  }

  // delete(id): Observable<any> {
  //   return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
  //     map(data => {
  //       return data
  //     })
  //   )
  // }

  update(res): Observable<any> {
    return this.apiService.put(`${this._apiPath.action}/${res.id}`, res).pipe(
      map(data => {
        return data
      })
    )
  }
  
  getWebsiteEnableLanguage(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.web_enable_language, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
