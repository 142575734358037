import { element } from 'protractor'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { User, Business, EntityRef } from '../../models'
import { Logger } from '../../logger.service'
import { LocalStorageService } from '../../helper'
import { JwtService } from '../../services/general/jwt.service'
import { LocalStorageConfig } from '../../config/local.cf'
import { UltilitiesService } from '../../services/general/ultilities.service'
import { T } from '@angular/cdk/keycodes'
import { map } from 'rxjs/operators'
const log = new Logger('AuthService')
const localConfig = new LocalStorageConfig()
/**
 * Service xử lý đăng nhập
 */

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>({} as User)
  private isLoggedInSubject = new BehaviorSubject<boolean>(false)
  private activeBusinessSubject = new BehaviorSubject<any>({})
  private currentPGSubject = new BehaviorSubject<any>({})

  public currentUser = this.currentUserSubject.asObservable()
  public currentPG = this.currentPGSubject.asObservable()
  public isLoggedIn = this.isLoggedInSubject.asObservable()
  public activeBusiness = this.activeBusinessSubject.asObservable()
  constructor(
    private jwt: JwtService,
    private storage: LocalStorageService,
    private ultilitiesService: UltilitiesService,
    private router: Router
  ) {}

  /**
   * Mỗi lần load lại trang thì sẻ chạy Function này để kiểm tra local cũng như get userprofile mới nhất.
   */
  popUser(): void {
    if (this.jwt.getToken()) {
      const user = this.getLocalUserProfile()
      this.isLoggedInSubject.next(true)
      this.setAuth(user)
      // let localBusiness = this.getActiveLocalBusiness();
      // this.setLocalBusiness(localBusiness);
    } else {
      this.destroyAuth()
    }
  }

  getJwtToken() {
    return this.jwt.getToken()
  }

  /**
   * Lưu thông tin userprofile khi đã đăng nhập thành công
   * @param userProfile Thông tin user
   */
  setAuth(userProfile: any) {
    if (this.isLoggedInSubject.getValue() != true) {
      this.isLoggedInSubject.next(true)
    }
    this.saveUserProfileLocal(userProfile)
    this.currentUserSubject.next(userProfile)
    const bDefault = userProfile && userProfile.business_default
    if (bDefault) {
      const bus = { bid: userProfile.business_default }
      this.setLocalBusiness(bus)
      const gen = userProfile.business_general
      if (gen) {
        this.setBusinessGeneralInfo(gen)
      }
    }
  }

  setActiveBusiness(b) {
    if (!b || !b.bid) {
      return
    }
    const logos = b && (b.business_logo as any[])
    if (logos && logos.length > 0) {
      const logoURl = logos[0].uri_full
      this.ultilitiesService.getBase64FromURLXML(logoURl).then(d => {
        b['business_logo_base64'] = d
        this.activeBusinessSubject.next(b)
        // log.info('getBase64FromURLXML', d)
      })
    }
    this.activeBusinessSubject.next(b)
    this.storage.saveItem('gen', b, true, true)
  }

  getActiveBusiness() {
    return this.activeBusinessSubject.value
  }

  getPayPal() {
    const pp = this.getCurrentPG()
    for (let i = 0; pp.data.length > 0; i++) {
      const element = pp.data[i]
      if (element.provider == 'paypal') {
        return element
      }
    }
  }

  setPG(p) {
    this.currentPGSubject.next(p)
    this.storage.saveItem('p', p, true, true)
  }

  getCurrentPG() {
    return this.currentPGSubject.value
  }

  getActiveBusinessTooltip(key) {
    const tt = this.getActiveBusiness().dashboard_tooltip || []
    if (tt && tt.length > 0) {
      const tF = tt.find(t => t.record_key == key)
      if (!tF) {
        return ''
      }
      return tF.record_value || ''
    }
    return ''
  }

  setBusinessGeneralInfo(gen) {
    this.storage.saveItem('gen', gen, true, true)
  }

  getBusinessGeneralInfo() {
    // return this.activeBusinessSubject.value
    return this.storage.getItem('gen', true, true)
  }

  getCurrentCurrency(): string {
    let cur = 'usd'
    const bus = this.getActiveBusiness()
    if (bus && bus.business_currency) {
      return bus.business_currency.toLocaleLowerCase()
    }
    return cur.toLocaleLowerCase()
    // let cUser = this.getCurrentUser()
    // if (!cUser) {
    //   cUser = this.getLocalUserProfile()
    // }
    // const bDefault = cUser.business_general
    // if (bDefault) {
    //   cur = bDefault.business_currency
    // }
    // return cur.toLocaleLowerCase()
  }

  selectCurrentCurrency() {
    return this.activeBusiness.pipe(map(e=>e.business_currency))
  }

  getBusinessName(): string {
    const bus = this.getActiveBusiness()
    if (bus && bus.title) {
      return bus.title
    }
  }

  /**
   * Logout và xoá toàn bộ thông tin user
   */
  destroyAuth() {
    this.jwt.destroyToken()
    this.isLoggedInSubject.next(false)
    this.currentUserSubject.next({} as User)
    this.destroyLocalBusiness()
  }

  getIsLogged() {
    return this.isLoggedInSubject.value
  }

  /**
   * Lấy user hiện tại
   */
  getCurrentUser() {
    return this.currentUserSubject.value
  }

  getCurrentUserName() {
    return this.currentUserSubject.value.full_name
  }

  getCurrentUserId() {
    return (
      this.currentUserSubject &&
      this.currentUserSubject.value &&
      this.currentUserSubject.value.uid
    )
  }

  /**
   * Thoát tài khoản
   * @param pathAfterLoggedOut Path muốn chuyển hướng sau khi thoát ra
   */
  logout(pathAfterLoggedOut: string = '', reload = false) {
    this.destroyAuth()
    this.router.navigate(['/', pathAfterLoggedOut])
    if (reload) {
      setTimeout(() => {
        location.reload()
      }, 200)
    }
  }

  /**
   * Lấy user hiện tại từ Local
   */
  getLocalUserProfile() {
    return this.storage.getItem(
      localConfig.localStorageKey.CurrentUser,
      true,
      true
    )
  }

  getCurrentUserRoleByActiveBusiness() {
    const curBus = this.getActiveLocalBusinessID()
    const curUser = this.currentUserSubject.value.roles_business as any[]
    if (curUser) {
      const curRole = curUser.find(f => f.business_id === curBus)
      if (curRole) {
        return curRole.business_role
      }
    }
    return
  }

  /**
   * Lưu thông tin user vào local
   *
   * @param userProfile Thông tin user
   */
  saveUserProfileLocal(userProfile) {
    this.storage.saveItem(
      localConfig.localStorageKey.CurrentUser,
      userProfile,
      true,
      true
    )
  }

  setLocalBusiness(business) {
    this.storage.saveItem('atb', business, true, true)
    // this.activeBusinessSubject.next(business);
  }

  getActiveLocalBusiness() {
    return this.storage.getItem('atb', true, true)
  }

  getActiveLocalBusinessEntity(): EntityRef {
    const cur = this.getActiveLocalBusiness()
    if (cur) {
      const entity: EntityRef = {
        entity_id: cur.bid,
        entity_type: 'business_entity',
        bundle: 'business',
      }
      return entity
    }
    return
  }

  getActiveLocalBusinessID() {
    const bs = this.storage.getItem('atb', true, true)
    if (bs) {
      return bs.bid
    }
    return
  }

  getActiveLocalBusinessName() {
    const bs = this.storage.getItem('atb', true, true)
    if (bs) {
      return bs.title
    }
    return
  }
  
  business(): string {
    const bus = this.getActiveBusiness()
    if (bus) {
      return bus
    }
  }

  destroyLocalBusiness() {
    this.storage.removeItem('atb')
  }
}
