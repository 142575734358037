import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { QuickLaunchNavigationComponent } from './quick-launch-navigation.component'
import { QuickLaunchNavigationService } from './quick-launch-navigation.service'

@NgModule({
  declarations: [QuickLaunchNavigationComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
  ],
  providers: [QuickLaunchNavigationService],
  exports: [QuickLaunchNavigationComponent],
  entryComponents: [QuickLaunchNavigationComponent],
})
export class QuickLaunchNavigationModule {}
