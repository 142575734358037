import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
const log = new Logger('DailyPaymentGatewayExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class DailyPaymentGatewayExportService {
  constructor(private auth: AuthService) {}

  onGenerateReportExcel(businessName, data: any, date, gatewayName, filename) {
    const headerRow1Columns = [
      '',
      `${gatewayName}`,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      `${businessName}`,
    ]

    const headerRow2Columns = [
      '',
      'Order No.',
      'Res / Folio No.',
      'Guest Name',
      'Patron Type',
      'Staff Name',
      'Sub Total',
      'S/C',
      'Tax',
      'Discount',
      'Adjustment',
      'Total ',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(
      `${moment.unix(date).format('DD-MMM-YY')}`,
      {
        properties: {
          tabColor: { argb: 'FFFFFF00' },
          showGridLines: true,
          defaultRowHeight: 20,
        },
        views: [{ state: 'normal', showGridLines: false }],
      }
    )

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 15 },
      { key: 'C2', width: 15 },
      { key: 'C3', width: 15 },
      { key: 'C4', width: 15 },
      { key: 'C5', width: 15 },
      { key: 'C6', width: 15 },
      { key: 'C7', width: 15 },
      { key: 'C8', width: 15 },
      { key: 'C9', width: 15 },
      { key: 'C10', width: 15 },
      { key: 'C11', width: 15 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `Payment Gateway Records: ${moment
      .unix(date)
      .format('DD-MMM-YY')}`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:M4')

    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 18,
      bold: true,
    }
    // titleCell.border = {
    //   bottom: { style: 'medium' },
    // }
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }

    titleRow.height = 20
    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    worksheet.mergeCells('J6:L6')

    worksheet.mergeCells('B6:E6')
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        // cell.border = {
        //   top: { style: 'thin' },
        //   bottom: { style: 'medium' },
        // }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    let businessNameCell = worksheet.getCell('L6')
    businessNameCell.alignment = { horizontal: 'right', vertical: 'middle' }
    headerRow1.getCell(7).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow1.getCell(6).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow1.getCell(8).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow1.getCell(13).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }

    headerRow1.height = 20

    let headerRow2 = worksheet.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow2.getCell(8).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(9).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(10).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(11).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(12).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(13).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.height = 20
    if (data) {
      for (const key in data.data) {
        const item = data.data[key]
        let dataRowColumn = this.generateReportColumnArr(item)
        let dataRow = worksheet.addRow(dataRowColumn)
        dataRow.font = {
          name: 'Calibri',
          size: 10,
          bold: false,
        }
        dataRow.height = 17
        dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
        dataRow.getCell(2).alignment = { horizontal: 'left' }
        dataRow.getCell(3).alignment = { horizontal: 'left' }
        dataRow.getCell(4).alignment = { horizontal: 'left' }
        dataRow.getCell(5).alignment = { horizontal: 'left' }
        dataRow.getCell(6).alignment = { horizontal: 'left' }
        dataRow.getCell(7).alignment = { horizontal: 'left' }
        dataRow.getCell(8).alignment = { horizontal: 'right' }
        dataRow.getCell(9).alignment = { horizontal: 'right' }
        dataRow.getCell(10).alignment = { horizontal: 'right' }
        dataRow.getCell(11).alignment = { horizontal: 'right' }
        dataRow.getCell(12).alignment = { horizontal: 'right' }
      }
      let totalRow = worksheet.addRow([
        '',
        'Total',
        '',
        '',
        '',
        '',
        `${data.total.subtotal}`,
        `${data.total.service_charge}`,
        `${data.total.tax}`,
        `${data.total.discount}`,
        `${data.total.adjustment}`,
        `${data.total.total}`,
      ])
      totalRow.font = {
        name: 'Calibri',
        size: 10,
        bold: true,
      }
      totalRow.eachCell((cell, number) => {
        if (number > 1) {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
          }
        }
      })
      totalRow.getCell(8).alignment = { horizontal: 'right' }
      totalRow.getCell(9).alignment = { horizontal: 'right' }
      totalRow.getCell(10).alignment = { horizontal: 'right' }
      totalRow.getCell(11).alignment = { horizontal: 'right' }
      totalRow.getCell(12).alignment = { horizontal: 'right' }
      worksheet.addRow([])
    }

    // return

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.order_no)
    row.push(data.reservation_no)
    row.push(data.guest_name)
    row.push(data.patron_type)
    row.push(data.staff_name)
    row.push(data.subtotal)
    row.push(data.service_charge)
    row.push(data.tax)
    row.push(data.discount)
    row.push(data.adjustment)
    row.push(data.total)
    return row
  }
}
