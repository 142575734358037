import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { DiscountPromotionsService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { DiscountPromotions } from '../../models'

const log = new Logger('DiscountPromotionsDetailResolver')
@Injectable({ providedIn: 'root' })
export class DiscountPromotionsDetailResolver
  implements Resolve<Observable<DiscountPromotions>> {
  constructor(
    private resService: DiscountPromotionsService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['did']
    
    return this.resService.detail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/promotions']))
    )
  }
}
