import { Injectable } from '@angular/core'
import { Agent } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('AgentService')

@Injectable({ providedIn: 'root' })
export class AgentService {
  constructor(
    private apiService: ApiService,
    private globalEntityServices: GlobalEntityService,
    private storage: LocalStorageService,
    private jwtService: JwtService,
    private router: Router
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho Agent
   */
  private _apiPath = {
    listAgent: 'room/agent_class_entity',
    agentDetail: 'room/agent_class_entity',
  }

  /**
   * Lấy tất cả agent đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAllAgent(params?: any): Observable<Agent[]> {
    let pa = new HttpParams().set(
      'parameters[ac_business_id][]',
      this.storage.getActiveLocalBusinessID()
    )
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<Agent[]>(this._apiPath.listAgent, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllAgentSeason(params?: any, public_price?): Observable<Agent[]> {
    let pa = new HttpParams()
      .set(
        'parameters[ac_business_id][]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('public_price', public_price)
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<Agent[]>(this._apiPath.listAgent, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getTotalAgentNumber(): Observable<any> {
    return this.globalEntityServices.getTotalEntity('agent_entity').pipe(
      map(data => {
        return data
      })
    )
  }
  /**
   * Lấy thông tin chi tiết của Agent dưạ vào @id
   * @param id ID của Reservation
   */
  getAgentDetail(id: string): Observable<Agent> {
    return this.apiService
      .get<Agent>(`${this._apiPath.agentDetail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo Agent
   * @param res Thông tin Agent
   */
  createAgent(res: Agent): Observable<any> {
    res.aid = null
    return this.apiService.post(this._apiPath.agentDetail, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Agent
   *
   * @param res Thông tin Agent muốn chỉnh sửa
   */
  editAgent(res: Agent): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.agentDetail}/${res.aid}`, res)
      .pipe(
        map(data => {
        })
      )
  }

  /**
   * Xóa thông tin Agent
   *
   * @param rid Xóa Agent bằng Agent ID
   */
  deleteAgent(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.agentDetail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
