import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders,
} from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { HttpOverrideInterceptor } from './http'
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpOverrideInterceptor,
      multi: true,
    },
    DecimalPipe,
  ],
  declarations: [],
  exports: [CommonModule],
})
export class HMPModule {
  constructor(@Optional() @SkipSelf() parentModule: HMPModule) {
    if (parentModule) {
      throw new Error(
        'HMPModule is already loaded. Import it in the AppModule only!'
      )
    }
  }
}
