import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('CashJournalExportService')
declare const ExcelJS: any
import * as _moment from 'moment'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class PaymentRegisterExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGeneratePaymentRegisterReportExcel(
    title,
    data: any[],
    sheetname,
    filename,
    logo,
    dateLabel,
    sumVal
  ) {
    const headerRow1Columns = [
      '',
      'Date',
      'Time',
      'Title',
      'Payment Type',
      'Category',
      'Method',
      'Accounting Code',
      'Tax Inv No.',
      'Receipt No.',
      'Res No.',
      'Payer Type',
      'Payer Name',
      'Amount',
      'Staff Name',
      'Txn ID',
      'Comments',
      'Void',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 13 },
      { key: 'C2', width: 13 },
      { key: 'C3', width: 30 },
      { key: 'C4', width: 20 },
      { key: 'C5', width: 14 },
      { key: 'C6', width: 20 },
      { key: 'C7', width: 20 },
      { key: 'C8', width: 20 },
      { key: 'C9', width: 20 },
      { key: 'C10', width: 20 },
      { key: 'C11', width: 16 },
      { key: 'C12', width: 20 },
      { key: 'C13', width: 20 },
      { key: 'C14', width: 20 },
      { key: 'C15', width: 16 },
      { key: 'C16', width: 20 },
      { key: 'C17', width: 13 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    let titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:G4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    let headerRow2 = worksheet.addRow([])
    headerRow2.height = 20

    headerRow2.getCell(2).value = dateLabel
    headerRow2.getCell(2).alignment = {
      horizontal: 'left',
      vertical: 'middle',
    }
    headerRow2.getCell(2).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      underline: 'single',
    }

    headerRow2.getCell(6).value = 'Total Amount'
    headerRow2.getCell(6).alignment = {
      horizontal: 'left',
      vertical: 'middle',
    }
    headerRow2.getCell(6).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      underline: 'single',
    }

    headerRow2.getCell(7).value = sumVal
    headerRow2.getCell(7).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(7).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      underline: 'single',
    }

    headerRow2.getCell(7).numFmt = '#,##0.00'

    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 17
      dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(2).alignment = { horizontal: 'center' }
      dataRow.getCell(2).numFmt = 'DD-MMM-YY'
      dataRow.getCell(3).alignment = { horizontal: 'center' }
      dataRow.getCell(4).numFmt = '#,##0.00'
      dataRow.getCell(4).alignment = { horizontal: 'left' }
      dataRow.getCell(5).alignment = { horizontal: 'center' }
      dataRow.getCell(6).alignment = { horizontal: 'center' }
      dataRow.getCell(7).alignment = { horizontal: 'center' }
      dataRow.getCell(13).alignment = { horizontal: 'right' }
      dataRow.getCell(13).numFmt = '#,##0.00'
      dataRow.getCell(15).alignment = { horizontal: 'center' }
      dataRow.getCell(16).alignment = { horizontal: 'left' }
      dataRow.getCell(17).alignment = { horizontal: 'center' }
    }

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.date)
    row.push(data.time)
    row.push(data.detail)
    row.push(data.payment_input_type)
    row.push(data.category)
    row.push(data.payment_method)
    row.push(data.accounting_code)
    row.push(data.entity_reference)
    row.push(data.pmt_tax_inv_no)
    row.push(data.booking_ref)
    row.push(data.payment_type)
    row.push(data.client_name)
    row.push(data.amount)
    row.push(data.staff_name)
    row.push(data.pid)
    row.push(data.payment_notes)
    row.push(data.pmt_void ? data.pmt_void : '')
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
