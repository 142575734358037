import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { GuestLetter } from '@hmp/models'

const log = new Logger('GuestLetterService')

/**
 * Đây là services dành cho GuestLetterService
 */

@Injectable({ providedIn: 'root' })
export class GuestLetterService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    list: 'hmp_letter/hmp_letter_entity',
    detail: 'hmp_letter/hmp_letter_entity',
    list_letter_type: 'hmp_letter/letter_type_entity',
    detail_type: 'hmp_letter/letter_type_entity',
    letter_res: 'hmp_letter/get_letter_reservation',
  }

  getThankYouLetterForReservation(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any[]>(this._apiPath.letter_res, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getWelceomeLetterForReservation(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('letter_type', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.letter_res, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllGuestLetter(params?: any): Observable<any[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[letter_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllLetterType(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[lt_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list_letter_type, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService.get<any>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
  getLetterById(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.list_letter_type}/${id}`)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  createLetterType(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.list_letter_type, res).pipe(
      map(data => {
        return data
      })
    )
  }
  editLetterType(res: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.list_letter_type}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        log.debug('Add:', data)
        return data
      })
    )
  }

  edit(res: GuestLetter): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.id}`, res).pipe(
      map(data => {
        log.debug('Edit:', data)
        return data
      })
    )
  }

  deleteType(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail_type}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
