import * as _ from 'lodash'
import { QueryParam } from '../models'
export class RouterQueryParamsHelper {
  private qParams: QueryParam[] = []

  constructor(params: QueryParam[]) {
    this.qParams = _.cloneDeep(params)
  }

  /**
   * Lấy tất cả Query Params
   */
  get all() {
    return this.qParams
  }

  /**
   * Lấy Query Param dựa vào ID
   * @param id: Id Query Param
   */
  getId(id) {
    if (!this.qParams || this.qParams.length == 0) {
      return undefined
    }
    return this.qParams.find(p => p.id == id)
  }

  /**
   * Clear toàn bộ Query Params
   */
  clear() {
    this.qParams = []
  }

  /**
   * Tạo mới nếu không có Param (dựa vào @id)
   * Cập nhất nếu đã có Param (dựa vào @id)
   * @param param: Query Param muốn tạo/cập nhật
   */
  upsert(param: QueryParam) {
    if (!this.qParams || this.qParams.length == 0) {
      this.qParams = []
    }
    if (this.qParams && this.qParams.length > 0) {
      const idIndex = this.qParams.findIndex(i => i.id == param.id)
      if (idIndex !== -1) {
        this.qParams[idIndex].display = param.display
        this.qParams[idIndex].query = param.query
        this.qParams[idIndex].hidden = param.hidden || false
        return
      }
    }
    const qParam: QueryParam = {
      id: param.id,
      display: param.display,
      query: param.query,
      hidden: param.hidden,
    }
    this.qParams.push(qParam)
  }

  /**
   * Xoá Query Param
   * @param id: Id Query Param
   */
  remove(id) {
    if (!id || !this.qParams || this.qParams.length == 0) {
      return
    }
    this.qParams = this.qParams.filter(f => f.id != id)
  }

  /**
   * Lọc dựa vào điều kiện
   *
   * @param type Loại Query Param muốn lọc
   * - `query` Nếu là giá trị của query sẻ lấy tất cả bao gồm hidden = true
   * - `display` Sẻ lấy giá trị của display.
   * @param hidden lọc các giá trị hidden hay không?
   * - `default`: false
   * - `Note`: Chỉ lọc khi `type` là `display`
   */
  filter(type: 'query' | 'display', hidden = false) {
    const queryParams = {}
    if (this.qParams && this.qParams.length > 0) {
      if (type === 'query') {
        _.map(this.qParams, q => {
          if (q.query) {
            queryParams[q.query.id] = q.query.value
          }
        })
      } else {
        if (hidden) {
          _.map(this.qParams, q => {
            queryParams[q.display.id] = q.display.value
          })
        } else {
          _.filter(this.qParams, e => !e.hidden).map(q => {
            queryParams[q.display.id] = q.display.value
          })
        }
      }
    }
    return queryParams
  }
}
