import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('PrinterSettingsService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class PrinterSettingsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    printer_settings: 'global/receipt_printer',
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.printer_settings, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  update(id: string, body: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.printer_settings}/${id}`, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res): Observable<any> {
    res.tid = null
    return this.apiService.post(this._apiPath.printer_settings, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.printer_settings}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.printer_settings}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
