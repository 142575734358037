import { Injectable } from '@angular/core'
import { RoomType, RoomTypePhoto } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { FieldInfoService } from '../field-info/field-info.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('RoomService')

/**
 * Đây là service xử lý về RoomType
 */
@Injectable({ providedIn: 'root' })
export class RoomTypeService {
  constructor(
    private apiService: ApiService,
    private fieldInfoServices: FieldInfoService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    updateRoomType: 'room/room_type_entity',
    list_rooms: 'room/room_type_entity',
    room_detail: 'room/room_type_entity',
    photos: 'room/room_type_retrieve',
    getRoomByType: 'room/get_room_by_type',
    add_extra: 'room/room_type_entity/room_type_add_extra',
    list_rate_code: 'reservations/ratecode_entity',
    listRoomTypeDirect: 'room/room_type_select',
    list_daily_price: 'room/room_type_daily_prices',
    listroomTypeDirectNEW: 'room/room_type_for_select',
  }

  /**
   * Lấy tất cả rooms đang available
   */
  getAllRoomType(params?: any): Observable<RoomType[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[room_type_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<RoomType[]>(this._apiPath.list_rooms, pa).pipe(
      map(data => {
        return data
      })
      // map(data => {
      //   let room: [RoomType] = data.map(roomType =>
      //     this.mapJsonToObject(roomType)
      //   );
      //   log.debug("All Room Type: ", room);
      //   return room;
      // })
    )
  }

  getAllRoomTypeDirect(params?: any): Observable<RoomType[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<RoomType[]>(this._apiPath.listRoomTypeDirect, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllRoomTypeDirectNEW(params?: any): Observable<RoomType[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<RoomType[]>(this._apiPath.listroomTypeDirectNEW, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /*
   * lấy toàn bộ room type chỉ có option là (rid,title).
   * room/room_type_entity?parameters[room_type_business_id]=60&select_options=true
   */
  getAllRoomsTypeNew(params?: any): Observable<RoomType[]> {
    let pa = new HttpParams()
      .set(
        'parameters[room_type_business_id]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('select_options', 'true')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<RoomType[]>(this._apiPath.list_rooms, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllRoomsTypeOTA(params?: any): Observable<RoomType[]> {
    let pa = new HttpParams()
      .set(
        'parameters[room_type_business_id]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('select_options', 'true')
      .set('select_ota', 'true')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<RoomType[]>(this._apiPath.list_rooms, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /*
   * lấy toàn bộ room có cùng room type.
   * room/get_room_by_type?type_ids=36
   */
  getRoomByTypes(type_ids) {
    let params = new HttpParams().set('type_ids', type_ids)

    return this.apiService
      .get<RoomType>(this._apiPath.getRoomByType, params)
      .pipe(
        map(data => {
          return data
        })
      )

    // let params = new HttpParams().set("field_name", fieldName);
    // return this.apiService.get(this._apiPath.info, params).pipe(
    //   map(data => {
    //     log.debug("Field: ", data);
    //     return data;
    //   })
    // );
  }

  /**
   * Lấy các fields
   *
   * @return values
   * - cardinality: Nếu âm -1 là cho chọn toàn bộ, 1...n là cho chọn 1...n cái
   *
   */
  getCostTypeFields(): Observable<any> {
    return this.fieldInfoServices.getFieldInfo('rooms_cost_type').pipe(
      map(data => {
        log.debug('CostTypeFields: ', data)
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Room dưạ vào @roomId
   * @param roomId ID của Room
   */
  getRoomTypeDetail(roomId: string): Observable<RoomType> {
    return this.apiService
      .get<RoomType>(`${this._apiPath.room_detail}/${roomId}`)
      .pipe(
        map(data => {
          log.debug('ROOM TYPE DETAIL XXX', data)
          return data
          // let roomType = this.mapJsonToObject(data);
          // return roomType;
        })
      )
  }

  /**
   *
   * @param room Thông tin phòng
   */
  createRoomType(room: RoomType): Observable<any> {
    room.rid = null

    return this.apiService.post(this._apiPath.room_detail, room).pipe(
      map(data => {
        return data
      })
    )
  }

  add_extra(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_extra, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin room
   *
   * @param room Thông tin room muốn chỉnh sửa
   */
  editRoomType(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.room_detail}/${room.rid}`, room)
      .pipe(
        map(data => {
          let roomType = this.mapJsonToObject(data)
          return roomType
        })
      )
  }
  updateRoomAmenities(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createRoomAmenities(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateRoomOffers(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createRoomOffers(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateRoomFeatures(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createRoomFeatures(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  updateRoomBenefit(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createRoomBenefit(room: RoomType): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.updateRoomType}/${room.rid}`, room)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Xóa thông tin room
   *
   * @param rid Xóa room bằng Room ID
   */
  deleteRoom(roomId): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.room_detail}/${roomId}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getListPhotos(rid): Observable<any> {
    return this.apiService.get<any>(`${this._apiPath.photos}?rid=${rid}`).pipe(
      map(data => {
        return data
      })
    )
  }

  private mapJsonToObject(roomJson): RoomType {
    log.debug('Json Data: ', roomJson)

    let roomType: RoomType = {
      rid: roomJson.rid,
      type: roomJson.type,
      title: roomJson.title,
      uid: roomJson.uid,
      created: roomJson.created,
      changed: roomJson.changed,
      access_edit: roomJson.access_edit,
      access_delete: roomJson.access_delete,
      info_on_rooms: roomJson.info_on_rooms,
      room_type_specs: roomJson.room_type_specs,
      room_type_photos: roomJson.room_type_photos,
      room_type_quality: roomJson.room_type_quality,
      room_type_size: roomJson.room_type_size,
      room_type_business_id: roomJson.room_type_business_id,
    }
    return roomType
  }
  getAllRateCode(params?: any): Observable<any> {
    let bid = this.storage.getActiveLocalBusinessID()
    let pa = new HttpParams().set('bid', bid).set('select_box', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(`${this._apiPath.list_rate_code}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getListDailyPriceCalendar(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.list_daily_price, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
