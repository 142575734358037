import { Pipe, PipeTransform } from '@angular/core'
import { isObservable, of, Observable } from 'rxjs'
import { map, startWith, catchError } from 'rxjs/operators'

interface ObLoading {
  loading?: boolean
  value?: any
  error?: any
}

@Pipe({
  name: 'ob$',
})
export class LoadingObsPipe implements PipeTransform {
  transform(val): Observable<ObLoading> {
    return isObservable(val)
      ? val.pipe(
          map((value: any) => {
            const obs: ObLoading = {
              loading: value.type === 'start',
              value: value,
            }
            return obs
          }),
          startWith({ loading: true }),
          catchError(error => of({ loading: false, error }))
        )
      : val
  }
}
