import { Injectable } from '@angular/core'
import { Activities } from '../../../models/activities.model'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
const log = new Logger('ActivitiesService')
@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    add_activities: 'activities/reservations_activities/add_activities',
    create_activities:
      'activities/reservations_activities/create_new_activities',
    list_transfer: 'activities/transfer_entity',
    list_excursion: 'activities/excursion_entity',
    list_excursion_activiti: 'activities/reservations_activities',
    detail_excursion_activiti: 'activities/reservations_activities',
    list_transfer_activiti: 'activities/reservations_activities',
    excursionAccount: 'business/get_all_supplier',
    list_new_activities: 'activities/list_new_activities',
    excursion_detail: 'activities/excursion_entity',
    transfer_detail: 'activities/transfer_entity',
    extra_detail: 'activities/extra_entity',
    extra: 'room/extra_amenities_entity',
    fb_detail: 'fb/fb_order_entity',
    activities_calendar: 'activities/activities_calendar',
    assign_extra_to_activities: 'activities/asign_extra/create_activity',
    deleteOutlets: 'outlets/outlets_order_entity',
    fb_activities_serviceCharge_settings:
      'servicecharge/sc_settings_entity/switch_on_off_charge',
    change_driver_name: 'activities/transfer_entity/change_driver_name',
    tips: 'activities/reservations_activities/add_tip',
    del_tip: 'activities/tip_entity',
  }

  getActivitySchedulerCalendar(
    startDate?: number,
    endDate?: number
  ): Observable<any[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('start_date', `${startDate}`)
      .set('end_date', `${endDate}`)
    return this.apiService
      .get<any[]>(this._apiPath.activities_calendar, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   *
   * @param activities Thông tin
   */
  addActivities(activities: Activities): Observable<any> {
    return this.apiService.post(this._apiPath.add_activities, activities).pipe(
      map(data => {
        return data
      })
    )
  }
  

  getListNewActivities(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .get(`${this._apiPath.list_new_activities}/${bid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createActivitiesExcursion(activities: Activities): Observable<any> {
    return this.apiService
      .post(this._apiPath.create_activities, activities)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createActivitiesTransfer(activities: Activities): Observable<any> {
    return this.apiService
      .post(this._apiPath.create_activities, activities)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createTips(body: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.tips, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  editTips(body: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.tips}/${body.rid}`, body)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  editActivitiesExcursion(activities: Activities): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.create_activities}/${activities.rid}`, activities)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy tất cả Excursion of business
   */
  getAllExcurtion(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list_excursion, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getExcursionAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[excursion_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_excursion, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getTransferAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[transfer_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_transfer, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllActivities(rid): Observable<Activities[]> {
    return this.apiService
      .get<Activities[]>(`${this._apiPath.list_excursion_activiti}/${rid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetailExcurtionActiviti(id: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.detail_excursion_activiti}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getExcursionDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.excursion_detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getfbDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.fb_detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getTransferDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.transfer_detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getExtraDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.extra_detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  editExtra(res, eid): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.extra_detail}/${eid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  deleteExtra(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.extra}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  deleteTip(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.del_tip}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteOutlets(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.deleteOutlets}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  assignExtraToActivites(
    eid,
    type = 'excursion' || 'transfer',
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('eid', eid)
      .set('entity_type', type)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .post(this._apiPath.assign_extra_to_activities, {}, {}, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy tất cả Transfer of business
   */
  getAllTransfer(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list_transfer, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllEXAccount(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get(this._apiPath.excursionAccount, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getActivitiesSupplier(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'business_id',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.excursionAccount, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  updateServiceChargesSetting(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.fb_activities_serviceCharge_settings, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  changeDriverName(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_driver_name, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  onCreateFlyExtraCharges(body: any): Observable<any> {
    return this.apiService.post(this._apiPath.add_activities, body).pipe(
      map(data => {
        return data
      })
    )
  }
}
