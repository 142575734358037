import { Injectable } from '@angular/core'
import { Transfer } from '../../../models'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
const log = new Logger('TransferService')
@Injectable({
  providedIn: 'root',
})
export class TransferService {
  constructor(private apiService: ApiService) {}

  private _apiPath = {
    list: 'activities/reservations_activities',
    detail: 'activities/transfer_entity',
    transfer_list: 'activities/transfer_entity',
    transfer_detail: 'activities/transfer_entity',
    transfer_status: 'activities/transfer_entity/change_status',
  }

  getAll(rid): Observable<Transfer[]> {
    return this.apiService.get<Transfer[]>(`${this._apiPath.list}/${rid}`).pipe(
      map(data => {
        return data
      })
    )
  }
  /**
   * Lấy tất cả rooms đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */

  getAllTransfer(params?: any): Observable<any> {
    let pa = new HttpParams()
    // let pa = new HttpParams().set("parameters[activities_template]", 1);
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.transfer_list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  editTransfer(transfer: Transfer): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.transfer_detail}/${transfer.tid}`, transfer)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  /**
   *
   * @param excursion Thông tin
   */
  createTransfer(transfer: Transfer): Observable<any> {
    return this.apiService.post(this._apiPath.transfer_detail, transfer).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteTransfer(tid): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.transfer_detail}/${tid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.transfer_detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  changeStatus(status): Observable<any> {
    return this.apiService.post(this._apiPath.transfer_status, status).pipe(
      map(data => {
        return data
      })
    )
  }
}
