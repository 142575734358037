import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { AuthService } from '@hmp/services/general/auth.service'
import { LocalStorageService } from '@hmp/helper'
import { Logger } from '@hmp/logger.service'
import { ApiService } from '@hmp/services/general/api.service'

const log = new Logger('erviceChargeRegisterService')

/**
 * Đây là services dành cho TransactionRegister
 */

@Injectable({ providedIn: 'root' })
export class ServiceChargeRegisterService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private auth: AuthService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    all_service_charge_history: 'report/reports_service_charge_register',
    all_service_charge_summary: 'report/reports_service_charge_register_history',
    mark_as_allocated: 'report/reports_service_charge_register/mark_as_allocated',
    mark_as_unllocated: 'report/reports_service_charge_register/mark_as_unallocated',
  }

  getAllSummary(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.all_service_charge_summary, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllHistory(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.all_service_charge_history, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  

  onMarkAsUnallocated(res): Observable<any> {
    return this.apiService.post(this._apiPath.mark_as_unllocated, res).pipe(
      map(data => {
        return data
      })
    )
  }

  onMarkAsAllocated(res): Observable<any> {
    return this.apiService.post(this._apiPath.mark_as_allocated, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
