import { Injectable } from '@angular/core'
import { HouseKeeping, Maintenance, HR, Comment } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('HouseKeepingService')

@Injectable({ providedIn: 'root' })
export class HouseKeepingService {
  constructor(
    private apiService: ApiService,
    private globalEntityServices: GlobalEntityService,
    private jwtService: JwtService,
    private router: Router,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    list: 'housekeeping/housekeeping_entity',
    detail: 'housekeeping/housekeeping_entity',
    comment: 'housekeeping/housekeeping_entity/housekeeping_add_comment',
    create_maintenance: 'maintenance/maintenance_entity',
    change_status: 'housekeeping/housekeeping_entity/change_status',
    change_type: 'housekeeping/housekeeping_entity/change_type',
    listAccByRole: 'business/hr_account',
  }

  /**
   * Lấy tất cả housekeeping đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAllHouseKeeping(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[hk_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getHousekeepingByAccount(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getTotalHousekeepingNumber(): Observable<any> {
    return this.globalEntityServices.getTotalEntity('housekeeping_entity').pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của HouseKeeping dưạ vào @id
   * @param id ID của HouseKeeping
   */
  getHouseKeepingDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  /**
   * Tạo Agent
   * @param res Thông tin HouseKeeping
   */
  createHouseKeeping(res: HouseKeeping): Observable<any> {
    // res.hid = null;
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        log.debug(data)
      })
    )
  }

  /**
   * Chỉnh sửa thông tin HouseKeeping
   *
   * @param res Thông tin HouseKeeping muốn chỉnh sửa
   */
  editHouseKeeping(res: HouseKeeping): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.hid}`, res).pipe(
      map(data => {
        log.debug(data)
      })
    )
  }

  createCommentHouseKeeping(res: Comment): Observable<any> {
    return this.apiService.post(this._apiPath.comment, res).pipe(
      map(data => {
        log.debug(data)
      })
    )
  }

  /**
   * Xóa thông tin HouseKeeping
   *
   * @param rid Xóa HouseKeeping bằng HouseKeeping ID
   */
  deleteHouseKeeping(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  createMaintenanceFromHK(maintenance: Maintenance): Observable<any> {
    let pa = this.storage.getActiveLocalBusinessID()
    maintenance.mt_business_id = { entity_id: pa }
    // if (maintenance) {
    //   Object.keys(pa).forEach(function(key) {
    //     maintenance.mt_business_id = maintenance.mt_business_id.append(
    //       key,
    //       maintenance[key]
    //     );
    //   });
    // }
    log.debug('Maintenance', maintenance)
    return this.apiService
      .post(this._apiPath.create_maintenance, maintenance)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  /**
   * Lấy thông tin User
   *
   */
  getListAccountByRoles(bid, role): Observable<HR[]> {
    let pa = new HttpParams().set('bid', bid).set('role', role)
    return this.apiService.get<HR[]>(this._apiPath.listAccByRole, pa).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  changeStatus(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_status, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeType(res): Observable<any> {
    return this.apiService.post(this._apiPath.change_type, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
