import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('GuestExportDatabaseService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class BudgetReportExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  private getCellFormat(isPercent = true) {
    if (!isPercent) {
      return '#,##0.00'
    }
    return
  }

  onGenerateReportExcel(businessName, data: any[], year = '2021') {
    const headerRow1Columns = [
      '',
      `(Currency in ${this.getCurrencyInfo().name})`,
      'Year',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(year, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: true,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: true }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 40 },
      { key: 'C2', width: 20 },
      { key: 'C3', width: 20 },
      { key: 'C4', width: 20 },
      { key: 'C5', width: 20 },
      { key: 'C6', width: 20 },
      { key: 'C7', width: 20 },
      { key: 'C8', width: 20 },
      { key: 'C9', width: 20 },
      { key: 'C10', width: 20 },
      { key: 'C11', width: 20 },
      { key: 'C12', width: 20 },
      { key: 'C13', width: 20 },
      { key: 'C14', width: 20 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `Budget Report - ${year}`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:O4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
    }
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }

    titleRow.height = 20
    worksheet.addRow([])

    let budgetGenerator = worksheet.addRow([])
    budgetGenerator.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    budgetGenerator.alignment = { horizontal: 'left', vertical: 'middle' }
    budgetGenerator.getCell(2).value = 'Budget Generator'
    budgetGenerator.getCell(2).font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }

    budgetGenerator.getCell(15).value = businessName
    budgetGenerator.getCell(15).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    budgetGenerator.getCell(15).font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number == 2) {
        cell.border = {
          top: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        cell.font = { bold: false, italic: true, size: 8 }
      }
      if (number > 2) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    headerRow1.height = 20
    worksheet.addRow([])

    const dataOccupancyDrivers =
      data.filter(f => f.type == 'occupacy_drivers') || []
    const dataOccupancyMix = data.filter(f => f.type == 'occupancy_mix') || []
    const dataRevenueDrivers =
      data.filter(f => f.type == 'revenue_drivers') || []
    const dataCostDrivers = data.filter(f => f.type == 'cost_drivers') || []
    const dataOperatingExpensesPer =
      data.filter(f => f.type == 'operating_expenses_percent') || []

    const budgetGroup = [
      dataOccupancyDrivers,
      dataOccupancyMix,
      dataRevenueDrivers,
      dataCostDrivers,
      dataOperatingExpensesPer,
    ]

    budgetGroup.map((val, index) => {
      let budgetRow = worksheet.addRow([])
      budgetRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      budgetRow.alignment = { horizontal: 'left', vertical: 'middle' }
      switch (index) {
        case 0:
          budgetRow.getCell(2).value = 'Occupancy Drivers'
          break
        case 1:
          budgetRow.getCell(2).value = 'Occupancy Mix'
          break
        case 2:
          budgetRow.getCell(2).value = 'Revenue Drivers'
          break
        case 3:
          budgetRow.getCell(2).value = 'Cost Drivers (% of Segment Revenue)'
          break
        case 4:
          budgetRow.getCell(2).value = 'Operating Expenses (% of Gross Revenue)'
          break
        default:
          break
      }

      for (let index = 2; index < 16; index++) {
        budgetRow.getCell(index).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9E1F2' },
        }
      }

      budgetRow.getCell(2).font = {
        name: 'Calibri',
        size: 10,
        bold: true,
      }
      if (val && val.length > 0) {
        for (let row of val) {
          let dataRowColumn = this.generateReportColumnArr(
            row.name,
            row.data,
            row.data_type == 'percent'
          )
          let dataRow = worksheet.addRow(dataRowColumn)
          dataRow.font = {
            name: 'Calibri',
            size: 10,
            bold: false,
          }
          dataRow.eachCell((cell, number) => {
            if (number > 2) {
              if (row.data_type != 'percent') {
                if (row.data_type != 'number') {
                  cell.numFmt = '#,##0.0'
                } else {
                  cell.numFmt = '#,##0'
                }
              } else {
                cell.numFmt = '0.0%'
              }
            }
          })
          dataRow.height = 20
          dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
          dataRow.getCell(2).alignment = { horizontal: 'left' }
        }
      }
      worksheet.addRow([])
    })

    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    let plGenerator = worksheet.addRow([])
    plGenerator.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    plGenerator.alignment = { horizontal: 'left', vertical: 'middle' }
    plGenerator.getCell(2).value = 'Projected P&L'
    plGenerator.getCell(2).font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }

    plGenerator.getCell(15).value = businessName
    plGenerator.getCell(15).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    plGenerator.getCell(15).font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }

    let headerRow2 = worksheet.addRow(headerRow1Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number == 2) {
        cell.border = {
          top: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        cell.font = { bold: false, italic: true, size: 8 }
      }
      if (number > 2) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    headerRow2.height = 20
    worksheet.addRow([])

    const dataRevenue = data.filter(f => f.type == 'revenue') || []
    const dataCost = data.filter(f => f.type == 'cost') || []
    const dataGrossProfit = data.filter(f => f.type == 'gross_profit') || []
    const dataOperatingExpenses =
      data.filter(f => f.type == 'operating_expenses') || []
    const dataGrossOperatingProfit =
      data.filter(f => f.type == 'gross_operating_profit') || []

    const plGroup = [
      dataRevenue,
      dataCost,
      dataGrossProfit,
      dataOperatingExpenses,
      dataGrossOperatingProfit,
    ]

    plGroup.map((val, index) => {
      let plRow = worksheet.addRow([])
      plRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      plRow.alignment = { horizontal: 'left', vertical: 'middle' }
      switch (index) {
        case 1:
          plRow.getCell(2).value = 'Revenue'
          break
        case 2:
          plRow.getCell(2).value = 'Cost'
          break
        case 3:
          plRow.getCell(2).value = 'Gross Profit'
          break
        case 4:
          plRow.getCell(2).value = 'Operating Expenses'
          break
        case 5:
          plRow.getCell(2).value = 'Gross Operating Profit'
          break
        default:
          break
      }

      for (let index = 2; index < 16; index++) {
        plRow.getCell(index).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9E1F2' },
        }
      }

      plRow.getCell(2).font = {
        name: 'Calibri',
        size: 10,
        bold: true,
      }
      if (val && val.length > 0) {
        for (let row of val) {
          let dataRowColumn = this.generateReportColumnArr(
            row.name,
            row.data,
            row.data_type == 'percent'
          )
          let dataRow = worksheet.addRow(dataRowColumn)
          const isBold =
            row.key == 'br_re_tot_revenue' ||
            row.key == 'br_co_tot_cost' ||
            row.key == 'br_oep_tot'
          dataRow.font = {
            name: 'Calibri',
            size: 10,
            bold: isBold,
          }
          dataRow.eachCell((cell, number) => {
            if (number > 1) {
              if (isBold) {
                cell.border = {
                  top: { style: 'thin' },
                }
              }
            }
            if (number > 2) {
              if (row.data_type != 'percent') {
                if (row.data_type != 'number') {
                  cell.numFmt = '#,##0.0'
                } else {
                  cell.numFmt = '#,##0'
                }
              } else {
                cell.numFmt = '0.0%'
              }
            }
            if (number > 3 && row.key == 'br_gop_tot') {
              dataRow.font = {
                bold: true,
              }
            }
          })

          dataRow.height = 20
          dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
          dataRow.getCell(2).alignment = { horizontal: 'left' }
        }
      }
      worksheet.addRow([])
    })

    worksheet.addRow([])
    let headerNotes = worksheet.addRow([])
    headerNotes.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    headerNotes.alignment = { horizontal: 'left', vertical: 'middle' }
    headerNotes.getCell(2).value = 'Notes'
    for (let index = 2; index < 16; index++) {
      headerNotes.getCell(index).border = {
        bottom: { style: 'thin' },
      }
    }

    const dataNotes = data.filter(f => f.type == 'notes') || []

    dataNotes.map((val, index) => {
      let noteRow = worksheet.addRow([])
      noteRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
        italic: true,
      }
      noteRow.alignment = { horizontal: 'left', vertical: 'middle' }
      noteRow.getCell(2).value = val.data
    })

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, `Budget ${year}` + '.xlsx')
    })
  }

  private generateReportColumnArr(name, data, isPercent) {
    let row = []
    row.push('')
    row.push(name)
    for (let mon of data) {
      const val = isPercent ? mon.amount / 100 : mon.amount
      row.push(val)
    }
    return row
  }
}
