import { Injectable } from '@angular/core'
import { Room, HouseKeeping } from '../../../models'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { LocalStorageService } from '../../../helper'
const log = new Logger('RoomService')

/**
 * Đây là service xử lý về Room
 */
@Injectable({ providedIn: 'root' })
export class RoomService {
  constructor(
    private apiService: ApiService,
    private globalEntityServices: GlobalEntityService,
    private jwtService: JwtService,
    private router: Router,
    private fieldInfo: FieldInfoService,
    private storage: LocalStorageService
  ) { }

  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    listrooms: 'room/room_entity',
    roomdetail: 'room/room_entity',
    info: 'field/field_info',
    detail: 'housekeeping/housekeeping_entity',
    getReservationHistory: 'reservations/reservations_entity',
    getMaintenanceHistory: 'maintenance/maintenance_entity',
    list_accouting_codes: 'manager/account_code_entity',
    room_report: 'room/report_rooms_register',
    settings: 'room/room_register_settings'
  }

  getAllRooms(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[room_business_id]', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.listrooms, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getListAccountingCodes(account_code?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[ac_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', 'true')
      .set('parameters[ac_number_type]', account_code)
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_accouting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllRoomsRegister(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[room_business_id]', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.listrooms, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getRoomReport(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.room_report, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getReservationHistory(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(this._apiPath.getReservationHistory, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getMaintenanceHistory(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(this._apiPath.getMaintenanceHistory, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getFieldInfo(fieldName): Observable<any> {
    let params = new HttpParams().set('field_name', fieldName)
    return this.apiService.get(this._apiPath.info, params).pipe(
      map(data => {
        log.debug('Field: ', data)
        return data
      })
    )
  }

  getTotalRoomsNumber(): Observable<any> {
    return this.globalEntityServices.getTotalEntity('room_entity').pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Room dưạ vào @roomId
   * @param roomId ID của Room
   */
  getRoomDetail(roomId: string): Observable<Room> {
    return this.apiService
      .get<Room>(`${this._apiPath.roomdetail}/${roomId}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   *
   * @param room Thông tin phòng
   */
  createRoom(room: Room): Observable<any> {
    room.rid = null
    return this.apiService.post(this._apiPath.roomdetail, room).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin room
   *
   * @param room Thông tin room muốn chỉnh sửa
   */
  editRoom(room: Room): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.roomdetail}/${room.rid}`, room)
      .pipe(
        map(data => {
        })
      )
  }

  /**
   * Xóa thông tin room
   *
   * @param rid Xóa room bằng Room ID
   */
  deleteRoom(roomId): Observable<any> {
    return this.apiService.delete(`${this._apiPath.roomdetail}/${roomId}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * get Rooms Status
   */
  getStatus(): Observable<any> {
    return this.fieldInfo.getFieldInfo('room_status').pipe(
      map(data => {
        return data.values
      })
    )
  }

  /**
   * Create HouseKeeping
   */

  createHouseKeeping(res: HouseKeeping): Observable<any> {
    res.hid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * get Room Type
   */
  getRoomType(): Observable<any> {
    return this.fieldInfo.getFieldInfo('room_type').pipe(
      map(data => {
        return data.values
        log.debug('show field Roomtype', data.values)
      })
    )
  }
  
  getRoomRegisterSettings(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService
      .get(`${this._apiPath.settings}/${bid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateSettings(res: any): Observable<any> {
    return this.apiService.post(this._apiPath.settings, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
