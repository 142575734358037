export class SocialsConfig {
  FaceBookAppID: string = '439568213109661'
  FacebookAppVersion: string = 'v3.2'
  FacebookAppScope: 'email,public_profile'
  GoogleClientID: string =
    '629261140638-dfo66v7sglc7vsd1n68lmpmi88d7943q.apps.googleusercontent.com'
  GoogleClientSecret: string = '8N8QA2OzvsQ2aI4BB7oW4Zb3'
  GoolgeAppScope: 'email,profile'
  constructor() {}
}
