import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class QuickLaunchNavigationService {
  private isShow$ = new BehaviorSubject<boolean>(false)
  isShow = this.isShow$.asObservable()
  constructor() {}

  get show() {
    return this.isShow$.value
  }

  set show(val: boolean) {
    this.isShow$.next(val)
  }
}
