import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { BusinessService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Business } from '../../models/business.model'

const log = new Logger('BusinessResolver')
@Injectable({ providedIn: 'root' })
export class BusinessDetailResolver implements Resolve<Observable<Business>> {
  constructor(private resService: BusinessService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['bid']
    
    return this.resService.getBusinessDetail(rid).pipe(
      map(data => {
        // log.debug("Data: ", data);
        return data
      }),
      catchError(err => this.router.navigate(['/admin/business']))
    )
  }
}
