import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Assets } from '../../../models/assets.model'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('AssetsService')

/**
 * Đây là services dành cho AssetsService
 */
@Injectable({ providedIn: 'root' })
export class AssetsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'assets/assets_entity',
    list_asset_types: 'procurement/asset_types_entity',
    detail: 'assets/assets_entity',
  }

  /**
   * Lấy tất cả assets đang available
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[asset_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Reservation dưạ vào @resId
   * @param Id ID của Reservation
   */
  getDetail(id: string): Observable<Assets> {
    return this.apiService.get<Assets>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Assets
   * @param res Thông tin Assets
   */
  create(res: Assets): Observable<any> {
    res.aid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Assets
   *
   * @param res Thông tin Assets muốn chỉnh sửa
   */
  edit(res: Assets): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.aid}`, res).pipe(
      map(data => {
      })
    )
  }

  /**
   * Xóa thông tin Assets
   *
   * @param id Xóa Season bằng Assets ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả asset types đang available
   */
  getAllAssettypes(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list_asset_types, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
