import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { PaymentMethod } from '@hmp/models/payment-method.model'

const log = new Logger('PaymentMethodService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class PaymentMethodService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'payment/pay_method_entity',
    detail: 'payment/pay_method_entity',
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[pm_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllPaymentMethod(
    bill_to?: any,
    entity_type?,
    foc?: any,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[pm_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
      .set('bill_to', bill_to || '')
      .set('entity_type', entity_type)
      .set('foc', foc || '')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: PaymentMethod): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.id}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res: PaymentMethod): Observable<any> {
    res.id = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<PaymentMethod> {
    return this.apiService
      .get<PaymentMethod>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
