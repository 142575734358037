/*
 * CÁCH  SỬ DỤNG
 * #IMPORT:
 * import { Logger } from "./core"; //Cách này cho toàn bộ core
 * import { Logger } from './app/core/logger.service'; // Cách này riêng lẽ
 * const log = new Logger('TenClass/Project');
 * ...
 * log.debug('Đây là log hiển thị');
 * Nếu muốn disable log khi build production thì add vào AppComponent
 *  export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 */
export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}
/**
 * Log output handler function.
 */
export type LogOutput = (
  source: string,
  level: LogLevel,
  ...objects: any[]
) => void

export class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.Debug

  /**
   * Additional log outputs.
   */
  static outputs: LogOutput[] = []

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode(isDebug = false) {
    Logger.level = isDebug ? LogLevel.Debug : LogLevel.Off
  }

  constructor(private source?: string) {}

  /**
   * Logs messages or objects  with the table level.
   * Works the same as console.log().
   */
  table(...objects: any[]) {
    this.log(console.table, LogLevel.Debug, objects)
  }

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects: any[]) {
    this.log(console.log, LogLevel.Debug, objects)
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects: any[]) {
    this.log(console.info, LogLevel.Info, objects)
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects: any[]) {
    this.log(console.warn, LogLevel.Warning, objects)
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects: any[]) {
    this.log(console.error, LogLevel.Error, objects)
  }

  private log(func: Function, level: LogLevel, objects: any[]) {
    if (level <= Logger.level) {
      const log = this.source
        ? ['[' + this.source + ']'].concat(objects)
        : objects
      func.apply(console, log)
      Logger.outputs.forEach(output =>
        output.apply(output, [this.source, level].concat(objects))
      )
    }
  }
}
