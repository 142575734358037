import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { Observable, BehaviorSubject } from 'rxjs'
import { User, UserToken } from '../../../models'
import { ApiService } from '../../../services/general/api.service'
import { JwtService } from '../../../services/general/jwt.service'
import { AuthService } from '../../../services/general/auth.service'
import { PermissionsService } from '../../../services/general/permissions.service'
import { Logger } from '../../../logger.service'
import { LocalStorageService, DeviceInfoService } from '../../../helper'
import { LocalStorageConfig } from '../../../config/local.cf'
import { BusinessService } from '../business/business.service'
const log = new Logger('UserService')
const localConfig = new LocalStorageConfig()

/**
 * Đây là class handle dữ liệu user như kiểm tra login, user hiện tại, verify account
 */

@Injectable({ providedIn: 'root' })
export class UserService {
  isProfileChange$ = new BehaviorSubject<boolean>(false)
  isProfileChange = this.isProfileChange$.asObservable()
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private storage: LocalStorageService,
    private auth: AuthService,
    private busService: BusinessService,
    private deviceInfo: DeviceInfoService,
    private permissionService: PermissionsService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    login: 'user_oauth/login',
    register: 'user/register',
    userProfile: 'user_profile',
    resetPassword: 'user/user_pass_reset',
    requestNewPassword: 'user/request_new_password',
    passwordReset: 'user/password_reset',
    getRefreshToken: 'user_oauth/refresh_token',
    userupdate: 'user',
    facebooklogin: 'user_oauth/login_facebook',
    googlelogin: 'user_oauth/login_google',
    requestdemo: 'user_oauth/register_demo_account',
  }

  /**
   * Tiến hành xác thực và lấy token về
   * @param user Chứa username và password
   * @return access token, refresh token.
   */
  login(username: string, password: string): Observable<any> {
    const agent = this.deviceInfo.getDeviceInfo()
    log.debug('Device Info: ', agent)
    return this.apiService
      .post<UserToken>(this._apiPath.login, {
        username: username,
        password: password,
        agent: agent,
      })
      .pipe(
        map(userToken => {
          if (userToken.access_token) {
            this.jwtService.saveToken(userToken)
          }
          return userToken
        })
      )
  }

  requestdemo(res): Observable<any> {
    return this.apiService.post<any>(this._apiPath.requestdemo, res).pipe(
      map(data => {
        return data
      })
    )
  }

  loginFacebok(token): Observable<any> {
    return this.apiService
      .post<UserToken>(this._apiPath.facebooklogin, {
        token: token,
      })
      .pipe(
        map(userToken => {
          if (userToken.access_token) {
            this.jwtService.saveToken(userToken)
          }
          return userToken
        })
      )
  }

  loginGoogle(token): Observable<any> {
    return this.apiService
      .post<UserToken>(this._apiPath.googlelogin, {
        token: token,
      })
      .pipe(
        map(userToken => {
          if (userToken.access_token) {
            this.jwtService.saveToken(userToken)
          }
          return userToken
        })
      )
  }

  /**
   * Đăng ký tài khoản user
   * @param user Chứa thông tin user
   */
  register(user): Observable<any> {
    return this.apiService.post(this._apiPath.register, user).pipe(
      map(userProfile => {
        return userProfile
      })
    )
  }

  /**
   * Lấy thông tin user dựa vào access token người dùng nhập.
   * @return là thông tin user nếu có, và báo lỗi nếu chưa có token header.
   */
  userProfile(): Observable<User> {
    return this.apiService.get<User>(this._apiPath.userProfile).pipe(
      map(user => {
        this.auth.setAuth(user)
        const roles = (user && user.user_roles) || []
        this.permissionService.setProfileRoles(roles)
        if (user && user.uid) {
          this.isProfileChange$.next(true)
        }
        return user
      })
    )
  }

  /**
   * Lấy token mới
   */
  getRefreshToken(): Observable<any> {
    const refreshToken = this.jwtService.getRefreshToken()
    if (!refreshToken) {
      return
    }
    const headers = {
      'Content-Type': 'application/json',
      // "X-Skip-Interceptor": ""
    }
    log.debug('=> Local RefreshToken: ', refreshToken)
    return this.apiService.getRefreshToken(
      this._apiPath.getRefreshToken,
      { refresh_token: refreshToken },
      headers
    )
  }

  /**
   * Cập nhật thông tin
   * @param uid Chứa thông ID user
   * @param user Chứa thông tin user
   */
  userUpdate(uid, user): Observable<any> {
    return this.apiService.put(this._apiPath.userupdate + '/' + uid, user).pipe(
      map(userProfile => {
        this.auth.setAuth(userProfile)
        return userProfile
      })
    )
  }

  /**
   * Cập nhật thông tin
   * @param uid Chứa thông ID user
   * @param user Chứa thông tin hình ảnh đại diện của User
   */
  avatarUpdate(uid, user): Observable<any> {
    return this.apiService.put(this._apiPath.userupdate + '/' + uid, user).pipe(
      map(userProfile => {
        this.auth.setAuth(userProfile)
        return userProfile
      })
    )
  }

  /**
   * Lấy lại tài khoản user
   * @param user Chứa thông tin user
   */
  forgotpass(mail): Observable<any> {
    return this.apiService
      .post(this._apiPath.requestNewPassword, { name: mail })
      .pipe(
        map(user => {
          return user
        })
      )
  }

  /**
   * Lưu ngôn ngữ vào local cũng như lưu online
   * @param lang Ngôn ngữ
   */
  // tslint:disable-next-line: typedef
  saveUserProfileLanguage(lang: string) {
    const curUserProfile = this.storage.getItem(
      localConfig.localStorageKey.CurrentUser,
      true,
      true
    )
    if (curUserProfile) {
      log.debug('Current User: ', curUserProfile)
      curUserProfile.language = lang
      this.storage.saveItem(
        localConfig.localStorageKey.CurrentUser,
        curUserProfile,
        true,
        true
      )
      this.storage.saveItem(
        localConfig.localStorageKey.LocalLanguage,
        lang,
        false,
        false
      )
    }
  }
}
