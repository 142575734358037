import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('ReportExportService')
import * as _moment from 'moment'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class FixedAssetExportService {
  constructor(private auth: AuthService) {}

  getCurrencyInfo() {
    const currentGeneral = this.auth.getBusinessGeneralInfo()
    let currency = 'usd'
    if (currentGeneral) {
      currency = currentGeneral.business_currency || 'usd'
    }
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateReportExcel(
    title,
    data: any[],
    sheetname,
    filename,
    logo,
    kpi?: any[]
  ) {
    const headerRow1Columns = [
      '',
      '',
      'Asset Class',
      'Net Fixed Asset',
      'Less: Accum Depreciation',
      'Total Cost',
      'Installation Date',
      'Depreciation Life (yrs)',
      'Salvage Value',
    ]

    const workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 7 },
      { key: 'C2', width: 30 },
      { key: 'C3', width: 20 },
      { key: 'C4', width: 20 },
      { key: 'C5', width: 20 },
      { key: 'C6', width: 20 },
      { key: 'C7', width: 20 },
      { key: 'C8', width: 20 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    const titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    const titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:O4')
    const titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      //Add Image
      const logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    const headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 2) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      const dataRowColumn = this.generateRowValueColumnArr(r)
      const rootvalueRow = worksheet.addRow(dataRowColumn)
      this.formatRowDefaultValue(rootvalueRow)
      rootvalueRow.eachCell((cell, number) => {
        if (number > 2) {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
          }
        }
      })
    }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private formatRowDefaultValue(dataRow) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.height = 17
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(3).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(4).numFmt = '#,##0.00'
    dataRow.getCell(5).numFmt = '#,##0.00'
    dataRow.getCell(6).numFmt = '#,##0.00'
    dataRow.getCell(7).numFmt = 'DD-MMM-YY'
    dataRow.getCell(7).alignment = { horizontal: 'center', vertical: 'middle' }
    dataRow.getCell(8).numFmt = '#,##0'
    dataRow.getCell(8).alignment = { horizontal: 'center', vertical: 'middle' }
    dataRow.getCell(9).numFmt = '#,##0'
    dataRow.getCell(9).alignment = { horizontal: 'center', vertical: 'middle' }
    return dataRow
  }

  private generateRowValueColumnArr(data, prefixText?) {
    const row = []
    row.push('')
    row.push('')
    row.push(data.asset_class || ' ')
    row.push(data.net)
    row.push(data.less)
    row.push(data.total_cost)
    if (data.installed_date) {
      const date = moment.unix(data.installed_date).format('DD-MMM-YYYY')
      row.push(date)
    } else {
      row.push(' ')
    }
    row.push(data.useful_life_year)
    row.push(data.salvage_value)
    return row
  }
}
