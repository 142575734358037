import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "FilterPipe"
})
export class SearchPipe implements PipeTransform {
  transform(items: any, filter: any, defaultFilter: boolean): any {
    if (!filter || !Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter(item =>
          filterKeys.reduce(
            (x, keyName) =>
              (x && new RegExp(filter[keyName], "gi").test(item[keyName])) ||
              filter[keyName] == "",
            true
          )
        );
      } else {
        return items.filter(item => {
          return Object.keys(item).some(keyName => {
            return (
              new RegExp(filter, "gi").test(item[keyName]) ||
              new RegExp(filter, "gi").test(item.title) ||
              new RegExp(filter, "gi").test(item.reservations_client_name) ||
              new RegExp(filter, "gi").test(item.room_type.title) ||
              new RegExp(filter, "gi").test(item.reservation_room.rr_reservation.book_reference) ||
              filter[keyName] == ""
            );
          });
        });
      }
    }
  }
}
