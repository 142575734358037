import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
const log = new Logger('DemoAccountService')
@Injectable({
  providedIn: 'root',
})
export class DemoAccountService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    list: 'list_demo_account',
    active_account: 'list_demo_account/active_account',
    remove_account: 'list_demo_account/remove_account',
    set_passowrd: 'list_demo_account/set_pass_account',
  }

  getAll(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(key => {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  active(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.active_account}`, res).pipe(
      map(data => {
        return data
      })
    )
  }
  setpassword(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.set_passowrd}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService
      .post(`${this._apiPath.remove_account}`, { uid: id })
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
