import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { PackagesActivities, DailyActivities } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('PackagesService')

/**
 * Đây là services dành cho PaybillService
 */
@Injectable({ providedIn: 'root' })
export class PackagesService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    DailyPackage: {
      list: 'activities/daily_activities_entity',
      detail: 'activities/daily_activities_entity',
      order: 'activities/daily_activities_entity/add_item_for_order_packages',
      remove_activities: 'activities/daily_activities_entity/remove_activities',
    },
    ActivitiesPackage: {
      list: 'activities/packages_entity',
      detail: 'activities/packages_entity',
    },
    ListPackagesAvailable: 'activities/packages_available',
    report_packages_sales: {
      lists: 'report/report_package_sales'
    }
  }

  /**
   * Get danh sách Daily Activities
   * @param params
   */
  getDailyActivities(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[daily_activities_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.DailyPackage.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<PackagesActivities> {
    return this.apiService
      .get<PackagesActivities>(`${this._apiPath.ActivitiesPackage.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy thông tin chi tiết của Daily Activities dưạ vào @id
   * @param Id ID của Paybill
   */
  getDailyActivitiesDetail(id: string): Observable<DailyActivities> {
    return this.apiService
      .get<DailyActivities>(`${this._apiPath.DailyPackage.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo Daily Activities
   * @param res Thông tin Daily Activities
   */
  createDailyActivities(res: DailyActivities): Observable<any> {
    res.did = null
    return this.apiService.post(this._apiPath.DailyPackage.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Order
   * @param res Thông tin Order
   */
  creatOrder(res): Observable<any> {
    return this.apiService.post(this._apiPath.DailyPackage.order, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Daily Activities
   *
   * @param res Thông tin Daily Activities muốn chỉnh sửa
   */
  editDailyActivities(res: DailyActivities): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.DailyPackage.detail}/${res.did}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Xóa thông tin Daily Activities
   *
   * @param id Xóa Daily Activities bằng Daily Activities ID
   */
  deleteDailyActivities(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.DailyPackage.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  removeActivities(activities): Observable<any> {
    return this.apiService
      .post(this._apiPath.DailyPackage.remove_activities, activities)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Get danh sách Packages Activities
   * @param params
   */
  getPackagesActivities(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[packages_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(this._apiPath.ActivitiesPackage.list, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Lấy thông tin chi tiết của Packages Activities dưạ vào @id
   * @param Id ID của Paybill
   */
  getPackagesActivitiesDetail(id: string): Observable<PackagesActivities> {
    return this.apiService
      .get<PackagesActivities>(
        `${this._apiPath.ActivitiesPackage.detail}/${id}`
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo Packages Activities
   * @param res Thông tin Daily Activities
   */
  createPackagesActivities(res: PackagesActivities): Observable<any> {
    res.pid = null
    return this.apiService
      .post(this._apiPath.ActivitiesPackage.detail, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Chỉnh sửa thông tin Packages Activities
   *
   * @param res Thông tin Packages Activities muốn chỉnh sửa
   */
  editPackagesActivities(res: PackagesActivities): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.ActivitiesPackage.detail}/${res.pid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Xóa thông tin Packages Activities
   *
   * @param id Xóa Packages Activities bằng Packages Activities ID
   */
  deletePackagesActivities(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.ActivitiesPackage.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Get danh sách Packages Available
   * @param params
   */
  getPackagesAvailable(
    cin,
    cout,
    params?: any
  ): Observable<PackagesActivities[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('check_in', cin)
      .set('check_out', cout)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<PackagesActivities[]>(this._apiPath.ListPackagesAvailable, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReportPackageSales(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.report_packages_sales.lists, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
