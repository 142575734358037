import { Injectable } from '@angular/core'
import { SocialsConfig } from '../../../config/social.cf'
import { Logger } from '../../../logger.service'

const log = new Logger('GoogleLoginService')
declare let gapi: any

@Injectable({ providedIn: 'root' })
export class GoogleLoginService {
  private cf = new SocialsConfig()
  private auth2: any

  constructor() {}

  initScipt(curLang) {
    let lang = `https://apis.google.com/js/platform.js?hl=${curLang}`
    log.debug('Google URL: ', lang)
    this.loadScript(lang, () => {
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          client_id: this.cf.GoogleClientID,
          scope: this.cf.GoolgeAppScope,
        })

        this.auth2.then(() => {
          if (this.auth2.isSignedIn.get()) {
            let profile = this.auth2.currentUser.get().getBasicProfile()
            let authResponseObj = this.auth2.currentUser
              .get()
              .getAuthResponse(true)
            log.debug('profile: ', profile)
            log.debug('authResponseObj: ', authResponseObj)
          }
        })
      })
    })
  }

  checkAuth() {}

  signIn(): Promise<any> {
    log.debug('signIn')
    return new Promise((resolve, reject) => {
      let promise = this.auth2.signIn()
      promise.then(() => {
        this.auth2.then(() => {
          if (this.auth2.isSignedIn.get()) {
            let profile = this.auth2.currentUser.get().getBasicProfile()
            let authResponseObj = this.auth2.currentUser
              .get()
              .getAuthResponse(true)
            log.debug('profile: ', profile)
            log.debug('authResponseObj: ', authResponseObj)
            resolve(authResponseObj)
          }
        })
      })
    })
  }

  private loadScript(url: string, callback: any) {
    if (document.getElementById('google-sdk')) {
      return
    }
    let signInJS = document.createElement('script')
    signInJS.async = true
    signInJS.src = url
    signInJS.id = 'google-sdk'
    signInJS.onload = callback

    document.head.appendChild(signInJS)
  }
}
