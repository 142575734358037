import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  AfterViewInit,
} from '@angular/core'
import { Logger } from '../logger.service'
import { PermissionsService, RoleActionsData } from '@hmp/services'

const log = new Logger('HmpContentPermissionDirective')
@Directive({
  selector: '[PERC]',
})
export class HMPContentPermissionDirective implements AfterViewInit {
  @Input() Entity: string
  @Input() Action: string
  @Input() Dis: boolean
  @Input() Hid: boolean

  constructor(
    private elem: ElementRef,
    private renderer: Renderer2,
    private permissionService: PermissionsService
  ) {}

  ngAfterViewInit(): void {
    this.onChecking()
  }

  onChecking() {
    let entity = this.Entity
    let action = this.Action
    let isDisable = this.Dis
    let isHidden = this.Hid
    if (entity && action) {
      let roleAction = RoleActionsData.View
      switch (action) {
        case '1':
          roleAction = RoleActionsData.View
          break
        case '2':
          roleAction = RoleActionsData.Edit
          break
        case '3':
          roleAction = RoleActionsData.Delete
          break
        case '4':
          roleAction = RoleActionsData.Create
          break
        default:
          return
      }
      let isHasPermission = this.permissionService.hasPermission(
        entity,
        roleAction
      )
      if (!isHasPermission) {
        if (isHidden !== undefined) {
          let parNode = this.renderer.parentNode(this.elem.nativeElement)
          this.renderer.removeChild(parNode, this.elem.nativeElement)
        }
        if (isDisable !== undefined) {
          this.renderer.setAttribute(
            this.elem.nativeElement,
            'disabled',
            'true'
          )
        }
      }
      return
    }
    log.debug('ELE ', this.elem.nativeElement)
    log.debug('ELE ', this.renderer)
  }
}
