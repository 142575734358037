import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'

const log = new Logger('GlobalEntityService')
@Injectable({ providedIn: 'root' })
export class GlobalEntityService {
  constructor(private apiService: ApiService) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    get_entity_content: 'global/get_entity_content',
    get_total_entity: 'global/get_total_entity',
  }

  /**
   * Lấy nội dung dựa vào Entity
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'entity_id': 10, 'entity_type': 'season_entity', 'bundle': 'season' }
   */
  getEntityContent(params?: any): Observable<any> {
    let pa = new HttpParams()
    Object.keys(params).forEach(function(key) {
      pa = pa.append(key, params[key])
    })
    return this.apiService.get(this._apiPath.get_entity_content, pa).pipe(
      map(data => {
        log.debug('getEntityContent: ', data)
        return data
      })
    )
  }

  getTotalEntity(entityValue: string): Observable<any> {
    let param = new HttpParams().set('entity_type', entityValue)
    return this.apiService.get(this._apiPath.get_total_entity, param).pipe(
      map(data => {
        log.debug('getTotalEntity: ', data)
        if (data) {
          return data[0]
        }
        return 0
      })
    )
  }
}
