import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('ReportExportService')
import * as _moment from 'moment'
import { DecimalPipe } from '@angular/common'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class ReportExportService {
  constructor(private auth: AuthService,private decimal: DecimalPipe,) {}

  getCurrencyInfo() {
    const currentGeneral = this.auth.getBusinessGeneralInfo()
    let currency = 'usd'
    if (currentGeneral) {
      currency = currentGeneral.business_currency || 'usd'
    }
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateBoBReportExcel(
    title,
    yearTitle,
    data: any[],
    sheetname,
    filename,
    logo,
    kpi?: any[],
    isActual?,
    dataUse?: any[]
  ) {
    const headerRow1Columns = [
      '',
      '',
      'YTD',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 28 },
      { key: 'C2', width: 13 },
      { key: 'C3', width: 11 },
      { key: 'C4', width: 11 },
      { key: 'C5', width: 11 },
      { key: 'C6', width: 11 },
      { key: 'C7', width: 11 },
      { key: 'C8', width: 11 },
      { key: 'C9', width: 11 },
      { key: 'C10', width: 11 },
      { key: 'C11', width: 11 },
      { key: 'C12', width: 11 },
      { key: 'C13', width: 11 },
      { key: 'C14', width: 11 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    const titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    const titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:O4')
    const titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      const logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    const headerRow2 = worksheet.addRow([])
    headerRow2.height = 20

    headerRow2.getCell(2).value = yearTitle

    headerRow2.getCell(2).alignment = {
      horizontal: 'left',
      vertical: 'middle',
    }
    headerRow2.getCell(2).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      underline: 'single',
    }

    worksheet.addRow([])

    const headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 2) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        if (isActual) {
          cell.border = {
            top: { style: 'thin' },
          }
        }
      }
    })
    headerRow1.height = 18

    if (isActual) {
      const dataUseData = ['', '', '']
      for (let index = 1; index <= 12; index++) {
        const dataN = this.getDataUseByMonth(index, dataUse)
        dataUseData.push(dataN)
      }
      const headerRow2 = worksheet.addRow(dataUseData)
      headerRow2.font = {
        name: 'Calibri',
        size: 11,
        bold: true,
      }
      headerRow2.eachCell((cell, number) => {
        if (number == 3) {
          cell.border = {
            bottom: { style: 'medium' },
          }
        }
        if (number > 3) {
          cell.border = {
            bottom: { style: 'medium' },
          }
          cell.alignment = { horizontal: 'center', vertical: 'middle' }
          const dataN = this.getDataUseByMonth(number - 3, dataUse)
          if (dataN == 'Actual') {
            cell.font = { color: { argb: '001195E5' } }
          } else if (dataN == 'Budget') {
            cell.font = { color: { argb: '00E51111' } }
          }
        }
      })
      headerRow2.height = 22
    }
    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      const children = r.children as any[]
      const type = r.type
      switch (type) {
        case 'root':
          const dataRootRowColumn = this.generateBoBRootColumnArr(r)
          this.generateRootRow(worksheet, r, dataRootRowColumn)
          break
        case 'rootvalue':
          const dataRowColumn = this.generateBoBRowValueColumnArr(r)
          const rootvalueRow = worksheet.addRow(dataRowColumn)
          this.formatRowDefaultValue(rootvalueRow, r)
          rootvalueRow.font = {
            name: 'Calibri',
            size: 10,
            bold: true,
          }
          rootvalueRow.eachCell((cell, number) => {
            if (number > 2) {
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'medium' },
              }
              if (number === 3) {
                cell.border = {
                  right: { style: 'thin' },
                  top: { style: 'thin' },
                  bottom: { style: 'medium' },
                }
              }
            }
          })

          break
        default:
          break
      }
      if (children && children.length > 0) {
        children.map(c => {
          const cType = c.type
          if (cType === 'row') {
            const arrRowColumn = this.generateBoBRowValueColumnArr(c, '    ')
            const defaultRow = worksheet.addRow(arrRowColumn)
            this.formatRowDefaultValue(defaultRow, c)
            if (c.id === 'gopmargin' || c.id === 'gross_profit_margin') {
              defaultRow.font = {
                name: 'Calibri',
                size: 10,
                bold: false,
                italic: 'true',
              }
            }
          } else if (cType === 'total') {
            const dataRowColumnArr = this.generateBoBRowValueColumnArr(
              c,
              '        '
            )
            const totalRow = worksheet.addRow(dataRowColumnArr)
            this.formatRowDefaultValue(totalRow, c)
            totalRow.font = {
              name: 'Calibri',
              size: 10,
              bold: true,
            }
            totalRow.eachCell((cell, number) => {
              if (number > 2) {
                cell.border = {
                  top: { style: 'thin' },
                }
                if (number === 3) {
                  cell.border = {
                    right: { style: 'thin' },
                    top: { style: 'thin' },
                  }
                }
              }
            })
          }
        })
      }
    }
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    if (kpi && kpi.length > 0) {
      const kipTitle = `KEY OPERATING INDICATORS (currency in ${
        this.getCurrencyInfo().name
      })`
      const kpiRow = worksheet.addRow([' ', kipTitle])
      kpiRow.font = {
        name: 'Calibri',
        size: 11,
        bold: true,
      }
      for (let index = 0; index < kpi.length; index++) {
        const r = kpi[index]
        const type = r.type
        switch (type) {
          case 'root':
            const dataRootRowColumn = this.generateBoBRootColumnArr(r)
            this.generateRootRow(worksheet, r, dataRootRowColumn)
            break
          case 'row':
            const dataRowColumn = this.generateBoBRowValueColumnArr(r, '')
            const defaultRow = worksheet.addRow(dataRowColumn)
            this.formatRowDefaultValue(defaultRow, r)
            break
          default:
            break
        }
      }
    }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private getCellFormat(row) {
    if (row.id === 'gopmargin' || row.id === 'gross_profit_margin' || row.id === 'paid_occupancy') {
      return '0.0%'
    }
    return '0.0'
  }

  private getDataUseByMonth(m, dataUse) {
    if (!dataUse || dataUse.length === 0) {
      return
    }
    const isDa = dataUse.find(d => d.month == m)
    if (isDa) {
      const d = isDa.data_use as string
      const ma = d.charAt(0).toUpperCase() + d.substr(1).toLowerCase()
      return ma
    }
    return
  }

  private generateRootRow(worksheet, r, column) {
    const dataRow = worksheet.addRow(column)
    dataRow.height = 18
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(2).font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    dataRow.getCell(3).border = {
      right: { style: 'thin' },
    }
    return dataRow
  }

  private formatRowDefaultValue(dataRow, r, isKpi?) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    if (isKpi) {
      dataRow.getCell(2).alignment = { horizontal: 'right', vertical: 'middle' }
    }
    dataRow.getCell(3).numFmt = this.getCellFormat(r)
    dataRow.getCell(3).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(4).numFmt = this.getCellFormat(r)
    dataRow.getCell(5).numFmt = this.getCellFormat(r)
    dataRow.getCell(6).numFmt = this.getCellFormat(r)
    dataRow.getCell(7).numFmt = this.getCellFormat(r)
    dataRow.getCell(8).numFmt = this.getCellFormat(r)
    dataRow.getCell(9).numFmt = this.getCellFormat(r)
    dataRow.getCell(10).numFmt = this.getCellFormat(r)
    dataRow.getCell(11).numFmt = this.getCellFormat(r)
    dataRow.getCell(12).numFmt = this.getCellFormat(r)
    dataRow.getCell(13).numFmt = this.getCellFormat(r)
    dataRow.getCell(14).numFmt = this.getCellFormat(r)
    dataRow.getCell(15).numFmt = this.getCellFormat(r)
    return dataRow
  }

  private generateBoBRootColumnArr(data) {
    const row = []
    row.push('')
    row.push(data.title)
    row.push('')
    row.push('')
    return row
  }

  formatMonthValues(value, isPercent = false) {
    const val = value ? Number(value) : 0
    return isPercent
      ? this.decimal.transform(Number(val) * 100, '1.1-1') + '%'
      : this.decimal.transform(val, '1.0-0')
  }

  private generateBoBRowValueColumnArr(data, prefixText?) {
    const monthValue = data.monthvalues
    const row = []
    row.push('')
    if (prefixText) {
      row.push(prefixText + data.title)
    } else {
      row.push(data.title)
    }
    row.push(monthValue['ytd'])
    for (let index = 1; index <= 12; index++) {
      const keyVal = index.toString()
      const keyName = this.getKeyByMonthNumber(keyVal)
      row.push(monthValue[keyName])
    }
    return row
  }
  

  private getKeyByMonthNumber(m) {
    switch (m) {
      case '1':
        return 'jan'
      case '2':
        return 'feb'
      case '3':
        return 'mar'
      case '4':
        return 'apr'
      case '5':
        return 'may'
      case '6':
        return 'jun'
      case '7':
        return 'jul'
      case '8':
        return 'aug'
      case '9':
        return 'sep'
      case '10':
        return 'oct'
      case '11':
        return 'nov'
      case '12':
        return 'dec'
      default:
        return 'jan'
    }
  }
}
