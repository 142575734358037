import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('OccupancyOnTheBooksExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class OccupancyOnTheBooksExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateOccupancyExcel(
    title,
    yearTitle,
    data: any[],
    sheetName,
    filename?
  ) {
    const headerRow1Cells = ['', '', '']
    const headerRow2Cells = ['']
    const headerRow3Cells = [
      'Daily Revenue Report',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      `${title}`,
    ]
    const headerRow4Cells = ['', '', '']
    const headerRow5Cells = [
      'Date',
      '# of Keys',
      'OOO',
      'Stay Overs',
      'Due Outs',
      'Reserved ',
      'Blocked',
      'Total',
      'Rooms OCC',
      'OCC %',
      'Rooms AFS',
      'Room Rev',
      'ADR',
      'Adults',
      'Children',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetName, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C1', width: 24 },
      { key: 'C2', width: 14 },
      { key: 'C3', width: 14 },
      { key: 'C4', width: 14 },
      { key: 'C5', width: 14 },
      { key: 'C6', width: 14 },
      { key: 'C7', width: 14 },
      { key: 'C8', width: 14 },
      { key: 'C9', width: 14 },
      { key: 'C10', width: 14 },
      { key: 'C11', width: 14 },
      { key: 'C12', width: 14 },
      { key: 'C13', width: 14 },
      { key: 'C14', width: 14 },
      { key: 'C15', width: 14 },
      { key: 'C16', width: 14 },
    ]

    // TITLE ROW 0,1,2
    let titleText = `${yearTitle}`
    let titleRow = worksheet.addRow([titleText])
    worksheet.mergeCells('A1:O1')
    let titleCell = worksheet.getCell('A1')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 16,
      bold: true,
    }
    titleCell.border = {
      // bottom: { style: 'thin' },
    }
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }
    titleRow.height = 20
    // worksheet.addRow([])
    let headerRow2 = worksheet.addRow(headerRow2Cells)
    worksheet.getCell('A2:D2').value = 'Excluding Day-Use Reservations'
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    headerRow2.alignment = { horizontal: 'center', vertical: 'middle' }
    headerRow2.height = 20

    let headerRow3 = worksheet.addRow(headerRow3Cells)
    headerRow3.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    worksheet.mergeCells('A3:C3')
    worksheet.mergeCells('L3:O3')
    headerRow3.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow3.getCell(7).alignment = { horizontal: 'right' }
    headerRow3.getCell(6).alignment = { horizontal: 'right' }
    headerRow3.getCell(8).alignment = { horizontal: 'right' }
    headerRow3.getCell(13).alignment = { horizontal: 'right' }
    headerRow3.getCell(14).alignment = { horizontal: 'right' }
    headerRow3.getCell(15).alignment = { horizontal: 'right' }
    headerRow3.height = 20

    let headerRow4 = worksheet.addRow(headerRow4Cells)
    ;(worksheet.getCell('A4:D4').value = `(Currency in ${
      this.getCurrencyInfo().name
    })`),
      (headerRow4.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
        italic: 'true',
      })
    headerRow4.alignment = { horizontal: 'left', vertical: 'middle' }
    headerRow4.height = 20
    let headerRow1 = worksheet.addRow(headerRow1Cells)
    worksheet.mergeCells('E5:F5')
    worksheet.mergeCells('G5:I5')
    worksheet.getCell('E5:F5').value = 'Occupied'
    // worksheet.getCell('E5:F5').border = {
    //   right: { style: 'thin' },
    //   left: { style: 'thin' },
    // }
    worksheet.getCell('G5:I5').value = 'Arrivals'
    // worksheet.getCell('G5:I5').border = {
    //   right: { style: 'thin' },
    //   left: { style: 'thin' },
    // }
    headerRow1.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    }
    headerRow1.alignment = { horizontal: 'center', vertical: 'middle' }
    headerRow1.height = 20

    let headerRow5 = worksheet.addRow(headerRow5Cells)
    headerRow5.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    }
    headerRow1.alignment = { horizontal: 'center', vertical: 'middle' }
    headerRow5.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 20
      dataRow.alignment = { horizontal: 'center', vertical: 'center' }
      dataRow.getCell(5).alignment = { horizontal: 'center' }
      // dataRow.getCell(4).alignment = { horizontal: 'center' }
      // dataRow.getCell(27).alignment = { horizontal: 'center' }
      dataRow.getCell(26).border = {
        right: { style: 'thin' },
        left: { style: 'thin' },
      }
    }
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push(data.date)
    row.push(data.total_arrivals)
    row.push(data.out_of_order)
    row.push(data.stay_overs)
    row.push(data.due_outs)
    row.push(data.reserved)
    row.push(data.blocked)
    row.push(data.total_rooms)
    row.push(data.rooms_occ)
    row.push(data.occ_percent)
    row.push(data.rooms_afs)
    row.push(data.room_rev)
    row.push(data.adr)
    row.push(data.adults)
    row.push(data.children)

    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
