import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Table, CommonPages } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('DirectBookingSetting')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class DirectBookingSettingService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) { }
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'direct_booking/direct_booking_entity',
    detail: 'direct_booking/direct_booking_entity',
    enable_language: 'direct_booking/get_business_enable_languages',
    common_pages: {
      list: 'common_pages/common_pages_entity',
      detail: 'common_pages/common_pages_entity',
    },
    packages: {
      list: 'db_packages/db_packages_entity',
      detail: 'db_packages/db_packages_entity',
      fbOrder: 'db_packages/db_packages_fb_orders/add_order_for_db_packages_entity',
      other_act_db: 'activities/other_activities',
    }
  }

  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[db_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getEnableLanguage(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.enable_language, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  save(res): Observable<any> {
    res.db_business = { entity_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllCommonPages(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[cp_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.common_pages.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteCommonPages(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.common_pages.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getCommonPagesDetail(id: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.common_pages.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createCommonPages(res: CommonPages): Observable<any> {
    res.id = null
    return this.apiService.post(this._apiPath.common_pages.detail, res).pipe(
      map(data => {
      })
    )
  }

  editCommonPages(res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.common_pages.detail}/${res.id}`, res)
      .pipe(
        map(data => {
        })
      )
  }

  getAllDirectPackages(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[dbp_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.packages.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteDirectPackages(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.packages.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDBPackagesDetail(id: string): Observable<any> {
    return this.apiService
      .get(`${this._apiPath.packages.detail}/${id}`)
      .pipe(
        map(data => {
          log.debug('DETAIL SERVICE', data)
          return data
        })
      )
  }

  addFBOrderForDBPackages(res): Observable<any> {
    res.bid = this.storage.getActiveLocalBusinessID()
    return this.apiService.post(this._apiPath.packages.fbOrder, res).pipe(
      map(data => {
        return data;
      })
    )
  }
  addOtherForDBPackages(res): Observable<any> {
    res.bid = this.storage.getActiveLocalBusinessID()
    return this.apiService.post(this._apiPath.packages.other_act_db, res).pipe(
      map(data => {
        log.debug('data', data)
        return data;
      })
    )
  }

  createDBPackages(res): Observable<any> {
    res.dbp_business = { entity_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.packages.detail, res).pipe(
      map(data => {
      })
    )
  }

  editDBPackages(res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.packages.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
