import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('WebNotificationsSettingsService')

@Injectable({ providedIn: 'root' })
export class WebNotificationsSettingsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    detail: 'business/notification_settings_entity',
    list_notifications: 'business/web_notifications',
    unCoundNotifications: 'business/web_notifications_number_unread',
    mark_as_starred: 'business/web_notifications/mark_as_starred',
    mark_as_read: 'business/web_notifications/mark_as_read',
    mark_all_as_read: 'business/web_notifications/mark_all_as_read',
  }

  getWebNotificationsSettings(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    let bid = this.storage.getActiveLocalBusinessID()
    return this.apiService.get(`${this._apiPath.detail}/${bid}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.detail}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  markAsStarred(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.mark_as_starred}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  markAsRead(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.mark_as_read}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  markAllAsRead(res?): Observable<any> {
    return this.apiService.post(`${this._apiPath.mark_all_as_read}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  update(res): Observable<any> {
    return this.apiService.post(`${this._apiPath.detail}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getUnreadWebNotifications(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('unread', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list_notifications, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getReadWebNotifications(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('unread', '2')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list_notifications, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  unCountNotifications(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.unCoundNotifications, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getStarredNotifications(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('starred', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list_notifications, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  // getAllWebNotifications(
  //   params?: any,
  //   unread?: any,
  //   starred?: any,
  //   from_date?: any,
  //   to_date?: any
  // ): Observable<any> {
  //   let pa = new HttpParams()
  //     .set('bid', this.storage.getActiveLocalBusinessID())
  //     .set('unread', unread)
  //     .set('starred', starred)
  //     .set('from_date', from_date)
  //     .set('to_date', to_date)
  //   if (params) {
  //     Object.keys(params).forEach(function(key) {
  //       pa = pa.append(key, params[key])
  //     })
  //   }

  //   return this.apiService.get<any>(this._apiPath.list_notifications, pa).pipe(
  //     map(data => {
  //       return data
  //     })
  //   )
  // }
}
