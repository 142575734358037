import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Schedule, ScheduleCalendar } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('ScheduleService')

/**
 * Đây là services dành cho Schedule
 */

@Injectable({ providedIn: 'root' })
export class ScheduleService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'schedule/schedule_entity',
    detail: 'schedule/schedule_entity',
    calendar: 'schedule/schedule_calendar',
    calendar_detail: 'schedule/schedule_calendar_detail',
    list_employee: 'schedule/schedule_employee',
  }

  /**
   * Lấy tất cả Schedule Calendar
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAllSchedule(params?: any): Observable<ScheduleCalendar[]> {
    let pa = new HttpParams().set(
      'parameters[schedule_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<ScheduleCalendar[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả Schedule
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getScheduleCalendar(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.calendar, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getCalendarScheduleDetail(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.calendar_detail, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả Employee chưa tạo Schedule
   */
  getEmployeeSchedule(): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    return this.apiService.get<any>(this._apiPath.list_employee, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Schedule dưạ vào @id
   * @param Id ID của Schedule
   */
  getDetail(id: string): Observable<Schedule> {
    return this.apiService.get<Schedule>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Schedule
   * @param res Thông tin Schedule
   */
  create(res: Schedule): Observable<any> {
    res.sid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Schedule
   *
   * @param res Thông tin Schedule muốn chỉnh sửa
   */
  edit(res: Schedule): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.sid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Schedule
   *
   * @param id Xóa Schedule bằng Schedule ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
