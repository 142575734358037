import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take, map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { AngularFirestore } from '@angular/fire/firestore'
import { UserChatRoom, ChatRoom } from '@hmp'
import { AuthService } from '@hmp/services/general/auth.service'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { environment } from 'environments/environment'
const log = new Logger('ChatRoomService')

/**
 * Đây là services dành cho ChatRoomService
 */
@Injectable({ providedIn: 'root' })
export class ChatRoomService {
  busPath = `${environment.name.toLowerCase()}/${this.auth.getActiveLocalBusinessID()}`
  constructor(private afs: AngularFirestore, private auth: AuthService) {}

  /**
   *
   */
  getUserChatRooms(): Observable<UserChatRoom[]> {
    const curUid = this.auth.getCurrentUserId()
    const path = `${this.busPath}/Users/${curUid}/ChatRooms`
    return this.afs.collection<UserChatRoom>(path).valueChanges()
  }

  getChatRoomById(id): Observable<ChatRoom> {
    const path = `${this.busPath}/ChatRooms/${id}`
    return this.afs.doc<ChatRoom>(path).valueChanges()
  }

  createNewChatRoom(chatRoom: ChatRoom): Promise<ChatRoom> {
    return new Promise((res, rej) => {
      const path = `${this.busPath}/ChatRooms/`
      const id = this.afs.createId()
      chatRoom.id = id
      const cRoomRef = this.afs.collection(path).doc(id)
      const batch = this.afs.firestore.batch()
      batch.set(cRoomRef.ref, chatRoom)
      chatRoom.users.map(u => {
        const roomDr: UserChatRoom = {
          id: chatRoom.id,
          rid: chatRoom.rid,
          unread: true,
          timestamp: firebase.firestore.Timestamp.now(),
        }
        roomDr.unread = this.auth.getCurrentUserId() === u ? false : true
        const drPath = `${this.busPath}/Users/${u}/ChatRooms`
        const drRef = this.afs.collection(drPath).doc(chatRoom.id)
        batch.set(drRef.ref, roomDr)
      })
      batch.commit().then(
        () => {
          res(chatRoom)
        },
        err => {
          rej(err)
        }
      )
    })
  }

  setReadedRoom(curUid, chatRoom: ChatRoom) {
    const roomDr: UserChatRoom = {
      id: chatRoom.id,
      rid: chatRoom.rid,
      unread: false,
      timestamp: firebase.firestore.Timestamp.now(),
    }
    const path = `${this.busPath}/Users/${curUid}/ChatRooms`
    return this.setUserData(path, chatRoom.id, roomDr)
  }

  /**
   * Update thông tin Chatrooom khi add new Messate
   * @param chatRoom ChatRoom
   */
  updateChatRoomAfterAddMessage(chatRoom: ChatRoom) {
    chatRoom.updated = firebase.firestore.Timestamp.now()
    const batch = this.afs.firestore.batch()
    const pathChatRoom = `${this.busPath}/ChatRooms/${chatRoom.id}`
    const roomRef = this.afs.doc(pathChatRoom)
    batch.set(roomRef.ref, chatRoom)
    // chatRoom.users = chatRoom.users.filter(
    //   u => u !== this.auth.getCurrentUserId()
    // );
    chatRoom.users.map(u => {
      const roomDr: UserChatRoom = {
        id: chatRoom.id,
        rid: chatRoom.rid,
        unread: true,
        timestamp: firebase.firestore.Timestamp.now(),
      }
      roomDr.unread = this.auth.getCurrentUserId() === u ? false : true
      const drPath = `${this.busPath}/Users/${u}/ChatRooms`
      const drRef = this.afs.collection(drPath).doc(chatRoom.id)
      batch.set(drRef.ref, roomDr)
    })
    return batch.commit()
  }

  updateChatRoomData(chatroom: ChatRoom) {
    const path = `${this.busPath}/ChatRooms/${chatroom.id}`
    return this.afs.doc(path).update(chatroom)
  }

  deleteUserChatRoom(curUid, rId) {
    const path = `${this.busPath}/Users/${curUid}/ChatRooms/`
    return this.afs
      .collection(path)
      .doc(rId)
      .delete()
  }

  deleteChatRoom(chatroom: ChatRoom): Promise<any> {
    const batch = this.afs.firestore.batch()
    chatroom.users.map(u => {
      const userPath = `${this.busPath}/Users/${u}/ChatRooms/`
      const userRef = this.afs.collection(userPath).doc(chatroom.id).ref
      batch.delete(userRef)
    })
    // let roomPath = `${this.busPath}/ChatRooms/`;
    // let roomRef = this.afs.collection(roomPath).doc(chatroom.id).ref;
    // batch.delete(roomRef);
    return batch.commit()
  }

  setUserData(path, id, data): Promise<any> {
    return this.afs
      .collection(path)
      .doc(id)
      .set(data)
  }

  isChatRoomRIdExist(rid): Observable<ChatRoom[]> {
    const path = `${this.busPath}/ChatRooms/`
    const cols = this.afs.collection(path, ref => ref.where('rid', '==', rid))
    const items = cols.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as ChatRoom
          // const id = a.payload.doc.id;
          return data
        })
      )
    )
    return items
  }

  isChatRoomExist(rid) {
    const path = `${this.busPath}/ChatRooms/`
    return this.afs
      .collection<ChatRoom>(path, ref => ref.where('rid', '==', rid).limit(1))
      .valueChanges()
      .pipe(take(1))
      .toPromise()
  }
}
