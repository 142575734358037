import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { AgentService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Agent } from '../../models'

const log = new Logger('AgentDetailResolver')
@Injectable({ providedIn: 'root' })
export class AgentDetailResolver implements Resolve<Observable<Agent>> {
  constructor(private resService: AgentService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['aid']
    
    return this.resService.getAgentDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/admin/agent']))
    )
  }
}
