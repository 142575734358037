import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Paybill } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'

const log = new Logger('PaybillService')

/**
 * Đây là services dành cho PaybillService
 */
@Injectable({ providedIn: 'root' })
export class PaybillService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    list: 'paybill/paybill_entity',
    detail: 'paybill/paybill_entity',
    purchaseList: 'paybill/paybill_purchase',
    purcharseAll: 'paybill/paybill_purchase_all',
  }

  /**
   * Lấy tất cả Paybill Purchase theo Title
   */
  getPurchaseListByTitle(title?: string): Observable<Paybill[]> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('title', title)

    return this.apiService.get<Paybill[]>(this._apiPath.purchaseList, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   *
   * @param params
   */

  getAllPurchase(params?: any): Observable<Paybill[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<Paybill[]>(this._apiPath.purcharseAll, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy tất cả Paybill
   */
  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[paybill_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Paybill dưạ vào @id
   * @param Id ID của Paybill
   */
  getDetail(id: string): Observable<Paybill> {
    return this.apiService.get<Paybill>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  /**
   * Tạo Paybill
   * @param res Thông tin Paybill
   */
  create(res: Paybill): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Paybill
   *
   * @param res Thông tin Paybill muốn chỉnh sửa
   */
  edit(res: Paybill): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.pid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Paybill
   *
   * @param id Xóa Paybill bằng Paybill ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }
}
