import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
@Pipe({
  name: 'hmpDate',
})
export class HMPDatePipe implements PipeTransform {
  transform(value: number, dateFormat: string, utcDisplay?: boolean): any {
    if (utcDisplay) return moment.utc(moment.unix(value)).format(dateFormat)
    return moment.utc(moment.unix(value)).format(dateFormat)
  }
}
