import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { AuthService, NoticeService } from '../../services'
import { Notice } from '@hmp/models/notice.model'

const log = new Logger('NoticeDetailResolver')
@Injectable({ providedIn: 'root' })
export class NoticeDetailResolver implements Resolve<Observable<Notice>> {
  constructor(
    private resService: NoticeService,
    private router: Router,
    private auth: AuthService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['nid']
    
    return this.resService.getNoticeDetail(rid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/notice']))
    )
  }
}
