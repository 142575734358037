import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { AuthService } from '../../../services/general/auth.service'
import {
  BudgetBuilder,
  BudgetBuilderVersion,
  BudgetComponent,
} from '@hmp/models'
const log = new Logger('BudgetService')

@Injectable({ providedIn: 'root' })
export class BudgetService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private auth: AuthService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    add_budget_input: 'v1/budget/budget-input',
    list_version: 'v1/version/version-by-type',
    detail: 'v1/budget/budget-version',
    rolling_forecast_detail: 'v1/forecast/rolling-forecast-version',
    list_components: 'v1/budget/budget-version-components',
    components_detail: 'v1/forecast/forecast-version',
    add_budget_forecast: 'v1/forecast/forecast-input',
    cashflow_detail: 'v1/cash-flow/cash-flow-version',
    add_cashflow: 'v1/cash-flow/cash-flow-input',
    export_all: 'v1/version/export-all-data',
  }

  getBudgetDetail(id: string): Observable<any> {
    return this.apiService.getBudget<any>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getVersionBudget(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('type', 'BUDGET')
      .set('businessId', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.getBudget(this._apiPath.list_version, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getVersionBudgetRolling(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('type', 'FORECAST')
      .set('businessId', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.getBudget(this._apiPath.list_version, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getVersionBudgetCashFLow(params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('type', 'CASH_FLOW')
      .set('businessId', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.getBudget(this._apiPath.list_version, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getRollingForecastDetail(id: string): Observable<any> {
    return this.apiService
      .getBudget<any>(`${this._apiPath.rolling_forecast_detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getListComponent(params?: any): Observable<BudgetComponent> {
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<BudgetComponent>(this._apiPath.list_components, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getListVersionComponent(
    id: string,
    params?: any
  ): Observable<[BudgetBuilderVersion]> {
    let pa = new HttpParams()
      .set('type', 'FORECAST')
      .set('componentId', id)
      .set('businessId', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .getBudget<[BudgetBuilderVersion]>(this._apiPath.list_version, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getComponentDetail(id: string): Observable<any> {
    return this.apiService
      .getBudget<any>(`${this._apiPath.components_detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getCashFlowDetail(id: string): Observable<any> {
    return this.apiService
      .getBudget<any>(`${this._apiPath.cashflow_detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res: any, params?: any): Observable<any> {
    // res.id = null
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('enteredBy', this.auth.getCurrentUserName())
      .set('uid', this.auth.getCurrentUserId())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .postBudget(`${this._apiPath.add_budget_input}${pa}`, res)
      .pipe(
        map(data => {
          log.debug(data)
        })
      )
  }

  createForeCast(id: string, res: any, params?: any): Observable<any> {
    let pa = new HttpParams().set(
      '?businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .postBudget(`${this._apiPath.add_budget_forecast}/${id}${pa}`, res)
      .pipe(
        map(data => {
          log.debug(data)
        })
      )
  }

  createCashFlow(res: any, params?: any): Observable<any> {
    let pa = new HttpParams().set(
      '?businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .postBudget(`${this._apiPath.add_cashflow}${pa}`, res)
      .pipe(
        map(data => {
          log.debug(data)
        })
      )
  }

  getAllDataExportBudgetBuilder(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'businessId',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.getBudget(this._apiPath.export_all, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
