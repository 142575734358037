import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { GuestFolio } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('GuestFolioService')

/**
 * Đây là services dành cho GuestFolio
 */

@Injectable({ providedIn: 'root' })
export class GuestFolioService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) { }
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'guestfolio/guestfolio_entity',
    detail: 'guestfolio/guestfolio_entity',
    list_payment_room: 'payment/room_guest_folio',
    list_open_tab: 'activities/open_tabs',
    create_order_open_tabs: 'activities/open_tabs/create_order_open_tabs',
    add_item_for_order_open_tabs:
      'activities/open_tabs/add_item_for_order_open_tabs',
    open_tabs_payment: 'activities/open_tabs_payment',
  }

  getAll(params?: any): Observable<GuestFolio[]> {
    let pa = new HttpParams().set(
      'parameters[guest_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<GuestFolio[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getPaymentGuestFolio(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_payment_room, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<GuestFolio> {
    return this.apiService
      .get<GuestFolio>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res: GuestFolio): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: GuestFolio): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.pid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteOpenTab(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.list_open_tab}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllOpenTab(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[ot_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_open_tab, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  createOrderOpenTab(): Observable<any> {
    let bid = { business_id: this.storage.getActiveLocalBusinessID() }
    return this.apiService.post(this._apiPath.create_order_open_tabs, bid).pipe(
      map(data => {
        return data
      })
    )
  }

  addItemForOderOpenTab(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.add_item_for_order_open_tabs, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createOpenTab(oid, res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.list_open_tab}/${oid}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  paymentOpenTab(res): Observable<any> {
    return this.apiService.post(this._apiPath.open_tabs_payment, res).pipe(
      map(data => {
        return data
      })
    )
  }

  getOpenTabById(oid): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.list_open_tab}/${oid}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
