import { Injectable } from '@angular/core'
import * as crypto from 'crypto-js'
@Injectable({ providedIn: 'root' })
export class EncryptDescryptService {
  private readonly _codeKey =
    'LttafZoZPrpwcLtKmnEEUYNBKdHbCwwMMi9aMLrppPbqbBQttHJxqeTGXTYVtMxo'

  constructor() {}

  encrypt(data: any, isJson: boolean = false): string {
    if (isJson) {
      return crypto.AES.encrypt(JSON.stringify(data), this._codeKey)
    }
    return crypto.AES.encrypt(data, this._codeKey)
  }

  descrypt(data: string, isJson: boolean = false) {
    const bytes = crypto.AES.decrypt(data, this._codeKey)
    if (isJson) {
      try {
        return JSON.parse(bytes.toString(crypto.enc.Utf8))
      } catch (e) {
        return null
      }
    }
    return bytes.toString(crypto.enc.Utf8)
  }
}
