import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Reviews } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
const log = new Logger('ReviewsService')
@Injectable({ providedIn: 'root' })
export class ReviewsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    list: 'review/review_entity',
    detail: 'review/review_entity',
    add_for_guest: 'review/review_entity/add_review_for_guest',
  }

  getAll(entity_type, entity_id, params?: any): Observable<Reviews[]> {
    let pa = new HttpParams().set(
      'parameters[review_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (entity_type == 'list_for_guest') {
      params = { 'parameters[review_author_id][entity_id]': entity_id }
    } else {
      params = {
        'parameters[review_content][entity_type]': entity_type,
        'parameters[review_content][entity_id]': entity_id,
      }
    }
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<Reviews[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDetail(id: string): Observable<Reviews> {
    return this.apiService.get<Reviews>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  getReviewsStatistics(entity_type, entity_id, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('entity_type', entity_type)
      .set('entity_id', entity_id)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(`${this._apiPath.detail}`, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res: Reviews): Observable<any> {
    res.rid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createReviewForGuest(res: Reviews): Observable<any> {
    res.rid = null
    return this.apiService.post(this._apiPath.add_for_guest, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: Reviews): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.rid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }


  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
