import { Injectable } from '@angular/core'
import {
  DashboardFD,
  DashboardBOB,
  DashboardAnalytics,
  DashboardAnalyticsRoomRate,
} from '../../../models'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams, HttpClient } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
const log = new Logger('DashboardService')
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private _http: HttpClient
  ) {}

  private _apiPath = {
    fd: 'business/dashboard_fd_tab',
    bob: 'business/dashboard_bob_tab',
    bob_pickup: 'business/dashboard_bob_tab_pickup',
    analytics: 'business/dashboard_analytics_tab',
    analytics_room_rate: 'room/report_average_room_rate',
    booking_ticker: 'business/dashboard_booking_ticker_tab',
  }

  getFD(date, params?: any): Observable<DashboardFD> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('date', date)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<DashboardFD>(this._apiPath.fd, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getBob(params?: any): Observable<DashboardBOB> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<DashboardBOB>(this._apiPath.bob, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getBobPickup(
    type = 'month' || 'week',
    startDate,
    endDate,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('type', type)
      .set('start_date', startDate)
      .set('end_date', endDate)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.bob_pickup, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getBookingTicker(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any[]>(this._apiPath.booking_ticker, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAnalytics(params?: any): Observable<DashboardAnalytics> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<DashboardAnalytics>(this._apiPath.analytics, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAnalyticsRoomRate(
    year,
    params?: any
  ): Observable<DashboardAnalyticsRoomRate> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('year', year)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<DashboardAnalyticsRoomRate>(this._apiPath.analytics_room_rate, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getweatherData(city: string, API_KEY: string): Observable<any> {
    return this._http
      .get(
        'https://api.openweathermap.org/data/2.5/weather?q=' +
          city +
          '&APPID=' +
          API_KEY +
          '&units=metric'
      )
      .pipe(
        map(data => {
          // log.debug(data.json());
          return data
        })
      )
  }

  getWeatherFiveDays(
    lat: string,
    lng: string,
    API_KEY: string
  ): Observable<any> {
    return this._http
      .get(
        'https://api.openweathermap.org/data/2.5/forecast?lat=' +
          lat +
          '&lon=' +
          lng +
          '&APPID=' +
          API_KEY +
          '&units=metric'
      )
      .pipe(
        map(data => {
          // log.debug(data.json());
          return data
        })
      )
  }
}
