import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { InvoiceAgent } from '../../models'
import { ReportsService } from '@hmp/services'

const log = new Logger('InvoiceAgentDetailResolver')
@Injectable({ providedIn: 'root' })
export class InvoiceAgentDetailResolver
  implements Resolve<Observable<InvoiceAgent>> {
  constructor(private report: ReportsService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let id = route.params['id']
    
    return this.report.getDetail(id).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/arr-register']))
    )
  }
}
