import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('DailyRevenueExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class DailyRevenueExportService {
  constructor(private auth: AuthService) {}

  onGenerateReportExcel(businessName, data: any, date, dateType, filename) {
    const headerRow1Columns = [
      '',
      'Revenue',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      `${businessName}`,
    ]

    const headerRow2Columns = [
      '',
      'Revenue Type',
      'Order No.',
      'Res / Folio No.',
      'Acct. Code',
      'Patron Type',
      'Staff Name',
      'Sub Total',
      'S/C',
      'Tax',
      'Discount',
      'Adjustment',
      'Total ',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    let sheetName
    if (dateType == 'day') {
      sheetName = `${moment.unix(date).format('DD-MMM-YY')}`
    } else {
      sheetName = `${moment.unix(date).format('MMMM-YYYY')}`
    }
    let worksheet = workbook.addWorksheet(sheetName, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: true,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 15 },
      { key: 'C2', width: 15 },
      { key: 'C3', width: 15 },
      { key: 'C4', width: 15 },
      { key: 'C5', width: 15 },
      { key: 'C6', width: 15 },
      { key: 'C7', width: 15 },
      { key: 'C8', width: 15 },
      { key: 'C9', width: 15 },
      { key: 'C10', width: 15 },
      { key: 'C11', width: 15 },
      { key: 'C12', width: 15 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText
    if (dateType == 'day') {
      titleText = `Revenue: ${moment.unix(date).format('DD-MMM-YY')}`
    } else {
      titleText = `Revenue: ${moment.unix(date).format('MMMM-YYYY')}`
    }
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:M4')

    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 18,
      bold: true,
    }
    // titleCell.border = {
    //   bottom: { style: 'medium' },
    // }
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }

    titleRow.height = 20
    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    worksheet.mergeCells('J6:M6')
    worksheet.mergeCells('B6:E6')
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        // cell.border = {
        //   top: { style: 'thin' },
        //   bottom: { style: 'medium' },
        // }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow1.getCell(7).alignment = { horizontal: 'right' }
    headerRow1.getCell(6).alignment = { horizontal: 'right' }
    headerRow1.getCell(8).alignment = { horizontal: 'right' }
    headerRow1.getCell(13).alignment = { horizontal: 'right' }

    headerRow1.height = 20

    let headerRow2 = worksheet.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow2.getCell(8).alignment = { horizontal: 'right' }
    headerRow2.getCell(9).alignment = { horizontal: 'right' }
    headerRow2.getCell(10).alignment = { horizontal: 'right' }
    headerRow2.getCell(11).alignment = { horizontal: 'right' }
    headerRow2.getCell(12).alignment = { horizontal: 'right' }
    headerRow2.getCell(13).alignment = { horizontal: 'right' }
    headerRow2.height = 20
    for (const k in data) {
      if (data.hasOwnProperty(k)) {
        data[k] = data[k]
        const res = data[k]
        log.debug('data', res)
        const headerRevenueTypeColumns = [
          '',
          `${res.title}`,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ]
        let headerRevenueType = worksheet.addRow(headerRevenueTypeColumns)
        headerRevenueType.font = {
          name: 'Calibri',
          size: 10,
          bold: true,
        }
        if (res && res.data) {
          for (const key in res.data) {
            const item = res.data[key]
            let dataRowColumn = this.generateReportColumnArr(item)
            let dataRow = worksheet.addRow(dataRowColumn)
            dataRow.font = {
              name: 'Calibri',
              size: 10,
              bold: false,
            }
            dataRow.height = 17
            dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
            dataRow.getCell(2).alignment = { horizontal: 'left' }
            dataRow.getCell(3).alignment = { horizontal: 'left' }
            dataRow.getCell(4).alignment = { horizontal: 'left' }
            dataRow.getCell(5).alignment = { horizontal: 'left' }
            dataRow.getCell(6).alignment = { horizontal: 'left' }
            dataRow.getCell(7).alignment = { horizontal: 'left' }
            dataRow.getCell(8).alignment = { horizontal: 'right' }
            dataRow.getCell(9).alignment = { horizontal: 'right' }
            dataRow.getCell(10).alignment = { horizontal: 'right' }
            dataRow.getCell(11).alignment = { horizontal: 'right' }
            dataRow.getCell(12).alignment = { horizontal: 'right' }
            dataRow.getCell(13).alignment = { horizontal: 'right' }
          }
          let totalRow = worksheet.addRow([
            '',
            'Total',
            '',
            '',
            '',
            '',
            '',
            `${res.total.subtotal}`,
            `${res.total.service_charge}`,
            `${res.total.tax}`,
            `${res.total.discount}`,
            `${res.total.adjustment}`,
            `${res.total.total}`,
          ])
          totalRow.font = {
            name: 'Calibri',
            size: 10,
            bold: true,
          }
          totalRow.eachCell((cell, number) => {
            if (number > 1) {
              cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
              }
            }
          })
          totalRow.getCell(8).alignment = { horizontal: 'right' }
          totalRow.getCell(9).alignment = { horizontal: 'right' }
          totalRow.getCell(10).alignment = { horizontal: 'right' }
          totalRow.getCell(11).alignment = { horizontal: 'right' }
          totalRow.getCell(12).alignment = { horizontal: 'right' }
          totalRow.getCell(13).alignment = { horizontal: 'right' }
          worksheet.addRow([])
        }
      }
    }

    // return

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateReportColumnArr(data) {
    let row = []
    row.push('')
    row.push('')
    row.push(data.order_no)
    row.push(data.reservation_no)
    row.push(data.acc_code)
    row.push(data.patron_type)
    row.push(data.staff_name)
    row.push(data.subtotal)
    row.push(data.service_charge)
    row.push(data.tax)
    row.push(data.discount)
    row.push(data.adjustment)
    row.push(data.total)
    return row
  }
}
