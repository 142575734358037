import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { Notice } from '@hmp/models/notice.model'

const log = new Logger('NoticeService')

@Injectable({ providedIn: 'root' })
export class NoticeService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    list: 'notice/notification_entity',
    list_by_user: 'notice/notification_entity',
    detail: 'notice/notification_entity',
  }

  getAllNotice(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[n_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      // tslint:disable-next-line: typedef
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllNoticeByUser(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[n_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      // tslint:disable-next-line: typedef
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get(this._apiPath.list_by_user, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getNoticeDetail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  create(res: Notice): Observable<any> {
    res.nid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        log.debug(data)
      })
    )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.nid}`, res).pipe(
      map(data => {
        log.debug(data)
      })
    )
  }
}
