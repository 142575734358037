import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('OrderHistoryExportService')

@Injectable({ providedIn: 'root' })
export class OrderHistoryExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  /**
   * Cái tên nói lên tất cả rồi, đọc làm éo gì :/
   */

  onGenerateExcelOrderHistory(title, data: any[], sheetname, filename, logo) {
    const headerRowColumns = [
      '',
      'Order Status',
      'Date',
      'Order Number',
      'Total Price',
      'Payment Status',
      'Payment Method',
      'Patron Type',
      'Billed To',
      'Staff',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: { tabColor: { argb: 'FFFFFF00' } },
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 2 },
      { key: 'C1', width: 20 },
      { key: 'C2', width: 15 },
      { key: 'C3', width: 11 },
      { key: 'C4', width: 20 },
      { key: 'C5', width: 15 },
      { key: 'C6', width: 20 },
      { key: 'C7', width: 11 },
      { key: 'C8', width: 20 },
      { key: 'C9', width: 20 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    let titleRow = worksheet.addRow([' ', titleText])
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleRow.border = {
      bottom: { style: 'medium' },
    }
    worksheet.getCell('A3').border = {}

    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    let headerRow = worksheet.addRow(headerRowColumns)
    headerRow.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center' }
      }
    })

    data.map(r => {
      let dataRowColumn = this.generateOrderHistoryColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.getCell(2).alignment = { horizontal: 'center' }
      dataRow.getCell(3).alignment = { horizontal: 'center' }
      dataRow.getCell(3).numFmt = 'DD-MMM-YY'
      dataRow.getCell(4).alignment = { horizontal: 'left' }
      dataRow.getCell(5).alignment = { horizontal: 'right' }
      dataRow.getCell(5).numFmt = '#,##0.00'
      dataRow.getCell(6).alignment = { horizontal: 'center' }
      dataRow.getCell(7).alignment = { horizontal: 'center' }
    })

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateOrderHistoryColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.status)
    row.push(data.timestamp)
    row.push(data.detail)
    row.push(data.amount)
    row.push(data.payment)
    row.push(data.method)
    row.push(data.patron_type)
    row.push(data.bill_to)
    row.push(data.staff)
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
