import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core'
import { CoreIconsComponent } from './core-icons.component'
@NgModule({
  declarations: [CoreIconsComponent],
  exports: [CoreIconsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CoreIconsModule {}
