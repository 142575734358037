import { environment } from 'environments/environment'

export const CURRENCY_SUPPORT = [
  /*
  - negativeNumber: 
    + 1: -123.456
    + 2: (123.456)
  */
  {
    id: 'usd',
    name: 'USD',
    symbol: '$',
    prefix: true,
    digit: '0.2-2',
    negativeNumber: 1,
  },
  {
    id: 'thb',
    name: 'THB',
    symbol: '฿',
    prefix: true,
    digit: '0.2-2',
    negativeNumber: 1,
  },
  {
    id: 'vnd',
    name: 'VND',
    symbol: '₫',
    prefix: false,
    digit: '0.2-2',
    negativeNumber: 1,
  },
  {
    id: 'php',
    name: 'PHP',
    symbol: '₱',
    prefix: true,
    digit: '0.2-2',
    negativeNumber: 1,
  },
  {
    id: 'npr',
    name: 'NPR',
    symbol: 'रु',
    prefix: true,
    digit: '0.2-2',
    negativeNumber: 1,
  },
  {
    id: 'lak',
    name: 'LAK',
    symbol: '₭',
    prefix: true,
    digit: '0.2-2',
    negativeNumber: 1,
  },
]
export class HMPGlobal {
  static getCurrency(id: string) {
    // console.log("CURRENCY_SUPPORT: ", CURRENCY_SUPPORT);
    return CURRENCY_SUPPORT.find(f => f.id.toLowerCase() === id.toLowerCase())
  }

  static getCurrencyDisplay(id = 'usd', displayby: 'symbol' | 'name') {
    let curr = this.getCurrency(id)
    if (!curr) {
      curr = CURRENCY_SUPPORT[0]
    }
    switch (displayby) {
      case 'name':
        return curr.name
      case 'symbol':
        return curr.symbol
      default:
        return curr.name
    }
  }

  static isCurrencyPrefix(id) {
    const curr = this.getCurrency(id)
    if (curr) {
      return curr.prefix
    }
    return
  }

  static getCurrentHMPVersion() {
    return environment.version
  }
}
