import { element } from 'protractor';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Platform } from '@angular/cdk/platform'
import { TranslateService } from '@ngx-translate/core'
import {
  Subject,
  Observable,
  fromEvent,
  merge,
  timer,
  combineLatest,
} from 'rxjs'
import { takeUntil, debounce, map } from 'rxjs/operators'

import { FuseConfigService } from '@fuse/services/config.service'
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service'
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service'

import {
  AuthService,
  Logger,
  UserService,
  BusinessService,
  PermissionsService,
  CoreIconsRegistry,
  RoleActionsData,
  RestaurantService,
  FrontDeskService,
  PaymentGatewaysService,
  LocalStorageService,
} from '@hmp'
import {
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  Router,
} from '@angular/router'
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service'
import { environment } from 'environments/environment'
import { WebNotificationsService } from './helper/services/web-notifications.service'
import { MatDialog } from '@angular/material/dialog'
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service'
import { AngularFireMessaging } from '@angular/fire/messaging'
import * as _ from 'lodash'
import {
  navigationClient,
  navigationOps,
  navigationSalesMarketing,
  navigationSettings,
  navigationTravelX,
  navigationCityLedger,
  navigationManager,
  navigationFinance,
  navigationFrontdesk,
  navigationPosCenter,
  navigationOTA,
  navigationDirectBookingSetting,
  navigation,
  navigationWeb,
  navigationBudgetBuilder,
} from './navigation/navigation'
import { QuickLaunchNavigationComponent } from './others/quick-launch-navigation/quick-launch-navigation.component'
import { HmpIcons } from '@hmp/config/hmp-icons'
import { FuseNavigation, FuseNavigationItem } from '@fuse/types'
import { D, I } from '@angular/cdk/keycodes'
const log = new Logger('AppComponent')

declare global {
  interface Window {
    userpilot: any
    analytics: any
  }
}
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<any>

  fuseConfig: any
  currentUser: any
  currentUid: any
  dataRestaurant: any
  navResPosRoles: FuseNavigation[]
  navFDPosRoles: FuseNavigation[]
  isShowQuickSearch = false
  dataResFilter
  dataFDFiter
  dataBusiness
  currentBid
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private auth: AuthService,
    private router: Router,
    private _fuseProgressBarService: FuseProgressBarService,
    private userService: UserService,
    private busService: BusinessService,
    private webNotify: WebNotificationsService,
    private angularFireMessaging: AngularFireMessaging,
    private paymentGateWays: PaymentGatewaysService,
    public dialog: MatDialog,
    private permissionServices: PermissionsService,
    private coreIconsRegistry: CoreIconsRegistry,
    private restaurantService: RestaurantService,
    private frontDeskService: FrontDeskService,
    private _cdr: ChangeDetectorRef,
    private storage: LocalStorageService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {
    // Add languages
    this._translateService.addLangs(['en'])
    // Set the default language
    this._translateService.setDefaultLang('en')
    // Use a language
    this._translateService.use('en')

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile')
    }

    // Set the private defaults
    this.destroy$ = new Subject()

    router.events.subscribe(event => {
      // log.debug('ROUTE EVENTS CHANGES: ', event)
      if (event instanceof NavigationEnd) {
        try {
          window.userpilot.reload()
        } catch (error) {}
      }
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.setDebug()
    this.auth.popUser()
    this.popUser()
    if(this.auth.getActiveLocalBusinessID()){
     this.getBusiness(this.auth.getActiveLocalBusinessID())
    }
    let numberLoad = 0
    this.auth.currentUser.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (!user || !user.uid) {
        return
      }
      this.currentUser = user
      this.coreIconsRegistry.registerIcons(HmpIcons)
      if (this.currentUser && !this.currentUser.is_manager) {
        const curBid = this.auth.getActiveBusiness()
        if (!curBid || !curBid.bid) {
          if(numberLoad == 1) {
          this.busService
          .getBusinessDetails(this.currentUser.business_default)
          .pipe(takeUntil(this.destroy$))
          .subscribe(d => {
            this.dataBusiness = d
            log.debug('BUSINESS',this.dataBusiness)
            this.storage.saveItem('currency', this.dataBusiness.business_currency)
          })
        }
        }
      } else {
        const curBid = this.auth.getActiveLocalBusiness()
        if (curBid && curBid.bid) {
          if(numberLoad == 1) {
            this.busService
              .getBusinessDetails(curBid.bid)
              .pipe(takeUntil(this.destroy$))
              .subscribe(d => {
                this.dataBusiness = d
                log.debug('Bus',this.dataBusiness)
              })
          }
        }
      }
      if (environment.production) {
        setTimeout(() => {
          this.onUserPilot(user)
        }, 3000)
      }
    })
    
    

  
    // log.debug('U.Profile',this.currentUser)
    // this.coreIconsRegistry.registerIcons(HmpIcons)
    // if (this.currentUser && !this.currentUser.is_manager) {
    //   const curBid = this.auth.getActiveBusiness()
    //   if (!curBid || !curBid.bid) {
    //     this.busService
    //       .getBusinessDetail(this.currentUser.business_default)
    //       .pipe(takeUntil(this.destroy$))
    //       .subscribe(d => {
    //         this.dataBusiness = d
    //         log.debug('BUSINESS',this.dataBusiness)
    //       })
    //     }
    //   } else {
    //     const curBid = this.auth.getActiveLocalBusiness()
    //     if (curBid && curBid.bid) {
    //       this.busService
    //         .getBusinessDetail(curBid.bid)
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe(d => {
    //           this.dataBusiness = d
    //         })
    //     }
    //   }
    // combineLatest([
    //   this.auth.activeBusiness,
    //   this.userService.isProfileChange,
    // ]).subscribe(([v, a]) => {})

    // this.auth.activeBusiness

    // this.userService.isProfileChange.subscribe(yes => {
    //   log.debug('isProfileChange', yes)
    //   if (yes) {
    //     const curBid = this.auth.getActiveLocalBusiness()
    //     log.debug("curBidddddd", curBid)
    //     if(curBid && curBid.bid){
    //       this.busService
    //         .getBusinessDetail(curBid.bid)
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe(d=>{
    //           this.dataBusiness = d
    //     })
    //     }
    //   }
    // })
    
    this.busService.isBusinessChange.subscribe(yes => {
      numberLoad++
      if (yes && numberLoad === 2) {
        const curBid = this.auth.getActiveBusiness()
        if (curBid) {
          this.dataBusiness = curBid
        }
        this.setNavPOS()
        this.setAppNavigation()
      }
    })

    this.restaurantService.isRestaurantChange.subscribe(yes => {
      // log.debug('isRESTAURANTCHANGE', yes)
      if (yes) {
        this.navResPosRoles = this.onGetNavigationByRoles(navigationPosCenter)
        for (var i = 0; i < this.navResPosRoles[0].children.length; i++) {
          if (this.navResPosRoles[0].children[i].id === 'custom_restaurant') {
            this.navResPosRoles[0].children.splice(i, 1)
            i--
          }
        }
        this.navFDPosRoles = this.onGetNavigationByRoles(navigationPosCenter)
        for (var i = 0; i < this.navFDPosRoles[0].children.length; i++) {
          if (this.navFDPosRoles[0].children[i].id === 'custom_fd') {
            this.navFDPosRoles[0].children.splice(i, 1)
            i--
          }
        }
        this.setNavPOS()
        this.setAppNavigation()
      }
    })

    this.frontDeskService.isfrontDeskChange.subscribe(yes => {
      // log.debug('isFRONTDESKCHANGE', yes)
      if (yes) {
        this.navResPosRoles = this.onGetNavigationByRoles(navigationPosCenter)
        for (var i = 0; i < this.navResPosRoles[0].children.length; i++) {
          if (this.navResPosRoles[0].children[i].id === 'custom_restaurant') {
            this.navResPosRoles[0].children.splice(i, 1)
            i--
          }
        }
        this.navFDPosRoles = this.onGetNavigationByRoles(navigationPosCenter)
        for (var i = 0; i < this.navFDPosRoles[0].children.length; i++) {
          if (this.navFDPosRoles[0].children[i].id === 'custom_fd') {
            this.navFDPosRoles[0].children.splice(i, 1)
            i--
          }
        }
        this.setNavPOS()
        this.setAppNavigation()
      }
    })

    // this.onNavigationDisplay()
    this._fuseConfigService.config
      .pipe(takeUntil(this.destroy$))
      .subscribe(config => {
        this.fuseConfig = config
        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed')
        } else {
          this.document.body.classList.remove('boxed')
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i]

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className)
          }
        }
        this.document.body.classList.add(this.fuseConfig.colorTheme)
      })
  }

  ngAfterViewInit(): void {
    this.setLoadingEnd()
    this.webNotify.isSubscribe$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isYes => {
        // log.debug('isSubscribe$: ', isYes)
        if (isYes) {
          // this.onNotificationsRequestPermission();
          this.webNotify
            .onReceiveMessages()
            .pipe(takeUntil(this.destroy$))
            .subscribe(msg => {
              log.debug('NEW MESSAGE: ', msg)
            })
        }
      })
    this.onNotifications()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  getBusiness(bid) {
    this.busService
      .getBusinessDetails(bid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(d => {
        this.dataBusiness = d
      })
  }

  // getPaymentGateways() {
  //   this.paymentGateWays
  //     .getAll()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(
  //       data => {
  //         // log.debug('Payment Gateways', data)
  //       },
  //       error => {
  //         log.debug('error', error)
  //       }
  //     )
  // }

  getUserRoleGroup(roleId) {
    switch (roleId) {
      case 1:
      case 2:
      case 3:
      case 11:
      case 13:
      case 18:
        return 'Hotel Management'
      case 17:
        return 'Front Desk & Office'
      case 10:
        return 'HR'
      case 12:
        return 'Sales & Marketing'
      case 4:
      case 20:
        return 'F&B Management'
      default:
        return 'Operations'
    }
  }

  onUserPilot(user) {
    if (window.userpilot) {
      try {
        const uid = user.uid
        const bid = this.auth.getActiveLocalBusinessID()
        const busDev = [39, 40, 41, 42, 44, 46, 47]
        log.debug(`UID ${uid} BID ${bid} BUSDEV ${busDev}`)
        if (
          !bid ||
          bid == '' ||
          !uid ||
          uid == '' ||
          busDev.some(e => e == bid)
        ) {
          log.debug('Not accept userpilot')
          return
        }
        var busName
        const isAdmin = user.admin_business
        const uName = `${user.first_name} ${user.last_name}`
        var userRole
        const busRoles = user.roles_business as any[]
        if (busRoles.length > 0) {
          const busRole = busRoles.find(e => e.business_id == bid)
          if (busRole) {
            userRole = busRole.business_role
            busName = busRole.business_name
          }
        }
        if (isAdmin) {
          userRole = 1
          const busOwners = user && (user && user.business_owners as any[])
          const busData = busOwners.find(e => e.entity_id == bid)
          if (busData) {
            busName = busData.title
          }
        }
        const roleType = this.getUserRoleGroup(Number(userRole))

        window.userpilot.identify(
          `${uid}_${bid}`, // Used to identify users
          {
            name: uName, // Full name
            email: user.mail, // Email address
            created_at: user.created, // Signup date as a Unix timestamp
            // optional
            company: {
              id: `${bid}`, // Company Unique ID
              name: busName,
            },
            user_type: roleType,
          }
        )
      } catch (error) {
        log.error('Userpilot err', error)
      }
    }
  }

  onSegment(user) {
    if (window.analytics) {
      try {
        const uid = `${this.auth.getActiveLocalBusinessID()}_${user.uid}`
        const uName = `${user.first_name} ${user.last_name}`
        window.analytics.identify(
          uid, // Used to identify users
          {
            name: uName, // Full name
            email: user.mail, // Email address
            // optional
            company: {
              id: this.auth.getActiveLocalBusinessID, // Company Unique ID
              // created_at: '1519205055', // Signup date as a Unix timestamp
            },
            // Additional user properties
            // plan: "free",
            // trialEnds: '2019-10-31T09:29:33.401Z'"
          }
        )
      } catch (error) {
        log.error('Userpilot err', error)
      }
    }
  }

  setLoadingEnd(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._fuseProgressBarService.show()
        this._fuseProgressBarService.setMode('indeterminate')
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this._fuseProgressBarService.hide()
      }
    })
  }

  onNotifications() {
    if (!this.currentUser || !this.currentUser.uid) {
      return
    }
    this.angularFireMessaging.messaging
      .pipe(takeUntil(this.destroy$))
      .subscribe(_messaging => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging)
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging)
        // log.debug('MESSAGING: ', _messaging);
      })
    this.webNotify
      .onTokenChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(token => {
        // log.debug('onTokenChanges: ', token)
        if (this.webNotify.isTopicAllow) {
          this.webNotify
            .onSubscribeTopic(token)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              log.debug('onSubscribeTopic: ', data)
            })
        }
      })
  }

  onRequestPermissionNotifications() {
    if (!this.currentUser || !this.currentUser.uid) {
      return
    }
    this.webNotify
      .onRequestPermission()
      .pipe(takeUntil(this.destroy$))
      .subscribe(token => {
        log.debug('TOKEN: ', token)
        this.webNotify
          .onSubscribeTopic(token)
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            log.debug('onSubscribeTopic: ', data)
          })
      })
  }

  onRequestNotifications() {
    if (this.webNotify.isTopicAllow) {
      this.onRequestPermissionNotifications()
    }
    // else {
    //   setTimeout(() => {
    //     const dialogRef = this.dialog.open(ConfirmActionComponent, {
    //       data: {
    //         headerTitle: "",
    //         contentData: `We'd like to send you notifications.`,
    //         yesButtonTitle: "ALLOW",
    //         noButtonTitle: "No Thanks"
    //       }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result == "yes") {
    //         this.onRequestPermissionNotifications();
    //       }
    //     });
    //   }, 5000);
    // }
  }

  onKeyPress($event: KeyboardEvent): void {
    // tslint:disable: deprecation
    // tslint:disable: triple-equals
    if ($event.shiftKey && $event.keyCode == 32) {
    // if ($event.shiftKey && $event.keyCode == 18) {
      this.showQuickSearch()
    }
  }

  private bindKeypressEvent(): Observable<KeyboardEvent> {
    const eventsType$ = [
      fromEvent(window, 'keypress'),
      fromEvent(window, 'keydown'),
    ]
    // we merge all kind of event as one observable.
    return merge(...eventsType$).pipe(
      // We prevent multiple next by wait 10ms before to next value.
      debounce(() => timer(10)),
      // We map answer to KeyboardEvent, typescript strong typing...
      map(state => state as KeyboardEvent)
    )
  }

  showQuickSearch(): void {
    if (this.isShowQuickSearch) {
      return
    }

    this.isShowQuickSearch = true
    const dialogRef = this.dialog.open(QuickLaunchNavigationComponent, {
      panelClass: 'quick-launch-navigaton-modal',
      data: {
        navigation: navigation,
      },
    })

    dialogRef.afterClosed().subscribe(() => {
      this.isShowQuickSearch = false
    })
  }

  setDebug(): void {
    const gen = this.auth.getBusinessGeneralInfo()
    if (gen && gen.is_debug === true) {
      Logger.enableProductionMode(true)
      this.bindKeypressEvent().subscribe(($event: KeyboardEvent) =>
        this.onKeyPress($event)
      )
    } else {
      if (environment.production) {
        Logger.enableProductionMode()
        if (window) {
          // tslint:disable: typedef
          window.console.log = function() {}
          window.console.warn = function() {}
          window.console.info = function() {}
          window.console.error = function() {}
        }
      }
      // else {
      //   this.bindKeypressEvent().subscribe(($event: KeyboardEvent) =>
      //     this.onKeyPress($event)
      //   )
      // }
    }
  }

  popUser(): void {
    const userToken = this.auth.getJwtToken()
    if (userToken) {
      this.userService
        .userProfile()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.onRequestNotifications()
        })
    } else {
      this.auth.destroyAuth()
    }
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen()
  }

  registerNavigation(key, value) {
    this._fuseNavigationService.unregister(key)
    this._fuseNavigationService.register(key, value)
  }

  getCloneNav(nav) {
    return _.cloneDeep(nav)
  }

  setAppNavigation() {
    if (this.currentUser && this.currentUser.uid) {
      const navClientRoles = this.onGetNavigationByRoles(navigationClient)
      const navFrontRoles = this.onGetNavigationByRoles(navigationFrontdesk)
      const navSaleRoles = this.onGetNavigationByRoles(navigationSalesMarketing)
      const navOpsRoles = this.onGetNavigationByRoles(navigationOps)
      const navFinanceRoles = this.onGetNavigationByRoles(navigationFinance)
      const navCityLedgerRoles = this.onGetNavigationByRoles(
        navigationCityLedger
      )
      const navManagerRoles = this.onGetNavigationByRoles(navigationManager)
      const navSettingsRoles = this.onGetNavigationByRoles(navigationSettings)
      const navBudgetBuilder = this.onGetNavigationByRoles(
        navigationBudgetBuilder
      )
      const navTravelXRoles = this.onGetNavigationByRoles(navigationTravelX)
      const navOTARoles = this.onGetNavigationByRoles(navigationOTA)
      const navWebRoles = this.onGetNavigationByRoles(navigationWeb)
      const navDBRoles = this.onGetNavigationByRoles(
        navigationDirectBookingSetting
      )

      // const navMainRoles = this.onGetNavigationByRoles(navigation)
      this.registerNavigation('client', navClientRoles)
      this.registerNavigation('frontdesk', navFrontRoles)

      this.registerNavigation('salesmarketing', navSaleRoles)
      this.registerNavigation('ops', navOpsRoles)
      this.registerNavigation('finance', navFinanceRoles)
      this.registerNavigation('budgetbuilder', navBudgetBuilder)
      this.registerNavigation('travelx', navTravelXRoles)
      this.registerNavigation('cityledger', navCityLedgerRoles)
      this.registerNavigation('manager', navManagerRoles)
      this.registerNavigation('setting', navSettingsRoles)
      this.registerNavigation('ota_optimizer', navOTARoles)
      this.registerNavigation('website_organizer', navWebRoles)
      this.registerNavigation('direct_booking_setting', navDBRoles)
      // this.registerNavigation('applications', navMainRoles)
      this._fuseNavigationService.setCurrentNavigation('frontdesk')
    }
  }

  setNavPOS() {
    this.onPOSCenter()
    this.onFrontDeskPOSCenter()
    // this.onManagerCustom();
  }

  onGetNavigationByRoles(navs) {
    if (navs) {
      const isAdminBussiness = this.currentUser.admin_business || false
      if (isAdminBussiness) {
        return navs //this.getCloneNav(navs)
      }
      const newNav = this.getCloneNav(navs)
      return this.filterRoles(newNav)
    }
    return null
  }

  filterRoles(navs) {
    const nNav = _.filter(navs, nav => {
      if (nav.children) {
        const newChild = this.onCheckChildrenRoles(nav.children)
        if (nav.children && nav.children.length > 0) {
          nav.children = newChild
        }
      }
      return this.onCheckItemRoles(nav)
    })
    return nNav
  }

  onCheckChildrenRoles(navs) {
    return _.filter(navs, nav => {
      if (nav.children) {
        const newChild = this.onCheckChildrenRoles(nav.children)
        nav.children = newChild
      }
      return this.onCheckItemRoles(nav)
    })
  }

  onCheckItemRoles(nav) {
    if (nav.type == 'group') {
      return true
    }
    if (nav.type == 'collapsable') {
      if (!nav.children || nav.children.length == 0) {
        return false
      }
    }
    let isMatch = false
    if (nav.roles) {
      if (nav.roles.entity && nav.roles.action) {
        if (
          this.permissionServices.hasPermission(
            nav.roles.entity,
            nav.roles.action
          )
        ) {
          isMatch = true
        }
      }
    }
    if (nav.type == 'collapsable' || nav.type == 'group') {
      if (nav.children && nav.children.length > 0) {
        isMatch = true
      }
    }
    return isMatch
  }

  // getRestaurant(){
  //   this.busService.getRestaurantPOS().subscribe(data=>{
  //     this.dataRestaurant = data?.data
  //   })
  // }

  onPOSCenter() {
    this.navResPosRoles = this.onGetNavigationByRoles(navigationPosCenter)
    let restaurantsPOS = []
    let res
    // DEMO RESTARAUTN POS -> Get from API
    // let restaurantsPOS = [
    //   { id: '123', title: 'Restaurant 1' },
    //   { id: '1234', title: 'Restaurant 2' },
    // ]
    let param = {}
    param['p[type]'] = 'restaurant_pos'
    this.busService.getRestaurantPOS(param).subscribe(data => {
      this.dataResFilter = data.data.filter(x => x.status === 1)
      // log.debug('dataResFilter', this.dataResFilter)
      for (let index = 0; index < this.dataResFilter.length; index++) {
        res = {
          id: this.dataResFilter[index]._id,
          title: this.dataResFilter[index].name,
        }
        restaurantsPOS.push(...res)
      }
      // Check have custom restaurant pos?
      // log.debug('dataBusiness_xxxxx', this.dataBusiness)
      if (this.dataBusiness && !this.dataBusiness.restaurant_manager) {
        if (restaurantsPOS && restaurantsPOS.length > 0) {
          // Set default restaurant pos
          const customRestaurants: FuseNavigationItem[] = restaurantsPOS.map(
            r => this.getCustomRestaurantPOS(r)
          )
          if (this.navResPosRoles && this.navResPosRoles.length > 0) {
            this.navResPosRoles[0].children.splice(1, 0, ...customRestaurants)
          }
        }
      }

      this.registerNavigation('poscenter', this.navResPosRoles)
    })
  }

  getCustomRestaurantPOS(restaurant: any): FuseNavigationItem {
    const restaurantID = restaurant['id']
    return {
      id: `custom_restaurant`,
      title: restaurant['title'],
      translate: restaurant['title'],
      type: 'collapsable',
      svg: 'assets/icons/hmp-web/new-icon/White-Restaurant-POS.svg',

      children: [
        {
          id: 'pos',
          title: 'Orders',
          translate: 'Orders',
          type: 'item',
          svg: 'assets/icons/hmp-web/new-icon/Orders.svg',
          url: `/restaurants/${restaurantID}/restaurants-pos`,
          roles: {
            entity: 'pos',
            action: RoleActionsData.View,
          },
          // exactMatch: true,
        },
        {
          id: 'menu',
          title: 'Menu',
          translate: 'Menu',
          type: 'item',
          url: `/restaurants/${restaurantID}/restaurants-food-beverage`,
          roles: {
            entity: 'fb_management',
            action: RoleActionsData.Create,
          },
          svg: 'assets/icons/hmp-web/new-icon/Menu.svg',
          // exactMatch: true,
        },
        {
          id: 'recipemanagaer',
          title: 'Recipes',
          translate: 'Recipes',
          type: 'item',
          url: `/restaurants/${restaurantID}/restaurants-inventory-master`,
          roles: {
            entity: 'inventorymaster_entity',
            action: RoleActionsData.View,
          },
          svg: 'assets/icons/hmp-web/new-icon/Recipes.svg',
          // exactMatch: true,
        },
        {
          id: 'table-manager',
          title: 'Tables',
          translate: 'Tables',
          type: 'item',
          url: `/restaurants/${restaurantID}/restaurants-table-manager`,
          roles: {
            entity: 'table_entity',
            action: RoleActionsData.View,
          },
          svg: 'assets/icons/hmp-web/new-icon/Tables.svg',
        },
        {
          id: 'fb-daily-report',
          title: 'Daily Report',
          translate: 'Daily Report',
          type: 'item',
          url: `/restaurants/${restaurantID}/restaurants-fb-daily-report`,
          roles: {
            entity: 'restaurant_daily_report',
            action: RoleActionsData.View,
          },
          svg: 'assets/icons/hmp-web/new-icon/daily-report.svg',
        },
        // {
        //   id: 'category-food-daily-report',
        //   title: 'Food Daily Report',
        //   translate: 'Food Daily Report',
        //   type: 'item',
        //   url: `/restaurants/${restaurantID}/food-daily-report`,
        //   roles: {
        //     entity: 'table_entity',
        //     action: RoleActionsData.View,
        //   },
        //   svg: 'assets/icons/hmp-web/new-icon/food-daily-report.svg',
        // },
        // {
        //   id: 'category-beverage-daily-report',
        //   title: 'Beverage Daily Report',
        //   translate: 'Beverage Daily Report',
        //   type: 'item',
        //   url: `/restaurants/${restaurantID}/beverage-daily-report`,
        //   roles: {
        //     entity: 'table_entity',
        //     action: RoleActionsData.View,
        //   },
        //   svg: 'assets/icons/hmp-web/new-icon/beverage-daily-report.svg',
        // },
        // {
        //   id: 'printer-settings',
        //   title: 'Pinter Settings',
        //   translate: 'Printer Settings',
        //   type: 'item',
        //   svg: 'assets/icons/hmp-web/new-icon/printer.svg',
        //   url: `/restaurants/${restaurantID}/printer-settings`,
        //   roles: {
        //     // entity: 'email_setting',
        //     action: RoleActionsData.View,
        //   },
        //   exactMatch: true,
        // },
      ],
    }
  }

  onFrontDeskPOSCenter() {
    this.navFDPosRoles = this.navResPosRoles
    // log.debug('navFDPosRoles', this.navFDPosRoles)
    let frontDeskPOS = []
    let res
    let param = {}
    param['p[type]'] = 'front_desk'
    this.busService.getRestaurantPOS(param).subscribe(data => {
      this.dataFDFiter = data.data.filter(x => x.status === 1)
      // log.debug('DATAPOS_FD', this.dataFDFiter)
      for (let index = 0; index < this.dataFDFiter.length; index++) {
        res = {
          id: this.dataFDFiter[index]._id,
          title: this.dataFDFiter[index].name,
        }
        frontDeskPOS.push(...res)
      }
      // Check have custom restaurant pos?
      const navPDPOS = this.navResPosRoles[0].children.filter(
        x => x.id === 'fd_POS'
      )
      const navResPOS = this.navResPosRoles[0].children.filter(
        x => x.id === 'Restaurants_POS'
      )
      if (
        this.dataResFilter &&
        this.dataResFilter.length === 0 &&
        this.dataFDFiter &&
        this.dataFDFiter.length === 0
      ) {
        navPDPOS[0].title = ''
        navPDPOS[0].translate = ''
        navResPOS[0].title = ''
        navResPOS[0].translate = ''
      } else {
        navPDPOS[0].title = 'Front Desk POS'
        navPDPOS[0].translate = 'Front Desk POS'
        navResPOS[0].title = 'Restaurant Pos'
        navResPOS[0].translate = 'Restaurant Pos'
      }
      const length = this.navResPosRoles[0].children.length - 1
      const navManager = this.navResPosRoles[0].children.filter(
        x => x.id === 'manager'
      )
      const navPD = this.navResPosRoles[0].children[length].children.filter(
        x => x.id === 'frontdesk'
      )
      const navRes = this.navResPosRoles[0].children[length].children.filter(
        x => x.id === 'restaurant'
      )

      if (this.dataBusiness && this.dataBusiness.front_desk_manager) {
        if (navPD[0] && navPD[0].title) {
          navPD[0].title = ''
        }
      }

      if (this.dataBusiness && this.dataBusiness.restaurant_manager) {
        if (navRes[0] && navRes[0].title) {
          navRes[0].title = ''
        }
      }

      if (
        this.dataBusiness &&
        this.dataBusiness.front_desk_manager &&
        this.dataBusiness.restaurant_manager
      ) {
        if (navManager[0] && navManager[0].title) {
          navManager[0].title = ''
        }
      }
      if (this.dataBusiness && !this.dataBusiness.front_desk_manager) {
        if (frontDeskPOS && frontDeskPOS.length > 0) {
          // Set default restaurant pos
          const customFronsDesk: FuseNavigationItem[] = frontDeskPOS.map(r =>
            this.getCustomFrontDeskPOS(r)
          )
          if (this.navResPosRoles && this.navResPosRoles.length > 0) {
            this.navResPosRoles[0].children.splice(-1, 0, ...customFronsDesk)
          }
        }
      }
      this.registerNavigation('poscenter', this.navResPosRoles)
      // log.debug('navResPosRoles', this.navResPosRoles)
    })
  }

  getCustomFrontDeskPOS(restaurant: any): FuseNavigationItem {
    const restaurantID = restaurant['id']
    return {
      id: `custom_fd`,
      title: restaurant['title'],
      translate: restaurant['title'],
      type: 'collapsable',
      svg: 'assets/icons/hmp-web/new-icon/White-Front-Desk-POS.svg',
      children: [
        {
          id: 'pos-fd',
          title: 'Orders',
          translate: 'Orders',
          type: 'item',
          svg: 'assets/icons/hmp-web/new-icon/Orders.svg',
          url: `/frontdesk/${restaurantID}/frontdesk-pos`,
          roles: {
            entity: 'pos',
            action: RoleActionsData.View,
          },
        },
        {
          id: 'menu-front-desk',
          title: 'Menu',
          translate: 'Menu',
          type: 'item',
          url: `/frontdesk/${restaurantID}/frontdesk-food-beverage`,
          roles: {
            entity: 'fb_management',
            action: RoleActionsData.Create,
          },
          svg: 'assets/icons/hmp-web/new-icon/Menu.svg',
        },
        {
          id: 'fd-daily-report',
          title: 'Daily Report',
          translate: 'Daily Report',
          type: 'item',
          url: `/frontdesk/${restaurantID}/frontdesk-daily-report`,
          roles: {
            entity: 'table_entity',
            action: RoleActionsData.View,
          },
          svg: 'assets/icons/hmp-web/new-icon/daily-report.svg',
        },
      ],
    }
  }
}
