import { Injectable } from '@angular/core'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
const log = new Logger('ServiceChargeRegisterExportService')
declare const ExcelJS: any
import * as _moment from 'moment'
import { HMPGlobal } from '@hmp/config/HMPGlobal'
import { Logger } from '@hmp/logger.service'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class ServiceChargeRegisterExportService {
  customData
  headerRow2
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateHistoryServiceChargeRegisterReportExcel(
    title,
    dataHistory: any[],
    filename,
    dateLabel,
    businessName,
    type_range,
    status,
    typeItems,
    category,
  ) {
    const headerRow1Columns = [
      '',
      'Status',
      'Type',
      'Date',
      'Time',
      `Amount (${this.getCurrencyInfo().name})`,
      `Total (${this.getCurrencyInfo().name})`,
      'Category',
      'Method',
      'Receipt No.',
      'Txn. ID',
    ]
    const headerRow2Columns = [
      '',
      `${dateLabel}`,
      '',
      '',
      '',
      '',
      '',
      `${businessName}`,
    ]
    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    let serviceCharge: any[] = []
    let gratuity: any[] = []
    
    serviceCharge = dataHistory.filter(e => e.type == 'Service Charge')
    gratuity = dataHistory.filter(e => e.type == 'Gratuity')

    let worksheet = workbook.addWorksheet('Service Charge & Gratuity', {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 35,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 13.83 },
      { key: 'C2', width: 16.83 },
      { key: 'C3', width: 13.83 },
      { key: 'C4', width: 13.83 },
      { key: 'C5', width: 13.63 },
      { key: 'C6', width: 13.63 },
      { key: 'C7', width: 13.83 },
      { key: 'C8', width: 13.83 },
      { key: 'C9', width: 13.83 },
      { key: 'C10', width: 13.83 },
      { key: 'C11', width: 13.83 },
      { key: 'C12', width: 13.83 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])

    let worksheetpm = workbook.addWorksheet('Service Charge Only', {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 35,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })
    // COLUMN WIDTH PAYMENT
    worksheetpm.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 13.83 },
      { key: 'C2', width: 16.83 },
      { key: 'C3', width: 13.83 },
      { key: 'C4', width: 13.83 },
      { key: 'C5', width: 13.83 },
      { key: 'C6', width: 16.63 },
      { key: 'C7', width: 16.63 },
      { key: 'C8', width: 13.83 },
      { key: 'C9', width: 13.83 },
      { key: 'C10', width: 13.83 },
      { key: 'C11', width: 13.83 },
      { key: 'C12', width: 13.83 },
    ]

    // TITLE ROW 0,1,2
    worksheetpm.addRow([])

    let titleRowpm = worksheetpm.addRow([
      ' ',
      'Service Charge Register Report',
    ])
    titleRowpm.height = 20

    /// WORKSHET EXPENSE
    let worksheetexpense = workbook.addWorksheet('Gratuity Only', {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 35,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })
    // COLUMN WIDTH EXPENSE
    worksheetexpense.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 13.83 },
      { key: 'C2', width: 16.83 },
      { key: 'C3', width: 13.83 },
      { key: 'C4', width: 13.83 },
      { key: 'C5', width: 13.83 },
      { key: 'C6', width: 16.63 },
      { key: 'C7', width: 16.63 },
      { key: 'C8', width: 13.83 },
      { key: 'C9', width: 13.83 },
      { key: 'C10', width: 13.83 },
      { key: 'C11', width: 13.83 },
      { key: 'C12', width: 13.83 },
    ]

    // TITLE ROW 0,1,2
    worksheetexpense.addRow([])

    let titleRowexpense = worksheetexpense.addRow([
      ' ',
      'Service Charge Register Report',
    ])
    titleRowexpense.height = 20
    let titleText
    let titleRow
    let titleCell
    titleText = `${title}`
    titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B2:K2')
    titleCell = worksheet.getCell('B2')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
    }

    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }

    titleText = `${title}`
    worksheetpm.mergeCells('B2:K2')
    titleCell = worksheetpm.getCell('B2')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
    }

    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }
    titleText = `${title}`
    worksheetexpense.mergeCells('B2:K2')
    titleCell = worksheetexpense.getCell('B2')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
    }

    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }

    titleRow.height = 20
    let headerRow2
    let titleRowType
    let titleRowStatus
    let titleRowCategory
    let titleRowDateRange

    titleRowType = worksheet.addRow([' ', 'Type:' + ' ' + typeItems])
    titleRowStatus = worksheet.addRow([' ', 'Status:' + ' ' + status])
    titleRowCategory = worksheet.addRow([' ', 'Category:' + ' ' + category])
    titleRowDateRange = worksheet.addRow([
      ' ',
      'Date Range:' + ' ' + type_range,
    ])
    titleRowType.height = 20

    titleRowType.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowStatus.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowCategory.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowDateRange.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }

    headerRow2 = worksheet.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    worksheet.mergeCells('H7:K7')
    worksheet.mergeCells('B7:E7')
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow2.height = 25
    headerRow2.getCell(10).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(13).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(16).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }

    // SERVICE CHARGE
    titleRowType = worksheetpm.addRow([' ', 'Type:' + ' ' + 'Service Charge'])
    titleRowStatus = worksheetpm.addRow([' ', 'Status:' + ' ' + status])
    titleRowCategory = worksheetpm.addRow([' ', 'Category:' + ' ' + category])
    titleRowDateRange = worksheetpm.addRow([
      ' ',
      'Date Range:' + ' ' + type_range,
    ])
    titleRowType.height = 20

    titleRowType.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowStatus.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowCategory.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowDateRange.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }

    headerRow2 = worksheetpm.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    worksheetpm.mergeCells('H7:K7')
    worksheetpm.mergeCells('B7:E7')
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow2.height = 25
    headerRow2.getCell(10).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(13).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(16).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }

    // GRATUITY
    titleRowType = worksheetexpense.addRow([' ', 'Type:' + ' ' + 'Gratuity'])
    titleRowStatus = worksheetexpense.addRow([' ', 'Status:' + ' ' + status])
    titleRowCategory = worksheetexpense.addRow([
      ' ',
      'Category:' + ' ' + category,
    ])
    titleRowDateRange = worksheetexpense.addRow([
      ' ',
      'Date Range:' + ' ' + type_range,
    ])
    titleRowType.height = 20

    titleRowType.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowStatus.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowCategory.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }
    titleRowDateRange.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      bold: false,
      italic: 'true',
    }

    headerRow2 = worksheetexpense.addRow(headerRow2Columns)
    headerRow2.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
    }
    worksheetexpense.mergeCells('H7:K7')
    worksheetexpense.mergeCells('B7:E7')
    headerRow2.eachCell((cell, number) => {
      if (number > 1) {
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow2.height = 25
    headerRow2.getCell(10).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(13).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow2.getCell(16).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }

    var headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    // worksheetpm.addRow([])

    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.getCell(6).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow1.height = 25

    var headerRow1 = worksheetpm.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow1.getCell(6).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow1.height = 25

    var headerRow1 = worksheetexpense.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }

    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
      }
    })
    headerRow1.getCell(6).alignment = {
      horizontal: 'right',
      vertical: 'middle',
    }
    headerRow1.height = 25

    for (let index = 0; index < dataHistory.length; index++) {
      const r = dataHistory[index]
      let dataRowColumn = this.generateTransactionRegisterHistoryColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 20

      dataRow.alignment = {
        horizontal: 'left',
        vertical: 'middle',
      }
      dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(3).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(4).alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(4).numFmt = 'DD-MMM-YY'
      dataRow.getCell(5).alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(5).numFmt = 'hh:mm A'
      dataRow.getCell(6).alignment = { horizontal: 'right', vertical: 'middle' }
      dataRow.getCell(6).numFmt = '#,##0.00'
      dataRow.getCell(7).alignment = { horizontal: 'right', vertical: 'middle' }
      dataRow.getCell(7).numFmt = '#,##0.00'
      dataRow.getCell(13).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(14).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(15).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(16).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(17).alignment = { horizontal: 'left', vertical: 'middle' }
    }

    for (let index = 0; index < serviceCharge.length; index++) {
      const r = serviceCharge[index]
      let dataRowColumn = this.generateTransactionRegisterHistoryColumnArr(r)
      let dataRow = worksheetpm.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 20
      dataRow.alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(3).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(4).alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(4).numFmt = 'DD-MMM-YY'
      dataRow.getCell(5).alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(5).numFmt = 'hh:mm A'
      dataRow.getCell(6).alignment = { horizontal: 'right' }
      dataRow.getCell(6).numFmt = '#,##0.00'
      dataRow.getCell(7).alignment = { horizontal: 'right', vertical: 'middle' }
      dataRow.getCell(7).numFmt = '#,##0.00'
      dataRow.getCell(13).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(14).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(15).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(16).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(17).alignment = { horizontal: 'left', vertical: 'middle' }
    }

    for (let index = 0; index < gratuity.length; index++) {
      const r = gratuity[index]
      let dataRowColumn = this.generateTransactionRegisterHistoryColumnArr(r)
      let dataRow = worksheetexpense.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 20
      dataRow.alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(3).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(4).alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(4).numFmt = 'DD-MMM-YY'
      dataRow.getCell(5).alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(5).numFmt = 'hh:mm A'
      dataRow.getCell(6).alignment = { horizontal: 'right', vertical: 'middle' }
      dataRow.getCell(6).numFmt = '#,##0.00'
      dataRow.getCell(7).alignment = { horizontal: 'right', vertical: 'middle' }
      dataRow.getCell(7).numFmt = '#,##0.00'
      dataRow.getCell(13).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(14).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(15).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(16).alignment = { horizontal: 'left', vertical: 'middle' }
      dataRow.getCell(17).alignment = { horizontal: 'left', vertical: 'middle' }
    }

    workbook.xlsx.writeBuffer().then(dataHistory => {
      let blob = new Blob([dataHistory], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private formatRowDefaultValue(dataRow) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    dataRow.height = 20
    dataRow.numFmt = '#,##0.00'
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }

    return dataRow
  }


  private generateTransactionRegisterHistoryColumnArr(item) {
    let row = []
    row.push('')
    row.push(item.status)
    row.push(item.type)
    row.push(item.date)
    row.push(item.time)
    row.push(item.amount)
    row.push(item.total)
    row.push(item.category)
    row.push(item.method)
    row.push(item.receiptNo)
    row.push(item.txnId)
    return row
  }

}
