import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { GuestLetter } from '@hmp/models'
import { GuestLetterService } from '@hmp/services/hmp/guest-letter/guest-letter.service'

const log = new Logger('GuestLetterDetailResolver')
@Injectable({ providedIn: 'root' })
export class GuestLetterDetailResolver
  implements Resolve<Observable<GuestLetter>> {
  constructor(
    private guestLetterService: GuestLetterService,
    private router: Router
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let id = route.params['lt_id']
    // 
    return this.guestLetterService.getDetail(id).pipe(
      map(data => {
        log.debug('Data_Edit_Letter: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/guest-letter']))
    )
  }
}
