import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
import { FinancingJournal } from '@hmp'

const log = new Logger('FinancingJournalService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class FinancingJournalService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    financing_journal: {
      list_financing_journal: 'procurement/financing_journal_entity',
      detail: 'procurement/financing_journal_entity',
      repayment:
        'procurement/financing_journal_entity/financing_journal_repayment',
      sendmail: 'finance/finance_report_bob_and_pickup/send_email',
    },
  }

  /**
   * Lấy tất cả Promotions đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[financing_journal_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.financing_journal.list_financing_journal, pa)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  getDetail(id: string): Observable<FinancingJournal> {
    return this.apiService
      .get<FinancingJournal>(`${this._apiPath.financing_journal.detail}/${id}`)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  create(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.financing_journal.detail, res)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  createrepayment(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.financing_journal.repayment, res)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  edit(res: FinancingJournal): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.financing_journal.detail}/${res.id}`, res)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  // delete(id): Observable<any> {
  //   return this.apiService.delete(`${this._apiPath.financing_journal.detail}/${id}`).pipe(
  //     map(data => {
  //       log.debug(data);
  //       return data;
  //     })
  //   );
  // }

  sendmail(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.financing_journal.sendmail, res)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
}
