import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { ApiService } from '../../general/api.service'
import { FileManage, TransactionRegisterUploadFile } from '../../../models'
import { Logger } from '../../../logger.service'
import { Observable } from 'rxjs'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
import { AuthService } from '@hmp/services/general/auth.service'

const log = new Logger('FileManagerService')
@Injectable({ providedIn: 'root' })
export class FileManagerService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private auth: AuthService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    viewdetail: 'file_api/file_manager',
    upload: 'file_api/file_manager',
    transaction_register_upload: 'v1/media/upload-file',
    upload_media: 'v1/media/upload-save-file',
    transaction_register_delete: 'v1/transaction/delete',
  }

  /**
   * Xem thông tin file
   * @param id ID File muốn xem
   */
  getFileDetail(id): Observable<FileManage> {
    return this.apiService
      .get<FileManage>(`${this._apiPath.viewdetail}/${id}`)
      .pipe(
        map(data => {
          log.debug('Field: ', data)
          return data
        })
      )
  }

  /**
   * Upload file lên server
   * @param file Nội dung file
   */
  uploadFile(file: FileManage): Observable<FileManage> {
    file.fid = null
    return this.apiService.post(this._apiPath.upload, file).pipe(
      map(data => {
        log.debug('Upload File Data Transaction: ', data)
        return data
      })
    )
  }

  /**
   * Xoá file
   * @param id ID File muốn xoá
   */
  deleteFile(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.viewdetail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  deleteFileTR(id): Observable<any> {
    return this.apiService
      .deleteBudget(`${this._apiPath.transaction_register_delete}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  transactionRegisterUploadFile(
    file: any,
    params?: any
  ): Observable<TransactionRegisterUploadFile> {
    let formData = new FormData()
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('moduleName', 'transaction')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    formData.append('file', file)
    return this.apiService
      .postBudget(
        `${this._apiPath.transaction_register_upload}${pa}`,
        formData,
        {},
        undefined,
        'json',
        true,
        false
      )
      .pipe(
        map(data => {
          log.debug('Content uploadFile', data)
          return data
        })
      )
  }

  uploadImage(data: any, fieldImage = 'file', params?: any): Observable<any> {
    const formData = new FormData()
    let pa = new HttpParams()
      .set('?businessId', this.storage.getActiveLocalBusinessID())
      .set('moduleName', 'transaction')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    formData.append(fieldImage, data)
    return this.apiService
      .postBudget(
        `${this._apiPath.upload_media}${pa}`,
        formData,
        {},
        undefined,
        'json',
        true,
        false
      )
      .pipe(
        map(data => {
          log.debug('Content uploadFile', data)
          return data
        })
      )
  }
}
