import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('StatutoryExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class StatutoryExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onStatutoryExcel(title, data: any[], sheetname, filename) {
    const headerRow1Cells = ['', 'Guest Information', 'Reservation Information']
    const headerRow3Columns = [
      'First Name',
      'Last Name',
      'Primary Email',
      'Secondary Email',
      'Primary Phone Number',
      'Secondary Phone Number ',
      'Street Address',
      'Street Address - Line 2',
      'City / Town',
      'State / Province / Territory',
      'Country',
      'Postal / Zip Code',
      'Organisation',
      'Birthday',
      'Gender',
      'Nationality',
      'Country of Residence',
      'ID Type',
      'ID Number',
      'Date of Issue',
      'Date of Expiration',
      'Visa Type',
      'Port of Entry',
      'Date of Entry',
      'Date of Expiration',
      'Patron Type',
      'Reservation Number',
      'Reservation Pax',
      'Room Number',
      'Check-In Date',
      'Check-In Time',
      'Check-Out Date',
      'Check-Out Time',
      'Booking Type',
      'Agent/Company/OTA Name',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: true,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: true }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C1', width: 24 },
      { key: 'C2', width: 24 },
      { key: 'C3', width: 24 },
      { key: 'C4', width: 24 },
      { key: 'C5', width: 24 },
      { key: 'C6', width: 24 },
      { key: 'C7', width: 24 },
      { key: 'C8', width: 24 },
      { key: 'C9', width: 24 },
      { key: 'C10', width: 30 },
      { key: 'C11', width: 24 },
      { key: 'C12', width: 24 },
      { key: 'C13', width: 24 },
      { key: 'C14', width: 24 },
      { key: 'C15', width: 24 },
      { key: 'C16', width: 24 },
      { key: 'C17', width: 24 },
      { key: 'C18', width: 24 },
      { key: 'C19', width: 24 },
      { key: 'C20', width: 24 },
      { key: 'C21', width: 24 },
      { key: 'C22', width: 24 },
      { key: 'C23', width: 24 },
      { key: 'C24', width: 24 },
      { key: 'C25', width: 24 },
      { key: 'C26', width: 24 },
      { key: 'C27', width: 24 },
      { key: 'C28', width: 15 },
      { key: 'C29', width: 24 },
      { key: 'C30', width: 15 },
      { key: 'C31', width: 15 },
      { key: 'C32', width: 15 },
      { key: 'C33', width: 15 },
      { key: 'C34', width: 24 },
      { key: 'C35', width: 24 },
      { key: 'C36', width: 40 },
    ]

    // TITLE ROW 0,1,2
    let titleText = `${title}`
    let titleRow = worksheet.addRow([titleText])
    worksheet.mergeCells('A1:AI1')
    let titleCell = worksheet.getCell('A1')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'thin' },
    }
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' }
    titleRow.height = 20

    let headerRow1 = worksheet.addRow(headerRow1Cells)
    worksheet.mergeCells('A2:Z2')
    worksheet.mergeCells('AA2:AI2')
    worksheet.getCell('A2:Z2').value = 'Guest Information'
    worksheet.getCell('A2:Z2').border = {
      right: { style: 'thin' },
    }
    worksheet.getCell('AA2:AI2').value = 'Reservation Information'
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.alignment = { horizontal: 'center', vertical: 'middle' }
    headerRow1.height = 20

    let headerRow3 = worksheet.addRow(headerRow3Columns)
    headerRow3.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }

    headerRow3.eachCell((cell, number) => {
      if (number > 0) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
      if (number == 26) {
        cell.border = {
          right: { style: 'thin' },
          top: { style: 'thin' },
          bottom: { style: 'thin' },
        }
      }
    })
    headerRow3.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 20
      dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
      // dataRow.getCell(3).alignment = { horizontal: 'center' }
      // dataRow.getCell(4).alignment = { horizontal: 'center' }
      // dataRow.getCell(27).alignment = { horizontal: 'center' }
      dataRow.getCell(26).border = {
        right: { style: 'thin' },
      }
    }
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push(data.first_name)
    row.push(data.last_name)
    row.push(data.primary_email)
    row.push(data.secondary_email)
    row.push(data.primary_phone_number)
    row.push(data.secondary_phone_number)
    row.push(data.street_address)
    row.push(data.street_address_line2)
    row.push(data.city_town)
    row.push(data.state_privice_territory)
    row.push(data.country)
    row.push(data.postal_zipcode)
    row.push(data.organisation)
    row.push(data.birthday)
    row.push(data.gender)
    row.push(data.nationality)
    row.push(data.country_of_residence)
    row.push(data.id_type)
    row.push(data.id_number)
    row.push(data.date_of_issue)
    row.push(data.date_of_expiration)
    row.push(data.visa_type)
    row.push(data.port_of_entry)
    row.push(data.date_of_entry)
    row.push(data.date_of_expirations)
    row.push(data.patron_type)
    row.push(data.reservation_number)
    row.push(data.reservation_pax)
    row.push(data.room_number)
    row.push(data.check_in_date)
    row.push(data.check_in_time)
    row.push(data.check_out_date)
    row.push(data.check_out_time)
    row.push(data.booking_type)
    row.push(data.agent_company_ota_name)
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
