import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('DynamicInvoiceService')

@Injectable({ providedIn: 'root' })
export class DynamicInvoiceService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho Dynamic Invoice
   */
  private _apiPath = {
    list_dynamic_invoice: 'reservations/invoice_generator_entity',
    dynamic_invoice_update_bill_to:
      'reservations/invoice_generator_entity/update_bill_to',
    dynamic_invoice_export_pdf:
      'reservations/invoice_generator_entity/export_pdf',
    dynamic_invoice_send_email:
      'reservations/invoice_generator_entity/send_mail',
    dataLRT_UOM: 'reservations/invoice_generator_ltr_uom_data',
    dataAgentCorporate: 'reservations/invoice_generator_agent_corporate_data',
    data_invoice_Reservation: 'reservations/invoice_generator_reservation_data',
    filter_reservation:
      'reservations/invoice_generator_filter_guest_reservations',
    in_house_filter_reservation:
      'reservations/invoice_generator_filter_reservations',
    create_credit_note:
      'reservations/invoice_generator_reservation_data/credit_debit_note_create',
    export_pdf_list_invoice_generator:
      'reservations/invoice_generator_entity/export_list_pdf',
    dynamic_invoice_hidden_tax: 'taxes/taxes_entity/add_hidden_entity',
    dynamic_invoice_un_hidden_tax: 'taxes/taxes_entity/reset_hidden_entity',
    get_list_reservations_in_house:
      'reservations/invoice_generator_filter_reservations',
    all_guest_in_house: 'business/filter_ar_other',
    set_data_in_house: 'reservations/invoice_generator_reservation_room_data',
    list_invoice_combine: 'reservations/invoice_generator_combine_invoices',
    combine_invoices: 'reservations/invoice_generator_combine_invoices',
    list_dynamic_invoice_move_item:
      'reservations/invoice_generator_move_items_invoices',
    data_invoice_Reservation_move_item:
      'reservations/invoice_generator_move_items_invoices',
    get_data_combine_invoices:
      'reservations/invoice_generator_combine_invoices/data_invoice',
    save_data_combine_invoices:
      'reservations/invoice_generator_combine_invoices',
    dynamic_change_pro_forma:
      'reservations/invoice_generator_entity/custom_title',
      hide_item_invoice_reservation:
      'reservations/invoice_generator_reservation_invoice_data/hide_items',
    dynamic_multiple_invoice: {
      multiple_invoices_reservation:
        'reservations/invoice_generator_reservation_invoice_data',
      save:
        'reservations/invoice_generator_reservation_invoice_data/save_invoices',
      delete: 'reservations/invoice_generator_reservation_invoice_data',
    },
  }

  /**
   * Lấy tất cả Dynamic Invoice đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAllDynamicInvoice(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any[]>(this._apiPath.list_dynamic_invoice, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllDynamicInvoiceMoveItem(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any[]>(this._apiPath.list_dynamic_invoice_move_item, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.list_dynamic_invoice}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  updateBillToInvoiceGenerator(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_invoice_update_bill_to, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  dynamicInvoiceExportPDF(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.dynamic_invoice_export_pdf,
      res,
      null,
      null,
      'blob'
    )
  }

  dynamicInvoiceSendEmail(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_invoice_send_email, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  dynamicGetDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.list_dynamic_invoice}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  dynamicMoveItemsGetDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.list_dynamic_invoice_move_item}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getInHouseDetail(id?, params?: any): Observable<any> {
    let pa = new HttpParams().set('id', id)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.set_data_in_house, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  deleteDynamicInvoice(id): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.list_dynamic_invoice}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getLTR_UOMData(
    uid?,
    year?,
    month?,
    client_type?,
    include_unpaid?,
    unit_setting?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('year', year)
      .set('month', month)
      .set('client_type', client_type)
      .set('include_unpaid', include_unpaid)
      .set('unit_setting', unit_setting)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.dataLRT_UOM, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getInHouseReservationInvoice(
    uid?,
    room_id?,
    rid?,
    guest_name?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('room_id', room_id)
      .set('rid', rid)
      .set('guest_name', guest_name)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.set_data_in_house, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAgentCorporateData(
    uid?,
    year?,
    month?,
    client_type?,
    include_unpaid?,
    unit_setting?,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('year', year)
      .set('month', month)
      .set('client_type', client_type)
      .set('include_unpaid', include_unpaid)
      .set('unit_setting', unit_setting)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.dataAgentCorporate, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getReservationData(uid?, rid?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('uid', uid)
      .set('rid', rid)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.data_invoice_Reservation, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReservationDataMoveItem(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.data_invoice_Reservation, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createInvoiceGeneratorReservation(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.data_invoice_Reservation, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createInvoiceGeneratorReservationMoveItems(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.data_invoice_Reservation_move_item, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createInHouseInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.set_data_in_house, res).pipe(
      map(data => {
        return data
      })
    )
  }

  filter_reservation(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.filter_reservation, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  listReservationByInHouse(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.in_house_filter_reservation, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createLTR_UOMInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.dataLRT_UOM, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateLTR_UOMInvoice(id, res): Observable<any> {
    return this.apiService.put(`${this._apiPath.dataLRT_UOM}/${id}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createAgentCorporateInvoice(res): Observable<any> {
    return this.apiService.post(this._apiPath.dataAgentCorporate, res).pipe(
      map(data => {
        return data
      })
    )
  }

  updateAgentCorporateInvoice(id, res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.dataAgentCorporate}/${id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  createCreditNoteInvoiceGenerator(res): Observable<any> {
    return this.apiService.post(this._apiPath.create_credit_note, res).pipe(
      map(data => {
        return data
      })
    )
  }
  exportPDFListInvoiceGenerator(res): Observable<any> {
    return this.apiService.post<any>(
      this._apiPath.export_pdf_list_invoice_generator,
      res,
      null,
      null,
      'blob'
    )
  }
  dynamicInvoiceHiddenTax(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_invoice_hidden_tax, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getListReservationInHouseInvoiceGenerator(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.get_list_reservations_in_house, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getAllGuestInHouse(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.all_guest_in_house, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getAllInvoiceCombine(params?: any): Observable<any[]> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any[]>(this._apiPath.list_invoice_combine, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  onGetDataInvoiceCombine(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.get_data_combine_invoices, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  onSaveDataInvoiceCombine(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.save_data_combine_invoices, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getDynamicReservationDataNew(rid?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('rid', rid)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(
        this._apiPath.dynamic_multiple_invoice.multiple_invoices_reservation,
        pa
      )
      .pipe(
        map(data => {
          return data
        })
      )
  }

  dynamicCreateInvoiceNew(res): Observable<any> {
    return this.apiService
      .post(
        this._apiPath.dynamic_multiple_invoice.multiple_invoices_reservation,
        res
      )
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  onSaveMultipleInvoices(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_multiple_invoice.save, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  deleteMultipleInvoice(ivID): Observable<any> {
    return this.apiService
      .delete(`${this._apiPath.dynamic_multiple_invoice.delete}/${ivID}`)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  dynamicCreateHideTax(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_invoice_hidden_tax, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  dynamicUnHidenTax(res?: any): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_invoice_un_hidden_tax, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  dynamicChangeProFormaInvoice(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.dynamic_change_pro_forma, res)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
  updateItemInvoice(res?: any): Observable<any> {
    return this.apiService.post(this._apiPath.hide_item_invoice_reservation, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
