import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
import { Guest, ResidentFolio } from '@hmp/models'

const log = new Logger('ResidentFolioService')

/**
 * Đây là services dành cho ResidentFolioService
 */
@Injectable({ providedIn: 'root' })
export class ResidentFolioService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng
   */
  private _apiPath = {
    list: 'guest/resident_folio_entity',
    list_longterm_resident: 'business/resident_folio',
    retrieve: 'business/resident_folio',
    detailUnit: 'guest/resident_folio_entity',
    allroomUnit: 'room/room_entity',
    add_new_comment: 'guest/resident_folio_entity/add_comment',
    history_by_: 'guest/invoice_item_entity',
    list_accouting_codes: 'manager/account_code_entity',
    create_calculate_amount: 'guest/invoice_item_entity/calculate_amount',
    list_taxes_setting: 'taxes/taxes_entity',
    create_guest_ltr: 'business/user_guest_info',
  }

  /**
   * Lấy tất cả Resident Folio
   */
  getAllLongTermResident(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(this._apiPath.list_longterm_resident, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllUnitResident(uid?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[rf_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', '1')
      .set('parameters[rf_account]', uid)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getRetrieve(
    uid: number,
    sdate?,
    edate?,
    type?,
    unit?,
    params?: any
  ): Observable<any[]> {
    let bid = this.storage.getActiveLocalBusinessID()
    let pa = new HttpParams()
      .set('sdate', sdate)
      .set('edate', edate)
      .set('type', type)
      .set('unit', unit)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any[]>(`${this._apiPath.list_longterm_resident}/${bid}/${uid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getDetail(id: string): Observable<any> {
    return this.apiService.get<any>(`${this._apiPath.detailUnit}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getHistoryByUnit(
    account?: string,
    room?: string,
    unit_setting?: string
  ): Observable<any> {
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('type', '1')
      .set('account', account)
      .set('room', room)
      .set('unit_setting', unit_setting || '0')
    return this.apiService.get<any>(this._apiPath.history_by_, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getHistoryByID(id?: string): Observable<any> {
    return this.apiService.get<any>(`${this._apiPath.history_by_}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllRoomsUnit(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set(
        'parameters[room_business_id]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('select_box', 'true')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.allroomUnit, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  create(res): Observable<any> {
    res.uid = null
    return this.apiService.post(this._apiPath.list_longterm_resident, res).pipe(
      map(data => {
        return
      })
    )
  }
  createGuestLTR(res): Observable<any> {
    return this.apiService.post(this._apiPath.create_guest_ltr, res).pipe(
      map(data => {
        return data
      })
    )
  }
  createCalculateAmount(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.create_calculate_amount, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  createInvoiceItemLTR(res): Observable<any> {
    return this.apiService.post(this._apiPath.history_by_, res).pipe(
      map(data => {
        return data
      })
    )
  }
  editInvoiceItemLRT(res): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.history_by_}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  deleteInvoiceItemLRT(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.history_by_}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
  createRFSettings(res: ResidentFolio): Observable<any> {
    res.id = null
    return this.apiService.post(this._apiPath.detailUnit, res).pipe(
      map(data => {
        return data
      })
    )
  }

  edit(res: ResidentFolio): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.detailUnit}/${res.id}`, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  addComment(res): Observable<any> {
    return this.apiService.post(this._apiPath.add_new_comment, res).pipe(
      map(data => {
        log.debug('ADD CMT', data)
        return data
      })
    )
  }

  // edit(res: Revenue): Observable<any> {
  //   return this.apiService.put(`${this._apiPath.detail}/${res.rid}`, res).pipe(
  //     map(data => {
  //       return data
  //     })
  //   )
  // }

  // delete(id): Observable<any> {
  //   return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
  //     map(data => {
  //       return data
  //     })
  //   )
  // }
  getListAccountingCodes(account_code?, params?: any): Observable<any> {
    let pa = new HttpParams()
      .set('parameters[ac_business]', this.storage.getActiveLocalBusinessID())
      .set('select_box', 'true')
      .set('parameters[ac_number_type]', account_code)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.list_accouting_codes, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllTaxes(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set(
        'parameters[taxes_business_id]',
        this.storage.getActiveLocalBusinessID()
      )
      .set('parameters[taxes_type]', '1')
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list_taxes_setting, pa).pipe(
      map(data => {
        return data
      })
    )
  }
}
