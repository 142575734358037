import { Injectable } from '@angular/core'
import { DiscountPromotions } from '../../../models'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '@hmp/helper'
import { FieldInfoService } from '../field-info/field-info.service'
const log = new Logger('DiscountPromotionsService')
@Injectable({
  providedIn: 'root',
})
export class DiscountPromotionsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService,
    private fieldInfo: FieldInfoService
  ) {}

  private _apiPath = {
    list: 'promotions/discount_entity',
    detail: 'promotions/discount_entity',
    add_to_entity: 'promotions/discount_entity/add_discount_for_entity',
    remove_discount_entity:
      'promotions/discount_entity/remove_discount_for_entity',
  }

  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[discount_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getDiscountType(): Observable<any> {
    return this.fieldInfo.getFieldInfo('discount_type')
  }

  edit(val: DiscountPromotions): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${val.did}`, val).pipe(
      map(data => {
        return data
      })
    )
  }

  create(val: DiscountPromotions): Observable<any> {
    return this.apiService.post(this._apiPath.detail, val).pipe(
      map(data => {
        return data
      })
    )
  }

  addDiscountToEntity(type: string, id: string, code: string): Observable<any> {
    let data = {
      bid: this.storage.getActiveLocalBusinessID(),
      entity_id: id,
      entity_type: type,
      code: code,
    }
    return this.apiService.post(this._apiPath.add_to_entity, data).pipe(
      map(data => {
        return data
      })
    )
  }

  removeDiscountToEntity(
    type: string,
    id: string,
    did: string
  ): Observable<any> {
    let data = {
      entity_id: id,
      entity_type: type,
      did: did,
    }
    return this.apiService
      .post(this._apiPath.remove_discount_entity, data)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  detail(id: string): Observable<any> {
    return this.apiService.get(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
