import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
  Optional,
  Inject,
} from '@angular/core'
import { CoreIconsRegistry } from './core-icons.registry'
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'coreicon',
  template: `
    <ng-content></ng-content>
  `,
  styles: [
    '.coreicon{width:1em; height:1em; contain:strict; fill:currentcolor; box-sizing:content-box !important; font-size: inherit;}',
    '.coreicon svg{width: 100%; height: 100%; display: block}',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreIconsComponent {
  private svgIcon: SVGElement

  @HostBinding('class.coreicon') true
  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon)
    }
    const icon = this.registry.getIcon(iconName)
    this.svgIcon = this.getSVGElementFromString(icon)
    this.element.nativeElement.appendChild(this.svgIcon)
  }

  @HostBinding('style.width.px') @Input() width: number
  @HostBinding('style.height.px') @Input() height: number

  constructor(
    private element: ElementRef,
    private registry: CoreIconsRegistry,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  private getSVGElementFromString(svg: string): SVGElement {
    const div = this.document.createElement('DIV')
    div.innerHTML = svg
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    )
  }
}
