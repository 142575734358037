import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AngularFirestore } from '@angular/fire/firestore'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { ApiService } from '../../general/api.service'
import { AuthService } from '../../general/auth.service'
import { Logger } from '../../../logger.service'
import { ChatMessage, FileManage } from '../../../models'
import { map } from 'rxjs/operators'
import { environment } from 'environments/environment'
const log = new Logger('ChatMessageService')

/**
 * Đây là services dành cho ChatMessageService
 */
@Injectable({ providedIn: 'root' })
export class ChatMessageService {
  bid = this.auth.getActiveLocalBusinessID()
  busPath = `${environment.name.toLowerCase()}/${this.auth.getActiveLocalBusinessID()}`

  private _apiPath = {
    upload: 'file_api/file_manager',
  }
  constructor(
    private apiService: ApiService,
    private afs: AngularFirestore,
    private auth: AuthService
  ) {}

  getMessages(roomID): Observable<any[]> {
    const path = `${this.busPath}/Messages/All/${roomID}`
    return this.afs
      .collection<any>(path, ref => ref.orderBy('timestamp', 'asc'))
      .valueChanges()
  }

  addNewMessage(rid, message: ChatMessage): Promise<any> {
    const path = `${this.busPath}/Messages/All/${rid}`
    const mId = this.afs.createId()
    message.id = mId
    message.timestamp = firebase.firestore.Timestamp.now()
    return this.afs.collection(path).add(message)
  }

  uploadFile(file: FileManage): Observable<any> {
    file.status = '1'
    return this.apiService.post(this._apiPath.upload, file).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  // uploadImage(uid, file: FileManage): Promise<any> {
  //   let path = `${this.bid}/${uid}/images/${file.filename}`;
  //   log.debug("UPLOADING FILE PATH: ", path);
  //   return this.afstorage
  //     .ref(path)
  //     .putString(file.file, "data_url")
  //     .then(a => {
  //       return a.ref.getDownloadURL();
  //     });
  // }

  // uploadVideo(bid, uid, data, filename): Promise<any> {
  //   let path = `${bid}/${uid}/videos/${filename}`;
  //   log.debug("UPLOADING FILE PATH: ", path);
  //   return this.afstorage
  //     .ref(path)
  //     .put(data)
  //     .then(a => {
  //       return a.ref.getDownloadURL();
  //     });
  // }

  // uploadDoc(uid, data, filename, ext): Promise<any> {
  //   let path = `${this.bid}/${uid}/${ext}/${filename}`;
  //   log.debug("UPLOADING FILE PATH: ", path);
  //   return this.afstorage
  //     .ref(path)
  //     .put(data)
  //     .then(a => {
  //       return a.ref.getDownloadURL();
  //     });
  // }
}
