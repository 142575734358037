import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { HR } from '../../models'
import { HRService } from '../../services/hmp/hr/hr.service'
import { AuthService } from '../../services'

const log = new Logger('AgentManagerDetailResolver')
@Injectable({ providedIn: 'root' })
export class AgentAccountDetailResolver implements Resolve<Observable<HR>> {
  constructor(
    private resService: HRService,
    private router: Router,
    private auth: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let rid = route.params['uid']
    let bid = this.auth.getActiveLocalBusinessID()
    
    return this.resService.getHRByUserAndBusiness(bid, rid, 3).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['/agent-manager']))
    )
  }
}
