import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('GuestExportDatabaseService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class FinanceReservationExportService {
  customData
  headerRow2
  constructor(private auth: AuthService) {}

  onGenerateReportExcel(businessName, data: any, sheetname, filename) {
    const headerReservation = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Check-In Date',
      'Check-Out Date',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Booking Channel',
      'Amount Due',
      'Outstanding',
    ]

    const headerCheckIn = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Check-In Date',
      'Check-In Time',
      'Length of Stay',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Notes',
      'Staff Name',
      'Amount Due',
      'Outstanding',
    ]

    const headerArrival = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Check-In Date',
      'Length of Stay',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Notes',
      'Amount Due',
      'Outstanding',
    ]
    const headerCheckOut = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Check-Out Date',
      'Check-Out Time',
      'Length of Stay',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Notes',
      'Staff Name',
      'Amount Due',
      'Outstanding',
    ]
    const headerDepartures = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Check-Out Date',
      'Length of Stay',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Notes',
      'Amount Due',
      'Outstanding',
    ]
    const headerCancellations = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Reason',
      'Cancellation Date',
      'Check-In Date',
      'Check-Out Date',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Notes',
    ]
    const headerNewReservations = [
      '',
      'Reservation No.',
      'Guest Name',
      'Company',
      'Creation Date',
      'Check-In Date',
      'Check-Out Date',
      'Length of Stay',
      'Room No.',
      'Pax ( A | C | I )',
      'Room Rates',
      'Reservation Type',
      'Booking Channel',
      'Staff Name',
      'Amount Due',
      'Outstanding',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    for (const k in data) {
      if (data.hasOwnProperty(k)) {
        data[k] = data[k]
        const res = data[k]
        log.debug('data', res)
        this.customData = res
        let worksheet = workbook.addWorksheet(`${res.title}`, {
          properties: {
            tabColor: { argb: 'FFFFFF00' },
            showGridLines: true,
            defaultRowHeight: 20,
          },
          views: [{ state: 'normal', showGridLines: false }],
        })

        // COLUMN WIDTH
        worksheet.columns = [
          { key: 'C0', width: 7 },
          { key: 'C1', width: 30 },
          { key: 'C2', width: 30 },
          { key: 'C3', width: 30 },
          { key: 'C4', width: 30 },
          { key: 'C5', width: 20 },
          { key: 'C6', width: 30 },
          { key: 'C7', width: 30 },
          { key: 'C8', width: 30 },
          { key: 'C9', width: 30 },
          { key: 'C10', width: 30 },
          { key: 'C11', width: 30 },
          { key: 'C12', width: 30 },
          { key: 'C13', width: 30 },
          { key: 'C14', width: 30 },
          { key: 'C15', width: 30 },
          { key: 'C16', width: 30 },
          { key: 'C17', width: 30 },
        ]

        // TITLE ROW 0,1,2
        worksheet.addRow([])
        worksheet.addRow([])
        worksheet.addRow([])
        let titleText = `Reservation Reports ${moment
          .utc()
          .format('DD-MMM-YY')}`
        let titleRow = worksheet.addRow([' ', titleText])
        worksheet.mergeCells('B4:M4')
        let titleCell = worksheet.getCell('B4')
        titleCell.font = {
          name: 'Calibri',
          family: 4,
          size: 18,
          bold: true,
        }
        // titleCell.border = {
        //   bottom: { style: 'medium' },
        // }
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' }

        titleRow.height = 20
        worksheet.addRow([])
        const headerRow1Columns = [
          '',
          `${res.title}`,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          `${businessName}`,
        ]
        let headerRow1 = worksheet.addRow(headerRow1Columns)
        headerRow1.font = {
          name: 'Calibri',
          size: 14,
          bold: true,
        }
        // worksheet.mergeCells('J6:M6')
        if (this.customData.title == 'In-House Guests') {
          worksheet.mergeCells('J6:M6')
        }
        if (this.customData.title == 'Check-Ins') {
          worksheet.mergeCells('J6:O6')
        }
        if (this.customData.title == 'Arrivals') {
          worksheet.mergeCells('J6:M6')
        }
        if (this.customData.title == 'Check-Outs') {
          worksheet.mergeCells('J6:O6')
        }
        if (this.customData.title == 'Departures') {
          worksheet.mergeCells('J6:M6')
        }
        if (this.customData.title == 'Cancellations') {
          worksheet.mergeCells('J6:M6')
        }
        if (this.customData.title == 'New Reservations') {
          worksheet.mergeCells('J6:P6')
        }
        headerRow1.eachCell((cell, number) => {
          if (number > 1) {
            cell.border = {
              top: { style: 'thin' },
              bottom: { style: 'medium' },
            }
            cell.alignment = { horizontal: 'left', vertical: 'middle' }
          }
        })
        headerRow1.getCell(13).alignment = { horizontal: 'right' }
        headerRow1.height = 20
        if (this.customData.title == 'In-House Guests') {
          this.headerRow2 = worksheet.addRow(headerReservation)
        }
        if (this.customData.title == 'Check-Ins') {
          this.headerRow2 = worksheet.addRow(headerCheckIn)
        }
        if (this.customData.title == 'Arrivals') {
          this.headerRow2 = worksheet.addRow(headerArrival)
        }
        if (this.customData.title == 'Check-Outs') {
          this.headerRow2 = worksheet.addRow(headerCheckOut)
        }
        if (this.customData.title == 'Departures') {
          this.headerRow2 = worksheet.addRow(headerDepartures)
        }
        if (this.customData.title == 'Cancellations') {
          this.headerRow2 = worksheet.addRow(headerCancellations)
        }
        if (this.customData.title == 'New Reservations') {
          this.headerRow2 = worksheet.addRow(headerNewReservations)
        }
        this.headerRow2.font = {
          name: 'Calibri',
          size: 10,
          bold: true,
        }
        this.headerRow2.eachCell((cell, number) => {
          if (number > 1) {
            cell.border = {
              top: { style: 'thin' },
              bottom: { style: 'medium' },
            }
            cell.alignment = { horizontal: 'left', vertical: 'middle' }
          }
        })
        this.headerRow2.getCell(8).alignment = {
          horizontal: 'center',
          vertical: 'middle',
        }
        // this.headerRow2.getCell(12).alignment = { horizontal: 'center' }
        // this.headerRow2.getCell(13).alignment = { horizontal: 'center' }
        this.headerRow2.height = 20

        if (res && res.data) {
          for (const key in res.data) {
            const item = res.data[key]
            let dataRowColumn = this.generateReportColumnArr(item)
            let dataRow = worksheet.addRow(dataRowColumn)
            dataRow.font = {
              name: 'Calibri',
              size: 10,
              bold: false,
            }
            dataRow.height = 17
            dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
            if (this.customData.title == 'In-House Guests') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'right' }
              dataRow.getCell(10).alignment = { horizontal: 'left' }
              dataRow.getCell(11).alignment = { horizontal: 'left' }
              dataRow.getCell(12).alignment = { horizontal: 'right' }
              dataRow.getCell(13).alignment = { horizontal: 'right' }
            }
            if (this.customData.title == 'Check-Ins') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'left' }
              dataRow.getCell(10).alignment = { horizontal: 'right' }
              dataRow.getCell(11).alignment = { horizontal: 'left' }
              dataRow.getCell(12).alignment = { horizontal: 'left' }
              dataRow.getCell(13).alignment = { horizontal: 'left' }
              dataRow.getCell(14).alignment = { horizontal: 'right' }
              dataRow.getCell(15).alignment = { horizontal: 'right' }
            }
            if (this.customData.title == 'Arrivals') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'right' }
              dataRow.getCell(10).alignment = { horizontal: 'left' }
              dataRow.getCell(11).alignment = { horizontal: 'left' }
              dataRow.getCell(12).alignment = { horizontal: 'right' }
              dataRow.getCell(13).alignment = { horizontal: 'right' }
            }
            if (this.customData.title == 'Check-Outs') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'left' }
              dataRow.getCell(10).alignment = { horizontal: 'right' }
              dataRow.getCell(11).alignment = { horizontal: 'left' }
              dataRow.getCell(12).alignment = { horizontal: 'left' }
              dataRow.getCell(13).alignment = { horizontal: 'left' }
              dataRow.getCell(14).alignment = { horizontal: 'right' }
              dataRow.getCell(15).alignment = { horizontal: 'right' }
            }
            if (this.customData.title == 'Departures') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'right' }
              dataRow.getCell(10).alignment = { horizontal: 'left' }
              dataRow.getCell(11).alignment = { horizontal: 'left' }
              dataRow.getCell(12).alignment = { horizontal: 'right' }
              dataRow.getCell(13).alignment = { horizontal: 'right' }
            }
            if (this.customData.title == 'Cancellations') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'left' }
              dataRow.getCell(10).alignment = { horizontal: 'left' }
              dataRow.getCell(11).alignment = { horizontal: 'right' }
              dataRow.getCell(12).alignment = { horizontal: 'left' }
              dataRow.getCell(13).alignment = { horizontal: 'left' }
            }
            if (this.customData.title == 'New Reservations') {
              dataRow.getCell(2).alignment = { horizontal: 'left' }
              dataRow.getCell(3).alignment = { horizontal: 'left' }
              dataRow.getCell(4).alignment = { horizontal: 'left' }
              dataRow.getCell(5).alignment = { horizontal: 'left' }
              dataRow.getCell(6).alignment = { horizontal: 'left' }
              dataRow.getCell(7).alignment = { horizontal: 'left' }
              dataRow.getCell(8).alignment = { horizontal: 'left' }
              dataRow.getCell(9).alignment = { horizontal: 'left' }
              dataRow.getCell(10).alignment = { horizontal: 'left' }
              dataRow.getCell(11).alignment = { horizontal: 'right' }
              dataRow.getCell(12).alignment = { horizontal: 'left' }
              dataRow.getCell(13).alignment = { horizontal: 'left' }
              dataRow.getCell(14).alignment = { horizontal: 'left' }
              dataRow.getCell(15).alignment = { horizontal: 'right' }
              dataRow.getCell(16).alignment = { horizontal: 'right' }
            }
          }
        }
      }
    }

    // return

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.reservation_no)
    row.push(data.guest_name)
    row.push(data.company)
    if (this.customData.title == 'In-House Guests') {
      // const checkIn = data.nights_of_stay ? moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY') : moment.utc(moment.unix(data.check_in_time)).format('DD-MMM-YY hh:mm A')
      // const checkout = data.nights_of_stay ? moment.utc(moment.unix(data.check_out)).format('DD-MMM-YY') : moment.utc(moment.unix(data.check_out_time)).format('DD-MMM-YY hh:mm A')
      const checkIn = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
      const checkout = moment
        .utc(moment.unix(data.check_out))
        .format('DD-MMM-YY')
      data.check_in ? row.push(checkIn) : row.push('')
      data.check_out ? row.push(checkout) : row.push('')
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.booking_channel)
      row.push(data.amount_due)
      row.push(data.outstanding)
    }
    if (this.customData.title == 'Check-Ins') {
      const checkIn = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
      let checkInTime = moment
        .utc(moment.unix(data.check_in_time))
        .format('hh:mm A')
      data.check_in ? row.push(checkIn) : row.push('')
      data.check_in_time ? row.push(checkInTime) : row.push('')
      row.push(data.nights_of_stay)
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.notes)
      row.push(data.staff_name)
      row.push(data.amount_due)
      row.push(data.outstanding)
    }
    if (this.customData.title == 'Arrivals') {
      const checkIn = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
      data.check_in ? row.push(checkIn) : row.push('')
      row.push(data.nights_of_stay)
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.notes)
      row.push(data.amount_due)
      row.push(data.outstanding)
    }
    if (this.customData.title == 'Check-Outs') {
      const checkout = moment
        .utc(moment.unix(data.check_out))
        .format('DD-MMM-YY')
      const checkOutTime = moment
        .utc(moment.unix(data.check_out_time))
        .format('hh:mm A')
      data.check_out ? row.push(checkout) : row.push('')
      data.check_out ? row.push(checkOutTime) : row.push('')
      row.push(data.nights_of_stay)
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.notes)
      row.push(data.staff_name)
      row.push(data.amount_due)
      row.push(data.outstanding)
    }
    if (this.customData.title == 'Departures') {
      const checkout = moment
        .utc(moment.unix(data.check_out))
        .format('DD-MMM-YY')
      data.check_out ? row.push(checkout) : row.push('')
      row.push(data.nights_of_stay)
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.notes)
      row.push(data.amount_due)
      row.push(data.outstanding)
    }
    if (this.customData.title == 'Cancellations') {
      const cancel_date = moment
        .utc(moment.unix(data.cancellation_date))
        .format('DD-MMM-YY')
      const checkIn = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
      const checkout = moment
        .utc(moment.unix(data.check_out))
        .format('DD-MMM-YY')
      row.push(data.cancellation_reason)
      data.check_out ? row.push(cancel_date) : row.push('')
      data.check_in ? row.push(checkIn) : row.push('')
      data.check_out ? row.push(checkout) : row.push('')
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.notes)
    }
    if (this.customData.title == 'New Reservations') {
      const creation = moment
        .utc(moment.unix(data.creation_date))
        .format('DD-MMM-YY')
      const checkIn = moment.utc(moment.unix(data.check_in)).format('DD-MMM-YY')
      const checkout = moment
        .utc(moment.unix(data.check_out))
        .format('DD-MMM-YY')
      data.creation_date ? row.push(creation) : row.push('')
      data.check_in ? row.push(checkIn) : row.push('')
      data.check_out ? row.push(checkout) : row.push('')
      row.push(data.nights_of_stay)
      row.push(data.room_no)
      row.push(`${data.adults} | ${data.children} | ${data.infants} | `)
      row.push(data.room_rates)
      row.push(data.reservation_type)
      row.push(data.notes)
      row.push(data.staff_name)
      row.push(data.amount_due)
      row.push(data.outstanding)
    }
    return row
  }
}
