import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { JwtService } from '../../general/jwt.service'
import { GlobalEntityService } from '../global/global-entity.service'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { FieldInfoService } from '../field-info/field-info.service'
import { LocalStorageService } from '@hmp/helper'
import { EmailSettings } from '@hmp'
const log = new Logger('EmailSettingsService')
@Injectable({
  providedIn: 'root',
})
export class EmailSettingsService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  private _apiPath = {
    view: 'reservations/email_tamplete',
    email_setting: 'business/business_mail_setting',
    verify: 'business/business_mail_setting/verify',
  }

  getAll(params?: any): Observable<any> {
    let pa = new HttpParams()
    let bid = this.storage.getActiveLocalBusinessID()
    if (params) {
      Object.keys(params).forEach(key => {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(`${this._apiPath.view}/${bid}/reservation_detail`, pa)
      .pipe(
        map(data => {
          // log.debug(data)
          return data
        })
      )
  }

  getAllEmailSetting(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.email_setting, pa).pipe(
      map(data => {
        // log.debug(data)
        return data
      })
    )
  }

  updateEmailSettings(email: EmailSettings): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.view}/${email.bid}/reservation_detail`, email)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }

  verifyEmail(res): Observable<any> {
    return this.apiService.post(this._apiPath.verify, res).pipe(
      map(data => {
        log.debug(data)
        return data
      })
    )
  }

  updateGeneralEmailSetting(bid: string, body: any): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.email_setting}/${bid}`, body)
      .pipe(
        map(data => {
          log.debug(data)
          return data
        })
      )
  }
}
