import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment-timezone';
@Pipe({
  name: 'unix',
})
export class UnixDatePipe implements PipeTransform {
  transform(
    value: number,
    dateFormat: string = 'h:mm a',
    tz?: string
  ): any {
    if(value && tz){
        const day = moment.unix(value);
        const dF = dateFormat == '' ? 'hh:mm a' : dateFormat
        return moment(day).tz(tz).format(dF);
    }
  }
}
