import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ScheduleOff } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
const log = new Logger('ScheduleOffService')

/**
 * Đây là services dành cho Season
 */

@Injectable({ providedIn: 'root' })
export class ScheduleOffService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'scheduleoff/scheduleoff_entity',
    detail: 'scheduleoff/scheduleoff_entity',
    changetimework: 'schedule/schedule_employee/change_time_work',
  }

  /**
   * Lấy tất cả ScheduleOff
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<ScheduleOff[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[scheduleoff_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<ScheduleOff[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của ScheduleOff dưạ vào @sId
   * @param Id ID của Reservation
   */
  getDetail(id: string): Observable<ScheduleOff> {
    return this.apiService
      .get<ScheduleOff>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   * Tạo ScheduleOff
   * @param res Thông tin ScheduleOff
   */
  create(res: ScheduleOff): Observable<any> {
    res.sid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  changeTimeWork(res): Observable<any> {
    return this.apiService.post(this._apiPath.changetimework, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Chỉnh sửa thông tin ScheduleOff
   *
   * @param res Thông tin ScheduleOff muốn chỉnh sửa
   */
  edit(res: ScheduleOff): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.sid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin ScheduleOff
   *
   * @param id Xóa ScheduleOff bằng ScheduleOff ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
