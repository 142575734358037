import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('ARRegisterExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class ARRegisterExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGeneralExcel(title, data: any[], sheetname, filename, logo) {
    const headerRow1Columns = [
      '',
      'Invoice Number',
      'Client Type',
      'Invoice Type',
      'Order',
      'Client Name',
      'Company Name',
      'Invoice Total',
      'Balance Due',
      'Due Date',
      'Status',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 20 },
      { key: 'C2', width: 18 },
      { key: 'C2', width: 18 },
      { key: 'C3', width: 20 },
      { key: 'C4', width: 30 },
      { key: 'C5', width: 30 },
      { key: 'C6', width: 20 },
      { key: 'C7', width: 20 },
      { key: 'C8', width: 18 },
      { key: 'C9', width: 10 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title}`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:E4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E3:E4')
    }

    worksheet.addRow([])
    let sumRowHeader = worksheet.addRow([])
    sumRowHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 17
      dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(2).alignment = { horizontal: 'left' }
      dataRow.getCell(3).alignment = { horizontal: 'center' }
      dataRow.getCell(4).alignment = { horizontal: 'center' }
      dataRow.getCell(5).alignment = { horizontal: 'left' }
      dataRow.getCell(6).alignment = { horizontal: 'left' }
      dataRow.getCell(7).alignment = { horizontal: 'left' }
      dataRow.getCell(8).alignment = { horizontal: 'right' }
      dataRow.getCell(8).numFmt = '#,##0.00'
      dataRow.getCell(9).alignment = { horizontal: 'right' }
      dataRow.getCell(9).numFmt = '#,##0.00'
      dataRow.getCell(10).alignment = { horizontal: 'center' }
      dataRow.getCell(11).alignment = { horizontal: 'center' }
    }

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.entity_reference)
    row.push(data.ig_client_type)
    row.push(data.ig_invoice_type)
    row.push(data.ig_order_reference)
    row.push(data.ig_client_name)
    row.push(data.ig_company)
    row.push(data.ig_total)
    row.push(data.ig_balance_due)
    row.push(data.ig_due_date)
    row.push(data.ig_status)
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
