import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('ExpenseJournalService')

/**
 * Đây là services dành cho Promotions
 */

@Injectable({ providedIn: 'root' })
export class ExpenseJournalService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    procurement: {
      list: 'procurement/expense_journal_entity',
      detail: 'procurement/expense_journal_entity',
      expense_journal_list_cost: 'procurement/expense_journal_list_cost',
      sub_category: 'procurement/expense_journal_list_restaurant_category',
      void_expense: 'procurement/expense_journal_entity/expense_journal_void',
      lis_history: 'procurement/expense_journal_logs',
    },
    business: {
      report: 'business/report_statement_cash_flow',
    },
  }

  getAll(params?: any): Observable<any> {
    let pa = new HttpParams().set('parameters[expense_journal_business]', this.storage.getActiveLocalBusinessID())
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.procurement.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getDetail(id: string): Observable<any> {
    return this.apiService
      .get<any>(`${this._apiPath.procurement.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  create(res): Observable<any> {
    return this.apiService.post(this._apiPath.procurement.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  // edit(res: RoomRatesPrice): Observable<any> {
  //   return this.apiService.put(`${this._apiPath.room_rates.detail}/${res.rid}`, res).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }

  // delete(id): Observable<any> {
  //   return this.apiService.delete(`${this._apiPath.room_rates.detail}/${id}`).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }

  getListExpenseJournalCost(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.procurement.expense_journal_list_cost, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getAllHistoryExpenseJournal(id?: string, params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    ).set('id',id)
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.procurement.lis_history, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }
  getSubCategories(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService
      .get<any>(this._apiPath.procurement.sub_category, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  getReportCashFlow(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'bid',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.business.report, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  voidExpense(res): Observable<any> {
    return this.apiService
      .post(this._apiPath.procurement.void_expense, res)
      .pipe(
        map(data => {
          return data
        })
      )
  }
}
