import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, shareReplay } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '../../../environments/environment'
import { AuthService } from './auth.service'
import { JwtService } from './jwt.service'

import { Logger } from '../../logger.service'
const log = new Logger('ApiService')

/**
 * Đây là Class chính giúp mình có thể handle API (GET, POST,...)
 */
@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private jwt: JwtService
  ) {}

  /**
   * handle các lỗi khi API trả về fail.
   * @error
   * là nơi mình lấy lỗi và throw lỗi.
   */
  // tslint:disable-next-line: typedef
  handleError(error: any) {
    log.debug('handleError: ', error)
    switch (error.status) {
      // Nếu server bảo trì
      case 503:
        this.router.navigate(['/maintenance'])
        break

      case 401:
        const currentUser = this.jwt.getToken()
        if (!currentUser) {
          this.auth.logout()
        }
        return throwError(error)

      default:
        break
    }
    return throwError(error)
    // tslint:disable-next-line: deprecation
    return Observable.throw(error)
  }

  /**
   * Lấy dữ liệu về bằng method là GET
   * @param path là đường dẫn tới api, còn địa chỉ Server API nó được thiết lập ban đầu ở thư mục enviroments
   * @param params là các tham số mình muốn truyền thêm.
   * @return dữ liệu tuỳ thuộc vào APi trả về
   */
  get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
    const curLang = this.translate.currentLang || 'en'
    const datecreated = this.getCurrentDateTimeUTC()
    params = params
      .set('langset', curLang)
      .set('datecreated', datecreated.toString())
      if(path !== 'business/web_notifications_number_unread') {
        log.debug(`[GET]/${path}`)
        log.debug(params)
      }
    return this.http
      .get<T>(`${environment.API_PATH}${path}`, { params })
      .pipe(shareReplay(1), catchError(this.handleError.bind(this)))
  }

  getBudget<T>(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<T> {
    const curLang = this.translate.currentLang || 'en'
    const datecreated = this.getCurrentDateTimeUTC()
    params = params.set('langset', curLang).set('datecreated', datecreated.toString())
    log.debug(`[GET]/${path}`)
    log.debug(params)
    return this.http
      .get<T>(`${environment.API_PATH_BUDGET}${path}`, { params })
      .pipe(shareReplay(1), catchError(this.handleError.bind(this)))
  }

  getFromPath<T>(path: string, headers): Observable<T> {
    return this.http.get<T>(`${path}`, { headers: headers })
  }

  getRefreshToken(
    path: string,
    body: Object = {},
    headers?: any
  ): Observable<any> {
    return this.http.post(
      `${environment.API_PATH}${path}`,
      JSON.stringify(body),
      {
        headers: headers,
      }
    )
  }

  /**
   *
   * @param path là đường dẫn tới api, còn địa chỉ Server API nó được thiết lập ban đầu ở thư mục enviroments
   * @param body là nội dung muốn truyền vào.
   * @return dữ liệu tuỳ thuộc vào APi trả về
   */
  post<T>(
    path: string,
    body: any,
    headers?: any,
    params?: HttpParams,
    resType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json',
    isCustomerHeader = false,
    isJsonBody = true
  ): Observable<T> {
    if (!params) {
      params = new HttpParams()
    }
    const curLang = this.translate.currentLang || 'en'
    body['langset'] = curLang
    const datecreated = this.getCurrentDateTimeUTC()
    body['datecreated'] = datecreated
    log.debug('POST HEADERS: ', headers)
    log.debug(`[POST]/${path} | with Body`, body)
    log.debug('RES TYPE: ', resType)
    // log.debug("POST PARAMS: ", params);
    let newHeaders = {}
    if (headers) {
      newHeaders = { ...headers }
    }
    if (!isCustomerHeader) {
      newHeaders['Content-Type'] = 'application/json'
    }
    switch (resType) {
      case 'blob':
        return this.http
          .post(
            `${environment.API_PATH}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'blob',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
      case 'arraybuffer':
        return this.http
          .post(
            `${environment.API_PATH}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'arraybuffer',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
      case 'text':
        return this.http
          .post(
            `${environment.API_PATH}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'text',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))

      default:
        // const formData = body['formData']
        // log.debug("body['formData']", formData)
        return this.http
          .post(
            `${environment.API_PATH}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'json',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
    }
  }

  /**
   *
   * @param path là đường dẫn tới api, còn địa chỉ Server API nó được thiết lập ban đầu ở thư mục enviroments
   * @param body là nội dung muốn truyền vào.
   * @return dữ liệu tuỳ thuộc vào APi trả về
   */
  patch<T>(
    path: string,
    body?: any,
    headers?: any,
    params?: HttpParams,
    resType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json',
    isCustomerHeader = false,
    isJsonBody = true
  ): Observable<T> {
    if (!params) {
      params = new HttpParams()
    }
    const curLang = this.translate.currentLang || 'en'
    body['langset'] = curLang
    const datecreated = this.getCurrentDateTimeUTC()
    body['datecreated'] = datecreated
    log.debug('PATCH HEADERS: ', headers)
    log.debug(`[PATCH]/${path} | with Body`, body)
    log.debug('RES TYPE: ', resType)
    // log.debug("PATCH PARAMS: ", params);
    let newHeaders = {}
    if (headers) {
      newHeaders = { ...headers }
    }
    if (!isCustomerHeader) {
      newHeaders['Content-Type'] = 'application/json'
    }
    switch (resType) {
      case 'blob':
        return this.http
          .patch(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'blob',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
      case 'arraybuffer':
        return this.http
          .patch(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'arraybuffer',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
      case 'text':
        return this.http
          .patch(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'text',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))

      default:
        // const formData = body['formData']
        // log.debug("body['formData']", formData)
        return this.http
          .patch(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'json',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
    }
  }

  postBudget<T>(
    path: string,
    body: any,
    headers?: any,
    params?: HttpParams,
    resType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json',
    isCustomerHeader = false,
    isJsonBody = true
  ): Observable<T> {
    if (!params) {
      params = new HttpParams()
    }
    const curLang = this.translate.currentLang || 'en'
    body['langset'] = curLang
    const datecreated = this.getCurrentDateTimeUTC()
    body['datecreated'] = datecreated
    log.debug('POST HEADERS: ', headers)
    log.debug(`[POST]/${path} | with Body`, body)
    log.debug('RES TYPE: ', resType)
    // log.debug("POST PARAMS: ", params);
    let newHeaders = {}
    if (headers) {
      newHeaders = { ...headers }
    }
    if (!isCustomerHeader) {
      newHeaders['Content-Type'] = 'application/json'
    }
    switch (resType) {
      case 'blob':
        return this.http
          .post(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'blob',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
      case 'arraybuffer':
        return this.http
          .post(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'arraybuffer',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
      case 'text':
        return this.http
          .post(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'text',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))

      default:
        // const formData = body['formData']
        // log.debug("body['formData']", formData)
        return this.http
          .post(
            `${environment.API_PATH_BUDGET}${path}`,
            isJsonBody ? JSON.stringify(body) : body,
            {
              headers: newHeaders,
              params: params,
              responseType: 'json',
            }
          )
          .pipe(catchError(this.handleError.bind(this)))
    }
  }

  /**
   *
   * @param path là đường dẫn tới api, còn địa chỉ Server API nó được thiết lập ban đầu ở thư mục enviroments
   * @param body là nội dung muốn truyền vào.
   * @return dữ liệu tuỳ thuộc vào APi trả về
   */
  postNotify(path: string, body: Object = {}, headers?: any): Observable<any> {
    const curLang = this.translate.currentLang || 'en'
    body['langset'] = curLang
    log.debug('POST HEADERS: ', headers)
    log.debug(`[POST]/${path} | with Body`, body)
    return this.http
      .post(`${path}`, JSON.stringify(body), {
        headers: headers,
      })
      .pipe(catchError(this.handleError.bind(this)))
  }

  postOut(path: string, body: Object = {}, headers?: any): Observable<any> {
    log.debug('POST HEADERS: ', headers)
    log.debug(`[POST]/${path} | with Body`, body)
    return this.http.post(`${path}`, JSON.stringify(body), {
      headers: headers,
    })
  }

  getRefreshTokenPath(path): string {
    return `${environment.API_PATH}${path}`
  }

  put(
    path: string,
    body: Object = {},
    headers?: any,
    isCustomerHeader = false
  ): Observable<any> {
    const curLang = this.translate.currentLang || 'en'
    body['langset'] = curLang
    const datecreated = this.getCurrentDateTimeUTC()
    body['datecreated'] = datecreated
    // log.info(`[PUT]/${path} | with Body`);
    // log.info(body);
    let newHeaders = {}
    if (headers) {
      newHeaders = { ...headers }
    }
    if (!isCustomerHeader) {
      newHeaders['Content-Type'] = 'application/json'
    }
    return this.http
      .put(`${environment.API_PATH}${path}`, JSON.stringify(body), {
        headers: newHeaders,
      })
      .pipe(catchError(this.handleError.bind(this)))
  }

  /**
   *
   * @param path là đường dẫn tới api, còn địa chỉ Server API nó được thiết lập ban đầu ở thư mục enviroments
   */
  delete(path): Observable<any> {
    const datecreated = this.getCurrentDateTimeUTC()
    const params: HttpParams = new HttpParams().set(
      'datecreated',
      datecreated.toString()
    )
    return this.http
      .delete(`${environment.API_PATH}${path}`, { params })
      .pipe(catchError(this.handleError.bind(this)))
  }
  deleteBudget(path): Observable<any> {
    const datecreated = this.getCurrentDateTimeUTC()
    const params: HttpParams = new HttpParams().set(
      'datecreated',
      datecreated.toString()
    )
    return this.http
      .delete(`${environment.API_PATH_BUDGET}${path}`, { params })
      .pipe(catchError(this.handleError.bind(this)))
  }

  getCurrentDateTimeUTC(): number {
    const cur = new Date()
    const utcT = Date.UTC(
      cur.getFullYear(),
      cur.getMonth(),
      cur.getDate(),
      cur.getHours(),
      cur.getMinutes(),
      cur.getSeconds()
    )
    return utcT / 1000
  }
}
