import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
const log = new Logger('CashJournalExportService')
import * as _moment from 'moment'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class CashJournalExportService {
  constructor(private auth: AuthService) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateCashJournalReportExcel(
    title,
    data: any[],
    sheetname,
    filename,
    logo,
    total_begin,
    total_end,
    datalabel
  ) {
    const headerRow1Columns = [
      '',
      'Payment Date',
      'Title',
      'Nature',
      'Accounting Code',
      'Method',
      'Amount',
      'Paid To',
    ]

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 13 },
      { key: 'C2', width: 50 },
      { key: 'C3', width: 30 },
      { key: 'C2', width: 30 },
      { key: 'C4', width: 30 },
      { key: 'C5', width: 20 },
      { key: 'C5', width: 30 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    let titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    let titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:H4')
    let titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      //Add Image
      let logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E3:E4')
    }

    worksheet.addRow([])
    let sumRowHeader = worksheet.addRow([])
    sumRowHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    sumRowHeader.alignment = { horizontal: 'right', vertical: 'middle' }
    sumRowHeader.getCell(6).value = 'Total Expenditure'
    sumRowHeader.getCell(6).font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      underline: 'single',
    }
    let sumRowValue = worksheet.addRow([])
    sumRowValue.alignment = { horizontal: 'right', vertical: 'middle' }
    sumRowValue.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    sumRowValue.getCell(6).value = total_end
    sumRowValue.getCell(6).numFmt = '#,##0.00'

    worksheet.addRow([])

    let headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 20

    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      let dataRowColumn = this.generateProductionReportColumnArr(r)
      let dataRow = worksheet.addRow(dataRowColumn)
      dataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: false,
      }
      dataRow.height = 17
      dataRow.alignment = { horizontal: 'center', vertical: 'middle' }
      dataRow.getCell(2).alignment = { horizontal: 'center' }
      dataRow.getCell(2).numFmt = 'DD-MMM-YY'
      dataRow.getCell(3).alignment = { horizontal: 'left' }
      dataRow.getCell(4).alignment = { horizontal: 'center' }
      dataRow.getCell(4).numFmt = '#,##0.00'
      dataRow.getCell(5).alignment = { horizontal: 'center' }
      dataRow.getCell(5).numFmt = '#,##0.00'
      dataRow.getCell(6).alignment = { horizontal: 'right' }
    }

    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateProductionReportColumnArr(data) {
    let row = []
    row.push('')
    row.push(data.timestamp)
    row.push(data.title)
    row.push(data.nature)
    row.push(data.accounting_code)
    row.push(data.credit)
    row.push(data.amount)
    row.push(data.paidto)
    return row
  }

  private getImageDimensions(file): Promise<any> {
    return new Promise(function(resolved, rejected) {
      var i = new Image()
      i.onload = function() {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
}
