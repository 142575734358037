import { Injectable } from '@angular/core'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('OTAService')

@Injectable({ providedIn: 'root' })
export class OTAService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}

  /**
   * Khai báo tất cả các path sẻ sử dụng cho HouseKeeping
   */
  private _apiPath = {
    list: 'channel_ota/channel_room_calendar',
    room_date: 'channel_ota/channel_room_calendar/set_room_dates',
    room_price: 'channel_ota/channel_room_calendar/set_room_price',
    room_rate_detail: 'channel_ota/channel_room_rates',
  }

  getCalendar(startDate, endDate, params?: any): Observable<any[]> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
      .set('bid', this.storage.getActiveLocalBusinessID())
      .set('start', startDate)
      .set('end', endDate)
    if (params) {
      // tslint:disable-next-line: typedef
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any[]>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  getRoomrateDetail(rid, roomrateid, params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams()
    if (params) {
      // tslint:disable-next-line: typedef
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService
      .get<any>(`${this._apiPath.room_rate_detail}/${rid}/${roomrateid}`, pa)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  setRoomRate(rid, date, val, type, end,reset?): Observable<any> {
    const res = {
      rid: rid,
      start: date,
      end: end,
      reset: reset,
    }
    type === 'i' ? (res['i'] = val) : (res['i'] = val)
    return this.apiService.post(this._apiPath.room_date, res).pipe(
      map(data => {
        return data
      })
    )
  }
  setRoomPrice(rid, date, val, type, end, channel,reset?): Observable<any> {
    const res = {
      rid: rid,
      start: date,
      end: end,
      channel: channel,
      reset: reset,
    }
    type === 'i' ? (res['i'] = val) : (res['p'] = val)
    return this.apiService.post(this._apiPath.room_price, res).pipe(
      map(data => {
        return data
      })
    )
  }
}
