import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { getCurrencySymbol } from '@angular/common'
const log = new Logger('GuestExportDatabaseService')
import * as _moment from 'moment'
import { HMPGlobal } from '@hmp/config/HMPGlobal'
import { AuthService } from '../general/auth.service'
const moment = _moment
declare const ExcelJS: any
@Injectable({ providedIn: 'root' })
export class CashFlowForeCastExportService {
  constructor(
    private auth: AuthService
  ) {}

  private getCurrencyInfo() {
    const currency = this.auth.getCurrentCurrency()
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateReportExcel(
    title,
    data: any[],
    bName,
    sheetname,
    filename
  ) {
    let dayColumn: any[] = []
    let headerRowSummaryColumns
    if (data) {
      dayColumn = data[0].cashFlowData as any[]
      // row 2
      headerRowSummaryColumns = ['', '(Currency in ' + this.getCurrencyInfo().name + ')', ``]
      for (let day of dayColumn) {
        headerRowSummaryColumns.push(`Mon ${day.month}`)
      }
    }

    let workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    let worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    const transactionRegisterColumns = [
      ' ',
      'Budget Builder - Cash Flow Forecast',
    ]

    for (let index = 0; index < data[0].cashFlowData.length + 1; index++) {
      transactionRegisterColumns.push('')
    }

    let titleSummaryRow = worksheet.addRow(transactionRegisterColumns)
    let titleSummaryLastCellAddress
    titleSummaryRow.eachCell((cell, number) => {
      if (number == transactionRegisterColumns.length) {
        titleSummaryLastCellAddress = cell.address
      }
      cell.alignment = { horizontal: 'center', vertical: 'middle' }
      cell.font = {
        name: 'Calibri',
        family: 4,
        size: 14,
        bold: true,
      }
    })
    titleSummaryRow.height = 20
    worksheet.mergeCells(`B2:${titleSummaryLastCellAddress}`)

    // TITLE ROW 0,1,2

    let date = worksheet.getCell('B3')
    const now = new Date()
    const dateNow = moment(now)
    date.value = moment(dateNow).format('DD-MMM-YYYY, HH:mm A')
    date.font = {
      name: 'Calibri',
      size: 10,
      italic: true,
      bold: false,
      color: { argb: '000000' },
    }
    date.alignment = { vertical: 'middle', horizontal: 'left' }

    const businessName = ['', '', '']

    for (let index = 0; index < data[0].cashFlowData.length; index++) {
      businessName.push('')
    }

    let nameSummaryRow = worksheet.addRow(businessName)
    let nameLastCellAddress
    nameSummaryRow.eachCell((cell, number) => {
      if (number == businessName.length) {
        nameLastCellAddress = cell.address
      }
    })
    let namedemo = worksheet.getCell(`${nameLastCellAddress}`)
    namedemo.value = bName
    namedemo.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
      color: { argb: '000000' },
    }
    namedemo.alignment = { vertical: 'middle', horizontal: 'right' }

    let name = worksheet.getCell('B4')
    name.value = 'Component Summary'
    name.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
      color: { argb: '000000' },
    }
    name.alignment = { vertical: 'middle', horizontal: 'left' }

    let headerRow1 = worksheet.addRow(headerRowSummaryColumns)
    headerRow1.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    }

    headerRow1.eachCell((cell, number) => {
      if (number == 2) {
        cell.font = {
          name: 'Calibri',
          size: 10,
          bold: false,
          italic: true,
        }
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        const dobCol = worksheet.getColumn(number)
        dobCol.width = 20
      }
      if (number == 3) {
        const dobCol = worksheet.getColumn(number)
        dobCol.width = 15
      }
      if (number < 3) {
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        const dobCol = worksheet.getColumn(number)
        dobCol.width = 15
      }
      if (number > 1) {
        const dobCol = worksheet.getColumn(number)
        dobCol.width = 20
        cell.border = {
          top: { style: 'medium' },
        }
      }
      if (number > 3) {
        const dobCol = worksheet.getColumn(number)
        dobCol.width = 20
        cell.border = {
          top: { style: 'medium' },
          bottom: { style: 'thin' },
        }
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
      }
    })
    headerRow1.height = 30
    for (let index = 0; index < data.length; index++) {
      const r = data[index]
      if (r.cashFlowData && r.name !== 'TOTAL') {
        const dataPaymentRowColumnName = this.generateSummaryValueColumnArrName(
          r
        )
        const dataPaymentRowColumnAmount = this.generateSummaryValueColumnArr(
          r)
        const dataPaymentRowColumnPersion = this.generateSummaryValueColumnArr1(
          r
        )
        let dataRowName = worksheet.addRow(dataPaymentRowColumnName)
        this.formatRowDefaultValueName(worksheet, dataRowName)
        let dataRowAmout = worksheet.addRow(dataPaymentRowColumnAmount)
        this.formatRowDefaultValueAmount(dataRowAmout)
        let dataRowPersion = worksheet.addRow(dataPaymentRowColumnPersion)
        this.formatRowDefaultValuePersion(dataRowPersion)
      }
      if (r.name === 'TOTAL') {
        const dataPaymentRowColumn = this.generateSummaryValueColumnArrTotal(r)
        let dataRowTotal = worksheet.addRow(dataPaymentRowColumn)
        this.formatRowDefaultValue(dataRowTotal)
      }
    }

    const lastRow = worksheet.lastRow
    lastRow.font = {
      name: 'Calibri',
      color: { argb: '428BCA' },
      size: 12,
      bold: true,
    }
    worksheet.mergeCells('B5:C5')
    // worksheet.mergeCells('B6:C6')
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private formatRowDefaultValue(dataRow) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.numFmt = '#,##0.00'
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    return dataRow
  }

  private formatRowDefaultValueAmount(dataRow) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(3).numFmt = '#,##0.0'
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.numFmt = '#,##0.00'
      }
    })

    return dataRow
  }

  private formatRowDefaultValuePersion(dataRow) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    return dataRow
  }

  private formatRowDefaultValueName(worksheet, dataRow) {
    dataRow.eachCell((cell, number) => {
      if (number > 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'F3F4F3',
          },
          bgColor: {
            argb: 'F3F4F3',
          },
        }
      }
      if (number == 2) {
        const dobCol = worksheet.getColumn(number)
        dobCol.width = 25
        cell.alignment = { horizontal: 'left', vertical: 'middle' }
        cell.font = {
          name: 'Calibri',
          size: 14,
          bold: true,
        }
      }
      if (number == 3) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' }
        cell.font = {
          name: 'Calibri',
          size: 10,
          bold: false,
          italic: true,
        }
      }
    })
    return dataRow
  }

  private generateSummaryValueColumnArrName(data) {
    const row = []
    row.push('')
    row.push(`${data.costCenter}`)
    row.push(`Budget`)
    for (let index = 0; index < data.cashFlowData.length; index++) {
      row.push('')
    }
    return row
  }

  private generateSummaryValueColumnArr(data) {
    const value = data.cashFlowData
    const row = []
    const amountP = Math.round(data.amount).toFixed(2)
    const currency = this.getCurrencyInfo().name
    const symbol = this.getCurrency(currency, 'wide')
    row.push('')
    row.push(symbol)
    row.push(Number(`${amountP}`))
    for (let index = 0; index < value.length; index++) {
      const element = value[index]
      const amount = element.amount
      row.push(amount)
    }
    return row
  }

  private generateSummaryValueColumnArrTotal(data) {
    const value = data.cashFlowData
    const row = []
    const amountP = Math.round(data.amount)
    row.push('')
    row.push('Total')
    row.push(Number(`${amountP}`))
    for (let index = 0; index < value.length; index++) {
      const element = value[index]
      const amount = element.amount
      row.push(Number(amount))
    }
    return row
  }

  private generateSummaryValueColumnArr1(data) {
    const value = data.cashFlowData
    const row = []
    row.push('')
    row.push('%')
    row.push(`100.0%`)
    for (let index = 0; index < value.length; index++) {
      const element = value[index]
      row.push(element.percent+ '%')
    }
    return row
  }

  private getCurrency(
    code: string,
    format: 'wide' | 'narrow' = 'narrow',
    locale?: string
  ): any {
    return getCurrencySymbol(code, format, locale)
  }
  
}
