import { Injectable } from '@angular/core'
import { Logger } from '../../logger.service'
import * as Excel from 'exceljs/dist/exceljs.min.js'
import * as fs from 'file-saver'
import { AuthService } from '../general/auth.service'
import { HMPGlobal } from '../../config/HMPGlobal'
declare const ExcelJS: any
const log = new Logger('BoBPickupExportService')
import * as _moment from 'moment'
const moment = _moment
@Injectable({ providedIn: 'root' })
export class BoBPickupExportService {
  constructor(private auth: AuthService) {}

  // tslint:disable-next-line: typedef
  getCurrencyInfo() {
    const currentGeneral = this.auth.getBusinessGeneralInfo()
    let currency = 'usd'
    if (currentGeneral) {
      currency = currentGeneral.business_currency || 'usd'
    }
    return HMPGlobal.getCurrency(currency)
  }

  onGenerateBoBReportExcel(
    title,
    yearTitle,
    bobData: any[],
    weeklyData: any[],
    sheetname,
    filename,
    logo
  ) {
    const headerRow1Columns = [
      '',
      'Room Nights',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      yearTitle,
    ]

    const weeklyColumns = [
      '',
      'Week #',
      'Start Date',
      'End Date',
      'RNs Picked Up',
      'Reservations Booked',
      'Revenue Picked Up',
      '',
    ]

    const workbook = new Excel.Workbook()
    workbook.creator = 'HMP'
    workbook.lastModifiedBy = 'HMP'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    const worksheet = workbook.addWorksheet(sheetname, {
      properties: {
        tabColor: { argb: 'FFFFFF00' },
        showGridLines: false,
        defaultRowHeight: 20,
      },
      views: [{ state: 'normal', showGridLines: false }],
    })

    // COLUMN WIDTH
    worksheet.columns = [
      { key: 'C0', width: 7 },
      { key: 'C1', width: 28 },
      { key: 'C2', width: 11 },
      { key: 'C3', width: 11 },
      { key: 'C4', width: 11 },
      { key: 'C5', width: 11 },
      { key: 'C6', width: 11 },
      { key: 'C7', width: 11 },
      { key: 'C8', width: 11 },
      { key: 'C9', width: 11 },
      { key: 'C10', width: 11 },
      { key: 'C11', width: 11 },
      { key: 'C12', width: 11 },
      { key: 'C13', width: 11 },
    ]

    // TITLE ROW 0,1,2
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    const titleText = `${title} (currency in ${this.getCurrencyInfo().name})`
    const titleRow = worksheet.addRow([' ', titleText])
    worksheet.mergeCells('B4:O4')
    const titleCell = worksheet.getCell('B4')
    titleCell.font = {
      name: 'Calibri',
      family: 4,
      size: 12,
      bold: true,
    }
    titleCell.border = {
      bottom: { style: 'medium' },
    }
    titleCell.alignment = { horizontal: 'left', vertical: 'middle' }

    titleRow.height = 20
    if (logo) {
      const logoC = workbook.addImage({
        base64: 'data:image/png;base64,' + logo,
        extension: 'png',
      })
      worksheet.addImage(logoC, 'E2:E3')
    }

    worksheet.addRow([])

    const headerRow2 = worksheet.addRow([])
    headerRow2.height = 20

    headerRow2.getCell(2).value = 'Year of ' + yearTitle

    headerRow2.getCell(2).alignment = {
      horizontal: 'left',
      vertical: 'middle',
    }
    headerRow2.getCell(2).font = {
      name: 'Calibri',
      size: 12,
      bold: true,
      underline: 'single',
    }

    worksheet.addRow([])

    const headerRow1 = worksheet.addRow(headerRow1Columns)
    headerRow1.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    headerRow1.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    headerRow1.height = 18
    for (let index = 0; index < bobData.length; index++) {
      const r = bobData[index]
      const type = r.type
      const dataRowColumn = this.generateRoomnightRow(r)
      const rootvalueRow = worksheet.addRow(dataRowColumn)
      this.formatRoomnight(rootvalueRow, type, r.title)
      rootvalueRow.font = {
        name: 'Calibri',
        size: 10,
        bold: true,
      }
      rootvalueRow.eachCell((cell, number) => {
        if (number > 1) {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
          }
          if (number === 2) {
            cell.border = {
              right: { style: 'thin' },
              top: { style: 'thin' },
              bottom: { style: 'thin' },
            }
          }
        }
      })
    }

    worksheet.addRow([])
    worksheet.addRow([])

    // const weeklyRow = worksheet.addRow(weeklyColumns);

    worksheet.mergeCells(`C17:D17`)
    worksheet.mergeCells(`E17:F17`)
    worksheet.mergeCells(`G17:H17`)
    worksheet.mergeCells(`I17:J17`)
    worksheet.mergeCells(`K17:L17`)
    const weeklyHeaderRow = worksheet.getRow(17)
    weeklyHeaderRow.height = 18
    weeklyHeaderRow.getCell(2).value = weeklyColumns[1]
    weeklyHeaderRow.getCell(3).value = weeklyColumns[2]
    weeklyHeaderRow.getCell(5).value = weeklyColumns[3]
    weeklyHeaderRow.getCell(7).value = weeklyColumns[4]
    weeklyHeaderRow.getCell(9).value = weeklyColumns[5]
    weeklyHeaderRow.getCell(11).value = weeklyColumns[6]
    weeklyHeaderRow.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    weeklyHeaderRow.eachCell((cell, number) => {
      if (number > 1) {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'medium' },
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    let currentIndex = 18
    for (let index = 0; index < weeklyData.length; index++) {
      const r = weeklyData[index]
      worksheet.mergeCells(`C${currentIndex}:D${currentIndex}`)
      worksheet.mergeCells(`E${currentIndex}:F${currentIndex}`)
      worksheet.mergeCells(`G${currentIndex}:H${currentIndex}`)
      worksheet.mergeCells(`I${currentIndex}:J${currentIndex}`)
      worksheet.mergeCells(`K${currentIndex}:L${currentIndex}`)
      const weeklyDataRow = worksheet.getRow(currentIndex)
      const dataRowColumn = this.generateWeeklyRow(r)
      weeklyDataRow.getCell(2).value = dataRowColumn[1]
      weeklyDataRow.getCell(3).value = dataRowColumn[2]
      weeklyDataRow.getCell(5).value = dataRowColumn[3]
      weeklyDataRow.getCell(7).value = dataRowColumn[4]
      weeklyDataRow.getCell(9).value = dataRowColumn[5]
      weeklyDataRow.getCell(11).value = dataRowColumn[6]
      // const weeklyDataRow = worksheet.addRow(dataRowColumn);
      weeklyDataRow.font = {
        name: 'Calibri',
        size: 10,
        bold: true,
      }
      weeklyDataRow.eachCell((cell, number) => {
        if (number > 1) {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
          }
          if (number === 2) {
            cell.alignment = { horizontal: 'center', vertical: 'middle' }
            cell.border = {
              right: { style: 'thin' },
              top: { style: 'thin' },
              bottom: { style: 'thin' },
            }
          }
          if (number === 5 || number === 3) {
            cell.alignment = { horizontal: 'center', vertical: 'middle' }
          }
          if (number === 11) {
            cell.numFmt = '#,##0.00'
          }
        }
      })
      currentIndex += 1
    }

    worksheet.addRow([])

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      fs.saveAs(blob, filename + '.xlsx')
    })
  }

  private generateRoomnightRow(data, prefixText?) {
    const monthValue = data.data
    const types = data.type.split('-')
    const row = []
    row.push('')
    if (prefixText) {
      row.push(prefixText + data.title)
    } else {
      row.push(data.title)
    }
    for (let index = 1; index <= 13; index++) {
      if (types[0] === 'percent') {
        const val = monthValue[index]
        if (val == 0) {
          row.push(val)
        } else {
          row.push(val / 100)
        }
      } else {
        row.push(monthValue[index])
      }
    }
    // row.push(monthValue['13']);
    return row
  }

  private generateWeeklyRow(data, prefixText?) {
    const row = []
    row.push('')
    row.push(data.week_number)
    const startDate = moment.unix(data.week_start).format('DD-MMM-YYYY')
    row.push(startDate)
    const endDate = moment.unix(data.week_end).format('DD-MMM-YYYY')
    row.push(endDate)
    row.push(data.rns_picked_up)
    row.push(data.reservations_booked)
    row.push(data.revenue_picked_up)
    return row
  }

  private formatRoomnight(dataRow, type, title: string) {
    const types = type.split('-')
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 1) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
      if (number > 2) {
        const val = cell.value
        switch (title.toLowerCase()) {
          case 'budget variance (%)':
            if (
              (val < 0 && val <= -0.1) ||
              (val < 0 && val >= -0.1) ||
              val >= 0
            ) {
              if (val < 0 && val <= -0.1) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'EE4540' },
                }
              }
              if (val < 0 && val >= -0.1) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'DB9B2D' },
                }
              }
              if (val >= 0) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '6EA842' },
                }
              }
            } else {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'EE4540' },
              }
            }
            cell.font = {
              name: 'Calibri',
              size: 10,
              bold: false,
              color: { argb: 'FFFFFFFF' },
            }
            break
          case 'yoy variance (%)':
            if (
              (val < 0 && val <= -0.1) ||
              (val < 0 && val >= -0.1) ||
              val >= 0
            ) {
              if (val < 0 && val <= -0.1) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'EE4540' },
                }
              }
              if (val < 0 && val >= -0.1) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'DB9B2D' },
                }
              }
              if (val >= 0) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '6EA842' },
                }
              }
            } else {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'EE4540' },
              }
            }
            cell.font = {
              name: 'Calibri',
              size: 10,
              bold: false,
              color: { argb: 'FFFFFFFF' },
            }
            break
          case 'budgeted':
          case 'last year':
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD3EFBE' },
            }
            cell.font = {
              name: 'Calibri',
              size: 10,
              bold: false,
              color: { argb: 'FFFFFFFF' },
            }
            break
          case 'next year':
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD3EFBE' },
            }
            cell.font = {
              name: 'Calibri',
              size: 10,
              bold: false,
              color: { argb: 'FFFFFFFF' },
            }
            break
          case 'paid occ (%)':
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFFFF' },
            }
            break
          case 'on the books':
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFFFF' },
            }
            break
          default:
            break
        }
      }
    })
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(3).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(4).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(4).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(5).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(6).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(7).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(8).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(9).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(10).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(11).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(12).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(13).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(14).numFmt = this.getCellFormat(types[0] === 'percent')
    dataRow.getCell(15).numFmt = this.getCellFormat(types[0] === 'percent')
    return dataRow
  }

  // tslint:disable-next-line: typedef
  private getCellFormat(isPercent = true) {
    if (isPercent) {
      return '0.0%'
    }
    return '#,##0.00'
  }

  // tslint:disable-next-line: typedef
  private generateRootRow(worksheet, r, column) {
    const dataRow = worksheet.addRow(column)
    dataRow.height = 18
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(2).font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    }
    dataRow.getCell(3).border = {
      right: { style: 'thin' },
    }
    return dataRow
  }

  private formatRowDefaultValue(dataRow, r) {
    dataRow.font = {
      name: 'Calibri',
      size: 10,
      bold: false,
    }
    dataRow.eachCell((cell, number) => {
      if (number > 2) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
      }
    })
    dataRow.height = 15
    dataRow.alignment = { horizontal: 'right', vertical: 'middle' }
    dataRow.getCell(2).alignment = { horizontal: 'left', vertical: 'middle' }
    dataRow.getCell(3).numFmt = this.getCellFormat(r)
    dataRow.getCell(3).border = {
      right: { style: 'thin' },
    }
    dataRow.getCell(4).numFmt = this.getCellFormat(r)
    dataRow.getCell(5).numFmt = this.getCellFormat(r)
    dataRow.getCell(6).numFmt = this.getCellFormat(r)
    dataRow.getCell(7).numFmt = this.getCellFormat(r)
    dataRow.getCell(8).numFmt = this.getCellFormat(r)
    dataRow.getCell(9).numFmt = this.getCellFormat(r)
    dataRow.getCell(10).numFmt = this.getCellFormat(r)
    dataRow.getCell(11).numFmt = this.getCellFormat(r)
    dataRow.getCell(12).numFmt = this.getCellFormat(r)
    dataRow.getCell(13).numFmt = this.getCellFormat(r)
    dataRow.getCell(14).numFmt = this.getCellFormat(r)
    dataRow.getCell(15).numFmt = this.getCellFormat(r)
    return dataRow
  }

  // tslint:disable-next-line: typedef
  private generateBoBRootColumnArr(data) {
    const row = []
    row.push('')
    row.push(data.title)
    row.push('')
    row.push('')
    return row
  }

  private generateBoBRowValueColumnArr(data, prefixText?) {
    const monthValue = data.monthvalues
    const row = []
    row.push('')
    if (prefixText) {
      row.push(prefixText + data.title)
    } else {
      row.push(data.title)
    }
    row.push(monthValue['ytd'])
    for (let index = 1; index <= 12; index++) {
      const keyVal = index.toString()
      const keyName = this.getKeyByMonthNumber(keyVal)
      row.push(monthValue[keyName])
    }
    return row
  }

  private getKeyByMonthNumber(m) {
    switch (m) {
      case '1':
        return 'jan'
      case '2':
        return 'feb'
      case '3':
        return 'mar'
      case '4':
        return 'apr'
      case '5':
        return 'may'
      case '6':
        return 'jun'
      case '7':
        return 'jul'
      case '8':
        return 'aug'
      case '9':
        return 'sep'
      case '10':
        return 'oct'
      case '11':
        return 'nov'
      case '12':
        return 'dec'
      default:
        return 'jan'
    }
  }
}
