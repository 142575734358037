export class IToast {
  type: IToastType
  message: IToastMessage
  keepAfterRouteChange: boolean
}

export enum IToastType {
  Success,
  Add_Success,
  Edit_Success,
  Delete_Success,
  Access_Denied,
  Error,
  Info,
  Warning,
}

export enum IToastMessage {
  Success = 'Success!',
  Add_Success = 'Add New Success!',
  Edit_Success = 'Edit Success!',
  Delete_Success = 'Delete Success!',
  Access_Denied = 'Access denied!',
  Error = 'Oops, something went wrong.',
  Info = 'Here is some more info...',
  Warning = 'This is a warning.',
}
