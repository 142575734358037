import { Injectable, Injector } from '@angular/core'
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, BehaviorSubject, throwError } from 'rxjs'
import { JwtService, UserService, AuthService } from '../services'
import { Logger } from '../logger.service'
import { catchError, switchMap, take, filter } from 'rxjs/operators'
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service'
import { Router } from '@angular/router'
const log = new Logger('HttpOverrideInterceptor')

export const InterceptorSkipHeader = 'X-Skip-Interceptor'

@Injectable({ providedIn: 'root' })
export class HttpOverrideInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  )
  constructor(
    private injector: Injector,
    private router: Router,
    private fuseProgress: FuseProgressBarService,
    private auth: AuthService
  ) {}

  // tslint:disable-next-line: typedef
  private setRequest(req) {
    let headers = {
      // 'Content-Type': 'application/json',
      Accept: '*/*',
    }
    if (req.headers.has('Skip-Prefix')) {
      // tslint:disable-next-line: no-shadowed-variable
      const headers = req.headers.delete('Skip-Prefix')
      return req.clone({ setHeaders: headers })
    }
    const jwtService = this.injector.get(JwtService)
    const currentUser = jwtService.getToken()
    if (currentUser) {
      headers['Authorization'] = `Bearer ${currentUser}`
    }
    // if (req.headers.has('Skip-Content-Type')) {
    //   headers = req.headers.delete('Skip-Content-Type')
    //   console.error(headers);
    //   delete headers['Content-Type'];
    // }
    return req.clone({ setHeaders: headers })
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    if (req.headers.has('Skip-Prefix')) {
      return next.handle(this.setRequest(req))
    }
    return next.handle(this.setRequest(req)).pipe(
      catchError(err => {
        const errStatus = err && err.status
        // tslint:disable-next-line: triple-equals
        if (
          errStatus instanceof HttpErrorResponse &&
          errStatus.status === 401
        ) {
          return this.handle401Error(req, next)
        }
        // if (errStatus === 403) {
        //   this.router.navigate(['/denied'])
        //   return throwError(err)
        // }
        return throwError(err)
      })
    )
  }

  // tslint:disable-next-line: typedef
  handle401Error(_req: HttpRequest<any>, _next: HttpHandler) {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => _next.handle(this.setRequest(_req)))
      )
    } else {
      this.refreshTokenInProgress = true
      this.refreshTokenSubject.next(null)
      this.showLoading()
      const userService = this.injector.get(UserService)

      const jwtService = this.injector.get(JwtService)
      return userService.getRefreshToken().pipe(
        switchMap(t => {
          jwtService.saveToken(t)
          this.refreshTokenInProgress = false // Set refreshTokenInProgress to False
          this.refreshTokenSubject.next(t) // Add token to the refreshTokenSubject
          // userService.userProfile().subscribe();
          // return _next.handle(this.setRequest(_req));
          return this.loadingNewProfile(userService, _next, _req)
        }),
        catchError(err => {
          this.refreshTokenInProgress = false
          this.auth.destroyAuth()
          this.router.navigate(['/login'])
          return throwError(err)
        })
      )
    }
  }

  async loadingNewProfile(userService, _next, _req) {
    return userService.userProfile().subscribe(
      data => {
        log.debug('userService: ', data)
        this.hideLoading()
        return _next.handle(this.setRequest(_req))
      },
      err => {
        log.debug('SUPER BUGS: ', err)
        this.auth.destroyAuth()
        this.router.navigate(['/login'])
        this.hideLoading()
        return _next.handle(this.setRequest(_req))
      }
    )
  }

  showLoading() {
    this.fuseProgress.setMode('determinate')
    this.fuseProgress.show()
  }

  /**
   * Ẩn loading
   */
  hideLoading() {
    this.fuseProgress.hide()
  }
}
