import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Resolve,
} from '@angular/router'
import { Observable } from 'rxjs'
import { AssettypesService } from '../../services'
import { Logger } from '../../logger.service'
import { map, catchError } from 'rxjs/operators'
import { Assettypes } from '../../models'

const log = new Logger('AssetTypeDetailResolver')
@Injectable({ providedIn: 'root' })
export class AssetTypeDetailResolver
  implements Resolve<Observable<Assettypes>> {
  constructor(
    private assettypesService: AssettypesService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let mode = route.params['mode']
    let atid = route.params['atid']
    
    return this.assettypesService.getDetail(atid).pipe(
      map(data => {
        log.debug('Data: ', data)
        return data
      }),
      catchError(err => this.router.navigate(['assettype']))
    )
  }
}
