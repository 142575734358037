export class ExportPDF {
  constructor() {}
  static exportToPdf(tableId: string, name?: string) {
    let printContents, popupWin
    printContents = document.getElementById(tableId).innerHTML
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto')
    popupWin.document.open()
    popupWin.document.write(`
            <html>
                <head>
                <title>Print</title>
                <style>
                    body{
                    font-family: 'Roboto', 'Muli', 'Helvetica Neue', 'Arial', sans-serif !important;
                    .item-other-info:nth-child(2n + 1) {
                      margin-right: 14px;
                    }
                    .item-other-info:nth-child(2n + 2) {
                      padding-left: 0;
                    }
                  }
                </style>
                </head>
            <body onload="window.print();window.close()"><table class="table table-bordered">${printContents}</table></body>
            </html>`)
    popupWin.document.close()
  }
}
