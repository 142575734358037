import { Injectable } from '@angular/core'
import { Logger } from '../../../logger.service'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Payroll } from '../../../models'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'

const log = new Logger('PayrollService')

/**
 * Đây là services dành cho Payroll
 */

@Injectable({ providedIn: 'root' })
export class PayrollService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) {}
  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'payroll/payroll_entity',
    detail: 'payroll/payroll_entity',
    create_multi_payroll: 'payroll/payroll_entity/create_multi_payroll',
  }

  /**
   * Lấy tất cả Payroll đang available
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAll(params?: any): Observable<any> {
    // let pa = new HttpParams();
    let pa = new HttpParams().set(
      'parameters[payroll_business_id]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Payroll dưạ vào @id
   * @param Id ID của Payroll
   */
  getDetail(id: string): Observable<Payroll> {
    return this.apiService.get<Payroll>(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Tạo Payroll
   * @param res Thông tin Payroll
   */
  create(res: Payroll): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.detail, res).pipe(
      map(data => {
        return data
      })
    )
  }

  createMulti(res: Payroll): Observable<any> {
    res.pid = null
    return this.apiService.post(this._apiPath.create_multi_payroll, res).pipe(
      map(data => {
        return data
      })
    )
  }
  /**
   * Chỉnh sửa thông tin Payroll
   *
   * @param res Thông tin Payroll muốn chỉnh sửa
   */
  edit(res: Payroll): Observable<any> {
    return this.apiService.put(`${this._apiPath.detail}/${res.pid}`, res).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Xóa thông tin Payroll
   *
   * @param id Xóa Payroll bằng Payroll ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.detail}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
