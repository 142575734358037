import { Injectable } from '@angular/core'
import { ExtraAmenities } from '../../../models'
import { ApiService } from '../../general/api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Logger } from '../../../logger.service'
import { HttpParams } from '@angular/common/http'
import { LocalStorageService } from '../../../helper'
const log = new Logger('ExtraAmenitiesService')

/**
 * Đây là service xử lý về Extra Charges
 */
@Injectable({ providedIn: 'root' })
export class ExtraAmenitiesService {
  constructor(
    private apiService: ApiService,
    private storage: LocalStorageService
  ) { }

  /**
   * Khai báo tất cả các path sẻ sử dụng cho User
   */
  private _apiPath = {
    list: 'room/extra_amenities_entity',
    detail: 'room/extra_amenities_entity',
    create: 'room/extra_amenities_entity',
    delete: 'room/extra_amenities_entity',
  }

  /**
   * Lấy tất cả Extra Charges
   * @param params Danh sách tham số muốn truyền.
   * ex: { 'pagesize': 10, 'page':1 }
   */
  getAllExtra(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[extra_amenities_business]',
      this.storage.getActiveLocalBusinessID()
    )
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }
  getAllExtraRes(params?: any): Observable<any> {
    let pa = new HttpParams().set(
      'parameters[extra_amenities_business]',
      this.storage.getActiveLocalBusinessID()
    ).set('select_box', 'true')
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key])
      })
    }

    return this.apiService.get<any>(this._apiPath.list, pa).pipe(
      map(data => {
        return data
      })
    )
  }

  /**
   * Lấy thông tin chi tiết của Extra Charges dưạ vào @id
   * @param id ID của Extra Charges
   */
  detail(id: string): Observable<ExtraAmenities> {
    return this.apiService
      .get<ExtraAmenities>(`${this._apiPath.detail}/${id}`)
      .pipe(
        map(data => {
          return data
        })
      )
  }

  /**
   *
   * @param Thông tin Extra Charges
   */
  create(extra: ExtraAmenities): Observable<any> {
    extra.eid = null
    return this.apiService.post(this._apiPath.delete, extra).pipe(
      map(data => {
      })
    )
  }

  /**
   * Chỉnh sửa thông tin Extra Charges
   *
   * @param extra Thông tin Extra Charges muốn chỉnh sửa
   */
  edit(extra: ExtraAmenities): Observable<any> {
    return this.apiService
      .put(`${this._apiPath.create}/${extra.eid}`, extra)
      .pipe(
        map(data => {
        })
      )
  }

  /**
   * Xóa thông tin Extra Charges
   *
   * @param rid Xóa Extra Charges bằng Extra Charges ID
   */
  delete(id): Observable<any> {
    return this.apiService.delete(`${this._apiPath.delete}/${id}`).pipe(
      map(data => {
        return data
      })
    )
  }
}
